@charset "UTF-8";
/*
	button
*/
html,
body,
div,
span,
object,
iframe,
h1,
h2,
h3,
h4,
h5,
h6,
p,
p,
blockquote,
pre,
abbr,
address,
cite,
code,
del,
dfn,
em,
img,
ins,
kbd,
q,
samp,
small,
strong,
sub,
sup,
var,
b,
i,
dl,
dt,
dd,
ol,
ul,
li,
fieldset,
form,
label,
legend,
table,
caption,
tbody,
tfoot,
thead,
tr,
th,
td,
article,
aside,
canvas,
details,
figcaption,
figure,
footer,
header,
hgroup,
menu,
nav,
section,
summary,
time,
mark,
audio,
video,
a {
		margin: 0;
		padding: 0;
		border: 0;
		outline: 0;
		vertical-align: baseline;
		background: transparent;
		box-sizing: border-box;
		user-select: none;
}

h1,
h2,
h3,
h4,
h5,
h6 {
		font-size: 100%;
		font-weight: normal;
		box-sizing: border-box;
}

html {
		height: 100%;
}

html,
body {
		height: 100%;
		font-size: 14px;
		font-family: "NotoSansKR", sans-serif;
		font-weight: 300;
		line-height: 28px;
}

body {
		color: #494949;
		background-color: #fff;
}

article,
aside,
details,
figcaption,
figure,
footer,
header,
hgroup,
main,
nav,
section,
summary {
		display: block;
}

button,
input,
optgroup,
select,
textarea {
		font-size: 100%;
		box-sizing: border-box;
		margin: 0;
		appearance: none;
		font-size: 14px;
		font-family: "NotoSansKR", sans-serif;
		font-weight: 300;
}

input,
select,
button,
select {
		vertical-align: middle;
}

address,
caption,
cite,
code,
dfn {
		font-weight: normal;
		font-style: normal;
}

code,
pre {
		-moz-osx-font-smoothing: auto;
		-webkit-font-smoothing: auto;
}

button,
select {
		outline: 0;
		border: 0;
		border-radius: 0;
		margin: 0;
		padding: 0;
		cursor: pointer;
		background-color: transparent;
		appearance: none;
}

img {
		outline: 0;
		border: 0;
		vertical-align: top;
}

img,
video {
		height: auto;
		max-width: 100%;
}

blockquote,
q {
		quotes: none;
}
blockquote::before, blockquote::after,
q::before,
q::after {
		content: "";
}

ol,
ul {
		list-style: none;
}

fieldset {
		border: 0;
		outline: 0;
}

button.btn-txt {
		position: relative;
}

textarea {
		color: #1c1c1b;
}
textarea:focus {
		outline: 0;
}

a {
		background-color: transparent;
		color: inherit;
		text-decoration: none;
}
a:link, a:visited, a:hover, a:active {
		text-decoration: none;
}
a:hover {
		color: inherit;
}

select {
		appearance: none;
}
select::-ms-expand {
		display: none;
}

input[type=checkbox],
input[type=radio] {
		vertical-align: baseline;
}

input[type=text],
input[type=password],
textarea {
		color: #494949;
}
input[type=text], input[type=text]::placeholder,
input[type=password],
input[type=password]::placeholder,
textarea,
textarea::placeholder {
		font-size: 14px;
		font-weight: 300;
		line-height: 2.1428571429;
		color: #a4a4a4;
}

input,
textarea {
		max-width: 100%;
}

iframe {
		border: 0;
}

table {
		border-collapse: collapse;
		border-spacing: 0;
}

td,
th {
		padding: 0;
}
td:not([align]),
th:not([align]) {
		text-align: inherit;
}

em {
		font-style: normal;
}

/*
	button
*/
/*
	button
*/
.buttons {
		display: flex;
}

button.co-btn,
a.co-btn,
.co-btn {
		padding: 6px 20px;
		min-width: 80px;
		border-radius: 12px;
		text-align: center;
		color: #fff;
		transition: opacity 0.3s;
		background-color: #253981;
		display: inline-block;
		vertical-align: top;
		cursor: pointer;
		min-height: 44px;
		font-size: 14px;
		font-weight: 500;
		line-height: 2.1428571429;
		position: relative;
		overflow: hidden;
}
button.co-btn:after,
a.co-btn:after,
.co-btn:after {
		background-image: linear-gradient(to bottom, rgba(255, 255, 255, 0) 0, rgba(255, 255, 255, 0.493) 50%, rgba(255, 255, 255, 0) 100%);
		content: "";
		height: 200%;
		left: -50%;
		opacity: 0.2;
		position: absolute;
		top: 50%;
		transform: rotate(30deg) translateY(-50%);
		width: 20px;
		z-index: -10;
}
button.co-btn:hover,
a.co-btn:hover,
.co-btn:hover {
		opacity: 0.8;
}
button.co-btn > span,
a.co-btn > span,
.co-btn > span {
		position: relative;
		text-align: center;
}
button.co-btn + .co-btn,
a.co-btn + .co-btn,
.co-btn + .co-btn {
		margin-left: 16px;
}
button.co-btn.is-outline-blue-1,
a.co-btn.is-outline-blue-1,
.co-btn.is-outline-blue-1 {
		border-width: 1px;
		border-style: solid;
		background-color: #fff;
		border-color: #2f7ad3;
		color: #2f7ad3;
}
button.co-btn.is-outline-blue-1:hover,
a.co-btn.is-outline-blue-1:hover,
.co-btn.is-outline-blue-1:hover {
		opacity: 0.4;
}
button.co-btn.is-outline-navy-1,
a.co-btn.is-outline-navy-1,
.co-btn.is-outline-navy-1 {
		border-width: 1px;
		border-style: solid;
		background-color: #fff;
		border-color: #253981;
		color: #253981;
}
button.co-btn.is-outline-navy-1:hover,
a.co-btn.is-outline-navy-1:hover,
.co-btn.is-outline-navy-1:hover {
		opacity: 0.4;
}
button.co-btn.is-outline-black-op4,
a.co-btn.is-outline-black-op4,
.co-btn.is-outline-black-op4 {
		border-width: 1px;
		border-style: solid;
		background-color: #fff;
		border-color: rgba(28, 28, 27, 0.4);
		color: rgba(28, 28, 27, 0.4);
}
button.co-btn.is-outline-black-op4:hover,
a.co-btn.is-outline-black-op4:hover,
.co-btn.is-outline-black-op4:hover {
		opacity: 0.4;
}
button.co-btn.is-outline-blue-1.type02,
a.co-btn.is-outline-blue-1.type02,
.co-btn.is-outline-blue-1.type02 {
		background-color: #f5f5f9;
}
button.co-btn.w-full,
a.co-btn.w-full,
.co-btn.w-full {
		width: 100%;
}

button:disabled {
		opacity: 0.4;
		cursor: not-allowed;
}
button:disabled:active, button:disabled:hover {
		opacity: 0.4;
}
button > span {
		position: relative;
}

.c-arrow-btn {
		width: 20px;
		height: 20px;
		border-radius: 4px;
		background: url("../images/ico/ico_arrow_08.svg") #253981 center 6px/6px auto no-repeat;
}
.c-arrow-btn.left {
		background-position-y: 5px;
		transform: rotate(-180deg);
}

.c-plus-btn-1 {
		background: url("../images/ico/ico_plus_01.svg") center/100% auto no-repeat;
		width: 28px;
		height: 28px;
		display: inline-block;
}

.co-btn-field {
		display: flex;
		align-items: center;
		justify-content: flex-end;
}

st .c-panel {
		margin-left: auto;
		margin-right: auto;
}
st .c-panel + .c-panel {
		margin-top: 60px;
}
st .c-panel.top_head {
		border-bottom: 1px solid #e9e9e9;
}
st .c-panel.top_head .c-title-field {
		padding-bottom: 6px;
}
st .c-panel.top_head + .c-panel {
		margin-top: 40px;
}
st .c-panel.tab-panel + .c-panel {
		margin-top: 40px;
}

/*
		[c-article]
*/
.c-article {
		border: 1px solid #e9e9e9;
		background-color: #fff;
		border-radius: 8px;
		padding-top: 24px;
		padding-bottom: 24px;
		padding-right: 40px;
		padding-left: 40px;
		margin-top: 12px;
		margin-bottom: 12px;
}

.input-list [class*=col] {
		padding-top: 16px;
		padding-bottom: 16px;
}
.input-list .row {
		margin-left: -12px;
		margin-right: -12px;
}
.input-list .row > [class*=col-] {
		padding-left: 12px;
		padding-right: 12px;
		display: flex;
		flex-direction: column;
}

.c-title-field + .tab-panel {
		margin-top: 12px;
}

/*
	= Field
*/
.c-field ~ .c-field {
		margin-top: 20px;
}
.c-field .c-input-search {
		display: inline-block;
		width: auto;
}
.c-field .c-input-search input[type=text] {
		width: 240px;
		flex: 0 0 auto;
}

.c-field,
.c-title-field,
.c-btn-field {
		display: flex;
		justify-content: space-between;
		align-items: center;
}

.c-breadcrumb + .c-breadcrumb {
		margin-top: 12px;
}

.c-field .c-select select {
		min-width: 120px;
}
.c-field .c-select + .c-select {
		margin-left: 8px;
}

.c-field + .c-calendar-container {
		margin-top: 12px;
}

/*
  = Row Common
*/
.row.inner {
		margin-left: 0;
		margin-right: 0;
}
.row.inner [class*=col] {
		padding-top: 0;
		padding-bottom: 0;
}

.row {
		display: flex;
		flex-wrap: wrap;
		flex: 1 1 auto;
		margin-left: -10px;
		margin-right: -10px;
}

/*
  = Column Common
*/
.col {
		padding-left: 8px;
		padding-right: 8px;
		max-width: 100%;
		flex-basis: 0;
		flex: 1;
}

.col-1 {
		flex: 0 0 8.333%;
		max-width: 8.333%;
}

.col-2 {
		flex: 0 0 16.666%;
		max-width: 16.666%;
}

.col-3 {
		flex: 0 0 25%;
		max-width: 25%;
}

.col-4 {
		flex: 0 0 33.333%;
		max-width: 33.333%;
}

.col-5 {
		flex: 0 0 41.666%;
		max-width: 41.666%;
}

.col-6 {
		flex: 0 0 50%;
		max-width: 50%;
}

.col-7 {
		flex: 0 0 58.333%;
		max-width: 58.333%;
}

.col-8 {
		flex: 0 0 66.666%;
		max-width: 66.666%;
}

.col-9 {
		flex: 0 0 75%;
		max-width: 75%;
}

.col-10 {
		flex: 0 0 83.333%;
		max-width: 83.333%;
}

.col-11 {
		flex: 0 0 91.7%;
		max-width: 91.7%;
}

.col,
.col-12 {
		max-width: 100%;
		flex-basis: 0;
		flex: 1;
}

.flex-d-column {
		flex-direction: column;
}
.flex-d-column .input-list {
		align-items: stretch;
}

.align-stretch {
		align-items: stretch;
}

.inner-row {
		width: 100%;
		flex: 1 1 auto;
}

.justify-c-sb {
		justify-content: space-between;
}

.c-article .list-row {
		display: flex;
		flex-wrap: wrap;
		width: 100%;
		margin-left: -6px;
		margin-right: -6px;
}
.c-article .list-row .col {
		padding-left: 6px;
		padding-right: 6px;
}
.c-article .list-row .col.title {
		width: 180px;
		flex: 0 0 180px;
		font-size: 12px;
		color: #777676;
}
.c-article .list-row .col.cont {
		width: auto;
		line-height: 2.14;
}
.c-article .list-row .inner-row > .col {
		flex: 0 0 180px;
}

.row.number .col-4 {
		padding-left: 10px;
		padding-right: 10px;
}

/*
	[Artcle - Content]
*/
.c-article.c-contents .c-content-field padding {
		top: 16px;
		bottom: 16px;
}
.c-article.c-contents .c-cont-list {
		margin-top: 12px;
		line-height: 1.7;
}
.c-article.c-contents .c-cont-list > li {
		position: relative;
		padding-left: 10px;
}
.c-article.c-contents .c-cont-list > li:before {
		position: absolute;
		left: 0;
		top: 0;
		display: inline-block;
}
.c-article.c-contents .c-cont-list.asterisk > li:before {
		content: "*";
}
.c-article.c-contents .c-cont-list.disc > li {
		list-style-type: disc;
		left: 20px;
		padding-left: 0;
		padding-right: 20px;
}
.c-article.c-contents .c-cont-list.number > li {
		list-style-type: decimal;
		left: 20px;
		padding-left: 0;
		padding-right: 20px;
}
.c-article.c-contents.type01 .title {
		font-size: 12px;
		color: #a4a4a4;
}
.c-article.c-contents.type02 {
		border-color: #f3f3f3;
		background-color: #f3f3f3;
}
.c-article.c-contents.type02 .cont-box {
		color: #777676;
		line-height: 1.6;
}

.c-layout-type01 {
		margin-top: 0 !important;
		padding-top: 40px;
}
.c-layout-type01 > .row {
		margin-left: -10px;
		margin-right: -10px;
}
.c-layout-type01 > .row > .col {
		padding-left: 10px;
		padding-right: 10px;
}
.c-layout-type01 .right-wrap {
		position: relative;
}
.c-layout-type01 .c-floating-box {
		position: absolute;
		top: 0;
		left: 0;
		right: 0;
}

.row.col-full-height {
		display: flex;
		flex: 1 1 auto;
		margin-left: -20px;
		margin-right: -20px;
}
.row.col-full-height [class*=column] {
		display: flex;
		flex-direction: column;
		flex: 1 1 auto;
		width: 50%;
		padding-left: 20px;
		padding-right: 20px;
}
.row.col-full-height .inner-full-cont {
		flex: 1 1 auto;
		height: 100%;
}
.row.col-full-height .co-cont-article {
		flex: 1 1 auto;
		height: 100%;
}

.co-article ~ .co-article {
		margin-top: 60px;
}
.co-article.type02 ~ .co-article.type02 {
		margin-top: 40px;
}

.row {
		margin-left: -8px;
		margin-right: -8px;
}
.row [class|=col] {
		padding-left: 8px;
		padding-right: 8px;
}
.row.type02 {
		margin-left: -20px;
		margin-right: -20px;
}
.row.type02 > [class|=col] {
		padding-left: 20px;
		padding-right: 20px;
}
.row.type03 {
		margin-left: -30px;
		margin-right: -30px;
}
.row.type03 > [class|=col] {
		padding-left: 30px;
		padding-right: 30px;
}

.co-cont-list.type01 .co-cont-article ~ .co-cont-article {
		margin-top: 12px;
}

.c-layer-popup {
		position: fixed;
		top: 0;
		left: 0;
		width: 100%;
		height: 100%;
		overflow: hidden;
		outline: 0;
		background: rgba(0, 0, 0, 0.6);
		visibility: hidden;
		opacity: 0;
		z-index: -1;
		display: flex;
		justify-content: center;
		align-items: center;
		transition: opacity 0s ease-in-out;
}
.c-layer-popup .buttons {
		justify-content: flex-end;
}
.c-layer-popup .buttons .c-btn {
		min-width: 80px;
}
.c-layer-popup .c-tab-field .c-tab-list > li a {
		background-color: #fff;
}
.c-layer-popup .c-article {
		background-color: #fcfcfc;
}
.c-layer-popup.show {
		visibility: visible;
		z-index: 2000;
		opacity: 1;
}
.c-layer-popup.show .modal-field {
		top: 0;
		opacity: 1;
		transition: all 0.2s ease-in-out;
}

.modal-wrap {
		display: flex;
		align-items: center;
		max-height: calc(100% - 80px);
		flex-direction: column;
		justify-content: center;
		height: 100%;
}
.modal-wrap::before {
		content: none;
}

.modal-field {
		position: relative;
		width: 100%;
		border: 1px solid #e9e9e9;
		box-shadow: 4px 4px 4px rgba(0, 0, 0, 0.25);
		border-radius: 12px;
		background-color: #fff;
		z-index: 2001;
		overflow: hidden;
		color: #494949;
		display: flex;
		flex-direction: column;
		justify-content: center;
		top: -100px;
		opacity: 0;
		transition: all 0.3s ease-out;
		max-height: none;
}

.c-modal-body.co-modal-scroll {
		overflow-x: auto;
		margin-bottom: 10px;
}
.c-modal-body.co-modal-scroll .c-modal-inner {
		padding-bottom: 0;
}
.c-modal-body.co-modal-scroll .co-table-field {
		overflow: initial;
}

.c-modal-header {
		display: flex;
		align-items: flex-start;
		justify-content: space-between;
		position: relative;
		border: 0;
		border-radius: 0;
		flex-shrink: 0;
		padding: 32px 40px 20px;
}
.c-modal-header .c-modal-title-field {
		width: 100%;
}
.c-modal-header .c-modal-title-field .c-title {
		margin-right: 40px;
		text-align: left;
		overflow: hidden;
		text-overflow: ellipsis;
		white-space: nowrap;
		word-wrap: break-word;
		font-size: 24px;
		font-weight: 700;
		line-height: 1.5;
		color: #253981;
}
.c-modal-header .popup-cls-btn {
		position: absolute;
		top: 32px;
		right: 40px;
		z-index: 10;
		background: url("../images/ico/ico_close_01.svg") center/28px no-repeat;
		width: 28px;
		height: 28px;
		opacity: 1;
		transition: opacity 0.4s;
}
.c-modal-header .popup-cls-btn:hover {
		transition: opacity 0.4s;
		opacity: 0.6;
}

.c-modal-body {
		margin: 0 40px;
		position: relative;
		overflow-x: hidden;
		overflow-y: auto;
}
.c-modal-body::-webkit-scrollbar {
		width: 8px;
		height: 8px;
}
.c-modal-body::-webkit-scrollbar-track {
		background-color: #fff;
}
.c-modal-body::-webkit-scrollbar-thumb {
		background-color: #fff;
		border-radius: 3px;
		transition: background-color 1s;
}
.c-modal-body:hover {
		transition: background-color 1s;
}
.c-modal-body:hover::-webkit-scrollbar-thumb {
		background-color: #e9e9e9;
}
.c-modal-body .cont-box {
		color: #777676;
}
.c-modal-body .cont-box .title {
		font-size: 16px;
		font-weight: 500;
		line-height: 2;
		color: #1c1c1b;
}
.c-modal-body .cont-box .cont {
		color: #494949;
		font-size: 14px;
		font-weight: 300;
		line-height: 2.1428571429;
}
.c-modal-body .cont-box + .co-table-field {
		margin-top: 20px;
}
.c-modal-body .c-modal-inner {
		padding-bottom: 20px;
}
.c-modal-body .c-sub-title {
		font-size: 20px;
		font-weight: 500;
		line-height: 1.8;
		color: #253981;
}
.c-modal-body .c-modal-middle {
		margin-bottom: 30px;
}

.c-modal-footer {
		padding: 0 40px 32px;
		flex-shrink: 0;
}
.c-modal-footer .inner {
		align-items: center;
		padding-top: 20px;
		border-top: 1px solid #e9e9e9;
		display: flex;
		width: 100%;
		justify-content: flex-end;
		flex-shrink: 0;
}
.c-modal-footer .buttons .co-btn {
		width: auto;
		min-width: 80px;
		border-radius: 12px;
		position: relative;
		overflow: hidden;
}
.c-modal-footer .buttons .co-btn:not(:disabled):after {
		background-image: linear-gradient(to bottom, rgba(255, 255, 255, 0) 0, rgba(255, 255, 255, 0.493) 50%, rgba(255, 255, 255, 0) 100%);
		content: "";
		height: 200%;
		left: -80%;
		opacity: 0.2;
		position: absolute;
		top: 50%;
		transform: rotate(30deg) translateY(-50%);
		width: 20px;
		z-index: -10;
}
.c-modal-footer .buttons .co-btn:not(:disabled):hover {
		opacity: 0.8;
}
.c-modal-footer .buttons .co-btn:not(:disabled):hover:after {
		animation: coBtnHoverAni 0.5s linear 1;
}
.c-modal-footer .ref-txt {
		color: rgba(28, 28, 27, 0.6);
}

.c-layer-popup.add-popup.show {
		z-index: 2002;
}

.c-layer-popup.type01 .modal-field {
		width: 360px;
}
.c-layer-popup.type01 .c-modal-body {
		margin-top: 32px;
		margin-bottom: 0;
		text-align: center;
}
.c-layer-popup.type01 .c-modal-body .c-modal-inner {
		padding-bottom: 0;
}
.c-layer-popup.type01 .c-modal-body .in-cont {
		font-size: 14px;
		font-weight: 300;
		line-height: 2.1428571429;
}
.c-layer-popup.type01 .c-modal-body .in-cont > p ~ p {
		margin-top: 12px;
}
.c-layer-popup.type01 .c-modal-footer .inner {
		border-top: none;
		justify-content: center;
}
.c-layer-popup.type01 .c-modal-footer .buttons {
		margin-left: -6px;
		margin-right: -6px;
		justify-content: center;
		width: 100%;
}
.c-layer-popup.type01 .c-modal-footer .buttons > button {
		width: 100%;
		padding-left: 6px;
		padding-right: 6px;
}

.c-layer-popup.type02 .modal-field {
		width: 480px;
}
.c-layer-popup.type02 .c-modal-inner {
		padding-bottom: 20px;
}

.c-layer-popup.type03 .c-modal-inner {
		padding-left: 20px;
		padding-right: 20px;
}
.c-layer-popup.type03 .modal-field {
		width: 720px;
}

.c-layer-popup.type04 .c-modal-inner {
		padding-left: 20px;
		padding-right: 20px;
}
.c-layer-popup.type04 .modal-field {
		width: 1180px;
}

.c-layer-popup.type05 .c-modal-inner {
		padding-left: 20px;
		padding-right: 20px;
}
.c-layer-popup.type05 .modal-wrap {
		padding: 0 40px;
		width: 100%;
}
.c-layer-popup.type05 .modal-field {
		width: 100%;
}

.c-layer-popup.type06 .c-modal-inner {
		padding-left: 20px;
		padding-right: 20px;
}
.c-layer-popup.type06 .modal-wrap {
		padding: 0 40px;
		width: 100%;
}
.c-layer-popup.type06 .modal-field {
		width: 1180px;
}

.c-layer-popup.surcharges .modal-field {
		width: 480px;
}
.c-layer-popup.surcharges .c-modal-body {
		padding-left: 20px;
		padding-right: 20px;
}

.c-layer-popup textarea[readonly=readonly] {
		background-color: #fcfcfc;
}

.c-input-group {
		min-height: 44px;
		display: flex;
		flex-direction: row;
		align-items: center;
}

.c-input {
		width: 100%;
}

label.title-md {
		color: #494949;
}

.label-input label,
.label-input .c-label-txt {
		margin-bottom: 8px;
		display: flex;
}
.label-input .c-select {
		width: 100%;
}
.label-input .co-btn {
		width: 100%;
}

.c-label-box label,
.c-label-box .c-label-txt {
		margin-bottom: 4px;
}
.c-label-box label + .sub-cont,
.c-label-box .c-label-txt + .sub-cont {
		color: #a4a4a4;
		font-size: 12px;
		font-weight: 400;
		line-height: 2;
}

.col-chk-btn-type .c-chkoption-box {
		margin-top: 16px;
}

.input-list.detail-list {
		padding: 20px 40px;
}
.input-list.detail-list .row {
		margin-left: -20px;
		margin-right: -20px;
}
.input-list.detail-list .row > [class*=col] {
		padding-left: 20px;
		padding-right: 20px;
}

.label-input.detail > label,
.label-input.detail .c-label-txt {
		margin-bottom: 0;
}
.label-input.detail .input-view {
		line-height: 1.7;
		color: #494949;
		border-bottom: 1px solid #e9e9e9;
}
.label-input.detail .input-view input[type=text],
.label-input.detail .input-view input[type=password] {
		overflow: hidden;
		text-overflow: ellipsis;
		white-space: nowrap;
		word-wrap: break-word;
		padding-left: 0;
		width: 100%;
		border: 0;
		border-radius: 0;
		cursor: default;
}
.label-input.detail .input-view input[type=text]:focus,
.label-input.detail .input-view input[type=password]:focus {
		transition: none;
		box-shadow: none;
}

.c-btn.input-btn {
		width: 100%;
		height: 100%;
		border-radius: 8px;
		padding-left: 0;
		padding-right: 0;
}
.c-btn.input-btn span {
		line-height: 44px;
		display: block;
}

.search-box {
		width: 230px;
}

.search-input {
		border-radius: 0;
		border: none;
		min-width: 186px;
}
.search-input:focus {
		transition: none;
		box-shadow: none;
		border-color: inherit;
}

.c-input-search {
		position: relative;
		display: flex;
		align-items: stretch;
		width: 100%;
		display: flex;
		align-items: center;
		position: relative;
}
.c-input-search input[type=text] {
		border: 1px solid #d2d2d1;
		border-radius: 8px;
		flex: 1 1 auto;
		padding: 6px 44px 6px 12px;
		width: 100%;
}
.c-input-search input[type=text]:focus {
		transition: all 0;
		border-color: #2f7ad3;
}
.c-input-search input[type=text][readonly=readonly]:focus {
		border-color: #d2d2d1;
		transition: none;
		box-shadow: none;
}
.c-input-search .btn-search {
		background: url("../images/ico/ico_search_01.svg") 0/20px no-repeat;
		position: absolute;
		right: 12px;
		top: 50%;
		transform: translateY(-50%);
		width: 20px;
		height: 20px;
		content: "";
		display: inline-block;
		overflow: hidden;
}
.c-input-search.input-view .btn-search {
		right: 0;
}

.input-group {
		position: relative;
		display: flex;
		align-items: stretch;
		width: 100%;
}
.input-group input {
		flex: 1 1 auto;
}

.is-nec {
		overflow: hidden;
		white-space: nowrap;
		display: inline-block;
		width: 8px;
		height: 10px;
}
.is-nec:before {
		content: "*";
		color: #ff6859;
		font-size: 12px;
		font-weight: 300;
}

/*
	= checkbox, Radio
*/
.c-chk-group {
		width: 100%;
		display: flex;
		min-height: 40px;
		align-items: center;
}
.c-chk-group .c-chk-list {
		margin-left: -10px;
		display: flex;
		align-items: center;
		width: 100%;
}
.c-chk-group .c-chk-list .c-chkbox ~ .c-chkbox, .c-chk-group .c-chk-list .c-chkbox ~ .c-rdochk, .c-chk-group .c-chk-list .c-chkbox ~ .c-multi-input-group,
.c-chk-group .c-chk-list .c-rdochk ~ .c-chkbox,
.c-chk-group .c-chk-list .c-rdochk ~ .c-rdochk,
.c-chk-group .c-chk-list .c-rdochk ~ .c-multi-input-group {
		margin-left: 12px;
}

.c-multi-input-group > .c-chkbox {
		margin-right: 8px;
}

.c-article.input-list .input-chk-wrap .c-chk-list {
		padding-bottom: 12px;
}

label + .c-m-input-list {
		margin-top: 12px;
}

.c-m-input-list {
		display: flex;
		align-items: center;
		flex: 1 1 auto;
		width: 100%;
		white-space: nowrap;
		display: flex;
}
.c-m-input-list .input-col {
		padding: 0 !important;
}
.c-m-input-list .input-col ~ .input-col {
		padding: 0;
		margin-left: 20px;
}
.c-m-input-list.row.inner {
		margin-left: 0;
		margin-right: 0;
}
.c-m-input-list.row.inner .c-chk-group {
		padding-left: 6px;
		padding-right: 6px;
}
.c-m-input-list.row.inner .c-multi-input-group {
		width: 100%;
}
.c-m-input-list.row.inner .c-multi-input-group input[type=text] {
		width: 100%;
}

.c-check-list > .row + .row {
		margin-top: 20px;
}

.c-multi-input-group {
		display: flex;
		align-items: center;
		flex-wrap: nowrap;
}
.c-multi-input-group .c-rdochk + input[type=text],
.c-multi-input-group .c-chkbox + input[type=text] {
		margin-left: 0.857rem;
}
.c-multi-input-group .input-date-group {
		margin-left: 0;
}
.c-multi-input-group .c-btn {
		margin-left: 1.714rem;
}
.c-multi-input-group .c-input {
		flex: 1 1 auto;
}

.label-input .c-input-group {
		min-height: 40px;
}

.chk-label > i,
.chk-label > span {
		display: table-cell;
		vertical-align: middle;
		cursor: pointer;
}

.chk-label > span {
		font-size: 14px;
		font-weight: 300;
		line-height: 2.1428571429;
		line-height: 1;
		color: #a4a4a4;
		font-weight: 300;
}

.c-chkbox.txt-type02 span,
.c-rdochk.txt-type02 span {
		font-size: 12px;
		font-weight: 300;
		line-height: 1.4166666667;
}

.c-rdochk input[type=radio] {
		opacity: 0;
		cursor: pointer;
}
.c-rdochk input[type=radio]:checked + .chk-label > span {
		color: #253981;
}
.c-rdochk input[type=radio]:checked + .chk-label i {
		background-position: -56px 0;
}
.c-rdochk input[type=radio]:checked:disabled + .chk-label i {
		cursor: default;
		background-position: -84px 0;
}
.c-rdochk input[type=radio]:disabled + .chk-label i {
		cursor: default;
		background-position: -28px 0;
}

/*
	= Date
*/
.c-multi-input-group .row.c-input-date-group {
		margin-left: 5px;
		flex: 1 1 auto;
}

.row.c-input-date-group {
		margin-left: -12px;
		margin-right: -12px;
		display: flex;
		justify-content: space-between;
}
.row.c-input-date-group [class*=col-] {
		padding-left: 12px;
		padding-right: 12px;
}
.row.c-input-date-group .input-email-01 {
		position: relative;
}
.row.c-input-date-group .input-email-01:after {
		content: "@";
		position: absolute;
		bottom: 10px;
		right: -7px;
		color: #a4a4a4;
		font-size: 14px;
		font-weight: 300;
		line-height: 1.7;
}
.row.c-input-date-group .input-email-02 > .row {
		margin-left: -6px;
		margin-right: -6px;
}
.row.c-input-date-group .input-email-02 > .row > .col-6 {
		padding-left: 6px;
		padding-right: 4px;
}

.c-inquiry-date-group {
		display: flex;
		margin-left: -6px;
		margin-right: -6px;
}
.c-inquiry-date-group .c-input-date,
.c-inquiry-date-group .buttons {
		padding-left: 6px;
		padding-right: 6px;
}
.c-inquiry-date-group > .col {
		flex-grow: 1;
		flex-shrink: 1;
		flex-basis: 100%;
}
.c-inquiry-date-group .buttons {
		flex-shrink: 0;
		flex-basis: auto;
		width: auto;
}
@supports (min-width: fit-content) {
		.c-inquiry-date-group .buttons {
				min-width: fit-content;
		}
}
.c-inquiry-date-group .buttons .co-btn {
		min-width: auto;
		font-size: 12px;
		font-weight: 400;
		line-height: 2;
}

.c-article.input-list .input-date-group [class*=col-] {
		padding-top: 0;
		padding-bottom: 0;
}

.c-input-unit {
		position: relative;
}
.c-input-unit input[type=text] {
		width: 100%;
		padding-right: 36px;
}
.c-input-unit input[type=text][readonly=readonly] {
		font-size: 16px;
		font-weight: 500;
		line-height: 2;
}
.c-input-unit .unit {
		position: absolute;
		top: 50%;
		transform: translateY(-50%);
		right: 12px;
		text-align: center;
		color: #a4a4a4;
		font-size: 16px;
		font-weight: 500;
		line-height: 2;
}
.c-input-unit.input-view input[type=text] {
		padding-right: 36px;
}

.in-input-row .c-input-unit-field {
		border: 1px solid #d2d2d1;
		background-color: #fff;
		border-radius: 12px;
		padding: 12px 20px;
}
.in-input-row .c-input-unit-field input[type=text] {
		border: 0;
		padding: 0;
		min-height: initial;
}
.in-input-row .chkup-info-detail {
		margin-top: 2px;
		margin-left: -2px;
}

.c-input-file-group {
		position: relative;
		width: 100%;
		height: 40px;
}
.c-input-file-group input.c-input-file,
.c-input-file-group .c-file-label {
		padding: 8px 12px 8px 107px;
		overflow: hidden;
		width: 100%;
		height: 100%;
		line-height: 44px;
		background-color: #fff;
}
.c-input-file-group input.c-input-file {
		position: relative;
		z-index: 2;
		opacity: 0;
}
.c-input-file-group label:focus {
		transition: all 0;
		border-color: #2f7ad3;
}
.c-input-file-group .c-file-label {
		border-radius: 8px;
		padding: 6px 12px;
		outline: 0;
		min-height: 44px;
		font-size: 14px;
		font-weight: 300;
		font-family: "NotoSansKR";
		margin-bottom: 0;
		position: absolute;
		top: 0;
		right: 0;
		left: 0;
		z-index: 1;
		height: 100%;
		font-size: 14px;
		font-weight: 300;
		display: inline-block;
		overflow: hidden;
		text-overflow: ellipsis;
		white-space: nowrap;
		word-wrap: break-word;
}
.c-input-file-group .c-file-label:after {
		position: absolute;
		left: -1px;
		right: auto;
		top: -1px;
		bottom: -1px;
		z-index: 3;
		display: block;
		padding: 7px 20px;
		line-height: 1.7;
		color: #494949;
		content: "파일 선택";
		background-color: #e9e9e9;
		border-left: inherit;
		overflow: hidden;
		border-radius: 0;
}

.c-input-file-list {
		margin-top: 12px;
}
.c-input-file-list > li {
		margin-top: 4px;
}
.c-input-file-list .file-info {
		display: flex;
		align-items: center;
}
.c-input-file-list .name {
		overflow: hidden;
		text-overflow: ellipsis;
		white-space: nowrap;
		word-wrap: break-word;
		flex-grow: 1;
}
.c-input-file-list .btn-del {
		margin-left: 4px;
		flex-basis: 20px;
		background: url("../images/ico//ico_del_01.svg") 0/20px no-repeat;
		width: 20px;
		height: 20px;
		flex: 0 0 20px;
}

.c-input-mark {
		display: flex;
		align-items: center;
		position: relative;
}

.is-rate {
		position: absolute;
		right: 0;
		bottom: 12px;
		font-size: 12px;
		text-align: right;
}

.c-input-address-group > .row {
		margin-left: -8px;
		margin-right: -8px;
}
.c-input-address-group > .row ~ .row {
		margin-top: 16px;
}
.c-input-address-group .input-addr-01 {
		flex: 0 0 116px;
}
.c-input-address-group .input-addr-03 {
		flex: 0 0 108px;
}
.c-input-address-group .input-addr-03 .co-btn {
		padding: 6px;
}
.c-input-address-group.input-view > .row {
		margin: 0;
		display: block;
}
.c-input-address-group.input-view > .row > .col {
		padding: 0;
		display: inline-block;
}
.c-input-address-group.input-view input[type=text] {
		border-bottom: 0;
}

.inner.c-input-address-group {
		margin-left: -6px;
		margin-right: -6px;
}
.inner.c-input-address-group .col {
		padding-left: 6px;
		padding-right: 6px;
}

.c-input-btn-group {
		margin-left: -8px;
		margin-right: -8px;
}
.c-input-btn-group [class*=col] {
		padding-left: 8px;
		padding-right: 8px;
}
.c-input-btn-group .co-btn {
		width: 100%;
}

.c-input-download {
		position: relative;
		width: 100%;
}
.c-input-download .btn-download {
		position: relative;
		padding: 8px 28px 8px 0;
		min-height: 40px;
		display: block;
}
.c-input-download .btn-download:after {
		background: url("../images/ico/ico_download_01.svg") 0/24px no-repeat;
		position: absolute;
		right: 0;
		top: 50%;
		transform: translateY(-50%);
		width: 24px;
		height: 24px;
		content: "";
		display: inline-block;
		overflow: hidden;
		content: "";
}
.c-input-download .filename {
		border-bottom: 1px solid #2f7ad3;
		color: #2f7ad3;
		overflow: hidden;
}
.c-input-download.input-view {
		border-bottom: 1px solid #e9e9e9;
}

.c-input-url {
		padding-top: 8px;
		padding-bottom: 8px;
}
.c-input-url > a {
		color: #2f7ad3;
		border-bottom: 1px solid #2f7ad3;
}
.c-input-url.input-view {
		border-bottom: 1px solid #e9e9e9;
}

.c-input-search.detail-input input[type=text]:focus {
		border-color: #d2d2d1;
		transition: none;
		box-shadow: none;
}

.c-input-badge-group {
		border-bottom: 1px solid #e9e9e9;
		padding-bottom: 2px;
		display: flex;
		align-items: center;
}
.c-input-badge-group .c-badge-list {
		margin-left: -4px;
		margin-right: -4px;
		display: flex;
		flex-wrap: wrap;
}
.c-input-badge-group .badge-card {
		display: flex;
		align-items: center;
		padding: 4px;
}

.c-badge {
		padding: 2px 12px;
		background-color: #253981;
		border-radius: 8px;
}
.c-badge > span {
		line-height: 1.7;
		color: #fff;
}

.c-badge-detail {
		padding-left: 12px;
		line-height: 1.7;
}

input[type=text], input[type=password],
input[type=text],
input[type=password] {
		max-width: 100%;
		border-radius: 8px;
		padding: 6px 12px;
		outline: 0;
		min-height: 44px;
		font-size: 14px;
		font-weight: 300;
		font-family: "NotoSansKR";
		border: 1px solid #d2d2d1;
		color: #1c1c1b;
}
input[type=text]:focus, input[type=password]:focus,
input[type=text]:focus,
input[type=password]:focus {
		outline: 0;
		transition: all 0;
		border-color: #2f7ad3;
		transition: all 0.5s;
}
input[type=text]:disabled, input[type=password]:disabled,
input[type=text]:disabled,
input[type=password]:disabled {
		border-color: #d2d2d1;
		background-color: #e9e9e9;
		color: #494949;
}
input[type=text][readonly=readonly]:focus, input[type=password][readonly=readonly]:focus,
input[type=text][readonly=readonly]:focus,
input[type=password][readonly=readonly]:focus {
		border-color: #d2d2d1;
		transition: none;
		box-shadow: none;
}

label,
.c-label-txt {
		font-size: 12px;
		font-weight: 300;
		line-height: 1.6666666667;
		font-weight: 400;
		color: #777676;
}

.input-ref-txt {
		margin-top: 8px;
		font-size: 12px;
		font-weight: 300;
		line-height: 2;
		color: #a4a4a4;
}

.c-txtarea-field {
		height: 100%;
		width: 100%;
}

textarea,
.c-txt-area {
		display: block;
		border: 1px solid #d2d2d1;
		height: 120px;
		width: 100%;
		overflow-x: hidden;
		overflow-y: auto;
		border-radius: 8px;
		padding: 6px 12px;
		outline: 0;
		min-height: 44px;
		font-size: 14px;
		font-weight: 300;
		font-family: "NotoSansKR";
		padding: 16px 20px;
		min-width: 100%;
		color: #494949;
}
textarea:focus,
.c-txt-area:focus {
		transition: all 0;
		border-color: #2f7ad3;
}
textarea textarea[readonly=readonly],
.c-txt-area textarea[readonly=readonly] {
		line-height: 2.14;
		color: #1c1c1b;
		background-color: #fff;
		border: 0;
		min-height: initial;
		height: 100%;
		overflow: auto;
}
textarea textarea[readonly=readonly]:focus,
.c-txt-area textarea[readonly=readonly]:focus {
		transition: all 0;
		border-color: #2f7ad3;
		border: 0;
		box-shadow: none;
		transition: none;
}
textarea.type01,
.c-txt-area.type01 {
		height: 172px;
		min-height: 172px;
}
textarea.type02,
.c-txt-area.type02 {
		height: 300px;
		min-height: 300px;
}

.c-chkbox,
.c-rdochk {
		cursor: pointer;
		position: relative;
}
.c-chkbox input[type=checkbox],
.c-chkbox input[type=radio],
.c-rdochk input[type=checkbox],
.c-rdochk input[type=radio] {
		position: absolute;
		opacity: 0;
}
.c-chkbox input[type=checkbox] + .chk-label, .c-chkbox input[type=checkbox] + label.chk-label,
.c-chkbox input[type=radio] + .chk-label,
.c-chkbox input[type=radio] + label.chk-label,
.c-rdochk input[type=checkbox] + .chk-label,
.c-rdochk input[type=checkbox] + label.chk-label,
.c-rdochk input[type=radio] + .chk-label,
.c-rdochk input[type=radio] + label.chk-label {
		line-height: 1;
		display: table;
}
.c-chkbox input[type=checkbox] + .chk-label i, .c-chkbox input[type=checkbox] + label.chk-label i,
.c-chkbox input[type=radio] + .chk-label i,
.c-chkbox input[type=radio] + label.chk-label i,
.c-rdochk input[type=checkbox] + .chk-label i,
.c-rdochk input[type=checkbox] + label.chk-label i,
.c-rdochk input[type=radio] + .chk-label i,
.c-rdochk input[type=radio] + label.chk-label i {
		cursor: pointer;
		display: inline-block;
		background-repeat: no-repeat;
}
.c-chkbox.type01 input[type=checkbox],
.c-chkbox.type01 input[type=radio], .c-chkbox.type02 input[type=checkbox],
.c-chkbox.type02 input[type=radio],
.c-rdochk.type01 input[type=checkbox],
.c-rdochk.type01 input[type=radio],
.c-rdochk.type02 input[type=checkbox],
.c-rdochk.type02 input[type=radio] {
		top: 7px;
		left: 7px;
		opacity: 0;
}
.c-chkbox.type01 input[type=checkbox] + .chk-label, .c-chkbox.type01 input[type=checkbox] + label.chk-label,
.c-chkbox.type01 input[type=radio] + .chk-label,
.c-chkbox.type01 input[type=radio] + label.chk-label, .c-chkbox.type02 input[type=checkbox] + .chk-label, .c-chkbox.type02 input[type=checkbox] + label.chk-label,
.c-chkbox.type02 input[type=radio] + .chk-label,
.c-chkbox.type02 input[type=radio] + label.chk-label,
.c-rdochk.type01 input[type=checkbox] + .chk-label,
.c-rdochk.type01 input[type=checkbox] + label.chk-label,
.c-rdochk.type01 input[type=radio] + .chk-label,
.c-rdochk.type01 input[type=radio] + label.chk-label,
.c-rdochk.type02 input[type=checkbox] + .chk-label,
.c-rdochk.type02 input[type=checkbox] + label.chk-label,
.c-rdochk.type02 input[type=radio] + .chk-label,
.c-rdochk.type02 input[type=radio] + label.chk-label {
		line-height: 1;
		display: table;
}
.c-chkbox.type01 input[type=checkbox] + .chk-label i, .c-chkbox.type01 input[type=checkbox] + label.chk-label i,
.c-chkbox.type01 input[type=radio] + .chk-label i,
.c-chkbox.type01 input[type=radio] + label.chk-label i, .c-chkbox.type02 input[type=checkbox] + .chk-label i, .c-chkbox.type02 input[type=checkbox] + label.chk-label i,
.c-chkbox.type02 input[type=radio] + .chk-label i,
.c-chkbox.type02 input[type=radio] + label.chk-label i,
.c-rdochk.type01 input[type=checkbox] + .chk-label i,
.c-rdochk.type01 input[type=checkbox] + label.chk-label i,
.c-rdochk.type01 input[type=radio] + .chk-label i,
.c-rdochk.type01 input[type=radio] + label.chk-label i,
.c-rdochk.type02 input[type=checkbox] + .chk-label i,
.c-rdochk.type02 input[type=checkbox] + label.chk-label i,
.c-rdochk.type02 input[type=radio] + .chk-label i,
.c-rdochk.type02 input[type=radio] + label.chk-label i {
		margin-right: 4px;
		cursor: pointer;
		display: inline-block;
		width: 18px;
		height: 18px;
		background-image: url("../images/ico/ico-chk-01.svg");
		background-position: 0 0;
		background-size: auto 18px;
}
.c-chkbox.type01.type01, .c-chkbox.type02.type01,
.c-rdochk.type01.type01,
.c-rdochk.type02.type01 {
		margin-bottom: 10px;
}
.c-chkbox.type01.type01 input[type=checkbox]:checked + .chk-label > span,
.c-chkbox.type01.type01 input[type=radio]:checked + .chk-label > span, .c-chkbox.type02.type01 input[type=checkbox]:checked + .chk-label > span,
.c-chkbox.type02.type01 input[type=radio]:checked + .chk-label > span,
.c-rdochk.type01.type01 input[type=checkbox]:checked + .chk-label > span,
.c-rdochk.type01.type01 input[type=radio]:checked + .chk-label > span,
.c-rdochk.type02.type01 input[type=checkbox]:checked + .chk-label > span,
.c-rdochk.type02.type01 input[type=radio]:checked + .chk-label > span {
		color: #253981;
}
.c-chkbox.type01.type01 .chk-label i, .c-chkbox.type02.type01 .chk-label i,
.c-rdochk.type01.type01 .chk-label i,
.c-rdochk.type02.type01 .chk-label i {
		position: relative;
}
.c-chkbox.type01.type01 .chk-label i:before, .c-chkbox.type01.type01 .chk-label i:after, .c-chkbox.type02.type01 .chk-label i:before, .c-chkbox.type02.type01 .chk-label i:after,
.c-rdochk.type01.type01 .chk-label i:before,
.c-rdochk.type01.type01 .chk-label i:after,
.c-rdochk.type02.type01 .chk-label i:before,
.c-rdochk.type02.type01 .chk-label i:after {
		content: "";
		position: absolute;
		display: block;
		background-color: #fff;
		height: 0;
		opacity: 0;
		transition: width 0.2s linear;
}
.c-chkbox.type01.type01 .chk-label i:before, .c-chkbox.type02.type01 .chk-label i:before,
.c-rdochk.type01.type01 .chk-label i:before,
.c-rdochk.type02.type01 .chk-label i:before {
		width: 0;
		top: 8px;
		left: 5px;
		transform: rotate(-45deg);
}
.c-chkbox.type01.type01 .chk-label i:after, .c-chkbox.type02.type01 .chk-label i:after,
.c-rdochk.type01.type01 .chk-label i:after,
.c-rdochk.type02.type01 .chk-label i:after {
		width: 0;
		top: 9px;
		left: 2px;
		transform: rotate(45deg);
}
.c-chkbox.type01.type01 > input[type=checkbox]:checked + .chk-label > i, .c-chkbox.type01.type01 > input[type=radio]:checked + .chk-label > i, .c-chkbox.type02.type01 > input[type=checkbox]:checked + .chk-label > i, .c-chkbox.type02.type01 > input[type=radio]:checked + .chk-label > i,
.c-rdochk.type01.type01 > input[type=checkbox]:checked + .chk-label > i,
.c-rdochk.type01.type01 > input[type=radio]:checked + .chk-label > i,
.c-rdochk.type02.type01 > input[type=checkbox]:checked + .chk-label > i,
.c-rdochk.type02.type01 > input[type=radio]:checked + .chk-label > i {
		border: 0;
		border-radius: 50%;
		background-image: none;
		background-color: #253981;
}
.c-chkbox.type01.type01 > input[type=checkbox]:checked + .chk-label > i:before, .c-chkbox.type01.type01 > input[type=checkbox]:checked + .chk-label > i:after, .c-chkbox.type01.type01 > input[type=radio]:checked + .chk-label > i:before, .c-chkbox.type01.type01 > input[type=radio]:checked + .chk-label > i:after, .c-chkbox.type02.type01 > input[type=checkbox]:checked + .chk-label > i:before, .c-chkbox.type02.type01 > input[type=checkbox]:checked + .chk-label > i:after, .c-chkbox.type02.type01 > input[type=radio]:checked + .chk-label > i:before, .c-chkbox.type02.type01 > input[type=radio]:checked + .chk-label > i:after,
.c-rdochk.type01.type01 > input[type=checkbox]:checked + .chk-label > i:before,
.c-rdochk.type01.type01 > input[type=checkbox]:checked + .chk-label > i:after,
.c-rdochk.type01.type01 > input[type=radio]:checked + .chk-label > i:before,
.c-rdochk.type01.type01 > input[type=radio]:checked + .chk-label > i:after,
.c-rdochk.type02.type01 > input[type=checkbox]:checked + .chk-label > i:before,
.c-rdochk.type02.type01 > input[type=checkbox]:checked + .chk-label > i:after,
.c-rdochk.type02.type01 > input[type=radio]:checked + .chk-label > i:before,
.c-rdochk.type02.type01 > input[type=radio]:checked + .chk-label > i:after {
		opacity: 1;
		height: 2px;
}
.c-chkbox.type01.type01 > input[type=checkbox]:checked + .chk-label > i:before, .c-chkbox.type01.type01 > input[type=radio]:checked + .chk-label > i:before, .c-chkbox.type02.type01 > input[type=checkbox]:checked + .chk-label > i:before, .c-chkbox.type02.type01 > input[type=radio]:checked + .chk-label > i:before,
.c-rdochk.type01.type01 > input[type=checkbox]:checked + .chk-label > i:before,
.c-rdochk.type01.type01 > input[type=radio]:checked + .chk-label > i:before,
.c-rdochk.type02.type01 > input[type=checkbox]:checked + .chk-label > i:before,
.c-rdochk.type02.type01 > input[type=radio]:checked + .chk-label > i:before {
		width: 10px;
		transition: all 0.25s linear;
		transition-delay: 0.03s;
}
.c-chkbox.type01.type01 > input[type=checkbox]:checked + .chk-label > i:after, .c-chkbox.type01.type01 > input[type=radio]:checked + .chk-label > i:after, .c-chkbox.type02.type01 > input[type=checkbox]:checked + .chk-label > i:after, .c-chkbox.type02.type01 > input[type=radio]:checked + .chk-label > i:after,
.c-rdochk.type01.type01 > input[type=checkbox]:checked + .chk-label > i:after,
.c-rdochk.type01.type01 > input[type=radio]:checked + .chk-label > i:after,
.c-rdochk.type02.type01 > input[type=checkbox]:checked + .chk-label > i:after,
.c-rdochk.type02.type01 > input[type=radio]:checked + .chk-label > i:after {
		width: 6px;
		transition: width 0.2s linear;
		transition-delay: 0s;
}
.c-chkbox.type01.type01 > input[type=checkbox]:checked + .chk-label > span, .c-chkbox.type01.type01 > input[type=radio]:checked + .chk-label > span, .c-chkbox.type02.type01 > input[type=checkbox]:checked + .chk-label > span, .c-chkbox.type02.type01 > input[type=radio]:checked + .chk-label > span,
.c-rdochk.type01.type01 > input[type=checkbox]:checked + .chk-label > span,
.c-rdochk.type01.type01 > input[type=radio]:checked + .chk-label > span,
.c-rdochk.type02.type01 > input[type=checkbox]:checked + .chk-label > span,
.c-rdochk.type02.type01 > input[type=radio]:checked + .chk-label > span {
		color: #253981;
}
.c-chkbox.type01.type02 input[type=checkbox]:checked + .chk-label > span,
.c-chkbox.type01.type02 input[type=radio]:checked + .chk-label > span, .c-chkbox.type02.type02 input[type=checkbox]:checked + .chk-label > span,
.c-chkbox.type02.type02 input[type=radio]:checked + .chk-label > span,
.c-rdochk.type01.type02 input[type=checkbox]:checked + .chk-label > span,
.c-rdochk.type01.type02 input[type=radio]:checked + .chk-label > span,
.c-rdochk.type02.type02 input[type=checkbox]:checked + .chk-label > span,
.c-rdochk.type02.type02 input[type=radio]:checked + .chk-label > span {
		color: #253981;
}
.c-chkbox.type01.type02 .chk-label i, .c-chkbox.type02.type02 .chk-label i,
.c-rdochk.type01.type02 .chk-label i,
.c-rdochk.type02.type02 .chk-label i {
		position: relative;
}
.c-chkbox.type01.type02 .chk-label i:before, .c-chkbox.type01.type02 .chk-label i:after, .c-chkbox.type02.type02 .chk-label i:before, .c-chkbox.type02.type02 .chk-label i:after,
.c-rdochk.type01.type02 .chk-label i:before,
.c-rdochk.type01.type02 .chk-label i:after,
.c-rdochk.type02.type02 .chk-label i:before,
.c-rdochk.type02.type02 .chk-label i:after {
		content: "";
		position: absolute;
		display: block;
		background-color: #fff;
		height: 0;
		opacity: 0;
		transition: width 0.2s linear;
}
.c-chkbox.type01.type02 .chk-label i:before, .c-chkbox.type02.type02 .chk-label i:before,
.c-rdochk.type01.type02 .chk-label i:before,
.c-rdochk.type02.type02 .chk-label i:before {
		width: 0;
		top: 8px;
		left: 5px;
		transform: rotate(-45deg);
}
.c-chkbox.type01.type02 .chk-label i:after, .c-chkbox.type02.type02 .chk-label i:after,
.c-rdochk.type01.type02 .chk-label i:after,
.c-rdochk.type02.type02 .chk-label i:after {
		width: 0;
		top: 9px;
		left: 2px;
		transform: rotate(45deg);
}
.c-chkbox.type01.type02 > input[type=checkbox]:checked + .chk-label > i, .c-chkbox.type01.type02 > input[type=radio]:checked + .chk-label > i, .c-chkbox.type02.type02 > input[type=checkbox]:checked + .chk-label > i, .c-chkbox.type02.type02 > input[type=radio]:checked + .chk-label > i,
.c-rdochk.type01.type02 > input[type=checkbox]:checked + .chk-label > i,
.c-rdochk.type01.type02 > input[type=radio]:checked + .chk-label > i,
.c-rdochk.type02.type02 > input[type=checkbox]:checked + .chk-label > i,
.c-rdochk.type02.type02 > input[type=radio]:checked + .chk-label > i {
		border: 0;
		border-radius: 50%;
		background-image: none;
		background-color: #253981;
}
.c-chkbox.type01.type02 > input[type=checkbox]:checked + .chk-label > i:before, .c-chkbox.type01.type02 > input[type=checkbox]:checked + .chk-label > i:after, .c-chkbox.type01.type02 > input[type=radio]:checked + .chk-label > i:before, .c-chkbox.type01.type02 > input[type=radio]:checked + .chk-label > i:after, .c-chkbox.type02.type02 > input[type=checkbox]:checked + .chk-label > i:before, .c-chkbox.type02.type02 > input[type=checkbox]:checked + .chk-label > i:after, .c-chkbox.type02.type02 > input[type=radio]:checked + .chk-label > i:before, .c-chkbox.type02.type02 > input[type=radio]:checked + .chk-label > i:after,
.c-rdochk.type01.type02 > input[type=checkbox]:checked + .chk-label > i:before,
.c-rdochk.type01.type02 > input[type=checkbox]:checked + .chk-label > i:after,
.c-rdochk.type01.type02 > input[type=radio]:checked + .chk-label > i:before,
.c-rdochk.type01.type02 > input[type=radio]:checked + .chk-label > i:after,
.c-rdochk.type02.type02 > input[type=checkbox]:checked + .chk-label > i:before,
.c-rdochk.type02.type02 > input[type=checkbox]:checked + .chk-label > i:after,
.c-rdochk.type02.type02 > input[type=radio]:checked + .chk-label > i:before,
.c-rdochk.type02.type02 > input[type=radio]:checked + .chk-label > i:after {
		opacity: 1;
		height: 2px;
}
.c-chkbox.type01.type02 > input[type=checkbox]:checked + .chk-label > i:before, .c-chkbox.type01.type02 > input[type=radio]:checked + .chk-label > i:before, .c-chkbox.type02.type02 > input[type=checkbox]:checked + .chk-label > i:before, .c-chkbox.type02.type02 > input[type=radio]:checked + .chk-label > i:before,
.c-rdochk.type01.type02 > input[type=checkbox]:checked + .chk-label > i:before,
.c-rdochk.type01.type02 > input[type=radio]:checked + .chk-label > i:before,
.c-rdochk.type02.type02 > input[type=checkbox]:checked + .chk-label > i:before,
.c-rdochk.type02.type02 > input[type=radio]:checked + .chk-label > i:before {
		width: 10px;
		transition: all 0.25s linear;
		transition-delay: 0.03s;
}
.c-chkbox.type01.type02 > input[type=checkbox]:checked + .chk-label > i:after, .c-chkbox.type01.type02 > input[type=radio]:checked + .chk-label > i:after, .c-chkbox.type02.type02 > input[type=checkbox]:checked + .chk-label > i:after, .c-chkbox.type02.type02 > input[type=radio]:checked + .chk-label > i:after,
.c-rdochk.type01.type02 > input[type=checkbox]:checked + .chk-label > i:after,
.c-rdochk.type01.type02 > input[type=radio]:checked + .chk-label > i:after,
.c-rdochk.type02.type02 > input[type=checkbox]:checked + .chk-label > i:after,
.c-rdochk.type02.type02 > input[type=radio]:checked + .chk-label > i:after {
		width: 6px;
		transition: width 0.2s linear;
		transition-delay: 0s;
}
.c-chkbox.type01.type02 > input[type=checkbox]:checked + .chk-label > span, .c-chkbox.type01.type02 > input[type=radio]:checked + .chk-label > span, .c-chkbox.type02.type02 > input[type=checkbox]:checked + .chk-label > span, .c-chkbox.type02.type02 > input[type=radio]:checked + .chk-label > span,
.c-rdochk.type01.type02 > input[type=checkbox]:checked + .chk-label > span,
.c-rdochk.type01.type02 > input[type=radio]:checked + .chk-label > span,
.c-rdochk.type02.type02 > input[type=checkbox]:checked + .chk-label > span,
.c-rdochk.type02.type02 > input[type=radio]:checked + .chk-label > span {
		color: #253981;
}
.c-chkbox.type01 input[type=checkbox].all-terms-chk + .chk-label i, .c-chkbox.type02 input[type=checkbox].all-terms-chk + .chk-label i,
.c-rdochk.type01 input[type=checkbox].all-terms-chk + .chk-label i,
.c-rdochk.type02 input[type=checkbox].all-terms-chk + .chk-label i {
		margin-right: 10px;
}
.c-chkbox.type-blue input[type=checkbox],
.c-chkbox.type-blue input[type=radio],
.c-rdochk.type-blue input[type=checkbox],
.c-rdochk.type-blue input[type=radio] {
		top: 7px;
		left: 7px;
		opacity: 0;
}
.c-chkbox.type-blue input[type=checkbox] + .chk-label, .c-chkbox.type-blue input[type=checkbox] + label.chk-label,
.c-chkbox.type-blue input[type=radio] + .chk-label,
.c-chkbox.type-blue input[type=radio] + label.chk-label,
.c-rdochk.type-blue input[type=checkbox] + .chk-label,
.c-rdochk.type-blue input[type=checkbox] + label.chk-label,
.c-rdochk.type-blue input[type=radio] + .chk-label,
.c-rdochk.type-blue input[type=radio] + label.chk-label {
		line-height: 1;
		display: table;
}
.c-chkbox.type-blue input[type=checkbox] + .chk-label i, .c-chkbox.type-blue input[type=checkbox] + label.chk-label i,
.c-chkbox.type-blue input[type=radio] + .chk-label i,
.c-chkbox.type-blue input[type=radio] + label.chk-label i,
.c-rdochk.type-blue input[type=checkbox] + .chk-label i,
.c-rdochk.type-blue input[type=checkbox] + label.chk-label i,
.c-rdochk.type-blue input[type=radio] + .chk-label i,
.c-rdochk.type-blue input[type=radio] + label.chk-label i {
		margin-right: 4px;
		cursor: pointer;
		display: inline-block;
		width: 18px;
		height: 18px;
		background-image: url("../images/ico/ico-chk-01.svg");
		background-position: 0 0;
		background-size: auto 18px;
}
.c-chkbox.type-blue .chk-label i,
.c-rdochk.type-blue .chk-label i {
		position: relative;
}
.c-chkbox.type-blue .chk-label i:before, .c-chkbox.type-blue .chk-label i:after,
.c-rdochk.type-blue .chk-label i:before,
.c-rdochk.type-blue .chk-label i:after {
		content: "";
		position: absolute;
		display: block;
		background-color: #fff;
		height: 0;
		opacity: 0;
		transition: width 0.2s linear;
}
.c-chkbox.type-blue .chk-label i:before,
.c-rdochk.type-blue .chk-label i:before {
		width: 0;
		top: 8px;
		left: 5px;
		transform: rotate(-45deg);
}
.c-chkbox.type-blue .chk-label i:after,
.c-rdochk.type-blue .chk-label i:after {
		width: 0;
		top: 9px;
		left: 2px;
		transform: rotate(45deg);
}
.c-chkbox.type-blue > input[type=checkbox]:checked + .chk-label > i, .c-chkbox.type-blue > input[type=radio]:checked + .chk-label > i,
.c-rdochk.type-blue > input[type=checkbox]:checked + .chk-label > i,
.c-rdochk.type-blue > input[type=radio]:checked + .chk-label > i {
		border: 0;
		border-radius: 50%;
		background-image: none;
		background-color: #2f7ad3;
}
.c-chkbox.type-blue > input[type=checkbox]:checked + .chk-label > i:before, .c-chkbox.type-blue > input[type=checkbox]:checked + .chk-label > i:after, .c-chkbox.type-blue > input[type=radio]:checked + .chk-label > i:before, .c-chkbox.type-blue > input[type=radio]:checked + .chk-label > i:after,
.c-rdochk.type-blue > input[type=checkbox]:checked + .chk-label > i:before,
.c-rdochk.type-blue > input[type=checkbox]:checked + .chk-label > i:after,
.c-rdochk.type-blue > input[type=radio]:checked + .chk-label > i:before,
.c-rdochk.type-blue > input[type=radio]:checked + .chk-label > i:after {
		opacity: 1;
		height: 2px;
}
.c-chkbox.type-blue > input[type=checkbox]:checked + .chk-label > i:before, .c-chkbox.type-blue > input[type=radio]:checked + .chk-label > i:before,
.c-rdochk.type-blue > input[type=checkbox]:checked + .chk-label > i:before,
.c-rdochk.type-blue > input[type=radio]:checked + .chk-label > i:before {
		width: 10px;
		transition: all 0.25s linear;
		transition-delay: 0.03s;
}
.c-chkbox.type-blue > input[type=checkbox]:checked + .chk-label > i:after, .c-chkbox.type-blue > input[type=radio]:checked + .chk-label > i:after,
.c-rdochk.type-blue > input[type=checkbox]:checked + .chk-label > i:after,
.c-rdochk.type-blue > input[type=radio]:checked + .chk-label > i:after {
		width: 6px;
		transition: width 0.2s linear;
		transition-delay: 0s;
}
.c-chkbox.type-blue > input[type=checkbox]:checked + .chk-label > span, .c-chkbox.type-blue > input[type=radio]:checked + .chk-label > span,
.c-rdochk.type-blue > input[type=checkbox]:checked + .chk-label > span,
.c-rdochk.type-blue > input[type=radio]:checked + .chk-label > span {
		color: #2f7ad3;
}
.c-chkbox .chk-label,
.c-chkbox label.chk-label,
.c-rdochk .chk-label,
.c-rdochk label.chk-label {
		margin-bottom: 0;
}

.c-chkbtn {
		position: relative;
		cursor: pointer;
}
.c-chkbtn > label {
		display: block;
		width: 100%;
		padding: 4px 16px;
		border-radius: 8px;
		color: rgba(28, 28, 27, 0.8);
		background-color: #fff;
		border: 1px solid #d2d2d1;
		font-size: 12px;
		font-weight: 400;
		line-height: 2;
		cursor: pointer;
}
.c-chkbtn input {
		position: absolute;
		top: 0;
		left: 0;
		width: 1px;
		overflow: hidden;
		clip: rect(0 0 0 0);
		margin: -1px;
		padding: 0;
		width: 1px;
		height: 1px;
		border: 0;
}
.c-chkbtn input:checked + label {
		background-color: #253981;
		border-color: #253981;
		color: #fff;
}

.c-chkbox.type01 input[type=radio]:checked + .chk-label i,
.c-chkbox.type01 input[type=checkbox]:checked + .chk-label i {
		background-position-x: -76px;
}
.c-chkbox.type01 input[type=radio]:checked + .chk-label > span,
.c-chkbox.type01 input[type=checkbox]:checked + .chk-label > span {
		color: #253981;
}
.c-chkbox.type02 input[type=radio]:checked + .chk-label i,
.c-chkbox.type02 input[type=checkbox]:checked + .chk-label i {
		background-position-x: -76px;
}

.c-chkbox {
		position: relative;
}
.c-chkbox input[type=checkbox] + .chk-label i {
		margin-right: 8px;
		background-image: url("../images/ico/ico_sprites_checkbox02.svg");
		background-size: auto 18px;
		width: 18px;
		height: 18px;
		display: inline-block;
}
.c-chkbox input[type=checkbox]:checked + .chk-label i {
		background-position: -56px 0;
}
.c-chkbox input[type=checkbox]:checked:disabled + .chk-label i {
		cursor: default;
		background-position: -84px 0;
}
.c-chkbox input[type=checkbox]:disabled + .chk-label i {
		cursor: default;
		background-position: -28px 0;
}
.c-chkbox .chk-label i {
		position: relative;
}
.c-chkbox .chk-label i:before, .c-chkbox .chk-label i:after {
		content: "";
		position: absolute;
		display: block;
		background-color: #fff;
		width: 0;
		transition: width 0.2s cubic-bezier(0.34, 0.82, 0.45, 0.94);
}
.c-chkbox .chk-label i:before {
		top: 7px;
		left: 5px;
		transform: rotate(-45deg);
}
.c-chkbox .chk-label i:after {
		top: 9px;
		left: 1px;
		transform: rotate(45deg);
}
.c-chkbox > input[type=checkbox]:checked + .chk-label > i {
		border: 1px solid #d2d2d1;
		border-radius: 4px;
		background-image: none;
		background-color: #253981;
		transition: height 0.2s cubic-bezier(0.34, 0.82, 0.45, 0.94);
}
.c-chkbox > input[type=checkbox]:checked + .chk-label > i:before, .c-chkbox > input[type=checkbox]:checked + .chk-label > i:after {
		height: 1px;
}
.c-chkbox > input[type=checkbox]:checked + .chk-label > i:before {
		width: 10px;
		transition-delay: 0.02s;
}
.c-chkbox > input[type=checkbox]:checked + .chk-label > i:after {
		width: 6px;
		transition-delay: 0s;
}

.c-rdochk input[type=radio] + .chk-label i {
		margin-right: 8px;
		background-image: url("../images/ico/ico_sprites_radio.svg");
		width: 18px;
		height: 18px;
		display: inline-block;
}
.c-rdochk input[type=radio]:checked + .chk-label > span {
		color: #253981;
}
.c-rdochk input[type=radio]:checked + .chk-label i {
		background-position: -56px 0;
}
.c-rdochk input[type=radio]:checked:disabled + .chk-label i {
		cursor: default;
		background-position: -84px 0;
}
.c-rdochk input[type=radio]:disabled + .chk-label i {
		cursor: default;
		background-position: -28px 0;
}

/*
	 = Select
*/
.select-box.region01 {
		width: 120px;
}
.select-box.region02 {
		width: 160px;
}

.c-select-list {
		display: flex;
}

.c-select {
		position: relative;
		font-size: 0;
}
.c-select:after {
		background-image: url("../images/ico/ico_arrow_09.svg");
		background-position: right center;
		background-size: 16px auto;
		background-repeat: no-repeat;
		position: absolute;
		right: 16px;
		top: 50%;
		transform: translateY(-50%);
		width: 16px;
		height: 16px;
		content: "";
		display: inline-block;
}
.c-select select::ms-expand {
		display: none;
}
.c-select select {
		border: 1px solid #d2d2d1;
		border-radius: 8px;
		background-color: #fff;
		color: #494949;
		min-height: 44px;
		width: 100%;
		padding: 6px 36px 6px 12px;
		font-size: 14px;
		font-weight: 300;
		line-height: 2.1428571429;
}
.c-select select:focus {
		transition: all 0;
		border-color: #2f7ad3;
}
.c-select.type02 select {
		line-height: 20px;
}

.c-select + .c-select {
		margin-left: 8px;
}

.c-input-btn-group.row.inner {
		margin-left: -8px;
		margin-right: -8px;
}
.c-input-btn-group .col {
		padding-left: 8px;
		padding-right: 8px;
}
.c-input-btn-group .col.buttons {
		flex: 0 0 108px;
}

.c-input-list-basic > .row {
		margin-left: -16px;
		margin-right: -16px;
}
.c-input-list-basic > .row:not(:first-of-type) {
		padding-top: 16px;
}
.c-input-list-basic > .row:not(:last-of-type) {
		padding-bottom: 16px;
}
.c-input-list-basic > .row > [class|=col],
.c-input-list-basic > .row > .col {
		padding-left: 16px;
		padding-right: 16px;
}
.c-input-list-basic.input-cont > .row {
		padding-left: 16px;
		padding-right: 16px;
}
.c-input-list-basic .in-input-row ~ .in-input-row {
		margin-top: 20px;
}

.c-input-list-view {
		padding-top: 20px;
		padding-bottom: 20px;
}
.c-input-list-view.line {
		border-top: 1px solid #e9e9e9;
		border-bottom: 1px solid #e9e9e9;
}
.c-input-list-view > .row {
		margin-left: -16px;
		margin-right: -16px;
}
.c-input-list-view > .row:not(:first-of-type) {
		padding-top: 4px;
}
.c-input-list-view > .row > [class|=col],
.c-input-list-view > .row > .col {
		padding-left: 16px;
		padding-right: 16px;
}
.c-input-list-view > .row .c-input {
		margin-left: 12px;
}
.c-input-list-view.type02 {
		padding-top: 0;
		padding-bottom: 0;
}
.c-input-list-view.type02 > .row {
		margin-left: -8px;
		margin-right: -8px;
}
.c-input-list-view.type02 > .row:not(:first-of-type) {
		padding-top: 8px;
}
.c-input-list-view.type02 > .row > [class|=col],
.c-input-list-view.type02 > .row > .col {
		padding-left: 8px;
		padding-right: 8px;
}
.c-input-list-view.type02 > .row .c-input {
		margin-left: 12px;
}

.input-type.view01 {
		display: flex;
}
.input-type.view01 > label,
.input-type.view01 > input {
		align-items: center;
		display: inline-flex;
}
.input-type.view01 label {
		font-size: 12px;
		font-weight: 300;
		line-height: 2;
		color: #777676;
		min-width: 80px;
}
.input-type.view01 input[type=text].c-input,
.input-type.view01 input[type=password].c-input {
		font-size: 14px;
		font-weight: 300;
		line-height: 2.2857142857;
		padding: 0;
		border-radius: 0;
		min-height: initial;
		color: #494949;
}
.input-type.view01 input[type=text].c-input:disabled,
.input-type.view01 input[type=password].c-input:disabled {
		border: 0;
		background-color: transparent;
}
.input-type.view01 input[type=text].c-input[readonly=readonly],
.input-type.view01 input[type=password].c-input[readonly=readonly] {
		border: 0;
		background-color: transparent;
}
.input-type.view01 input[type=text].c-input.type02,
.input-type.view01 input[type=password].c-input.type02 {
		padding: 2px 8px;
		border-radius: 8px;
}
.input-type.view01 .co-link {
		font-size: 14px;
		font-weight: 300;
		line-height: 2.1428571429;
		margin-left: 12px;
}

.c-input-email-group > .row {
		margin-left: -15px;
		margin-right: -15px;
		padding-top: 0;
		padding-bottom: 0;
		display: flex;
		justify-content: space-between;
}
.c-input-email-group > .row > .col,
.c-input-email-group > .row .col-6 {
		padding-left: 15px;
		padding-right: 15px;
}
.c-input-email-group > .row .input-email-01 {
		position: relative;
}
.c-input-email-group > .row .input-email-01:after {
		content: "@";
		position: absolute;
		top: 10px;
		right: -7px;
		color: #a4a4a4;
		font-size: 14px;
		font-weight: 300;
		line-height: 1.7;
}
.c-input-email-group > .row .input-email-02 > .row {
		margin-left: -6px;
		margin-right: -6px;
}
.c-input-email-group > .row .input-email-02 > .row > .col-6 {
		padding-left: 8px;
		padding-right: 8px;
}
.c-input-email-group > .row select {
		min-width: auto;
}
.c-input-email-group > .row + .row {
		margin-top: 16px;
}
.c-input-email-group.certified .row.w-btn {
		margin-right: -6px;
}
.c-input-email-group.certified .row.w-btn .input-email-02 {
		padding-right: 6px;
}
.c-input-email-group.certified .row.w-btn .buttons {
		padding-left: 6px;
		padding-right: 6px;
}
.c-input-email-group.certified .buttons {
		flex: 0 0 104px;
}

.c-input-phone-group > .row {
		margin-left: -8px;
		margin-right: -8px;
		padding-top: 0;
		padding-bottom: 0;
}
.c-input-phone-group > .row + .row {
		margin-top: 16px;
}
.c-input-phone-group > .row .input-phone-01 {
		flex: 0 0 108px;
}
.c-input-phone-group > .row > .col {
		padding-left: 8px;
		padding-right: 8px;
}
.c-input-phone-group.certified .w-btn {
		margin-right: -6px;
}
.c-input-phone-group.certified .w-btn .input-phone-03 {
		padding-right: 6px;
}
.c-input-phone-group.certified .w-btn .buttons {
		padding-left: 6px;
		padding-right: 6px;
}
.c-input-phone-group.certified .buttons {
		flex: 0 0 104px;
}

.input-btn-type {
		display: flex;
		align-items: flex-end;
}

.c-input-date {
		width: 100%;
		display: flex;
		align-items: center;
		position: relative;
}
.c-input-date input[type=text] {
		border: 1px solid #d2d2d1;
		border-radius: 8px;
		flex: 1 1 auto;
		padding-right: 44px;
}
.c-input-date input[type=text] + span {
		display: inline-block;
}
.c-input-date input[type=text] + span i.date-btn {
		background: url("../images/ico/ico_sprites_01.svg") -228px 0/auto 218px no-repeat;
		position: absolute;
		right: 12px;
		top: 50%;
		transform: translateY(-50%);
		width: 28px;
		height: 28px;
		content: "";
		display: inline-block;
		overflow: hidden;
}
.c-input-date input[type=text]:focus {
		transition: all 0;
		border-color: #2f7ad3;
}
.c-input-date input[type=text][readonly=readonly]:focus {
		border-color: #d2d2d1;
		transition: none;
		box-shadow: none;
}

.c-input.is-error,
.c-select.is-error select,
.c-input-search input[type=text].is-error {
		border-color: #ff6859;
}

.c-select.is-error select {
		border-color: #ff6859;
}

.row.input-view .label-input > label {
		margin-bottom: 4px;
}
.row.input-view input[type=text][readonly=readonly],
.row.input-view input[type=password][readonly=readonly] {
		line-height: 30px;
		color: #494949;
		min-height: initial;
		border: 0;
		padding: 0;
		border-radius: 0;
		padding-left: 0;
		background: transparent;
}

.ui-widget {
		font-size: 14px;
		font-weight: 300;
		line-height: 2.1428571429;
}

.ui-datepicker {
		border: 1px solid #e9e9e9;
		box-shadow: 4px 4px 4px -3px rgba(0, 0, 0, 0.25);
		border-radius: 12px;
		width: 428px;
		padding: 0;
		overflow: hidden;
}
.ui-datepicker .ui-datepicker-title {
		display: flex;
		align-items: center;
		justify-content: center;
		color: #2f7ad3;
		margin: 0;
}
.ui-datepicker .ui-datepicker-title,
.ui-datepicker .ui-datepicker-title > span {
		font-size: 24px;
		font-weight: 400;
		line-height: 1.5;
}
.ui-datepicker .ui-datepicker-title select {
		color: #2f7ad3;
		border: 0;
		width: auto;
		margin: 0;
		line-height: 34px;
		vertical-align: top;
}
.ui-datepicker .ui-datepicker-title select > option {
		font-size: 14px;
		font-weight: 300;
		line-height: 2.1428571429;
		color: #1c1c1b;
}
.ui-datepicker .ui-datepicker-title .ui-datepicker-month {
		text-align: center;
}
.ui-datepicker .ui-datepicker-title .ui-datepicker-year,
.ui-datepicker .ui-datepicker-title .ui-datepicker-month {
		margin-top: 2px;
		line-height: 1;
}
.ui-datepicker .ui-datepicker-title .ui-datepicker-year {
		margin-right: 3px;
}
.ui-datepicker .ui-datepicker-title .ui-datepicker-year + .ui-datepicker-month {
		position: relative;
		margin-left: 3px;
}
.ui-datepicker .ui-widget-header {
		border-radius: 0;
		background: transparent;
		border: 0;
		border-bottom: 1px solid #e9e9e9;
		padding: 20px 0;
}
.ui-datepicker .ui-datepicker-calendar {
		border-collapse: inherit;
		margin: 20px 0;
		padding: 0 12px;
		background-color: #fff;
}
.ui-datepicker .ui-datepicker-calendar thead tr th {
		color: #777676;
		padding: 4px 0;
		text-align: center;
}
.ui-datepicker .ui-datepicker-calendar thead tr th > span {
		font-size: 16px;
		font-weight: 400;
		line-height: 2;
}
.ui-datepicker .ui-datepicker-calendar thead tr th:first-child {
		color: #ff6859;
}
.ui-datepicker .ui-datepicker-calendar .ui-datepicker-week-end:first-child a {
		color: #ff6859;
}
.ui-datepicker .ui-datepicker-calendar td {
		padding: 0;
}
.ui-datepicker .ui-datepicker-calendar td .ui-state-default {
		background: transparent;
		border: 0;
		border-radius: 4px;
		font-size: 16px;
		font-weight: 700;
		line-height: 2;
		color: #777676;
		text-align: center;
		padding: 4px 0;
}
.ui-datepicker .ui-datepicker-calendar td .ui-state-default.ui-state-active, .ui-datepicker .ui-datepicker-calendar td .ui-state-default.ui-state-hover {
		color: #fff;
		background: #2f7ad3;
		opacity: 1;
}
.ui-datepicker .ui-datepicker-calendar td .ui-state-default.ui-state-hover {
		border: 0;
		opacity: 0.3;
}
.ui-datepicker .ui-datepicker-calendar td .ui-state-default.ui-state-highlight {
		background: #253981;
		color: #fff;
		opacity: 1;
}
.ui-datepicker .ui-datepicker-buttonpane {
		height: 55px;
		margin: 0;
		min-height: 30px;
}
.ui-datepicker .ui-datepicker-prev,
.ui-datepicker .ui-datepicker-next {
		top: 50%;
		transform: translate(0, -50%);
		width: 30px;
		height: 30px;
}
.ui-datepicker .ui-datepicker-prev .ui-icon,
.ui-datepicker .ui-datepicker-next .ui-icon {
		cursor: pointer;
		width: 30px;
		height: 30px;
		margin: 0;
		left: 0;
		top: 0;
}
.ui-datepicker .ui-datepicker-prev > .ui-icon,
.ui-datepicker .ui-datepicker-next > .ui-icon {
		width: 28px;
		height: 28px;
		background-position: 0 0;
		background-size: 28px;
		background-repeat: no-repeat;
}
.ui-datepicker .ui-datepicker-prev:hover,
.ui-datepicker .ui-datepicker-next:hover {
		background-color: transparent;
		border: 0;
}
.ui-datepicker .ui-datepicker-prev {
		left: 109px;
}
.ui-datepicker .ui-datepicker-prev .ui-icon {
		background-image: url("../images/ico/ico_date_arrow_prev.svg");
}
.ui-datepicker .ui-datepicker-next {
		right: 109px;
}
.ui-datepicker .ui-datepicker-next .ui-icon {
		background-image: url("../images/ico/ico_date_arrow_next.svg");
}
.ui-datepicker .ui-datepicker-buttonpane {
		padding: 12px 0;
}
.ui-datepicker .ui-datepicker-buttonpane button {
		display: none;
}
.ui-datepicker .ui-datepicker-buttonpane .datepicker-caption-warp {
		text-align: center;
}
.ui-datepicker .ui-datepicker-buttonpane .datepicker-caption-warp .datepicker-caption {
		display: inline-block;
		padding: 0 10px;
}
.ui-datepicker .ui-datepicker-buttonpane .datepicker-caption-warp .datepicker-caption::before {
		content: "";
		display: inline-block;
		width: 6px;
		height: 6px;
		border-radius: 100%;
		position: relative;
		top: -2px;
		margin-right: 8px;
}
.ui-datepicker .ui-datepicker-buttonpane .datepicker-caption-warp .datepicker-caption.toDay {
		color: #253981;
}
.ui-datepicker .ui-datepicker-buttonpane .datepicker-caption-warp .datepicker-caption.toDay::before {
		background-color: #253981;
}
.ui-datepicker .ui-datepicker-buttonpane .datepicker-caption-warp .datepicker-caption.selectDay {
		color: #2f7ad3;
}
.ui-datepicker .ui-datepicker-buttonpane .datepicker-caption-warp .datepicker-caption.selectDay::before {
		background-color: #2f7ad3;
}
.ui-datepicker .ui-datepicker-buttonpane .datepicker-caption-warp .datepicker-caption.holiDay {
		color: #ff6859;
}
.ui-datepicker .ui-datepicker-buttonpane .datepicker-caption-warp .datepicker-caption.holiDay::before {
		background-color: #ff6859;
}
.ui-datepicker .ui-datepicker-buttonpane .datepicker-caption-warp .datepicker-caption.deadDay {
		color: #d2d2d1;
}
.ui-datepicker .ui-datepicker-buttonpane .datepicker-caption-warp .datepicker-caption.deadDay::before {
		background-color: #d2d2d1;
}
.ui-datepicker tr td:first-child.ui-datepicker-week-end.ui-state-disabled .ui-state-default {
		color: #ff6859;
}

.co-table-field {
		overflow: hidden;
}

.co-table {
		overflow: hidden;
		border: 1px solid #e9e9e9;
		border-radius: 12px;
}
.co-table table {
		width: 100%;
		border-collapse: collapse;
}
.co-table table > caption {
		position: absolute;
		overflow: hidden;
		clip: rect(0 0 0 0);
		margin: -1px;
		padding: 0;
		width: 1px;
		height: 1px;
		border: 0;
}
.co-table table tr:not(:last-child) {
		border-bottom: 1px solid #e9e9e9;
}
.co-table table thead th {
		padding: 8px;
		font-weight: 400;
}
.co-table table tbody th,
.co-table table tbody td {
		font-weight: 300;
		padding: 12px;
}
.co-table table th,
.co-table table td {
		font-size: 14px;
		letter-spacing: -0.3px;
		line-height: 28px;
		border-collapse: collapse;
		white-space: initial;
		vertical-align: middle;
		white-space: initial;
}
.co-table table th > .c-chkbox,
.co-table table th > .c-rdobox,
.co-table table td > .c-chkbox,
.co-table table td > .c-rdobox {
		display: inline-block;
		margin: 0 auto;
}
.co-table table th.tbl-in-cont,
.co-table table td.tbl-in-cont {
		font-size: 0;
		line-height: 1;
}
.co-table table th:not(:last-child),
.co-table table td:not(:last-child) {
		border-right: 1px solid #e9e9e9;
}
.co-table table th .co-link,
.co-table table td .co-link {
		font-size: 14px;
		font-weight: 300;
		line-height: 1.7142857143;
}
.co-table table thead th input[type=checkbox] + label i,
.co-table table thead th input[type=radio] + label i {
		margin-right: 0;
}
.co-table table thead th:last-child {
		border-right: 0;
}
.co-table table thead th {
		text-align: center;
}
.co-table table col.checkbox,
.co-table table th.checkbox,
.co-table table td.checkbox {
		width: 48px;
}
.co-table.tbl-center tbody td {
		text-align: center;
}
.co-table th.tbl-chk,
.co-table td.tbl-chk {
		margin: 0 auto;
}
.co-table th.tbl-chk .c-chkbox,
.co-table th.tbl-chk .c-rdochk,
.co-table td.tbl-chk .c-chkbox,
.co-table td.tbl-chk .c-rdochk {
		display: block;
		text-align: center;
		width: 18px;
}
.co-table th.tbl-chk .c-rdochk input[type=radio] + .chk-label i,
.co-table td.tbl-chk .c-rdochk input[type=radio] + .chk-label i {
		margin-left: 3px;
}
.co-table th.tbl-chk .chk-label,
.co-table td.tbl-chk .chk-label {
		display: block;
}
.co-table.table-type02 {
		overflow: hidden;
}
.co-table.table-type02 thead th {
		text-align: center;
		background-color: #494949;
		color: #fff;
}
.co-table.table-type02 thead th input[type=checkbox] + label i,
.co-table.table-type02 thead th input[type=radio] + label i {
		margin-right: 0;
}
.co-table.table-type02 tbody td:last-child {
		border-right: 0;
}
.co-table.table-type02 tbody tr:last-child th,
.co-table.table-type02 tbody tr:last-child td {
		border-bottom: none;
}
.co-table.cont-type01 .co-list-card > li, .co-table.cont-type02 .co-list-card > li {
		font-size: 14px;
		font-weight: 300;
		line-height: 2.1428571429;
}
.co-table.cont-type01 .co-list-card.circle01 > li:before, .co-table.cont-type02 .co-list-card.circle01 > li:before {
		background-color: #1c1c1b;
}
.co-table.cont-type01 table th,
.co-table.cont-type01 table td {
		padding: 20px 28px;
}
.co-table.cont-type01 table th {
		background-color: #f8f9fb;
}
.co-table.cont-type01 table tbody th {
		text-align: center;
		font-weight: 400;
}
.co-table.cont-type02 table th,
.co-table.cont-type02 table td {
		padding: 20px;
}
.co-table.cont-type02 table th {
		background-color: #f8f9fb;
}
.co-table.cont-type02 table tbody th {
		text-align: center;
		color: rgba(28, 28, 27, 0.8);
}
.co-table.cont-type02 table tbody td {
		vertical-align: top;
}
.co-table.cont-type03 table {
		table-layout: fixed;
}
.co-table.cont-type03 table thead th,
.co-table.cont-type03 table thead td,
.co-table.cont-type03 table tbody th,
.co-table.cont-type03 table tbody td {
		border-color: #e9e9e9;
		padding: 20px;
}
.co-table.cont-type03 table th {
		background-color: #ecf1f8;
		color: #494949;
}
.co-table.cont-type03 table thead th {
		padding: 8px;
}
.co-table.cont-type03 table tbody th {
		text-align: center;
		color: rgba(28, 28, 27, 0.8);
}
.co-table.cont-type03 table tbody td {
		color: #777676;
		vertical-align: top;
}
.co-table.cont-type04 {
		border-right: 0;
}
.co-table.cont-type04 table thead th {
		background-color: #ecf1f8;
		font-weight: 500;
}
.co-table.cont-type04 table td {
		padding: 8px;
		border-right: 1px solid #e9e9e9;
}
.co-table.striped tbody tr {
		transition: background-color 0.5s;
}
.co-table.striped tbody tr:nth-of-type(odd) {
		background-color: #fff;
}
.co-table.striped tbody tr:nth-of-type(even) {
		background-color: #fcfcfc;
}
.co-table.striped tbody tr:not(.tbl-list-none):hover {
		background-color: #f3f3f3;
}
.co-table.striped tbody tr.tbl-list-none {
		background-color: #fff;
}
.co-table.list-type01 table {
		table-layout: fixed;
}
.co-table.list-type01 table thead th {
		padding: 8px;
}
.co-table.list-type01 table th {
		background-color: #494949;
		color: #fff;
}
.co-table.list-type01 table th:not(:last-child),
.co-table.list-type01 table td:not(:last-child) {
		border-right: 0;
}
.co-table.list-type01 table tbody th,
.co-table.list-type01 table tbody td {
		color: #494949;
		padding: 12px;
}
.co-table.list-type01 table tbody th {
		text-align: center;
}
.co-table.list-type01 table tbody td.is-ready {
		color: #a4a4a4;
}
.co-table.list-type01 table tr:not(:last-child) {
		border-bottom: 0;
}
.co-table .tbl-sorting .sort-btn {
		margin-left: 8px;
		background: url("../images/ico/ico_arrow_06.svg") 0/10px no-repeat;
		width: 10px;
		height: 10px;
		transform: rotate(-180deg);
}
.co-table .tbl-sorting .sort-btn.active {
		transform: rotate(0);
}
.co-table + .co-ref-txt {
		margin-top: 12px;
}

.title + .co-list-card {
		margin-top: 4px;
}

.inner-cont ~ .inner-cont {
		margin-top: 20px;
}

.co-tbl-paging {
		margin-top: 40px;
		display: flex;
		justify-content: center;
		align-items: center;
}
.co-tbl-paging .page-info {
		font-weight: 400;
		font-size: 12px;
		color: #d2d2d1;
}
.co-tbl-paging .page-info > em {
		color: #2f7ad3;
}
.co-tbl-paging.type02 {
		justify-content: space-between;
		margin-top: 12px;
}
.co-tbl-paging .co-ref-txt {
		font-size: 12px;
		font-weight: 400;
		line-height: 2;
}

/*
	= Pagination
*/
.pagination > ul {
		display: flex;
}
.pagination > ul li {
		padding: 0 2px;
}

.page-link {
		width: 30px;
		height: 30px;
		line-height: 30px;
		border-radius: 8px;
		display: flex;
		align-items: center;
		justify-content: center;
		color: #d2d2d1;
}
.page-link:hover {
		transition: background-color 0.4s;
		background-color: #f8f9fb;
		color: #d2d2d1;
}
.page-link .is-blind {
		background-image: url("../images/ico/ico_sprites_pagination.svg");
		background-size: auto 28px;
		background-repeat: no-repeat;
		width: 30px;
		height: 30px;
		overflow: hidden;
		text-indent: 100%;
		white-space: nowrap;
		display: inline-block;
}
.page-link .is-blind.p-first {
		background-position: 0 center;
}
.page-link .is-blind.p-prev {
		background-position: -34px center;
}
.page-link .is-blind.p-next {
		background-position: -68px center;
}
.page-link .is-blind.p-end {
		background-position: -102px center;
}
.page-link.is-active {
		background-color: #ecf1f8;
		color: #253981;
}

.va-t {
		vertical-align: top !important;
}

.va-m {
		vertical-align: middle !important;
}

.co-horizontal-scroll {
		overflow-y: hidden;
		overflow-x: auto;
}
.co-horizontal-scroll::-webkit-scrollbar {
		opacity: 0;
		height: 8px;
		width: 8px;
		background-color: #fff;
}
.co-horizontal-scroll::-webkit-scrollbar:hover {
		opacity: 1;
}
.co-horizontal-scroll ::-webkit-scrollbar-track {
		background-color: #fff;
}
.co-horizontal-scroll::-webkit-scrollbar-thumb {
		opacity: 0;
		background: #e9e9e9;
		border-radius: 50px;
		background-clip: padding-box;
}
.co-horizontal-scroll::-webkit-scrollbar-corner {
		opacity: 0;
		background-color: transparent;
}
.co-horizontal-scroll .co-table {
		display: table;
		overflow: hidden;
}
.co-horizontal-scroll table {
		table-layout: fixed;
}

.tbl-campare-fix-field::-webkit-scrollbar {
		opacity: 0;
		height: 8px;
		width: 8px;
		background-color: #fff;
}
.tbl-campare-fix-field::-webkit-scrollbar:hover {
		opacity: 1;
}
.tbl-campare-fix-field ::-webkit-scrollbar-track {
		background-color: #fff;
}
.tbl-campare-fix-field::-webkit-scrollbar-thumb {
		opacity: 0;
		background: #e9e9e9;
		border-radius: 50px;
		background-clip: padding-box;
}
.tbl-campare-fix-field::-webkit-scrollbar-corner {
		opacity: 0;
		background-color: transparent;
}
.tbl-campare-fix-field th,
.tbl-campare-fix-field td {
		border-right: 1px solid #e9e9e9;
}

.co-tbl-head-fixed {
		display: table;
		width: 100%;
		overflow: auto;
		height: 100%;
		position: relative;
		border-radius: 12px;
		border: 1px solid #ddd;
}
.co-tbl-head-fixed::-webkit-scrollbar {
		opacity: 0;
		height: 8px;
		width: 8px;
		background-color: #fff;
}
.co-tbl-head-fixed::-webkit-scrollbar:hover {
		opacity: 1;
}
.co-tbl-head-fixed ::-webkit-scrollbar-track {
		background-color: #fff;
}
.co-tbl-head-fixed::-webkit-scrollbar-thumb {
		opacity: 0;
		background: #e9e9e9;
		border-radius: 50px;
		background-clip: padding-box;
}
.co-tbl-head-fixed::-webkit-scrollbar-corner {
		opacity: 0;
		background-color: transparent;
}
.co-tbl-head-fixed thead tr:first-of-type th:last-of-type, .co-tbl-head-fixed thead tr:first-of-type th:first-of-type {
		position: relative;
}
.co-tbl-head-fixed thead tr:first-of-type th:last-of-type:before, .co-tbl-head-fixed thead tr:first-of-type th:first-of-type:before {
		position: absolute;
		top: 0;
		content: "";
		width: 1px;
		height: 8px;
		z-index: 1;
		background-color: #fff;
}
.co-tbl-head-fixed thead tr:first-of-type th:first-of-type {
		border-top-left-radius: 12px;
}
.co-tbl-head-fixed thead tr:first-of-type th:first-of-type:before {
		left: -1px;
}
.co-tbl-head-fixed thead tr:first-of-type th:last-of-type {
		border-top-right-radius: 12px;
}
.co-tbl-head-fixed thead tr:first-of-type th:last-of-type:before {
		right: -1px;
}
.co-tbl-head-fixed thead tr:first-of-type th:last-of-type:after {
		position: absolute;
		top: 0;
		right: 0;
		content: "";
		width: 5px;
		height: 1px;
		z-index: 1;
		background-color: #fff;
}

.tbl-useagree {
		width: max-content;
		width: 100%;
		text-align: center;
		border-top: 1px solid #e9e9e9;
		border-bottom: 1px solid #e9e9e9;
}
.tbl-useagree thead th {
		text-align: center;
		border-bottom: 1px solid #e9e9e9;
		font-size: 14px;
		font-weight: 400;
		line-height: 2;
		vertical-align: middle;
		padding: 8px;
		color: #494949;
}
.tbl-useagree tbody {
		border-top: 1px solid #d2d2d1;
}
.tbl-useagree tbody th {
		font-weight: 300;
}
.tbl-useagree th,
.tbl-useagree td {
		word-break: keep-all;
		vertical-align: middle;
		padding: 8px;
		border-bottom: 1px solid #e9e9e9;
}
.tbl-useagree.type01 thead th,
.tbl-useagree.type01 tbody th {
		text-align: center;
}
.tbl-useagree.type01 tbody td {
		text-align: left;
}

.c-t-cont-type01 {
		font-size: 16px;
		font-weight: 400;
		line-height: 2;
}

.t-i-help01 {
		background-position: 0 0px;
}

.ico-type01 {
		background-image: url("../images/ico/ico_sprites_01.svg");
		background-size: auto 218px;
		background-repeat: no-repeat;
		width: 28px;
		height: 28px;
		overflow: hidden;
		text-indent: 100%;
		white-space: nowrap;
		display: inline-block;
}
.ico-type01.t-i-info01 {
		background-position: -38px 0;
}
.ico-type01.t-i-arrow01-01 {
		background-position: -76px 0;
}
.ico-type01.t-i-arrow01-double {
		background-position: -114px 0;
}
.ico-type01.t-i-arrow03-01 {
		background-position: -152px 0;
}
.ico-type01.t-i-arrow02-01 {
		background-position: -190px 0;
}
.ico-type01.t-i-date {
		background-position: -228px 0;
}
.ico-type01.t-i-time {
		background-position: -266px 0;
}
.ico-type01.t-i-search {
		background-position: -304px 0;
}
.ico-type01.t-i-close01-01 {
		background-position: -342px 0;
}
.ico-type01.t-i-home {
		background-position: -380px 0;
}
.ico-type01.t-i-profile {
		background-position: -418px 0;
}
.ico-type01.t-i-nav01 {
		background-position: -456px 0;
}
.ico-type01.t-i-nav02 {
		background-position: -494px 0;
}
.ico-type01.t-i-nav03 {
		background-position: -532px 0;
}
.ico-type01.t-i-nav04 {
		background-position: -570px 0;
}
.ico-type01.t-i-nav05 {
		background-position: -608px 0;
}
.ico-type01.t-i-add-ci {
		background-position: -646px 0;
}
.ico-type01.t-i-datepick {
		background-position: -684px 0;
}
.ico-type01.t-i-document-search {
		background-position: -722px 0;
}
.ico-type01.t-i-arrow-full {
		background-position: -760px 0;
}
.ico-type01.t-i-map {
		background-position: -798px 0;
}
.ico-type01.t-i-favorite01 {
		background-position: -836px 0;
}
.ico-type01.t-i-assignment {
		background-position: -874px 0;
}
.ico-type01.t-i-refresh {
		background-position: -912px 0;
}
.ico-type01.t-i-check {
		background-position: -950px 0;
}
.ico-type01.t-i-close02 {
		background-position: -988px 0;
}
.ico-type01.t-i-down {
		background-position: -1026px 0;
}
.ico-type01.t-i-eye {
		background-position: -1064px 0;
}
.ico-type01.t-i-menu-hamburger {
		background-position: -1102px 0;
}
.ico-type01.t-i-special {
		background-position: -1140px 0;
}
.ico-type01.t-i-center-info nav06 {
		background-position: -1178px 0;
}
.ico-type01.t-i-nav06 {
		background-position: -1216px 0;
}
.ico-type01.t-i-del02 {
		background-position: -1254px 0;
}
.ico-type01.t-i-help01-02 {
		background-position: 0 -38px;
}
.ico-type01.t-i-help01-03 {
		background-position: 0 -76px;
}
.ico-type01.t-i-del02:hover {
		background-position: -1254px -38px;
}

.ico-type01.t-i-arrow01-02 {
		background-position: -76px -38px;
}

.ico-type01.t-i-arrow01-03 {
		background-position: -76px -76px;
}

.ico-type01.t-i-arrow01-04 {
		background-position: -76px -114px;
}

.ico-type01.t-i-arrow01-05 {
		background-position: -76px -152px;
}

.ico-type01.t-i-arrow03-02 {
		background-position: -152px -38px;
}

.ico-type01.t-i-arrow03-03 {
		background-position: -152px -76px;
}

.ico-type01.t-i-arrow03-04 {
		background-position: -152px -114px;
}

.ico-type.t-i-arrow02-02 {
		background-position: -192px -36px;
}

.ico-type01.t-i-close01-02 {
		background-position: -152px -38px;
}

.ico-type01.t-i-close01-03 {
		background-position: -152px -76px;
}

.ico-type01.t-i-nav01-02 {
		background-position: -456px -38px;
}

.ico-type01.t-i-nav01-03 {
		background-position: -456px -76px;
}

.ico-type01.t-i-nav02-02 {
		background-position: -494px -38px;
}

.ico-type01.t-i-nav02-03 {
		background-position: -494px -76px;
}

.ico-type01.t-i-nav03-02 {
		background-position: -532px -38px;
}

.ico-type01.t-i-nav03-03 {
		background-position: -532px -76px;
}

.ico-type01.t-i-nav04-02 {
		background-position: -570px -38px;
}

.ico-type01.t-i-nav04-03 {
		background-position: -570px -76px;
}

.ico-type01.t-i-nav02-02 {
		background-position: -608px -38px;
}

.ico-type01.t-i-nav02-03 {
		background-position: -608px -76px;
}

.ico-type01.t-i-favorite01-02 {
		background-position: -836px -38px;
}

.ico-type02 {
		background-image: url("../images/ico/ico_sprites_02.svg");
		background-size: 542px auto;
		background-repeat: no-repeat;
		width: 28px;
		height: 28px;
		display: inline-block;
		overflow: hidden;
		text-indent: 100%;
		white-space: nowrap;
		display: inline-block;
}
.ico-type02.i-check01 {
		background-position: -38px 0;
}
.ico-type02.i-check02 {
		background-position: -76px 0;
}
.ico-type02.i-check03 {
		background-position: -114px 0;
}
.ico-type02.i-check04 {
		background-position: -152px 0;
}
.ico-type02.i-arrow-left01 {
		background-position: -190px 0;
}
.ico-type02.i-arrow-left02 {
		background-position: -228px 0;
}
.ico-type02.i-arrow-right01 {
		background-position: -266px 0;
}
.ico-type02.i-arrow-right02 {
		background-position: -304px 0;
}
.ico-type02.i-date {
		background-position: -342px 0;
}
.ico-type02.i-time {
		background-position: -380px 0;
}
.ico-type02.i-search {
		background-position: -418px 0;
}
.ico-type02.i-close {
		background-position: -456px 0;
}
.ico-type02.i-info {
		background-position: -494px 0;
}
.ico-type02.i-play {
		background-position: -532px 0;
}

.ico-search {
		background: url("../images/ico/ico_search_01.svg") center/100% no-repeat;
		width: 17px;
		height: 17px;
}

.ico-checkbox {
		background-image: url("../images/ico/ico_sprites_checkbox01.svg");
		background-size: auto 28px;
		background-repeat: no-repeat;
		width: 28px;
		height: 28px;
		display: inline-block;
		overflow: hidden;
		text-indent: 100%;
		white-space: nowrap;
		display: inline-block;
}
.ico-checkbox.is-default {
		background-position: 0 0;
}
.ico-checkbox.is-default {
		background-position: 0px 0;
}
.ico-checkbox.is-white {
		background-position: 0px 0;
}
.ico-checkbox.is-main {
		background-position: 0px 0;
}
.ico-checkbox.is-sub {
		background-position: 0px 0;
}
.ico-checkbox.is-red {
		background-position: 0px 0;
}
.ico-checkbox.is-full {
		background-position: 0px 0;
}

.ico-chkupitem {
		background: url("../images/ico/ico_sprites_checkup.png") center/396px auto no-repeat;
		width: 46px;
		height: 46px;
		display: inline-block;
		overflow: hidden;
		text-indent: 100%;
		white-space: nowrap;
		display: inline-block;
}
.ico-chkupitem.item-1 {
		background-position: -10px -10px;
}
.ico-chkupitem.item-7 {
		background-position: -10px -76px;
}
.ico-chkupitem.item-13 {
		background-position: -10px -142px;
}
.ico-chkupitem.item-19 {
		background-position: -10px -208px;
}
.ico-chkupitem.item-25 {
		background-position: -10px -274px;
}
.ico-chkupitem.item-31 {
		background-position: -10px -340px;
}
.ico-chkupitem.item-37 {
		background-position: -10px -406px;
}
.ico-chkupitem.item-43 {
		background-position: -10px -472px;
}
.ico-chkupitem.item-2 {
		background-position: -76px -10px;
}
.ico-chkupitem.item-3 {
		background-position: -142px -10px;
}
.ico-chkupitem.item-4 {
		background-position: -208px -10px;
}
.ico-chkupitem.item-5 {
		background-position: -274px -10px;
}
.ico-chkupitem.item-6 {
		background-position: -340px -10px;
}
.ico-chkupitem.item-8 {
		background-position: -76px -76px;
}
.ico-chkupitem.item-9 {
		background-position: -142px -76px;
}
.ico-chkupitem.item-10 {
		background-position: -208px -76px;
}
.ico-chkupitem.item-11 {
		background-position: -274px -76px;
}
.ico-chkupitem.item-12 {
		background-position: -340px -76px;
}
.ico-chkupitem.item-14 {
		background-position: -76px -142px;
}
.ico-chkupitem.item-15 {
		background-position: -142px -142px;
}
.ico-chkupitem.item-16 {
		background-position: -208px -142px;
}
.ico-chkupitem.item-17 {
		background-position: -274px -142px;
}
.ico-chkupitem.item-18 {
		background-position: -340px -142px;
}
.ico-chkupitem.item-20 {
		background-position: -76px -208px;
}
.ico-chkupitem.item-21 {
		background-position: -142px -208px;
}
.ico-chkupitem.item-22 {
		background-position: -208px -208px;
}
.ico-chkupitem.item-23 {
		background-position: -274px -208px;
}
.ico-chkupitem.item-24 {
		background-position: -340px -208px;
}
.ico-chkupitem.item-26 {
		background-position: -76px -274px;
}
.ico-chkupitem.item-27 {
		background-position: -142px -274px;
}
.ico-chkupitem.item-28 {
		background-position: -208px -274px;
}
.ico-chkupitem.item-29 {
		background-position: -274px -274px;
}
.ico-chkupitem.item-30 {
		background-position: -340px -274px;
}
.ico-chkupitem.item-32 {
		background-position: -76px -340px;
}
.ico-chkupitem.item-33 {
		background-position: -142px -340px;
}
.ico-chkupitem.item-34 {
		background-position: -208px -340px;
}
.ico-chkupitem.item-35 {
		background-position: -274px -340px;
}
.ico-chkupitem.item-36 {
		background-position: -340px -340px;
}
.ico-chkupitem.item-38 {
		background-position: -76px -406px;
}
.ico-chkupitem.item-39 {
		background-position: -142px -406px;
}
.ico-chkupitem.item-40 {
		background-position: -208px -406px;
}
.ico-chkupitem.item-41 {
		background-position: -274px -406px;
}
.ico-chkupitem.item-42 {
		background-position: -340px -406px;
}
.ico-chkupitem.item-44 {
		background-position: -76px -472px;
}
.ico-chkupitem.item-45 {
		background-position: -142px -472px;
}
.ico-chkupitem.item-46 {
		background-position: -208px -472px;
}

.ico-bg-type {
		padding: 6px;
		border-radius: 12px;
		min-width: 32px;
		min-height: 32px;
		display: inline-block;
		width: 32px;
		height: 32px;
		font-size: 0;
		background-image: url("../images/ico/ico_sprites_01.svg");
		background-size: auto 218px;
		background-repeat: no-repeat;
		display: inline-block;
}
.ico-bg-type.is-red {
		background-color: #ffebe9;
}
.ico-bg-type.is-blue {
		background-color: #ecf1f8;
}
.ico-bg-type.is-blue.info01 {
		background-position: -36px 2px;
}
.ico-bg-type.is-blue.assignment {
		background-position: -872px 2px;
}

.ico-bg-type.favorite {
		background-size: 20px;
		background-position: center;
		background-repeat: no-repeat;
}
.ico-bg-type.is-red.favorite, .ico-bg-type.is-red.compare {
		background-image: url("../images/ico/ico_compare_02.svg");
}
.ico-bg-type.is-blue.favorite, .ico-bg-type.is-blue.compare {
		background-image: url("../images/ico/ico_compare_01.svg");
}

.ico-checkup-01 {
		width: 17px;
		height: 13px;
		display: inline-block;
		background: url("../images/ico/ico_chk_01.svg") center/100% auto no-repeat;
}

.ico-checkup-01 {
		width: 17px;
		height: 13px;
		display: inline-block;
		background: url("../images/ico/ico_chk_01.svg") center/100% auto no-repeat;
}

.c-res-txt-type01 {
		display: flex;
		align-items: center;
		justify-content: center;
}
.c-res-txt-type01 > i {
		margin-left: 6px;
}

[class^=ico-res-arrow] {
		border-color: #253981;
		border-left: 5px solid transparent;
		border-right: 5px solid transparent;
		border-top: 10px solid #253981;
}

.ico-res-arrow-up {
		transform: rotate(-180deg);
}

.ico-res-arrow-down {
		transform: rotate(0);
}

#container {
		min-height: 100%;
		display: flex;
		flex: 1 0 auto;
		height: 100%;
}
#container.is-active #sideNav {
		width: 80px;
}
#container.login-container {
		min-height: 100%;
}

#container .content-wrapper {
		padding-left: 260px;
		width: 100%;
		min-height: 100%;
}

/*
	= Side Nav
*/
#sideNav {
		display: flex;
		flex-direction: column;
		width: 260px;
		overflow-x: hidden;
		position: fixed;
		background-color: #253981;
		top: 0;
		left: 0;
		height: 100%;
		z-index: 500;
}
#sideNav .sidenav-wrapper {
		display: flex;
		flex-direction: column;
		overflow: hidden;
}
#sideNav .sidebar-wrap {
		height: 100%;
		overflow: hidden;
}
#sideNav .top-logo {
		height: 80px;
		min-height: 80px;
		padding: 16px 28px 16px 20px;
		border-bottom: 1px solid #3b4e93;
}
#sideNav .top-logo .logo {
		position: relative;
		height: 100%;
		display: flex;
}
#sideNav .top-logo .logo > a {
		display: flex;
		align-items: center;
		padding: 8px 0;
		width: 100%;
		height: 48px;
		line-height: 1;
		overflow: hidden;
		text-align: center;
}
#sideNav .top-logo .logo img {
		height: auto;
		max-height: 100%;
		max-width: 100%;
}
#sideNav .top-logo .logo.type-color a {
		background-color: #fff;
		border-radius: 16px;
		justify-content: center;
		padding-left: 8px;
		padding-right: 8px;
}
.sidebar-menu {
		height: 100%;
		overflow-y: auto;
}
.sidebar-menu::-webkit-scrollbar {
		opacity: 0;
		height: 8px;
		width: 8px;
		background-color: #fff;
}
.sidebar-menu::-webkit-scrollbar:hover {
		opacity: 1;
}
.sidebar-menu ::-webkit-scrollbar-track {
		background-color: #fff;
}
.sidebar-menu::-webkit-scrollbar-thumb {
		opacity: 0;
		background: #e9e9e9;
		border-radius: 50px;
		background-clip: padding-box;
}
.sidebar-menu::-webkit-scrollbar-corner {
		opacity: 0;
		background-color: transparent;
}
.sidebar-menu li a {
		color: rgba(255, 255, 255, 0.8);
		position: relative;
}
.sidebar-menu > li > a {
		padding: 14px 20px;
		color: rgba(255, 255, 255, 0.8);
		display: flex;
		align-items: center;
		font-size: 16px;
		font-weight: 400;
		line-height: 2;
}
.sidebar-menu > li > a:after {
		position: absolute;
		top: 50%;
		right: 10px;
		background: url("../images/ico/ico_arrow_03.svg") right center/100% no-repeat;
		content: "";
		display: inline-block;
		width: 28px;
		height: 28px;
		transition: transform 0.4s;
		transform: translateY(-50%) rotate(0deg);
}
.sidebar-menu > li > a:only-child:after {
		display: none;
}
.sidebar-menu > li > a.on {
		color: #253981;
		background-color: #fff;
}
.sidebar-menu > li > a.on:after {
		background-image: url("../images/ico/ico_arrow_11.svg");
		transition: transform 0.4s;
		transform: translateY(-50%) rotate(-360deg);
}
.sidebar-menu > li > a.on > .s-menu:before {
		background-position-y: -38px;
}
.sidebar-menu > li > a.on .badge:hover {
		box-shadow: none;
}
.sidebar-menu .s-menu {
		position: relative;
		padding-left: 34px;
		vertical-align: middle;
}
.sidebar-menu .s-menu:before {
		display: inline-block;
		position: absolute;
		top: 0;
		left: 0;
		content: "";
		width: 28px;
		height: 28px;
		background-image: url("../images/ico/ico_sprites_01.svg");
		background-position-y: 0;
		background-size: auto 218px;
}
.sidebar-menu .s-menu.nav01:before {
		background-position-x: -456px;
}
.sidebar-menu .s-menu.nav02:before {
		background-position-x: -494px;
}
.sidebar-menu .s-menu.nav03:before {
		background-position-x: -532px;
}
.sidebar-menu .s-menu.nav04:before {
		background-position-x: -570px;
}
.sidebar-menu .s-menu.nav05:before {
		background-position-x: -608px;
}
.sidebar-menu .s-menu.nav06:before {
		background-position-x: -1216px;
}
.sidebar-menu .badge {
		margin-top: 1px;
		margin-left: 12px;
		display: inline-flex;
		font-size: 0;
		line-height: 12px;
		transition: box-shadow 0.5s;
}
.sidebar-menu .badge > em {
		padding: 0 8px;
		display: inline-block;
		border-radius: 4px;
		background-color: #ff6859;
		font-size: 12px;
		font-weight: 500;
		line-height: 1.6666666667;
		text-align: center;
		color: #fff;
		font-style: normal;
		position: relative;
}
.sidebar-menu .badge > em:before {
		content: "";
		position: absolute;
		top: 4px;
		left: -7px;
		border-top: 6px solid transparent;
		border-bottom: 6px solid transparent;
		border-right: 8px solid #ff6859;
}
.sidebar-menu .badge:hover {
		transition: box-shadow 0.5s;
		box-shadow: 0 0 10px 2px rgba(255, 255, 255, 0.25);
}

.sidebar-sub-menu {
		display: none;
		overflow: hidden;
		padding-top: 12px;
		padding-bottom: 12px;
}
.sidebar-sub-menu > li > a {
		padding: 12px 24px;
		display: inline-block;
		position: relative;
		color: rgba(255, 255, 255, 0.6);
}
.sidebar-sub-menu > li > a.on {
		color: #fff;
}
.sidebar-sub-menu > li > a.on:before {
		background-color: #fff;
}

/*
= Header
*/
.header {
		height: 80px;
		background-color: #fff;
		width: 100%;
		position: fixed;
		padding-left: 260px;
		top: 0;
		z-index: 100;
		border-bottom: 1px solid #e9e9e9;
		left: 0;
}
.header .wrap {
		display: flex;
		padding: 16px 60px;
		justify-content: space-between;
		width: 100%;
		align-items: center;
		max-width: 2300px;
}
.header .top-left,
.header .top-right {
		display: flex;
		flex: 0 0 auto;
		align-items: center;
}
.header .top-right .user-info {
		display: flex;
		align-items: center;
}
.header .top-right .user-info > li {
		vertical-align: middle;
		padding-left: 20px;
		color: #777676;
}
.header .slogan {
		font-size: 20px;
		font-weight: 500;
		line-height: 1.8;
		color: #494949;
}
.header .user-profile {
		position: relative;
		border-radius: 20px;
		margin-left: 30px;
		background-color: #253981;
}
.header .user-profile > a {
		opacity: 1;
		padding: 8px 20px 8px 16px;
		transition: opacity 0.3s;
		display: flex;
		align-items: center;
		border-radius: 20px;
		text-align: center;
}
.header .user-profile > a:hover {
		opacity: 0.8;
}
.header .user-profile .name {
		font-size: 16px;
		font-weight: 500;
		line-height: 2;
		color: rgba(255, 255, 255, 0.6);
		padding-left: 12px;
}
.header .user-profile .name > em {
		color: #fff;
}
.header .user-profile .img {
		width: 24px;
		height: 24px;
		border-radius: 50%;
		overflow: hidden;
}
.header .btn-area {
		float: left;
}
.header .btn-area:after {
		display: block;
		content: "";
		clear: both;
}
.header .user-control {
		width: 135px;
		position: absolute;
		z-index: 100;
		top: 49px;
		left: 0;
		border-radius: 8px;
		background-color: #fff;
		box-shadow: 2px 2px 4px 0 rgba(0, 0, 0, 0.25);
		border: 1px solid #e9e9e9;
		transform: scale(1, 0);
		transition: transform 0.1s ease-in;
		transform-origin: 0 0;
}
.header .user-control > ul {
		opacity: 0;
}
.header .user-control > ul > li ~ li {
		border-top: 1px solid #e9e9e9;
}
.header .user-control > ul > li a,
.header .user-control > ul > li button {
		display: block;
		padding: 11px 20px;
		color: #494949;
		font-size: 14px;
		font-weight: 300;
		line-height: 2.1428571429;
}
.header .user-control.on {
		transform: scale(1, 1);
		transition: transform 0.1s ease-in;
}
.header .user-control.on > ul {
		opacity: 1;
}

.contents {
		width: 100%;
		max-width: 2300px;
}

.login-wrapper .contents {
		height: auto;
}

.contents-wrap {
		min-width: 1200px;
		min-height: calc(100% - 223px);
		width: 100%;
		display: flex;
		flex-grow: 1;
		display: flex;
		flex-direction: column;
}
.contents-wrap::before {
		content: "";
		display: block;
		height: 100%;
}
.contents-wrap .contents {
		padding: 80px 0 0;
		flex: 1 0 auto;
}
.contents-wrap .header,
.contents-wrap .footer {
		flex-shrink: 0;
}
.contents-wrap .header {
		min-width: 700px;
}
.contents-wrap .cont-wrap {
		display: flex;
		width: 100%;
		height: 100%;
}
.contents-wrap .checkup-sidebar {
		border-right: 1px solid #e9e9e9;
		flex-shrink: 0;
		background-color: #f8f9fb;
		min-height: calc(100vh - 320px);
		max-height: 100vh;
		justify-content: stretch;
		overflow: hidden;
}
.contents-wrap .checkup-sidebar > .inner-wrap {
		padding: 40px;
		max-height: 100%;
		overflow-x: hidden;
		overflow-y: auto;
		max-width: 388px;
		min-width: 388px;
}
.contents-wrap .contents-area {
		flex-grow: 1;
		flex-shrink: 1;
		padding: 40px 60px 60px;
		min-width: 1200px;
}
.contents-wrap .contents-area.main-contents-area {
		height: 100%;
}
.contents-wrap .contents-area .co-btn:not([class*=btn-reservation-]) {
		position: relative;
		overflow: hidden;
}
.contents-wrap .contents-area .co-btn:not([class*=btn-reservation-]):not(:disabled):after {
		background-image: linear-gradient(to bottom, rgba(255, 255, 255, 0) 0, rgba(255, 255, 255, 0.493) 50%, rgba(255, 255, 255, 0) 100%);
		content: "";
		height: 200%;
		left: -80%;
		opacity: 0.2;
		position: absolute;
		top: 50%;
		transform: rotate(30deg) translateY(-50%);
		width: 20px;
		z-index: -10;
}
.contents-wrap .contents-area .co-btn:not([class*=btn-reservation-]):not(:disabled):hover {
		opacity: 0.8;
}
.contents-wrap .contents-area .co-btn:not([class*=btn-reservation-]):not(:disabled):hover:after {
		animation: coBtnHoverAni 0.5s linear 1;
}
.contents-wrap .contents-full {
		display: flex;
		flex-direction: column;
}

.co-wrap {
		min-width: 1200px;
		margin: 0 auto;
}

footer.footer {
		bottom: 0;
		background-color: #1c1c1b;
		width: 100%;
		min-width: 1200px;
		position: relative;
		z-index: 1;
}
footer.footer .container {
		display: flex;
		flex: 0 0 auto;
		width: 100%;
		align-items: center;
		max-width: 2300px;
		position: relative;
		padding: 40px 60px;
}
footer.footer .logo {
		flex-shrink: 0;
}
footer.footer .logo {
		padding-right: 60px;
}
footer.footer .logo img {
		width: 180px;
}
footer.footer .company-info {
		margin-top: 20px;
		white-space: nowrap;
		color: #a4a4a4;
}
footer.footer .company-info > li {
		line-height: 1.7;
}
footer.footer .company-info > li > span {
		display: inline-block;
}
footer.footer .company-info > li > span ~ span {
		margin-left: 6px;
}
footer.footer .top-useinfo:after {
		display: block;
		content: "";
		clear: both;
}
footer.footer .top-useinfo > li {
		float: left;
		position: relative;
}
footer.footer .top-useinfo > li ~ li {
		padding-left: 21px;
}
footer.footer .top-useinfo > li ~ li :before {
		position: absolute;
		left: 10px;
		position: absolute;
		content: "";
		width: 1px;
		height: 12px;
		background-color: #494949;
		top: calc(50% + 1px);
		transform: translateY(-50%);
}
footer.footer .top-useinfo a {
		color: #d2d2d1;
}
footer.footer .top-useinfo .personal-policy > a {
		color: #489cff;
}
footer.footer .btn-go-top {
		position: absolute;
		top: 80px;
		right: 60px;
		background: url("../images/ico//ico_arrow_12.svg") center/22px auto no-repeat;
		width: 46px;
		height: 46px;
		background-color: #282827;
		border-radius: 12px;
		display: inline-block;
}

.co-complete-wrap {
		display: flex;
		flex-direction: column;
}
.co-complete-wrap > .contents-area {
		display: flex;
		flex-direction: column;
}
.co-complete-wrap .co-complete-field {
		align-items: stretch;
}

.co-tab-wrap.type02 {
		display: inline-block;
}
.co-tab-wrap.type02 > li {
		width: auto;
}
.co-tab-wrap.type02 > li {
		float: left;
		width: auto;
}
.co-tab-wrap.type02 > li a {
		padding: 2px 30px;
}
.co-tab-wrap.type02 > li + li {
		margin-left: 8px;
}
.co-tab-wrap.type02 .co-tab-list {
		display: block;
}
.co-tab-wrap.type02 .co-tab-list:after {
		display: block;
		content: "";
		clear: both;
}

/*
	Common
*/
@font-face {
		font-family: NotoSansKR;
		font-weight: 100;
		font-style: normal;
		src: url("../fonts/NotoSansKR-Thin.woff") format("woff"), url("../fonts/NotoSansKR-Thin.woff2") format("woff2"), url("../fonts/NotoSansKR-Thin.otf") format("opentype");
}
@font-face {
		font-family: NotoSansKR;
		font-weight: 300;
		font-style: normal;
		src: url("../fonts/NotoSansKR-Light.woff") format("woff"), url("../fonts/NotoSansKR-Light.woff2") format("woff2"), url("../fonts/NotoSansKR-Light.otf") format("opentype");
}
@font-face {
		font-family: NotoSansKR;
		font-weight: 400;
		font-style: normal;
		src: url("../fonts/NotoSansKR-Regular.woff") format("woff"), url("../fonts/NotoSansKR-Regular.woff2") format("woff2"), url("../fonts/NotoSansKR-Regular.otf") format("opentype");
}
@font-face {
		font-family: NotoSansKR;
		font-weight: 500;
		font-style: normal;
		src: url("../fonts/NotoSansKR-Medium.woff") format("woff"), url("../fonts/NotoSansKR-Medium.woff2") format("woff2"), url("../fonts/NotoSansKR-Medium.otf") format("opentype");
}
@font-face {
		font-family: NotoSansKR;
		font-weight: 700;
		font-style: normal;
		src: url("../fonts/NotoSansKR-Bold.woff") format("woff"), url("../fonts/NotoSansKR-Bold.woff2") format("woff2"), url("../fonts/NotoSansKR-Bold.otf") format("opentype");
}
@font-face {
		font-family: NotoSansKR;
		font-weight: 900;
		font-style: normal;
		src: url("../fonts/NotoSansKR-Black.woff") format("woff"), url("../fonts/NotoSansKR-Black.woff2") format("woff2"), url("../fonts/NotoSansKR-Black.otf") format("opentype");
}
a.co-link,
.co-link,
a.co-link-2,
.co-link-2 {
		display: inline-block;
		text-decoration: underline;
		color: #2f7ad3;
}

a.co-link,
.co-link {
		font-size: 12px;
		font-weight: 300;
		line-height: 1.4166666667;
}

a.co-link-2,
.co-link-2 {
		font-size: 12px;
		font-weight: 400;
		line-height: 2;
}

a.co-link-lined,
.co-link-lined {
		text-decoration: underline;
}

.fw-bold {
		font-weight: 700;
}

.is-white-1 {
		color: #fff !important;
}

.is-white-2 {
		color: #fcfcfc !important;
}

.is-black-1 {
		color: #1c1c1b !important;
}

.is-black-2 {
		color: #222225 !important;
}

.is-blue-1 {
		color: #2f7ad3 !important;
}

.is-navy-1 {
		color: #253981 !important;
}

.is-navy-2 {
		color: #225490 !important;
}

.is-orange-1 {
		color: #ff6859 !important;
}

.bg-white-1 {
		background-color: #fff !important;
}

.bg-white-2 {
		background-color: #fcfcfc !important;
}

.bg-black-1 {
		background-color: #1c1c1b !important;
}

.bg-black-2 {
		background-color: #222225 !important;
}

.bg-blue-1 {
		background-color: #2f7ad3 !important;
}

.bg-navy-1 {
		background-color: #253981 !important;
}

.bg-navy-2 {
		background-color: #225490 !important;
}

.bg-green-1 {
		background-color: #206f44 !important;
}

.bg-orange-1 {
		background-color: #ff6859 !important;
}

/* $color_list[$i]*/
.is-gray-1 {
		color: #494949 !important;
}

.is-gray-2 {
		color: #777676 !important;
}

.is-gray-3 {
		color: #a4a4a4 !important;
}

.is-gray-4 {
		color: #d2d2d1 !important;
}

.is-gray-5 {
		color: #e9e9e9 !important;
}

.is-gray-6 {
		color: #d2d2d1 !important;
}

.is-gray-7 {
		color: #f3f3f3 !important;
}

.is-gray-8 {
		color: #ecf1f8 !important;
}

.is-gray-9 {
		color: #f5f5f9 !important;
}

.is-gray-10 {
		color: #c0c4d1 !important;
}

.is-gray-11 {
		color: #525258 !important;
}

.is-gray-12 {
		color: #f8f9fb !important;
}

.is-gray-13 {
		color: #d9d9d9 !important;
}

.bg-gray-1 {
		background-color: #494949 !important;
}

.bg-gray-2 {
		background-color: #777676 !important;
}

.bg-gray-3 {
		background-color: #a4a4a4 !important;
}

.bg-gray-4 {
		background-color: #d2d2d1 !important;
}

.bg-gray-5 {
		background-color: #e9e9e9 !important;
}

.bg-gray-6 {
		background-color: #d2d2d1 !important;
}

.bg-gray-7 {
		background-color: #f3f3f3 !important;
}

.bg-gray-8 {
		background-color: #ecf1f8 !important;
}

.bg-gray-9 {
		background-color: #f5f5f9 !important;
}

.bg-gray-10 {
		background-color: #c0c4d1 !important;
}

.bg-gray-11 {
		background-color: #525258 !important;
}

.bg-gray-12 {
		background-color: #f8f9fb !important;
}

.bg-gray-13 {
		background-color: #d9d9d9 !important;
}

.skip-navigation {
		position: absolute;
		left: 0;
		width: 100%;
		z-index: 1000;
}
.skip-navigation p a {
		position: absolute;
		top: -200px;
}
.skip-navigation p a:focus, .skip-navigation p a:active {
		top: 0;
		left: 0;
		display: block;
		width: 100%;
		color: #fff;
		padding: 5px 3px 0 3px;
		text-align: center;
		font-weight: 700;
		background: #2f7ad3;
}

.skip {
		position: absolute;
		overflow: hidden;
		clip: rect(0 0 0 0);
		margin: -1px;
		padding: 0;
		width: 1px;
		height: 1px;
		border: 0;
}

.menu-toggle {
		width: 59px;
		height: 43px;
}

.is-blind {
		overflow: hidden;
		text-indent: 100%;
		white-space: nowrap;
		display: inline-block;
}

.mt-0 {
		margin-top: 0 !important;
}

.ml-0 {
		margin-left: 0 !important;
}

.mr-0 {
		margin-right: 0 !important;
}

.mb-0 {
		margin-bottom: 0 !important;
}

.pt-0 {
		padding-top: 0 !important;
}

.pl-0 {
		padding-left: 0 !important;
}

.pr-0 {
		padding-right: 0 !important;
}

.pb-0 {
		padding-bottom: 0 !important;
}

.mt-1 {
		margin-top: 1 !important;
}

.ml-1 {
		margin-left: 1 !important;
}

.mr-1 {
		margin-right: 1 !important;
}

.mb-1 {
		margin-bottom: 1 !important;
}

.pt-1 {
		padding-top: 1 !important;
}

.pl-1 {
		padding-left: 1 !important;
}

.pr-1 {
		padding-right: 1 !important;
}

.pb-1 {
		padding-bottom: 1 !important;
}

.mt-2 {
		margin-top: 2 !important;
}

.ml-2 {
		margin-left: 2 !important;
}

.mr-2 {
		margin-right: 2 !important;
}

.mb-2 {
		margin-bottom: 2 !important;
}

.pt-2 {
		padding-top: 2 !important;
}

.pl-2 {
		padding-left: 2 !important;
}

.pr-2 {
		padding-right: 2 !important;
}

.pb-2 {
		padding-bottom: 2 !important;
}

.mt-4 {
		margin-top: 4 !important;
}

.ml-4 {
		margin-left: 4 !important;
}

.mr-4 {
		margin-right: 4 !important;
}

.mb-4 {
		margin-bottom: 4 !important;
}

.pt-4 {
		padding-top: 4 !important;
}

.pl-4 {
		padding-left: 4 !important;
}

.pr-4 {
		padding-right: 4 !important;
}

.pb-4 {
		padding-bottom: 4 !important;
}

.mt-6 {
		margin-top: 6 !important;
}

.ml-6 {
		margin-left: 6 !important;
}

.mr-6 {
		margin-right: 6 !important;
}

.mb-6 {
		margin-bottom: 6 !important;
}

.pt-6 {
		padding-top: 6 !important;
}

.pl-6 {
		padding-left: 6 !important;
}

.pr-6 {
		padding-right: 6 !important;
}

.pb-6 {
		padding-bottom: 6 !important;
}

.mt-8 {
		margin-top: 8 !important;
}

.ml-8 {
		margin-left: 8 !important;
}

.mr-8 {
		margin-right: 8 !important;
}

.mb-8 {
		margin-bottom: 8 !important;
}

.pt-8 {
		padding-top: 8 !important;
}

.pl-8 {
		padding-left: 8 !important;
}

.pr-8 {
		padding-right: 8 !important;
}

.pb-8 {
		padding-bottom: 8 !important;
}

.mt-10 {
		margin-top: 10 !important;
}

.ml-10 {
		margin-left: 10 !important;
}

.mr-10 {
		margin-right: 10 !important;
}

.mb-10 {
		margin-bottom: 10 !important;
}

.pt-10 {
		padding-top: 10 !important;
}

.pl-10 {
		padding-left: 10 !important;
}

.pr-10 {
		padding-right: 10 !important;
}

.pb-10 {
		padding-bottom: 10 !important;
}

.mt-12 {
		margin-top: 12 !important;
}

.ml-12 {
		margin-left: 12 !important;
}

.mr-12 {
		margin-right: 12 !important;
}

.mb-12 {
		margin-bottom: 12 !important;
}

.pt-12 {
		padding-top: 12 !important;
}

.pl-12 {
		padding-left: 12 !important;
}

.pr-12 {
		padding-right: 12 !important;
}

.pb-12 {
		padding-bottom: 12 !important;
}

.mt-14 {
		margin-top: 14 !important;
}

.ml-14 {
		margin-left: 14 !important;
}

.mr-14 {
		margin-right: 14 !important;
}

.mb-14 {
		margin-bottom: 14 !important;
}

.pt-14 {
		padding-top: 14 !important;
}

.pl-14 {
		padding-left: 14 !important;
}

.pr-14 {
		padding-right: 14 !important;
}

.pb-14 {
		padding-bottom: 14 !important;
}

.mt-15 {
		margin-top: 15 !important;
}

.ml-15 {
		margin-left: 15 !important;
}

.mr-15 {
		margin-right: 15 !important;
}

.mb-15 {
		margin-bottom: 15 !important;
}

.pt-15 {
		padding-top: 15 !important;
}

.pl-15 {
		padding-left: 15 !important;
}

.pr-15 {
		padding-right: 15 !important;
}

.pb-15 {
		padding-bottom: 15 !important;
}

.mt-16 {
		margin-top: 16 !important;
}

.ml-16 {
		margin-left: 16 !important;
}

.mr-16 {
		margin-right: 16 !important;
}

.mb-16 {
		margin-bottom: 16 !important;
}

.pt-16 {
		padding-top: 16 !important;
}

.pl-16 {
		padding-left: 16 !important;
}

.pr-16 {
		padding-right: 16 !important;
}

.pb-16 {
		padding-bottom: 16 !important;
}

.mt-18 {
		margin-top: 18 !important;
}

.ml-18 {
		margin-left: 18 !important;
}

.mr-18 {
		margin-right: 18 !important;
}

.mb-18 {
		margin-bottom: 18 !important;
}

.pt-18 {
		padding-top: 18 !important;
}

.pl-18 {
		padding-left: 18 !important;
}

.pr-18 {
		padding-right: 18 !important;
}

.pb-18 {
		padding-bottom: 18 !important;
}

.mt-20 {
		margin-top: 20 !important;
}

.ml-20 {
		margin-left: 20 !important;
}

.mr-20 {
		margin-right: 20 !important;
}

.mb-20 {
		margin-bottom: 20 !important;
}

.pt-20 {
		padding-top: 20 !important;
}

.pl-20 {
		padding-left: 20 !important;
}

.pr-20 {
		padding-right: 20 !important;
}

.pb-20 {
		padding-bottom: 20 !important;
}

.mt-24 {
		margin-top: 24 !important;
}

.ml-24 {
		margin-left: 24 !important;
}

.mr-24 {
		margin-right: 24 !important;
}

.mb-24 {
		margin-bottom: 24 !important;
}

.pt-24 {
		padding-top: 24 !important;
}

.pl-24 {
		padding-left: 24 !important;
}

.pr-24 {
		padding-right: 24 !important;
}

.pb-24 {
		padding-bottom: 24 !important;
}

.mt-25 {
		margin-top: 25 !important;
}

.ml-25 {
		margin-left: 25 !important;
}

.mr-25 {
		margin-right: 25 !important;
}

.mb-25 {
		margin-bottom: 25 !important;
}

.pt-25 {
		padding-top: 25 !important;
}

.pl-25 {
		padding-left: 25 !important;
}

.pr-25 {
		padding-right: 25 !important;
}

.pb-25 {
		padding-bottom: 25 !important;
}

.mt-30 {
		margin-top: 30 !important;
}

.ml-30 {
		margin-left: 30 !important;
}

.mr-30 {
		margin-right: 30 !important;
}

.mb-30 {
		margin-bottom: 30 !important;
}

.pt-30 {
		padding-top: 30 !important;
}

.pl-30 {
		padding-left: 30 !important;
}

.pr-30 {
		padding-right: 30 !important;
}

.pb-30 {
		padding-bottom: 30 !important;
}

.mt-35 {
		margin-top: 35 !important;
}

.ml-35 {
		margin-left: 35 !important;
}

.mr-35 {
		margin-right: 35 !important;
}

.mb-35 {
		margin-bottom: 35 !important;
}

.pt-35 {
		padding-top: 35 !important;
}

.pl-35 {
		padding-left: 35 !important;
}

.pr-35 {
		padding-right: 35 !important;
}

.pb-35 {
		padding-bottom: 35 !important;
}

.mt-40 {
		margin-top: 40 !important;
}

.ml-40 {
		margin-left: 40 !important;
}

.mr-40 {
		margin-right: 40 !important;
}

.mb-40 {
		margin-bottom: 40 !important;
}

.pt-40 {
		padding-top: 40 !important;
}

.pl-40 {
		padding-left: 40 !important;
}

.pr-40 {
		padding-right: 40 !important;
}

.pb-40 {
		padding-bottom: 40 !important;
}

.mt-42 {
		margin-top: 42 !important;
}

.ml-42 {
		margin-left: 42 !important;
}

.mr-42 {
		margin-right: 42 !important;
}

.mb-42 {
		margin-bottom: 42 !important;
}

.pt-42 {
		padding-top: 42 !important;
}

.pl-42 {
		padding-left: 42 !important;
}

.pr-42 {
		padding-right: 42 !important;
}

.pb-42 {
		padding-bottom: 42 !important;
}

.mt-45 {
		margin-top: 45 !important;
}

.ml-45 {
		margin-left: 45 !important;
}

.mr-45 {
		margin-right: 45 !important;
}

.mb-45 {
		margin-bottom: 45 !important;
}

.pt-45 {
		padding-top: 45 !important;
}

.pl-45 {
		padding-left: 45 !important;
}

.pr-45 {
		padding-right: 45 !important;
}

.pb-45 {
		padding-bottom: 45 !important;
}

.mt-50 {
		margin-top: 50 !important;
}

.ml-50 {
		margin-left: 50 !important;
}

.mr-50 {
		margin-right: 50 !important;
}

.mb-50 {
		margin-bottom: 50 !important;
}

.pt-50 {
		padding-top: 50 !important;
}

.pl-50 {
		padding-left: 50 !important;
}

.pr-50 {
		padding-right: 50 !important;
}

.pb-50 {
		padding-bottom: 50 !important;
}

.mt-60 {
		margin-top: 60 !important;
}

.ml-60 {
		margin-left: 60 !important;
}

.mr-60 {
		margin-right: 60 !important;
}

.mb-60 {
		margin-bottom: 60 !important;
}

.pt-60 {
		padding-top: 60 !important;
}

.pl-60 {
		padding-left: 60 !important;
}

.pr-60 {
		padding-right: 60 !important;
}

.pb-60 {
		padding-bottom: 60 !important;
}

.mt-80 {
		margin-top: 80 !important;
}

.ml-80 {
		margin-left: 80 !important;
}

.mr-80 {
		margin-right: 80 !important;
}

.mb-80 {
		margin-bottom: 80 !important;
}

.pt-80 {
		padding-top: 80 !important;
}

.pl-80 {
		padding-left: 80 !important;
}

.pr-80 {
		padding-right: 80 !important;
}

.pb-80 {
		padding-bottom: 80 !important;
}

.mt-100 {
		margin-top: 100 !important;
}

.ml-100 {
		margin-left: 100 !important;
}

.mr-100 {
		margin-right: 100 !important;
}

.mb-100 {
		margin-bottom: 100 !important;
}

.pt-100 {
		padding-top: 100 !important;
}

.pl-100 {
		padding-left: 100 !important;
}

.pr-100 {
		padding-right: 100 !important;
}

.pb-100 {
		padding-bottom: 100 !important;
}

.mt-120 {
		margin-top: 120 !important;
}

.ml-120 {
		margin-left: 120 !important;
}

.mr-120 {
		margin-right: 120 !important;
}

.mb-120 {
		margin-bottom: 120 !important;
}

.pt-120 {
		padding-top: 120 !important;
}

.pl-120 {
		padding-left: 120 !important;
}

.pr-120 {
		padding-right: 120 !important;
}

.pb-120 {
		padding-bottom: 120 !important;
}

.ta-l {
		text-align: left !important;
}

.ta-c {
		text-align: center !important;
}

.ta-r {
		text-align: right !important;
}

.va-t {
		text-align: left !important;
}

.va-m {
		text-align: center !important;
}

.va-b {
		text-align: right !important;
}

.justify-cont-s {
		justify-content: flex-start !important;
}

.justify-cont-e {
		justify-content: flex-end !important;
}

.justify-cont-c {
		justify-content: center !important;
}

.justify-cont-sb {
		justify-content: space-between !important;
}

.align-item-s {
		align-items: flex-start !important;
}

.align-item-e {
		align-items: flex-end !important;
}

.align-item-c {
		align-items: center !important;
}

.h300 {
		height: 300px;
}

.w-10 {
		width: 10% !important;
}

.w-20 {
		width: 20% !important;
}

.w-30 {
		width: 30% !important;
}

.w-40 {
		width: 40% !important;
}

.w-50 {
		width: 50% !important;
}

.w-60 {
		width: 60% !important;
}

.w-70 {
		width: 70% !important;
}

.w-80 {
		width: 80% !important;
}

.w-90 {
		width: 90% !important;
}

.co-badge {
		padding: 2px 8px;
		border-radius: 8px;
		color: #fff;
		font-size: 12px;
		font-weight: 300;
		line-height: 1.6666666667;
}
.co-badge.type01 {
		color: #253981;
		background-color: #ecf1f8;
}
.co-badge.type02 {
		color: #494949;
		background-color: #e9e9e9;
}
.co-badge.type03 {
		color: #fff;
		background-color: #2f7ad3;
}

.bdr-none {
		border-right: none !important;
}

.is-loading {
		overflow: hidden;
}

.c-loading-wrapper {
		height: 100%;
		display: flex;
		justify-content: center;
		position: fixed;
		top: 0;
		left: 0;
		bottom: 0;
		right: 0;
		background: rgba(255, 255, 255, 0.75);
		z-index: 6000;
}
.c-loading-wrapper .is-loading {
		display: flex;
		align-items: center;
		justify-content: center;
		flex: 1 1 auto;
}
.c-loading-wrapper .is-loading > div {
		display: flex;
		text-align: center;
		position: relative;
		flex: 1 1 100%;
		justify-content: center;
}
.c-loading-wrapper .is-loading img {
		display: block;
}
.c-loading-wrapper .is-loading .txt {
		font-size: 16px;
		font-weight: 500;
		line-height: 2;
		color: #777676;
		position: absolute;
		left: 0;
		right: 0;
		bottom: 0;
}

.co-list-card > li {
		white-space: initial;
}
.co-list-card.circle01 > li, .co-list-card.circle02 > li {
		position: relative;
}
.co-list-card.circle01 > li:before, .co-list-card.circle02 > li:before {
		content: "";
		width: 4px;
		height: 4px;
		border-radius: 50%;
		background-color: #a4a4a4;
		display: inline-block;
		position: absolute;
}
.co-list-card.circle01 li {
		color: #777676;
		font-size: 12px;
		font-weight: 400;
		line-height: 2;
		padding-left: 14px;
}
.co-list-card.circle01 li:before {
		top: 11px;
		left: 0;
}
.co-list-card.circle02 li {
		font-size: 14px;
		font-weight: 300;
		line-height: 2.1428571429;
		padding-left: 12px;
}
.co-list-card.circle02 li:before {
		top: 14px;
		left: 0;
}
.co-list-card.number01 > li {
		list-style: none;
		padding-left: 19px;
		counter-increment: list-cnt01;
		position: relative;
		font-size: 14px;
		font-weight: 300;
		line-height: 2.1428571429;
}
.co-list-card.number01 > li:before {
		content: counter(list-cnt01);
		position: absolute;
		top: 4px;
		left: 0;
		display: inline-block;
		text-align: center;
		line-height: 1;
		font-size: 9px;
		width: 10px;
		height: 10px;
		border-radius: 50%;
		border: 1px solid #777676;
}
.co-list-card.number02 > li {
		list-style: none;
		counter-increment: list-cnt02;
		position: relative;
		padding-left: 20px;
		font-size: 14px;
}
.co-list-card.number02 > li:before {
		position: absolute;
		left: 0;
		top: 8px;
		content: counter(list-cnt02) ".";
		display: inline-block;
		text-align: left;
		line-height: 1;
		padding-right: 8px;
}
.co-list-card.sequence-k > li {
		position: relative;
		padding-left: 20px;
}
.co-list-card.sequence-k > li .seq {
		position: absolute;
		top: 0;
		left: 0;
}

.co-none-txt {
		font-size: 14px;
		font-weight: 300;
		line-height: 2.1428571429;
		color: #939391;
		text-align: center;
}

.co-tab-list {
		width: 100%;
		display: flex;
		align-items: center;
}
.co-tab-list.w-auto {
		width: auto;
}
.co-tab-list > li {
		text-align: center;
}
.co-tab-list > li a {
		background-color: #fff;
		color: #a4a4a4;
		display: block;
		padding: 2px 0;
}
.co-tab-list > li a.on {
		background-color: #253981;
		color: #fff;
}
.co-tab-list.type01 {
		border: 1px solid #e9e9e9;
		border-radius: 12px;
		background-color: #fff;
}
.co-tab-list.type01 > li {
		display: inline-block;
}
.co-tab-list.type01 > li > a {
		border-radius: 12px;
}
.co-tab-list.type01.w-auto {
		display: inline-block;
		width: auto;
}
.co-tab-list.type01.w-auto > li a {
		padding-left: 30px;
		padding-right: 30px;
}
.co-tab-list.type02 > li + li {
		margin-left: 8px;
}
.co-tab-list.type02 > li > a {
		border-radius: 8px;
		border: 1px solid #d2d2d1;
		padding: 4px 16px;
		font-size: 12px;
		font-weight: 300;
		line-height: 1.6666666667;
}
.co-tab-list.tab-1 > li {
		width: calc(100% / 1);
}
.co-tab-list.tab-2 > li {
		width: calc(100% / 2);
}
.co-tab-list.tab-3 > li {
		width: calc(100% / 3);
}
.co-tab-list.tab-4 > li {
		width: calc(100% / 4);
}
.co-tab-list.tab-5 > li {
		width: calc(100% / 5);
}
.co-tab-list.tab-6 > li {
		width: calc(100% / 6);
}

.tab-content {
		display: none;
}
.tab-content.on {
		display: block;
}

.co-title-field {
		display: flex;
		align-items: center;
		justify-content: space-between;
}
.co-title-field .title {
		font-size: 24px;
		font-weight: 700;
		line-height: 1.5;
}
.co-title-field .main-title {
		font-size: 20px;
		font-weight: 500;
		line-height: 1.8;
}
.co-title-field .date {
		font-size: 12px;
		font-weight: 300;
		line-height: 1.6666666667;
		color: #777676;
}

.co-sub-title-01 {
		font-size: 16px;
		font-weight: 500;
		line-height: 2;
		color: #1c1c1b;
}
.co-sub-title-01 + p {
		margin-bottom: 12px;
}

.co-list-field {
		overflow: hidden;
		background-color: #fff;
		border: 1px solid #e9e9e9;
		border-radius: 12px;
}
.co-list-field.none-data {
		display: flex;
		flex: 1 0 auto;
		align-items: center;
		justify-content: center;
}
.co-list-field.none-data > div {
		display: block;
}
.co-list-field.none-data .img {
		text-align: center;
}
.co-list-field.none-data img {
		width: 110px;
		height: auto;
		opacity: 0.5;
}
.co-list-field.none-data p {
		margin-top: 19px;
		font-size: 16px;
		font-weight: 500;
		line-height: 2;
		color: #a4a4a4;
}
.co-list-field.is-loading {
		position: relative;
}
.co-list-field.is-loading .main-notice-list {
		display: none;
}

.co-list-article {
		border-radius: 12px;
}
.co-list-article > li {
		height: 45px;
		font-size: 12px;
		font-weight: 400;
		line-height: 2;
		padding: 10px 20px;
}
.co-list-article > li:nth-child(odd) {
		background-color: #fff;
}
.co-list-article > li:nth-child(even) {
		background-color: #fcfcfc;
}
.co-list-article > li > div {
		display: flex;
		width: 100%;
		align-items: center;
		flex: 1 1 auto;
}
.co-list-article .co-badge,
.co-list-article .date {
		flex-shrink: 0;
}
.co-list-article .co-badge {
		margin-right: 12px;
}
.co-list-article .title {
		flex-shrink: 1;
		flex-grow: 1;
		display: inline-block;
		overflow: hidden;
		text-overflow: ellipsis;
		white-space: nowrap;
		word-wrap: break-word;
}
.co-list-article .title > p {
		color: #777676;
		display: block;
		overflow: hidden;
		text-overflow: ellipsis;
		white-space: nowrap;
		word-wrap: break-word;
}
.co-list-article .date {
		padding-left: 12px;
		color: #777676;
}

.co-location-wrap {
		padding-bottom: 20px;
		border-bottom: 1px solid rgba(28, 28, 27, 0.1);
}
.co-location-wrap .co-title-field {
		margin-top: 12px;
}
.co-location-wrap .co-ref-txt {
		margin-top: 12px;
}

.co-location,
.co-location > ol {
		display: flex;
		font-size: 0;
		align-items: center;
}
.co-location > p,
.co-location > ol > li,
.co-location > ol > p,
.co-location > ol > ol > li {
		font-size: 12px;
		font-weight: 400;
		line-height: 2;
}
.co-location > p ~ p:before, .co-location > p ~ li:before,
.co-location > ol > li ~ p:before,
.co-location > ol > li ~ li:before,
.co-location > ol > p ~ p:before,
.co-location > ol > p ~ li:before,
.co-location > ol > ol > li ~ p:before,
.co-location > ol > ol > li ~ li:before {
		content: "/";
		display: inline-block;
		padding-left: 4px;
		padding-right: 4px;
		color: #e9e9e9;
}
.co-location a,
.co-location > ol a {
		background: url("../images/ico//ico_home_02.svg") center/12px auto no-repeat;
		display: inline-block;
		width: 20px;
		height: 20px;
		margin-top: 6px;
}
.co-location .on,
.co-location > ol .on {
		color: #253981;
}

.co-cont-section {
		padding-top: 40px;
}
.co-cont-section .sub-title {
		font-size: 16px;
		font-weight: 500;
		line-height: 2;
		color: #1c1c1b;
}
.co-cont-section .co-btn-field {
		margin-top: 40px;
}
.co-cont-section .co-btn-field.form-btn-field {
		margin-top: 60px;
}

.co-article .co-title-field.co-cont-list {
		margin-top: 8px;
}
.co-article .co-title-field + .co-no-data {
		margin-top: 12px;
}

.co-cont-article {
		border: 1px solid #e9e9e9;
		border-radius: 12px;
		color: #494949;
}
.co-cont-article.type01 {
		background-color: #fcfcfc;
}
.co-cont-article.type01 .inner {
		padding: 20px;
}
.co-cont-article.type02 {
		background-color: #fff;
}
.co-cont-article.type02 .inner {
		padding: 20px;
}
.co-cont-article.input-cont-article {
		background-color: #fcfcfc;
		padding: 32px 16px;
}
.co-cont-article.terms-type {
		background-color: #fff;
		padding: 20px;
}
.co-cont-article.input-view-cont.type02 {
		background-color: #fcfcfc;
}
.co-cont-article.input-view-cont.type02 > .inner {
		padding-left: 20px;
		padding-right: 20px;
}
.co-cont-article.input-view-cont.type02 .c-input-list-view {
		padding: 0;
}
.co-cont-article.input-view {
		background-color: #fcfcfc;
}
.co-cont-article.input-view.type01 {
		padding: 32px;
}

.co-inner-cont-article {
		background-color: #fff;
		border-radius: 12px;
		border: 1px solid #e9e9e9;
		padding: 32px;
}
.co-inner-cont-article.type01 .co-title-field .title {
		font-size: 20px;
		font-weight: 500;
		line-height: 1.8;
}
.co-inner-cont-article ~ .co-inner-cont-article {
		margin-top: 40px;
}
.co-inner-cont-article .co-title-field + .c-input-search {
		margin-top: 20px;
}
.co-inner-cont-article .co-title-field.hashtag {
		margin-top: 10px;
}

.co-input-article {
		border: 1px solid #e9e9e9;
		border-radius: 12px;
		background-color: #fcfcfc;
}
.co-input-article.type01 {
		padding: 32px;
}
.co-input-article.type01 .co-title-field .title {
		font-size: 20px;
		font-weight: 500;
		line-height: 1.8;
		color: #494949;
}

.co-ref-txt {
		color: #a4a4a4;
}
.co-ref-txt.fs-small {
		font-size: 12px;
		font-weight: 300;
		line-height: 2;
		color: #a4a4a4;
}

.txt-type01 {
		font-size: 14px;
		font-weight: 300;
		line-height: 2.1428571429;
		color: #494949;
}

.txt-type02 {
		font-size: 12px;
		font-weight: 300;
		line-height: 1.6666666667;
		color: #777676;
}

.txt-type03 {
		font-size: 14px;
		font-weight: 300;
		line-height: 2.1428571429;
		color: #777676;
}

.fc-red {
		color: red;
}

.co-table-field + .co-termschk-card {
		margin-top: 20px;
}

.co-title-field + .co-table-field {
		margin-top: 12px;
}
.co-title-field + .co-cont-article {
		margin-top: 12px;
}
.co-title-field + .col-full-height {
		margin-top: 12px;
}

.search-box + .co-tab-wrap {
		margin-top: 40px;
}

.co-complete-field {
		padding-top: 120px;
		padding-bottom: 60px;
		align-items: stretch;
		text-align: center;
		display: flex;
		justify-content: center;
		align-items: center;
		flex-direction: column;
		flex: 1 0 auto;
		color: #777676;
}
.co-complete-field .title {
		font-size: 24px;
		font-weight: 700;
		line-height: 1.5;
		color: #2f7ad3;
		margin-top: 20px;
}
.co-complete-field .title + .cont {
		margin-top: 12px;
}
.co-complete-field .co-btn-field,
.co-complete-field .co-cont-article {
		margin-top: 40px;
}
.co-complete-field .co-list-card {
		text-align: left;
}

.cont-vt-center {
		display: flex;
		align-items: center;
}

.co-accrodion-list > li ~ li {
		margin-top: 20px;
}

.co-accordion {
		border: 1px solid #d2d2d1;
		border-radius: 12px;
		overflow: hidden;
}
.co-accordion .accrodion-title {
		background-color: #fff;
}
.co-accordion .accrodion-title .txt {
		font-size: 16px;
		font-weight: 400;
		line-height: 1.4375;
		color: #494949;
		padding-right: 56px;
		overflow: hidden;
		text-overflow: ellipsis;
		white-space: nowrap;
		word-wrap: break-word;
}
.co-accordion .accrodion-panel {
		background-color: #fff;
		display: none;
}
.co-accordion .accrodion-panel .panel-cont {
		padding: 20px;
}
.co-accordion .btn-accrodion {
		display: flex;
		flex: 1 1 auto;
		max-width: 100%;
		justify-content: space-between;
		font-size: 0;
}
.co-accordion .btn-accrodion .col {
		padding-left: 0;
		padding-right: 0;
}
.co-accordion .btn-accrodion .inner {
		display: flex;
		align-items: center;
}
.co-accordion .btn-accrodion .txt {
		font-size: 16px;
		font-weight: 400;
		line-height: 2;
}
.co-accordion .btn-accrodion .cnt {
		font-size: 14px;
		font-weight: 300;
		line-height: 2.1428571429;
		color: #777676;
}
.co-accordion .btn-accrodion .cnt + .ico-accordion {
		margin-left: 16px;
}
.co-accordion.on {
		border-color: #2f7ad3;
}
.co-accordion.on .btn-accrodion {
		background-color: #f8f9fb;
}
.co-accordion.on .btn-accrodion .txt {
		color: #253981;
}
.co-accordion.on .ico-accordion span {
		transform: rotate(180deg);
		background-position: right center;
		transition: transform 0.1s;
}
.btn-accrodion {
		position: relative;
		padding: 11px 19px;
		display: block;
}

.ico-accordion {
		display: inline-block;
		width: 32px;
		height: 32px;
		border-radius: 12px;
		background-color: #ecf1f8;
}
.ico-accordion > span {
		width: 20px;
		height: 20px;
		margin: 6px;
		background: url("../images/ico/ico-sprites-accordion_01.png") 0 center/auto 20px no-repeat;
		transform: rotate(-180deg);
		transition: transform 0.1s;
}

.swiper-slide {
		touch-action: pan-x;
}

.co-slide-btn-prev,
.co-slide-btn-next {
		position: absolute;
		top: 50%;
		transform: translateY(-50%);
		margin-top: 0;
		width: 28px;
		height: 28px;
		background-image: url("../images/ico/ico_sprite_slide_arrow_02.svg");
		background-size: 28px auto;
		background-repeat: no-repeat;
		z-index: 10;
		cursor: pointer;
}
.co-slide-btn-prev.swiper-button-disabled,
.co-slide-btn-next.swiper-button-disabled {
		opacity: 1 !important;
}

.co-slide-btn-prev {
		left: 12px;
		background-position: 0 0px;
}
.co-slide-btn-prev:hover {
		background-position: 0 -76px;
}
.co-slide-btn-prev.swiper-button-disabled {
		background-position-y: 0;
}

.co-slide-btn-next {
		right: 12px;
		background-position: 0 -38px;
}
.co-slide-btn-next:hover {
		background-position: 0 -114px;
}

.swiper-pagination {
		font-size: 12px;
		font-weight: 300;
		line-height: 2;
		color: #a4a4a4;
		color: #a4a4a4;
}

.co-terms-chk-list > li {
		margin-top: 20px;
}
.co-terms-chk-list ~ .co-ref-txt {
		margin-top: 20px;
}

.co-terms-chk-card .c-chkbox + .co-terms-detail {
		margin-top: 4px;
}
.co-terms-chk-card .chk-list {
		display: flex;
		justify-content: flex-end;
}
.co-terms-chk-card .co-terms-tit {
		color: #a4a4a4;
}
.co-terms-chk-card .co-terms-tit + .co-terms-detail {
		margin-top: 4px;
}

.co-title-field + .co-terms-chk-card {
		margin-top: 12px;
}

.co-terms-detail {
		border-radius: 12px;
		border: 1px solid #e9e9e9;
		background-color: #fcfcfc;
		font-size: 12px;
		font-weight: 300;
		line-height: 1.6666666667;
		color: #777676;
		padding: 15px 5px;
}
.co-terms-detail.terms-scroll {
		height: 180px;
}
.co-terms-detail.terms-scroll.type02 {
		height: 360px;
}
.co-terms-detail.terms-scroll .c-terms-section {
		font-size: 12px;
		font-weight: 300;
		line-height: 2;
		color: #777676;
}
.co-terms-detail.terms-scroll .c-terms-section h2 {
		margin-top: 20px;
}
.co-terms-detail.terms-scroll .c-terms-section:first-of-type > h2:first-of-type {
		margin-top: 0;
}
.co-terms-detail .inner {
		padding: 5px 15px;
		height: 100%;
		overflow-x: hidden;
		overflow-y: auto;
		color: #fcfcfc;
}
.co-terms-detail .title {
		font-size: 12px;
		font-weight: 400;
		line-height: 2;
		color: #1c1c1b;
}
.co-terms-detail .title + .terms-cont {
		margin-top: 12px;
}
.co-terms-detail .terms-row ~ .terms-row {
		margin-top: 12px;
}
.co-terms-detail .terms-row .title ~ .cont {
		margin-top: 12px;
}
.co-terms-detail .terms-row .cont {
		font-size: 12px;
		font-weight: 400;
		line-height: 2;
		color: #777676;
}
.co-terms-detail .terms-row .cont hr {
		background-color: #e9e9e9;
		height: 1px;
		border: 0;
		margin: 12px 0;
}
.co-terms-detail .terms-row .cont .in-cont ~ .in-cont {
		margin-top: 12px;
}
.co-terms-detail .terms-row b {
		font-size: 14px;
		font-weight: 500;
		line-height: 2;
}
.co-terms-detail .terms-cont {
		font-size: 14px;
		font-weight: 300;
		line-height: 2.1428571429;
		color: #777676;
}
.co-terms-detail + .co-ref-txt {
		margin-top: 12px;
}
.co-terms-detail + .chk-list {
		margin-top: 14px;
}
.co-terms-detail + .chk-list > .c-chkbox {
		margin-bottom: 0;
}
.co-terms-detail + .chk-list > .c-chkbox ~ .c-chkbox {
		margin-left: 17px;
}
.co-terms-detail .terms-field {
		margin-top: 0;
}

.co-table .co-list-card.circle01 li:before {
		top: 13px;
}

.co-caption-txt {
		font-size: 12px;
		font-weight: 400;
		line-height: 2;
}

.co-subcont-field ~ .co-article, .co-subcont-field ~ .row {
		margin-top: 20px;
}
.co-subcont-field .co-ref-txt {
		color: rgba(28, 28, 27, 0.8);
}

.txtline-01 {
		overflow: hidden;
		text-overflow: ellipsis;
		white-space: nowrap;
		word-wrap: break-word;
}

.co-per-scroll-layout {
		overflow: hidden;
}
.co-per-scroll-layout .left-wrap,
.co-per-scroll-layout .right-wrap {
		padding: 0 20px;
		overflow: hidden;
}
.co-per-scroll-layout .left-wrap > .c-inner,
.co-per-scroll-layout .right-wrap > .c-inner {
		height: 100%;
		overflow-y: auto;
		overflow-x: hidden;
		padding-right: 10px;
		padding-bottom: 20px;
}
.co-per-scroll-layout .left-wrap > .c-inner::-webkit-scrollbar,
.co-per-scroll-layout .right-wrap > .c-inner::-webkit-scrollbar {
		opacity: 0;
		height: 8px;
		width: 8px;
		background-color: #fff;
}
.co-per-scroll-layout .left-wrap > .c-inner::-webkit-scrollbar:hover,
.co-per-scroll-layout .right-wrap > .c-inner::-webkit-scrollbar:hover {
		opacity: 1;
}
.co-per-scroll-layout .left-wrap > .c-inner ::-webkit-scrollbar-track,
.co-per-scroll-layout .right-wrap > .c-inner ::-webkit-scrollbar-track {
		background-color: #fff;
}
.co-per-scroll-layout .left-wrap > .c-inner::-webkit-scrollbar-thumb,
.co-per-scroll-layout .right-wrap > .c-inner::-webkit-scrollbar-thumb {
		opacity: 0;
		background: #e9e9e9;
		border-radius: 50px;
		background-clip: padding-box;
}
.co-per-scroll-layout .left-wrap > .c-inner::-webkit-scrollbar-corner,
.co-per-scroll-layout .right-wrap > .c-inner::-webkit-scrollbar-corner {
		opacity: 0;
		background-color: transparent;
}

.co-date-period {
		display: flex;
		align-items: center;
}
.co-date-period .c-input-date {
		width: 200px;
}
.co-date-period .c-input-date ~ .c-input-date {
		margin-left: 16px;
}

.co-detail-title-field {
		display: flex;
		align-items: center;
}
.co-detail-title-field .btn-chkup-info-detail {
		font-size: 0;
}

.btn-chkup-info-detail {
		line-height: 1;
}
.btn-chkup-info-detail > i {
		position: relative;
}

/*
RV-02-003_1
추가 검사 공통
*/
/* RV-02-005 */
.terms-type .terms-all-chkbox {
		padding-bottom: 20px;
}
.terms-type .terms-all-chkbox .co-ref-txt {
		margin-top: 4px;
		color: #777676;
}
.terms-type .terms-all-chkbox + .co-terms-chk-list {
		margin-top: 20px;
}
.terms-type .terms-all-chkbox .cont {
		margin-top: 8px;
		font-size: 12px;
		font-weight: 400;
		line-height: 2;
		color: #a4a4a4;
}
.terms-type .terms-all-chkbox.top {
		margin-top: 0;
}

.c-terms-txt {
		color: #777676;
}

.terms-all-chkbox.terms-co-chk .left + .cont {
		margin-top: 8px;
}
.terms-all-chkbox.terms-co-chk .left + .c-terms-txt {
		color: #a4a4a4;
}

.terms-all-chkbox.top {
		margin-top: 40px;
		padding-bottom: 20px;
		border-bottom: 1px solid #f3f3f3;
}
.terms-all-chkbox + .co-terms-chk-list {
		margin-top: 20px;
}

.btn-terms-accordion {
		position: relative;
		display: flex;
		align-items: center;
		justify-content: space-between;
}
.btn-terms-accordion + .co-terms-detail {
		margin-top: 5px;
}

.all-chk-field .btn-accrodion {
		position: relative;
		padding: 11px 19px;
		display: block;
}
.all-chk-field .ico-terms-accord {
		width: 28px;
		height: 28px;
		border-radius: 12px;
}
.all-chk-field .ico-terms-accord > span {
		width: 28px;
		height: 28px;
		background: url("../images/ico/ico_arrow_15.svg") 0 center/auto 28px no-repeat;
}
.all-chk-field .ico-terms-accord.on > span {
		background-image: url("../images/ico/ico_arrow_14.svg");
}

.co-terms-tbl {
		width: 100%;
		margin-top: 12px;
		margin-bottom: 12px;
		border-top: 1px solid #e9e9e9;
}
.co-terms-tbl caption {
		position: absolute;
		overflow: hidden;
		clip: rect(0 0 0 0);
		margin: -1px;
		padding: 0;
		width: 1px;
		height: 1px;
		border: 0;
}
.co-terms-tbl thead th {
		border-bottom: 2px solid #d2d2d1;
		text-align: center;
		font-size: 14px;
		font-weight: 700;
		line-height: 2;
		color: #494949;
}
.co-terms-tbl th,
.co-terms-tbl td {
		padding: 4px 8px;
}
.co-terms-tbl tbody tr {
		border-bottom: 1px solid #e9e9e9;
}
.co-terms-tbl tbody th,
.co-terms-tbl tbody td {
		font-size: 12px;
		font-weight: 400;
		line-height: 2;
		vertical-align: middle;
		color: #777676;
}
.co-terms-tbl.type02 tbody th {
		border-bottom: 2px solid #d2d2d1;
		text-align: center;
		font-size: 14px;
		font-weight: 700;
		line-height: 2;
		color: #494949;
}

.w-auto {
		width: auto !important;
}

.c-space-letter {
		letter-spacing: -0.3px;
}

.c-modal-inner .title + .c-space-letter {
		margin-top: 12px;
		color: #777676;
}

#container.type-container-02 .content-wrapper {
		padding-left: 0;
}
#container.type-container-02 .contents {
		max-width: 2560px;
}
#container.type-container-02 .header {
		padding-left: 0;
}
#container.type-container-02 footer.footer .container {
		max-width: 100%;
}

@keyframes coBtnHoverAni {
		from {
				left: -50%;
		}
		to {
				left: 150%;
		}
}
#container .top-header {
		width: 100%;
		display: flex;
		align-items: center;
		height: 80px;
		padding-left: 40px;
		padding-right: 40px;
		border-bottom: 1px solid #e9e9e9;
		background-color: #f8f9fb;
}
#container .top-header.type-color .logo > div {
		background-color: #d2d2d1;
		border-radius: 12px;
		padding: 8px 20px;
}
#container .top-header .logo {
		height: 100%;
		padding-top: 12px;
		padding-bottom: 12px;
}
#container .top-header .logo > div {
		display: flex;
		align-items: center;
		height: 100%;
}
#container .top-header .logo img {
		max-height: 100%;
		max-width: 100%;
}

/*
= bread crumb
*/
.c-breadcrumb .breadcrumb-list:after {
		display: block;
		content: "";
		clear: both;
}
.c-breadcrumb .breadcrumb-list > li {
		position: relative;
		margin-right: 8px;
		float: left;
		font-size: 12px;
		color: #a4a4a4;
}
.c-breadcrumb .breadcrumb-list > li ~ li:before {
		float: left;
		padding-right: 8px;
		color: #a4a4a4;
		content: "/";
}
.c-breadcrumb .breadcrumb-list > li.on {
		color: #253981;
}

.top_head .c-breadcrumb + .c-title-field {
		position: relative;
		margin-top: 12px;
		padding-bottom: 12px;
}
.top_head .c-breadcrumb + .c-title-field :after {
		display: block;
		content: "";
		clear: both;
}
.top_head .c-breadcrumb + .c-title-field .buttons {
		position: absolute;
		top: -6px;
		right: 0;
}

.co-table.cont-type04.tbl-chkup-result01 {
		border-right: 1px solid #e9e9e9;
}

.c-titcont-list {
		display: flex;
		align-items: center;
}
.c-titcont-list > dl {
		display: table;
}
.c-titcont-list > dl ~ dl {
		margin-left: 20px;
}
.c-titcont-list > dl dt,
.c-titcont-list > dl dd {
		font-size: 12px;
		font-weight: 300;
		display: table-cell;
		font-size: 12px;
}
.c-titcont-list > dl dt {
		color: #777676;
}
.c-titcont-list > dl dd {
		padding-left: 12px;
		color: #a4a4a4;
}

.c-txtarea-field + .c-titcont-list {
		margin-top: 12px;
}

.c-memo-card > a {
		border: 1px solid #e9e9e9;
		background-color: #fff;
		border-radius: 12px;
		padding: 12px 20px;
		margin-top: 12px;
		margin-bottom: 12px;
		display: flex;
		flex-wrap: nowrap;
		width: 100%;
		align-items: flex-start;
}
.c-memo-card > a > .col-cont.on + .col-name > i {
		transform: rotate(-180deg);
}
.c-memo-card > a > .col-cont.on .col-name i {
		transform: rotate(-180deg);
}
.c-memo-card .col-date,
.c-memo-card .col-name {
		flex-shrink: 0;
		flex-grow: 0;
}
.c-memo-card .col-date {
		width: 110px;
}
.c-memo-card .col-cont {
		padding-right: 40px;
		flex-grow: 1;
		overflow: hidden;
		height: 24px;
}
.c-memo-card .col-cont.on .detail-cont.on:after {
		display: none;
}
.c-memo-card .col-cont .detail-cont {
		min-height: 24px;
		line-height: 24px;
		position: relative;
		word-break: break-all;
}
.c-memo-card .col-cont .detail-cont.on:after {
		content: "...";
		position: absolute;
		top: 0;
		right: 0;
		display: inline-block;
		padding-left: 1px;
		background-color: #fff;
}
.c-memo-card .col-name {
		width: 100px;
}
.c-memo-card .col-name:after {
		display: block;
		content: "";
		clear: both;
}
.c-memo-card .col-name > .name {
		display: inline-block;
}
.c-memo-card .col-name i {
		float: right;
}
.c-memo-card .arrow {
		background: url("../images/ico/ico_arrow_07.svg") 0/20px no-repeat;
		width: 20px;
		height: 20px;
		content: "";
		display: inline-block;
		overflow: hidden;
		vertical-align: top;
}

/*
	= Card Type
*/
.c-title-field + .c-card-article {
		margin-top: 2px;
}

.c-card-article + .c-card-article {
		margin-top: 10px;
}

.c-card-article .c-card {
		display: flex;
		flex-direction: column;
		min-width: 0;
		word-wrap: break-word;
		background-color: #fff;
		border-radius: 8px;
		border: 1px solid #e9e9e9;
}

.c-card-article > [class*=col],
.c-card-article > [class*=col-] {
		padding-left: 12px;
		padding-right: 12px;
}
.c-card-article.type01 [class=col-3] {
		padding-top: 12px;
		padding-bottom: 12px;
		padding-left: 12px;
		padding-right: 12px;
}

.c-card.type01 {
		position: relative;
}
.c-card.type01 .card-body {
		padding: 0.428rem;
}
.c-card.type01 .add-entry {
		display: flex;
		display: flex;
		justify-content: space-between;
		align-items: center;
		padding-right: 0.928rem;
}
.c-card.type01 .info > ul:after {
		display: block;
		content: "";
		clear: both;
}
.c-card.type01 .info > ul > li {
		padding: 0.428rem;
		float: left;
}
.c-card.type01 .c-btn-del {
		background: url("../images/ico/ico_del_01.svg") center/100% no-repeat;
		width: 24px;
		height: 24px;
}
.c-card.type01 + .add-entry {
		margin-top: 12px;
		align-items: center;
}
.c-card.type02 {
		padding: 28px 20px;
		display: flex;
		flex-direction: column;
		align-items: center;
		text-align: center;
}
.c-card.type02 .txt {
		line-height: 1.7;
}
.c-card.type02 .cnt {
		line-height: 1.9;
		color: #2f7ad3;
		font-weight: 400;
		font-size: 24px;
}
.c-card.type03 {
		display: flex;
		flex-direction: column;
		padding: 12px 20px;
}
.c-card.type03 .name {
		font-size: 12px;
}
.c-card.type03 .c-multi-input-group-02 {
		display: flex;
		align-items: center;
		margin-top: 8px;
		display: flex;
		table-layout: fixed;
		overflow: hidden;
		width: 100%;
}
.c-card.type03 .c-multi-input-group-02 .input-col.time {
		margin-left: 8px;
}
.c-card.type03 .c-multi-input-group-02 .input-col.time > input[type=text] {
		text-align: center;
		padding: 2px;
		width: 40px;
		height: 29px;
}

.c-plan-chk-group {
		display: flex;
		align-items: center;
}
.c-plan-chk-group .c-multi-input-group-02 {
		display: inline-block;
}

.c-top-cont {
		margin-bottom: 12px;
}

.c-layout-type01 {
		overflow: hidden;
}
.c-layout-type01 .left-wrap,
.c-layout-type01 .right-wrap {
		height: calc(100vh - 330px);
		overflow: hidden;
}
.c-layout-type01 .left-wrap > .c-inner,
.c-layout-type01 .right-wrap > .c-inner {
		height: 100%;
		overflow-y: auto;
		padding-right: 10px;
}
.c-layout-type01 .left-wrap > .c-inner::-webkit-scrollbar,
.c-layout-type01 .right-wrap > .c-inner::-webkit-scrollbar {
		opacity: 0;
		height: 8px;
		width: 8px;
		background-color: #fff;
}
.c-layout-type01 .left-wrap > .c-inner::-webkit-scrollbar:hover,
.c-layout-type01 .right-wrap > .c-inner::-webkit-scrollbar:hover {
		opacity: 1;
}
.c-layout-type01 .left-wrap > .c-inner ::-webkit-scrollbar-track,
.c-layout-type01 .right-wrap > .c-inner ::-webkit-scrollbar-track {
		background-color: #fff;
}
.c-layout-type01 .left-wrap > .c-inner::-webkit-scrollbar-thumb,
.c-layout-type01 .right-wrap > .c-inner::-webkit-scrollbar-thumb {
		opacity: 0;
		background: #e9e9e9;
		border-radius: 50px;
		background-clip: padding-box;
}
.c-layout-type01 .left-wrap > .c-inner::-webkit-scrollbar-corner,
.c-layout-type01 .right-wrap > .c-inner::-webkit-scrollbar-corner {
		opacity: 0;
		background-color: transparent;
}

.c-calendar-container {
		padding: 24px 20px;
		background-color: #fff;
		border-radius: 8px;
		border: 1px solid #e9e9e9;
}

.c-calendar-wrap {
		background-color: #fff;
}
.c-calendar-wrap .top-field {
		position: relative;
		border-bottom: 2px solid #e9e9e9;
		padding-bottom: 20px;
}
.c-calendar-wrap .top-field:after {
		display: block;
		content: "";
		clear: both;
}
.c-calendar-wrap .top-field .c-controls {
		display: flex;
		justify-content: center;
		align-items: center;
}
.c-calendar-wrap .top-field .c-controls .year-month {
		padding: 0 20px;
}
.c-calendar-wrap .top-field .c-controls .year-month > p {
		font-size: 28px;
		font-weight: 400;
		line-height: 41px;
}
.c-calendar-wrap .top-field .c-controls > button {
		width: 20px;
		height: 21px;
		border-radius: 4px;
		background: url("../images/ico/ico_arrow_08.svg") #253981 center/6px auto no-repeat;
		transform: rotate(-180deg);
}
.c-calendar-wrap .top-field .c-controls > button.btn-next {
		transform: rotate(0deg);
}
.c-calendar-wrap .top-field .c-badge-info {
		position: absolute;
		top: 7px;
		right: 0;
		display: flex;
}
.c-calendar-wrap .top-field .c-badge-info > li {
		display: flex;
		align-items: center;
}
.c-calendar-wrap .top-field .c-badge-info > li i {
		display: inline-flex;
		width: 18px;
		height: 18px;
		border: 1px solid #d2d2d1;
		border-radius: 4px;
}
.c-calendar-wrap .top-field .c-badge-info > li span {
		display: inline-block;
		margin-left: 8px;
		color: #777676;
		line-height: 24px;
}
.c-calendar-wrap .top-field .c-badge-info > li ~ li {
		margin-left: 24px;
}
.c-calendar-wrap .weekdays {
		display: flex;
}
.c-calendar-wrap .weekdays > li {
		text-align: center;
		padding: 29px 0;
		border-bottom: 2px solid #e9e9e9;
		background-color: #fff;
		line-height: 23px;
		font-size: 16px;
		font-weight: 400;
}
.c-calendar-wrap .weekdays > li,
.c-calendar-wrap .calendar_days > li {
		width: 14.2857142857%;
		display: inline-block;
}
.c-calendar-wrap .calendar_days {
		display: flex;
		flex-wrap: wrap;
}
.c-calendar-wrap .calendar_days > li {
		padding: 12px;
		height: 157px;
		border-radius: 8px;
		border: 1px solid #e9e9e9;
		background-color: #fff;
}
.c-calendar-wrap .calendar_days > li.is-holiday, .c-calendar-wrap .calendar_days > li.is-reserve-unable, .c-calendar-wrap .calendar_days > li.is-reserve-closed {
		background-color: #e9e9e9;
		opacity: 0.5;
		border-color: #fff;
}
.c-calendar-wrap .calendar_days > li.is-reserve-unable .day {
		color: rgba(28, 28, 27, 0.4);
}
.c-calendar-wrap .calendar_days > li.is-reserve-closed .day {
		color: rgba(28, 28, 27, 0.8);
}
.c-calendar-wrap .day-top {
		display: flex;
		align-items: center;
}
.c-calendar-wrap .day-top .day {
		font-size: 24px;
		font-weight: 400;
		line-height: 29px;
}
.c-calendar-wrap .day-top .state {
		margin-left: 10px;
}
.c-calendar-wrap .day-top .state > span {
		border-radius: 8px;
		background-color: #ff6859;
		text-align: center;
		display: inline-block;
		padding: 2px 16px;
		font-size: 12px;
		line-height: 20px;
		color: #fff;
}
.c-calendar-wrap .day-top .state .holiday {
		background-color: #ff6859;
}
.c-calendar-wrap .day-top .state .reserve-available {
		background-color: #2f7ad3;
}
.c-calendar-wrap .day-top .state .reserve-unable {
		background-color: #a4a4a4;
}
.c-calendar-wrap .day-top .state .reserve-closed {
		background-color: #253981;
}
.c-calendar-wrap .day-cont {
		margin-top: 8px;
}
.c-calendar-wrap .reserve-list > li > a {
		color: #2f7ad3;
		text-decoration: underline;
		font-size: 14px;
		line-height: 24px;
}
.c-calendar-wrap .txt-sun {
		color: #ff6859;
}

.c-article.mod-reservation {
		padding: 12px;
		height: 384px;
		width: 100%;
		display: flex;
}
.c-article.mod-reservation .c-inner-left,
.c-article.mod-reservation .c-inner-right {
		height: 100%;
		overflow-x: hidden;
		overflow-y: auto;
}
.c-article.mod-reservation .c-inner-left::-webkit-scrollbar,
.c-article.mod-reservation .c-inner-right::-webkit-scrollbar {
		opacity: 0;
		height: 8px;
		width: 8px;
		background-color: #fff;
}
.c-article.mod-reservation .c-inner-left::-webkit-scrollbar:hover,
.c-article.mod-reservation .c-inner-right::-webkit-scrollbar:hover {
		opacity: 1;
}
.c-article.mod-reservation .c-inner-left ::-webkit-scrollbar-track,
.c-article.mod-reservation .c-inner-right ::-webkit-scrollbar-track {
		background-color: #fff;
}
.c-article.mod-reservation .c-inner-left::-webkit-scrollbar-thumb,
.c-article.mod-reservation .c-inner-right::-webkit-scrollbar-thumb {
		opacity: 0;
		background: #e9e9e9;
		border-radius: 50px;
		background-clip: padding-box;
}
.c-article.mod-reservation .c-inner-left::-webkit-scrollbar-corner,
.c-article.mod-reservation .c-inner-right::-webkit-scrollbar-corner {
		opacity: 0;
		background-color: transparent;
}
.c-article.mod-reservation .c-inner-left {
		width: 180px;
}
.c-article.mod-reservation .c-inner-right {
		padding-left: 24px;
		flex-grow: 1;
}
.c-article.mod-reservation .rsv-mod-list01 > li {
		padding-right: 12px;
}
.c-article.mod-reservation .rsv-mod-list01 > li button {
		text-align: left;
		padding: 10px 20px;
		display: block;
		min-width: 160px;
		display: block;
		border-radius: 6px;
		background-color: #fff;
}
.c-article.mod-reservation .rsv-mod-list01 > li button span {
		color: #1c1c1b;
		line-height: 1.7;
}
.c-article.mod-reservation .rsv-mod-list01 > li button span font {
		size: 14px;
}
.c-article.mod-reservation .rsv-mod-list01 > li button.on {
		background-color: #253981;
}
.c-article.mod-reservation .rsv-mod-list01 > li button.on span {
		color: #fff;
}
.c-article.mod-reservation .rsv-mode-list02 > li {
		padding-right: 12px;
}
.c-article.mod-reservation .rsv-mode-list02 > li + li {
		margin-top: 8px;
}
.c-article.mod-reservation .rsv-mod-detail {
		padding: 12px;
		border: 1px solid #e9e9e9;
		border-radius: 8px;
		display: flex;
		width: 100%;
		align-items: center;
		color: #777676;
}
.c-article.mod-reservation .rsv-mod-detail .c-chkbox {
		flex-grow: 1;
}
.c-article.mod-reservation .rsv-mod-detail .region {
		padding: 2px 16px;
}
.c-article.mod-reservation .rsv-mod-detail .link {
		margin-left: 12px;
		padding: 2px 16px;
}

#container .etc-wrapper {
		min-width: 1200px;
		padding-left: 0;
		background-color: #f8f9fb;
}

.c-full-info-wrap {
		position: relative;
		display: flex;
		align-items: center;
		justify-content: center;
		position: relative;
		padding-left: 14.285rem;
		padding-right: 14.285rem;
		height: 100%;
}
.c-full-info-wrap .c-full-info-field {
		justify-content: space-between;
		align-items: center;
		display: flex;
		width: 100%;
}
.c-full-info-wrap .c-full-info-field.is-error .info-cont {
		line-height: 36px;
}
.c-full-info-wrap .c-full-info-field.is-error .img img {
		width: 271px;
		height: 282px;
}
.c-full-info-wrap .c-full-info-field.is-update .img img {
		width: 328px;
		height: 282px;
}
.c-full-info-wrap .c-cont-field {
		color: rgba(28, 28, 27, 0.8);
}
.c-full-info-wrap .c-cont-field .title {
		font-size: 80px;
		font-weight: 700;
		line-height: 1.45;
}
.c-full-info-wrap .c-cont-field .info-cont {
		margin-top: 40px;
		font-size: 24px;
		font-weight: 700;
		line-height: 1.5;
}
.c-full-info-wrap .c-cont-field .sub-cont {
		margin-top: 8px;
}
.c-full-info-wrap .c-cont-field .co-btn {
		margin-top: 40px;
		display: inline-block;
		border-radius: 8px;
		min-width: 134px;
		padding: 8px 40px;
		height: 46px;
}
.c-full-info-wrap .c-cont-field .co-btn span {
		color: #fff;
		line-height: 30px;
}
.c-full-info-wrap .footer {
		position: absolute;
		bottom: 40px;
		left: 0;
		right: 0;
		text-align: center;
		margin: 0 auto;
}

.spinner-field {
		position: absolute;
		top: 0%;
		left: 0;
		right: 0;
		bottom: 0;
		display: flex;
		justify-content: center;
		align-items: center;
		width: 100%;
		height: 100%;
		display: block;
}
.spinner-field > div {
		position: absolute;
		left: 50%;
		top: 50%;
		transform: translate(-50%, -50%);
}

.spinner {
		border: 6px solid #e7e9ea;
		border-radius: 50%;
		width: 58px;
		height: 58px;
		animation: spinner 1.5s linear infinite;
		position: relative;
}
.spinner:after {
		content: "";
		background: url("../images/spinner.svg") 0 0/28px auto no-repeat;
		display: inline-block;
		width: 28px;
		height: 28px;
		position: absolute;
		top: -6px;
		left: 23px;
}

@keyframes spinner {
		0% {
				transform: rotate(0);
		}
		100% {
				transform: rotate(360deg);
		}
}
/*
 = Login
 */
#container .login-wrapper {
		padding-left: 0;
		min-height: 800px;
		height: inherit;
}

.login-wrapper {
		height: 100%;
		border-left: 1px solid #e9e9e9;
}
.login-wrapper .login-wrap {
		display: flex;
		width: 100%;
		height: 100%;
		flex: 1 0 auto;
}
.login-wrapper .side-area h1.title {
		display: block;
		width: 100%;
		text-align: left;
		font-size: 40px;
		font-weight: 500;
		line-height: 58px;
}
.login-wrapper .contents-area {
		width: 100%;
		display: flex;
		flex: 1 1 100%;
		flex-direction: column;
}
.login-wrapper .login-img {
		min-height: 781px;
}
.login-wrapper .login-box {
		margin-top: 20px;
}
.login-wrapper .login-box .c-input-btn-group {
		flex-wrap: wrap;
		display: flex;
		flex: 1 1 auto;
		margin-left: -8px;
		margin-right: -8px;
}
.login-wrapper .login-box .c-input-btn-group [class|=col] {
		padding-left: 8px;
		padding-right: 8px;
}
.login-wrapper .login-box .login-input input[type=text],
.login-wrapper .login-box .login-input input[type=password],
.login-wrapper .login-box .login-input .c-input-btn-group {
		width: 100%;
		flex: 0 0 0;
}
.login-wrapper .login-box .login-input input[type=text] + input[type=text], .login-wrapper .login-box .login-input input[type=text] + input[type=password],
.login-wrapper .login-box .login-input input[type=password] + input[type=text],
.login-wrapper .login-box .login-input input[type=password] + input[type=password],
.login-wrapper .login-box .login-input .c-input-btn-group + input[type=text],
.login-wrapper .login-box .login-input .c-input-btn-group + input[type=password] {
		margin-top: 16px;
}
.login-wrapper .login-box .login-input .row {
		width: 100%;
}
.login-wrapper .login-box .login-input .search-btn-group {
		position: relative;
		margin-left: 0;
		margin-right: 0;
}
.login-wrapper .login-box .login-input .search-btn-group.row {
		width: auto;
}
.login-wrapper .login-box .login-input .search-btn-group .col {
		padding-left: 8px;
		padding-right: 8px;
}
.login-wrapper .login-box .login-input .search-btn-group .input-button {
		flex: 0 0 80px;
}
.login-wrapper .login-box .login-input .search-btn-group .input-button button {
		width: 80px;
}
.login-wrapper .login-box .login-input .search-btn-group .c-input-search input[type=text] {
		width: 100%;
		flex: 1;
}
.login-wrapper .login-box .login-input .search-btn-group ~ input[type=text], .login-wrapper .login-box .login-input .search-btn-group ~ input[type=password] {
		margin-top: 12px;
}
.login-wrapper .login-box .login-input .search-related-field {
		position: absolute;
		top: 48px;
		left: 0;
		right: 0;
		z-index: 10;
}
.login-wrapper .login-box .login-input .search-related-field .search-list {
		background-color: #fff;
		border-radius: 8px;
		border: 1px solid #e9e9e9;
		overflow: hidden;
		box-shadow: 2px 2px 4px rgba(0, 0, 0, 0.25);
		min-width: 100%;
		padding-top: 4px;
		padding-bottom: 4px;
}
.login-wrapper .login-box .login-input .search-related-field .search-list > li ~ li {
		border-top: 1px solid #e9e9e9;
}
.login-wrapper .login-box .login-input .search-related-field .search-list > li a {
		padding: 7px 20px;
		display: block;
		color: #494949;
		font-size: 14px;
		font-weight: 300;
		line-height: 2.1428571429;
}
.login-wrapper .login-box .login-input .search-related-field .search-list > li a:hover {
		color: #253981;
}
.login-wrapper .login-box .info-cont {
		color: #777676;
		margin-top: 12px;
}
.login-wrapper .login-box .info-chk {
		margin-top: 40px;
}
.login-wrapper .login-box .info-chk:after {
		display: block;
		content: "";
		clear: both;
}
.login-wrapper .login-box .info-chk .c-chkbox {
		float: left;
}
.login-wrapper .login-box .info-chk .c-chkbox .chk-label {
		line-height: 2.14;
}
.login-wrapper .login-box .info-chk .c-chkbox + .link-guide {
		margin-top: 5px;
}
.login-wrapper .login-box .info-chk .link-guide {
		float: right;
}
.login-wrapper .login-box .c-btn.btn-login {
		margin-top: 40px;
		border-radius: 4px;
		width: 100%;
		padding-top: 16px;
		padding-bottom: 16px;
		color: #fff;
		line-height: 20px;
}
.login-wrapper .link-guide {
		font-size: 12px;
		color: #2f7ad3;
		line-height: 1.6;
		display: inline-block;
		text-decoration: underline;
}
.login-wrapper .login-btn {
		width: 100%;
		color: #fff;
		background-color: #253981;
		margin-top: 12px;
		font-size: 0;
		height: auto;
		padding-top: 16px;
		padding-bottom: 16px;
}
.login-wrapper .login-btn > span {
		font-size: 14px;
		font-weight: 300;
		line-height: 1.4285714286;
}
.login-wrapper .login-info-list {
		margin: 20px auto 0;
		display: table;
}
.login-wrapper .login-info-list:after {
		display: block;
		content: "";
		clear: both;
}
.login-wrapper .login-info-list > li {
		color: #a4a4a4;
		font-size: 12px;
		font-weight: 400;
		line-height: 2;
		float: left;
		position: relative;
}
.login-wrapper .login-info-list > li ~ li {
		padding-left: 16px;
}
.login-wrapper .login-info-list > li ~ li:before {
		position: absolute;
		top: 6px;
		left: 8px;
		content: "";
		width: 1px;
		height: 15px;
		display: inline-block;
		background-color: #d2d2d1;
}
.login-wrapper .login-info-list > li > a,
.login-wrapper .login-info-list > li > button {
		display: inline-block;
}
.login-wrapper .use-info {
		margin-top: 100px;
		text-align: center;
}
.login-wrapper .use-info p {
		color: #494949;
		font-size: 14px;
		font-weight: 300;
		line-height: 2.1428571429;
}
.login-wrapper .use-info p:first-child > span {
		font-weight: 400;
}
.login-wrapper .btm-logo {
		position: absolute;
		bottom: 20px;
		position: absolute;
		left: 50%;
		top: initial;
		transform: translateX(-50%);
}
.login-wrapper .btm-logo > .copyright {
		margin-top: 20px;
		color: rgba(28, 28, 27, 0.4);
}
.login-wrapper .contents-field {
		width: 100%;
		height: 100%;
		overflow: hidden;
}
.login-wrapper .contents-field > .login-img {
		background-color: #d3d7e6;
		display: flex;
		height: 100%;
		flex: 1 0 0;
		flex-direction: column;
		justify-content: flex-end;
		align-items: center;
}
.login-wrapper .contents-field > .login-img .c-title {
		width: 100%;
		white-space: nowrap;
		text-align: center;
		text-align: center;
		color: #253981;
		display: flex;
		flex-direction: column;
		flex: 1 0 auto;
		justify-content: center;
}
.login-wrapper .contents-field > .login-img .c-title > p {
		font-size: 32px;
		font-weight: 300;
		line-height: 1.4375;
		font-size: 1.667vw;
		font-weight: 300;
		line-height: 1.437;
}
.login-wrapper .contents-field > .login-img .c-title > em {
		font-size: 3.25vw;
		font-weight: 700;
		line-height: 1.467;
}
@media screen and (min-width: 1920px) {
		.login-wrapper .contents-field > .login-img .c-title > p {
				font-size: 32px;
		}
		.login-wrapper .contents-field > .login-img .c-title > em {
				font-size: 60px;
		}
}
.login-wrapper .contents-field > .login-img .reg-img {
		width: 100%;
		height: 100%;
}
.login-wrapper .contents-field > .login-img .img {
		text-align: center;
}
.login-wrapper .contents-field > .login-img .img img {
		width: 40vw;
		max-width: 40vw;
}
@media screen and (min-width: 1920px) {
		.login-wrapper .contents-field > .login-img .img img {
				min-width: initial;
				width: auto;
				max-height: 53vh;
		}
}
.login-wrapper .login-categeory {
		margin-top: 20px;
		width: 100%;
}
.login-wrapper .login-categeory + .login-box {
		margin-top: 16px;
}

#container.rsv-exam-container .content-wrapper {
		padding-left: 0;
}
#container.rsv-exam-container .header {
		padding-left: 0;
}
#container.rsv-exam-container .co-title-field.top {
		display: block;
}
#container.rsv-exam-container .co-title-field.top .title {
		padding-bottom: 20px;
		border-bottom: 1px solid rgba(28, 28, 27, 0.1);
}
#container.rsv-exam-container .reserve-contents-area {
		position: relative;
}
#container.rsv-exam-container .reserve-contents-area .co-title-field.top {
		padding: 40px 60px 0;
}
#container.rsv-exam-container .top-sub-cont {
		margin-bottom: 40px;
}
#container.rsv-exam-container .contents {
		max-width: 2560px;
}
#container.rsv-exam-container .footer .container {
		max-width: 2560px;
}
#container .top-header.type-kmi .logo img {
		height: 22px;
}
#container .top-header .slogan {
		padding-left: 20px;
}
#container .top-header .slogan p {
		font-size: 20px;
		font-weight: 500;
		line-height: 1.8;
		color: #494949;
}

.join-login-field .co-terms-chk-list {
		margin-top: 40px;
}
.join-login-field .terms-all-chkbox + .co-terms-chk-list {
		margin-top: 20px;
}
.join-login-field .co-article {
		margin-top: 40px;
}
.join-login-field .c-input-list-basic + .co-terms-detail {
		margin-top: 20px;
}
.join-login-field .info-cont-box {
		margin-top: 12px;
		color: #777676;
}
.join-login-field.step02 .co-cont-article {
		margin-top: 32px;
}
.join-login-field.step03 .c-input-list-basic + .co-cont-article {
		margin-top: 40px;
}
.join-login-field.step03 .c-input-list-basic {
		margin-top: 32px;
}
.join-login-field.step03 .c-input-list-basic > .row ~ .row {
		padding-top: 0;
}
.join-login-field.step04 .co-cont-article.type01 {
		margin-top: 40px;
}
.join-login-field.step04 .c-input-list-basic {
		margin-top: 40px;
}
.join-login-field.step04 .c-input-list-view {
		margin-top: 20px;
}
.join-login-field .cont-box + .c-input-list-basic {
		margin-top: 32px;
}

.find-accinfo-field .co-tab-list + .tab-cont-wrap {
		margin-top: 40px;
}

.client-card {
		position: relative;
		min-height: 160px;
		margin-top: 20px;
		padding: 31px 20px;
		border: 1px solid #e9e9e9;
		background-color: #fff;
		border-radius: 12px;
}
.client-card .none-data .img {
		width: 63px;
		height: auto;
		margin: 0 auto;
}
.client-card .none-data .co-none-txt {
		margin-top: 12px;
}
.client-card .relationship {
		font-size: 12px;
		font-weight: 300;
		line-height: 1.6666666667;
		color: #2f7ad3;
		display: flex;
		align-items: center;
}
.client-card .client-info {
		display: flex;
		align-items: center;
}
.client-card .client-info .name {
		font-size: 20px;
		font-weight: 500;
		line-height: 1.8;
		color: #1c1c1b;
}
.client-card .client-info .reg-number {
		padding-left: 12px;
		font-size: 12px;
		font-weight: 400;
		line-height: 2;
		color: #777676;
}
.client-card .detail-info {
		margin-top: 12px;
		padding-top: 12px;
		padding-bottom: 8px;
		border-top: 1px solid #e9e9e9;
}
.client-card .detail-info > ul > li {
		display: flex;
		align-items: center;
		font-size: 12px;
		font-weight: 300;
		line-height: 1.6666666667;
		overflow: hidden;
		text-overflow: ellipsis;
		white-space: nowrap;
		word-wrap: break-word;
}
.client-card .detail-info > ul > li ~ li {
		padding-top: 4px;
}
.client-card .detail-info > ul li span {
		color: #a4a4a4;
}
.client-card .detail-info > ul li > p {
		color: #1c1c1b;
		padding-left: 12px;
}
.client-card .buttons {
		margin-top: 12px;
}

.ctg-list-slide-wrap {
		margin-top: 12px;
		width: 100%;
		overflow: hidden;
		position: relative;
}
.ctg-list-slide-wrap .swiper-wrapper {
		width: 268px;
		position: relative;
}
.ctg-list-slide-wrap .category-swiper {
		position: relative;
}
.ctg-list-slide-wrap .category-swiper .co-badge {
		display: inline-block;
		border-radius: 12px;
		color: #253981;
		background-color: #ecf1f8;
}
.ctg-list-slide-wrap .category-swiper .swiper-slide {
		width: auto;
		display: flex;
		justify-content: center;
		align-items: center;
		margin-right: 8px;
}

.main-container .footer {
		min-width: 1588px;
}

.contents-area.main-contents-area .co-list-field.main-notice {
		position: relative;
		min-height: 226px;
}
.contents-area.main-contents-area .co-list-field.none-data > div {
		position: absolute;
		left: 50%;
		top: 50%;
		transform: translate(-50%, -50%);
}
.contents-area.main-contents-area .main-notice-list > li {
		height: 46.8px;
}
.contents-area.main-contents-area .main-notice-list > li:hover {
		background-color: #f8f9fb;
}
.contents-area.main-contents-area .main-notice-list .co-badge {
		min-width: 50px;
		text-align: center;
}
.contents-area.main-contents-area .main-card > .co-title-field {
		margin-bottom: 12px;
}

.rolling-banner {
		border: 1px solid #e9e9e9;
		border-radius: 12px;
		position: relative;
		padding: 2px 20px;
}
.rolling-banner .list {
		position: relative;
		height: 30px;
		overflow: hidden;
		padding-right: 64px;
		color: #494949;
}
.rolling-banner .list > li {
		overflow: hidden;
		text-overflow: ellipsis;
		white-space: nowrap;
		word-wrap: break-word;
		display: flex;
		align-items: center;
		height: 30px;
}
.rolling-banner .list > li .co-badge {
		color: #253981;
		margin-right: 20px;
}
.rolling-banner .rolling-btn-group {
		position: absolute;
		top: 8px;
		right: 20px;
}
.rolling-banner .rolling-btn-group .buttons:after {
		display: block;
		content: "";
		clear: both;
}
.rolling-banner .rolling-btn-group button {
		float: left;
}
.rolling-banner .rolling-btn-group button + button {
		margin-left: 4px;
}
.rolling-banner.type02 {
		border: 0;
		padding: 0;
}
.rolling-banner.type02 .rolling-btn-group {
		right: 0;
}

.main-slide-swiper {
		overflow: hidden;
}
.main-slide-swiper .slide img {
		width: 100%;
}
.main-slide-swiper .slide-card {
		position: relative;
		width: 100%;
		border-radius: 12px;
		overflow: hidden;
		height: 280px;
		max-height: 280px;
		background-position: center;
		background-repeat: no-repeat;
		background-size: cover;
}
.main-slide-swiper .main-banner-pagination {
		position: absolute;
		left: 0;
		width: 100%;
		text-align: center;
		bottom: 12px;
		z-index: 10;
}
.main-slide-swiper .main-banner-pagination .swiper-pagination-bullet {
		background-color: #d2d2d1;
		opacity: 1;
		margin: 0 6px;
}
.main-slide-swiper .main-banner-pagination .swiper-pagination-bullet.swiper-pagination-bullet-active {
		width: 20px;
		border-radius: 20px;
		background-color: #2f7ad3;
}

.main-card .card-cont {
		overflow: hidden;
		position: relative;
		display: flex;
		align-items: center;
		flex: 1 0 auto;
		padding: 20px 32px;
		width: 100%;
		border: 1px solid #e9e9e9;
		border-radius: 12px;
		background-color: #fff;
		height: 194px;
}
.main-card .card-cont .cont-inner {
		display: block;
		width: 100%;
		overflow: hidden;
}
.main-card .card-cont.type01 {
		text-align: center;
}
.main-card .card-cont.type01 .img {
		margin: 0 auto;
}
.main-card .card-cont.type01 .title-add {
		margin-top: 12px;
		display: block;
}
.main-card .card-cont.type01 .title-add span {
		font-size: 20px;
		font-weight: 400;
		line-height: 1.8;
		color: #253981;
}
.main-card .card-cont.type01 .title-add .c-plus-btn-1 {
		display: inline-block;
		vertical-align: top;
		margin-top: 4px;
}
.main-card .card-cont.type01 .ref-txt {
		font-size: 12px;
		font-weight: 300;
		line-height: 1.6666666667;
		color: #a4a4a4;
}
.main-card .card-cont.type02 {
		padding: 0;
}
.main-card .card-cont.type02 .inner {
		position: relative;
		overflow: hidden;
}
.main-card .card-cont.type02 .inner > a {
		padding: 20px 32px;
		overflow: hidden;
		display: table;
		table-layout: fixed;
		width: 100%;
		position: relative;
		transition: background-color 0.4s;
}
.main-card .card-cont.type02 .inner > a:hover {
		background-color: #f8f9fb;
}
.main-card .card-cont.type02 .inner ~ .inner {
		border-top: 1px solid #e9e9e9;
}
.main-card .card-cont.type02 .inner .ico,
.main-card .card-cont.type02 .inner .cont {
		display: table-cell;
		vertical-align: middle;
}
.main-card .card-cont.type02 .inner:after {
		position: absolute;
		top: 50%;
		right: 32px;
		transform: translateY(-50%);
		display: inline-block;
		width: 28px;
		height: 28px;
		content: "";
		background: url("../images/ico//ico_arrow_13.svg") center/28px no-repeat;
}
.main-card .card-cont.type02 .ico {
		width: 44px;
}
.main-card .card-cont.type02 .ico div {
		background-color: #ecf1f8;
		border-radius: 12px;
		margin: 0 auto;
		width: 44px;
		height: 44px;
		position: relative;
}
.main-card .card-cont.type02 .ico div img {
		position: absolute;
		left: 50%;
		top: 50%;
		transform: translate(-50%, -50%);
}
.main-card .card-cont.type02 .cont {
		padding-left: 20px;
}
.main-card .card-cont.type02 .cont .title {
		font-size: 20px;
		font-weight: 500;
		line-height: 1.8;
		color: #494949;
}
.main-card .card-cont.type02 .cont .go-link {
		font-size: 12px;
		font-weight: 300;
		line-height: 1.6666666667;
		color: #253981;
}
.main-card .card-cont.type03 {
		padding: 30px 28px;
}
.main-card .customer-service .title {
		font-size: 20px;
		font-weight: 500;
		line-height: 1.8;
}
.main-card .customer-service p .tel,
.main-card .customer-service .time {
		display: block;
}
.main-card .customer-service .tel {
		font-size: 30px;
		font-weight: 700;
		line-height: 1.0666666667;
		color: #2f7ad3;
		display: block;
		margin-bottom: 16px;
}
.main-card .customer-service .time {
		font-size: 14px;
		font-weight: 300;
		line-height: 2.1428571429;
		color: #1c1c1b;
}
.main-card .customer-service .time > span {
		color: #777676;
}
.main-card .customer-service .time + p {
		margin-top: 12px;
		color: #494949;
}

.main-center-introduce {
		position: relative;
}
.main-center-introduce .center-pagination,
.main-center-introduce .main-center-pagination {
		position: absolute;
		top: 8px;
		right: 0;
		left: auto;
		bottom: auto;
		width: auto;
		font-size: 12px;
		font-weight: 300;
		line-height: 2;
		color: #a4a4a4;
}
.main-center-introduce .co-list-field.none-data {
		height: 234px;
}

.center-introduce-wrapper .tab-cont-wrap {
		margin-top: 12px;
}

.main-popup-field .cont-box + .c-input-list-basic {
		margin-top: 40px;
}

.swiper .co-slide-cont {
		position: absolute;
		top: 0;
		left: 0;
		right: 0;
		bottom: 0;
}
.swiper .inner {
		height: 100%;
		width: 100%;
}
.swiper .swiper-slide img {
		display: block;
		width: 100%;
		height: 100%;
}
.swiper .swiper-button-disabled {
		opacity: 1;
}

.main-swiper-field {
		margin-top: 20px;
}
.main-swiper-field.is-loading {
		position: relative;
}
.main-swiper-field.is-loading .spinner-field {
		border: 1px solid #e9e9e9;
		border-radius: 12px;
		overflow: hidden;
}

.main-slide-swiper {
		position: relative;
		overflow: hidden;
		border-radius: 12px;
		height: 280px;
}
.main-slide-swiper.swiper {
		position: relative;
}
.main-slide-swiper .swiper-slide {
		display: flex;
		justify-content: center;
		align-items: center;
		background-color: #fcfcfc;
		border-radius: 12px;
		overflow: hidden;
}
.main-slide-swiper .txt-cont {
		display: flex;
		width: 100%;
		height: 100%;
		align-items: center;
		text-align: left;
		flex-direction: column;
		justify-content: center;
		align-items: flex-start;
		padding-left: 80px;
		padding-right: 80px;
}
.main-slide-swiper .sub-title-01 {
		font-size: 16px;
		font-weight: 400;
		line-height: 2;
}
.main-slide-swiper .title-01 {
		font-size: 32px;
		font-weight: 700;
		line-height: 1.4375;
		margin-top: 12px;
}
.main-slide-swiper .cont-01 {
		margin-top: 4px;
		font-size: 20px;
		font-weight: 300;
		line-height: 1.45;
}
.main-slide-swiper .swiper-button-field:hover:before {
		opacity: 1;
}
.main-slide-swiper .swiper-button-field.prev {
		left: 0;
}
.main-slide-swiper .swiper-button-field.prev:hover:before {
		background: linear-gradient(90deg, rgba(25, 25, 25, 0.1) 0%, rgba(217, 217, 217, 0) 38.05%);
}
.main-slide-swiper .swiper-button-field.next {
		right: 0;
}
.main-slide-swiper .swiper-button-field.next:hover:before {
		content: "";
		background: linear-gradient(90deg, rgba(25, 25, 25, 0.1) 0%, rgba(217, 217, 217, 0) 38.05%);
		transform: rotate(-180deg);
}
.main-slide-swiper .main-slide-btn-prev,
.main-slide-swiper .main-slide-btn-next {
		position: absolute;
		top: 0;
		width: 102px;
		height: 280px;
		z-index: 1;
		border-radius: 12px;
		cursor: pointer;
		background: none;
}
.main-slide-swiper .main-slide-btn-prev > div,
.main-slide-swiper .main-slide-btn-next > div {
		position: relative;
}
.main-slide-swiper .main-slide-btn-prev .ico,
.main-slide-swiper .main-slide-btn-next .ico {
		position: absolute;
		display: inline-block;
		width: 28px;
		height: 28px;
		background-image: url("../images/ico/ico_sprite_slide_arrow.svg");
		background-size: 28px auto;
		background-repeat: no-repeat;
		top: 50%;
		transform: translateY(-50%);
}
.main-slide-swiper .main-slide-btn-prev {
		left: 0;
}
.main-slide-swiper .main-slide-btn-prev .ico {
		left: 20px;
		background-position: 0 0px;
}
.main-slide-swiper .main-slide-btn-prev:hover:before {
		position: absolute;
		top: 0;
		left: 0;
		bottom: 0;
		right: 0;
		content: "";
		background: linear-gradient(90deg, rgba(25, 25, 25, 0.1) 0%, rgba(217, 217, 217, 0) 38.05%);
}
.main-slide-swiper .main-slide-btn-prev:hover .ico {
		background-position: 0 -76px;
}
.main-slide-swiper .main-slide-btn-next {
		right: 0;
}
.main-slide-swiper .main-slide-btn-next .ico {
		right: 20px;
		background-position: 0 -38px;
}
.main-slide-swiper .main-slide-btn-next:hover:before {
		position: absolute;
		top: 0;
		left: 0;
		bottom: 0;
		right: 0;
		content: "";
		background: linear-gradient(90deg, rgba(25, 25, 25, 0.1) 0%, rgba(217, 217, 217, 0) 38.05%);
		transform: rotate(-180deg);
}
.main-slide-swiper .main-slide-btn-next:hover .ico {
		background-position: 0 -114px;
}

.main-center-slide-field {
		min-height: 186px;
		overflow: hidden;
		border-radius: 12px;
		position: relative;
}
.main-center-slide-field .main-center-swiper {
		width: auto;
}
.main-center-slide-field .swiper {
		width: 100%;
		height: 100%;
}
.main-center-slide-field .swiper-slide {
		text-align: center;
		font-size: 18px;
		background: #fff;
		/* Center slide text vertically */
		display: flex;
		justify-content: center;
		align-items: center;
}
.main-center-slide-field .swiper-slide img {
		display: block;
		width: 100%;
		height: 100%;
		object-fit: cover;
}
.main-center-slide-field .swiper-slide {
		width: 360px;
		height: 234px;
}
.main-center-slide-field .swiper-slide img {
		width: 100%;
		height: 100%;
}
.main-center-slide-field .swiper-slide img {
		display: block;
		width: 100%;
		height: 100%;
		object-fit: cover;
}
.main-center-slide-field .tab-content {
		overflow: hidden;
}
.main-center-slide-field .slide-card {
		width: 100%;
		position: relative;
		border-radius: 12px;
		overflow: hidden;
		min-height: 234px;
}
.main-center-slide-field .swiper-button-prev,
.main-center-slide-field .swiper-button-next {
		position: absolute;
		top: calc(50% + 28px);
		transform: translateY(-50%);
}
.main-center-slide-field .swiper-button-prev {
		left: 0;
}
.main-center-slide-field .swiper-button-next {
		right: 0;
}
.main-center-slide-field .none-data {
		position: relative;
		display: flex;
		flex: 1 0 auto;
		height: 100%;
		justify-content: center;
		align-items: center;
		background-color: #f8f9fb;
		height: 186px;
}
.main-center-slide-field .none-data:before {
		content: "";
		position: absolute;
		bottom: 11px;
		right: 23px;
		width: 131px;
		height: 129px;
		background: url("../images/logo/kmi/kmi_05.svg") center/100% no-repeat;
		opacity: 0.5;
}

.consult-detail {
		padding: 20px;
		border-radius: 12px;
		border: 1px solid #e9e9e9;
}
.co-title-field + .consult-detail {
		margin-top: 12px;
}

.consult-detail dl ~ dl {
		margin-top: 24px;
}
.consult-detail dl dt {
		font-size: 12px;
		font-weight: 300;
		line-height: 2;
		color: #a4a4a4;
}
.consult-detail dl dd {
		margin-top: 4px;
}

.co-table-field + .co-terms-chk-card {
		margin-top: 20px;
}
.co-table-field .tbl-chkresult-compare {
		margin-top: 0;
}

.institution-info-list-field .list-field {
		margin-top: 20px;
}
.institution-info-list-field .institution-info-list {
		display: flex;
		flex-wrap: wrap;
		margin-left: -20px;
		margin-right: -20px;
}
.institution-info-list-field .institution-info-list > li {
		padding: 20px;
		width: 25%;
}
.institution-info-list-field .institution-info-card {
		position: relative;
}
.institution-info-list-field .institution-info-card .thumbnail {
		height: 160px;
		position: relative;
		overflow: hidden;
		border-radius: 12px;
}
.institution-info-list-field .institution-info-card .thumbnail img {
		position: absolute;
		top: 0;
		left: 0;
		width: 100%;
		height: 100%;
}
.institution-info-list-field .institution-info-card .cont {
		padding-top: 20px;
		font-size: 0;
}
.institution-info-list-field .institution-info-card .location {
		position: relative;
		font-size: 12px;
		font-weight: 300;
		line-height: 1.6666666667;
		color: #2f7ad3;
		padding-left: 17px;
}
.institution-info-list-field .institution-info-card .location:before {
		position: absolute;
		left: 0;
		top: 50%;
		transform: translateY(-50%);
		display: inline-block;
		width: 10px;
		height: 12px;
		background: url("../images/ico/ico_sprites_01.svg") -807px -8px/auto 218px no-repeat;
		content: "";
}
.institution-info-list-field .institution-info-card .title {
		font-size: 16px;
		font-weight: 500;
		line-height: 2;
		overflow: hidden;
		text-overflow: ellipsis;
		white-space: nowrap;
		word-wrap: break-word;
}
.institution-info-list-field .institution-info-card.none-data {
		position: relative;
		height: 270px;
		padding: 67px 0;
}
.institution-info-list-field .institution-info-card.none-data > div {
		position: absolute;
		left: 50%;
		top: 50%;
		transform: translate(-50%, -50%);
}
.institution-info-list-field .institution-info-card.co-none-data {
		position: relative;
		height: 232px;
		padding: 80px 0;
		border-radius: 12px;
		background-color: #fcfcfc;
}
.institution-info-list-field .institution-info-card.co-none-data > div {
		position: absolute;
		left: 0;
		width: 100%;
		top: 50%;
		transform: translateY(-50%);
}
.institution-info-list-field .institution-info-card.co-none-data .co-none-txt {
		margin-top: 12px;
}

.checkup-item-wrap .co-tab-wrap {
		margin-top: 20px;
}

.checkup-item-list-field {
		padding-top: 6px;
		margin-left: -20px;
		margin-right: -20px;
}

.checkup-item-list {
		display: flex;
		flex-wrap: wrap;
		width: 100%;
}
.checkup-item-list > li {
		width: 16.6666666667%;
		padding: 14px 20px;
}
.checkup-item-list a {
		display: block;
		text-align: center;
		margin: 0 auto;
}
.checkup-item-list .items-card {
		border-radius: 12px;
		height: 140px;
		color: #777676;
		border: 1px solid #e9e9e9;
		background-color: #fff;
		display: flex;
		align-items: center;
		justify-content: flex-start;
		padding-top: 27px;
		text-align: center;
		flex-direction: column;
}
.checkup-item-list .items-card i {
		height: 46px;
}
.checkup-item-list .items-card i > img {
		height: 100%;
}
.checkup-item-list .items-card:hover {
		background-color: #f3f3f3;
}
.checkup-item-list .items-card .name {
		font-size: 16px;
		font-weight: 500;
		line-height: 2;
		color: #777676;
		margin-top: 4px;
}
.checkup-item-list .items-card .sub-txt {
		font-size: 12px;
		font-weight: 300;
		line-height: 1.6666666667;
		color: #777676;
}

.checkup-item-detail-field .img {
		border-radius: 12px;
		background-color: #d9d9d9;
		overflow: hidden;
		height: 260px;
}
.checkup-item-detail-field .img img {
		width: 100%;
		height: 100%;
}
.checkup-item-detail-field .datail-card {
		margin-top: 20px;
}
.checkup-item-detail-field .datail-card .title {
		font-size: 16px;
		font-weight: 500;
		line-height: 2;
		color: #1c1c1b;
}
.checkup-item-detail-field .datail-card .cont {
		margin-top: 12px;
		border: 1px solid #e9e9e9;
		border-radius: 12px;
		padding: 20px;
		color: #494949;
}
.checkup-item-detail-field .datail-card .co-list-card > li {
		color: #494949;
}
.checkup-item-detail-field .datail-card + .co-ref-txt {
		margin-top: 12px;
}

.video-list-field {
		margin-top: 20px;
}

.video-list {
		display: flex;
		flex-wrap: wrap;
		margin-left: -20px;
		margin-right: -20px;
}
.video-list > li {
		padding: 20px;
		position: relative;
		width: 25%;
}

.video-card {
		position: relative;
		background-color: #fff;
		border: 1px solid rgba(28, 28, 27, 0.1);
		border-radius: 12px;
		overflow: hidden;
}
.video-card.co-none-data {
		position: relative;
		padding-bottom: calc(50.704% + 90px);
}
.video-card.co-none-data > div {
		position: absolute;
		top: 50%;
		left: 0;
		width: 100%;
		transform: translateY(-50%);
}
.video-card.co-none-data .img {
		overflow: hidden;
		opacity: 0.3;
		width: 100px;
		height: auto;
		margin: 0 auto;
}
.video-card.co-none-data .img img {
		width: 100px;
		height: auto;
}
.video-card.co-none-data .co-none-txt {
		margin-top: 12px;
}
.video-card .thumbnail {
		padding-bottom: 50.704%;
		position: relative;
		overflow: hidden;
}
.video-card .thumbnail .img {
		position: absolute;
		top: 0;
		left: 0;
		width: 100%;
		bottom: 0;
		height: inherit;
		background-position: center;
		background-size: cover;
		background-repeat: no-repeat;
}
.video-card .thumbnail img {
		position: absolute;
		top: 0;
		left: 0;
		width: 100%;
		height: 100%;
}
.video-card .cont {
		padding: 20px;
		font-size: 0;
}
.video-card .date {
		font-size: 12px;
		font-weight: 300;
		line-height: 1.6666666667;
		color: #2f7ad3;
}
.video-card .title {
		color: #494949;
		overflow: hidden;
		text-overflow: ellipsis;
		white-space: nowrap;
		word-wrap: break-word;
		font-size: 14px;
		font-weight: 300;
		line-height: 2.1428571429;
}
.video-card.none-data {
		position: relative;
		height: 270px;
		padding: 67px 0;
}
.video-card.none-data > div {
		position: absolute;
		left: 50%;
		top: 50%;
		transform: translate(-50%, -50%);
}

.board-detail-field .title-field {
		padding: 20px 0;
		border-bottom: 1px solid rgba(28, 28, 27, 0.1);
}
.board-detail-field .title {
		text-align: center;
		font-size: 20px;
		font-weight: 500;
		line-height: 1.8;
}

.board-detail-content {
		padding: 20px 0;
		border-bottom: 1px solid rgba(28, 28, 27, 0.1);
}
.board-detail-content .detail-info {
		display: flex;
		justify-content: flex-end;
		align-items: center;
		color: #a4a4a4;
}
.board-detail-content .detail-info .view-cnt p {
		position: relative;
}
.board-detail-content .detail-info .view-cnt p:before {
		display: inline-block;
		margin-right: 8px;
		content: "";
		width: 11px;
		height: 8px;
		background: url("../images/ico/ico_veiw_01.svg") center/11px auto no-repeat;
}
.board-detail-content .detail-info .date {
		padding-left: 29px;
		position: relative;
}
.board-detail-content .detail-info .date:before {
		position: absolute;
		left: 13px;
		content: "/";
}
.board-detail-content .view-video {
		border-radius: 12px;
		overflow: hidden;
		background-color: #d9d9d9;
		padding-bottom: 20px;
		height: 559px;
}
.board-detail-content .buttons {
		justify-content: center;
		padding: 20px 0;
}

.datail-inner-cont {
		margin-top: 20px;
		padding-bottom: 20px;
		min-height: 200px;
}

.btm-nav-board {
		padding: 20px 0;
		border-bottom: 1px solid rgba(28, 28, 27, 0.1);
		display: flex;
		align-items: center;
		justify-content: space-between;
}
.btm-nav-board .col {
		width: 50%;
		padding: 20px;
		text-align: center;
		color: #777676;
		font-size: 16px;
		font-weight: 500;
		line-height: 2;
		max-width: 50%;
}
.btm-nav-board .col i {
		margin-top: 20px;
		width: 28px;
		height: 28px;
		background-image: url("../images/ico/ico_sprites_01.svg");
		background-size: auto 218px;
		background-repeat: no-repeat;
		background-position: -190px 0;
}
.btm-nav-board .col i.prev {
		transform: rotate(-180deg);
}
.btm-nav-board .col:first-child {
		border-right: 1px solid rgba(28, 28, 27, 0.1);
}
.btm-nav-board .col a {
		display: inline-block;
}
.btm-nav-board .col a:hover {
		color: #253981;
}
.btm-nav-board .col a:hover i {
		background-position-y: -38px;
}

.visiting-center-section > .row {
		margin-left: -20px;
		margin-right: -20px;
}
.visiting-center-section .left-wrap,
.visiting-center-section .right-wrap {
		padding-left: 20px;
		padding-right: 20px;
}
.visiting-center-section .right-wrap {
		position: relative;
}
.visiting-center-section .right-wrap .floating-box {
		position: absolute;
		top: 0;
		right: 20px;
		left: 20px;
}

.visiting-center-list > li ~ li {
		margin-top: 40px;
}

.visit-center-card {
		border-radius: 12px;
		border: 1px solid #e9e9e9;
		overflow: hidden;
		padding: 24px 16px 24px 28px;
		background-color: #fcfcfc;
}
.visit-center-card .inner {
		display: flex;
		flex: 0 0 0;
		align-items: center;
}
.visit-center-card .cont-box {
		padding-right: 40px;
}
.visit-center-card .cont-box .title-box {
		display: flex;
		align-items: center;
}
.visit-center-card .cont-box .title-box .title {
		font-size: 20px;
		font-weight: 500;
		line-height: 1.8;
		color: #1c1c1b;
}
.visit-center-card .thumbnail {
		width: 148px;
		flex: 0 0 148px;
		border-radius: 12px;
		overflow: hidden;
}
.visit-center-card .thumbnail img {
		width: 100%;
		height: 100%;
}
.visit-center-card .is-new,
.visit-center-card .is-hot {
		margin-left: 8px;
		display: inline-block;
		font-size: 12px;
		font-weight: 300;
		line-height: 1.6666666667;
		padding: 2px 8px;
		border-radius: 8px;
}
.visit-center-card .is-new {
		color: #347928;
		background-color: #a4f897;
}
.visit-center-card .is-hot {
		color: #ff6859;
		background-color: #ffd4d0;
}
.visit-center-card .cont {
		margin-top: 4px;
}
.visit-center-card .cont p {
		color: #a4a4a4;
		overflow: hidden;
		text-overflow: ellipsis;
		display: -webkit-box;
		-webkit-box-orient: vertical;
		-webkit-line-clamp: 2;
		line-height: 30px;
		max-height: 60px;
}
.visit-center-card .hashtag-list {
		display: flex;
		flex-wrap: wrap;
		flex: 1 0 auto;
		margin-left: -4px;
		margin-right: -4px;
}
.visit-center-card .hashtag-list > li {
		padding: 4px;
}
.visit-center-card.none-data {
		height: 198px;
		text-align: center;
		display: flex;
		align-items: center;
		justify-content: center;
}
.visit-center-card.none-data .none-txt {
		font-size: 16px;
		font-weight: 500;
		line-height: 2;
		color: #a4a4a4;
}

.co-inner-cont-article .hashtag-list {
		display: flex;
		flex-wrap: wrap;
		flex: 1 0 auto;
		margin-left: -10px;
		margin-right: -10px;
}
.co-inner-cont-article .hashtag-list > li {
		padding: 6px 10px;
}

.event-tab-wrap {
		margin-top: 40px;
}

.event-list-field .event-list {
		display: flex;
		flex-wrap: wrap;
		margin-left: -20px;
		margin-right: -20px;
}
.event-list-field .event-list > li {
		padding: 20px;
		width: 25%;
		position: relative;
}

.event-card {
		position: relative;
		background-color: #fff;
		border-radius: 12px;
		overflow: hidden;
}
.event-card .thumbnail {
		padding-bottom: 56.338%;
		position: relative;
		overflow: hidden;
		border-radius: 12px;
		border: 1px solid #f3f3f3;
}
.event-card .thumbnail .img {
		position: absolute;
		top: 0;
		left: 0;
		width: 100%;
		bottom: 0;
		height: inherit;
		background-position: center;
		background-size: 100% 100%;
		background-repeat: no-repeat;
}
.event-card .thumbnail img {
		position: absolute;
		top: 0;
		left: 0;
		width: 100%;
		height: 100%;
}
.event-card .cont {
		padding: 20px 0;
		font-size: 0;
}
.event-card .date {
		font-size: 12px;
		font-weight: 300;
		line-height: 1.6666666667;
		color: #2f7ad3;
}
.event-card .title {
		color: #494949;
		overflow: hidden;
		text-overflow: ellipsis;
		white-space: nowrap;
		word-wrap: break-word;
		font-size: 14px;
		font-weight: 300;
		line-height: 2.1428571429;
}
.event-card.co-none-data {
		position: relative;
		padding-bottom: calc(56.338% + 90px);
		border: 1px solid #e9e9e9;
}
.event-card.co-none-data > div {
		position: absolute;
		top: 50%;
		left: 0;
		width: 100%;
		transform: translateY(-50%);
}
.event-card.co-none-data .img {
		overflow: hidden;
		opacity: 0.3;
		width: 100px;
		height: auto;
		margin: 0 auto;
}
.event-card.co-none-data .img img {
		width: 100px;
		height: auto;
}
.event-card.co-none-data .co-none-txt {
		margin-top: 12px;
}
.event-card.is-end:after {
		content: "";
		position: absolute;
		top: 0;
		bottom: 0;
		left: 0;
		right: 0;
		background-color: #fff;
		opacity: 0.5;
}

.detail-download-field {
		margin-top: 20px;
}
.detail-download-field .download-list {
		overflow: hidden;
		border-radius: 12px;
		border: 1px solid #e9e9e9;
}
.detail-download-field .download-list > li {
		padding: 14px 32px;
		display: flex;
		align-items: center;
		justify-content: space-between;
}
.detail-download-field .download-list > li:nth-child(odd) {
		background-color: #fff;
}
.detail-download-field .download-list > li:nth-child(even) {
		background-color: #fcfcfc;
}
.detail-download-field .download-list .file-info {
		color: #494949;
}
.detail-download-field .download-list .file-info .file-size {
		margin-left: 8px;
		font-size: 12px;
		font-weight: 300;
		line-height: 1.6666666667;
		color: #a4a4a4;
}
.detail-download-field .btn-file-download {
		font-size: 14px;
		font-weight: 300;
		line-height: 1.7142857143;
		color: #a4a4a4;
		position: relative;
}
.detail-download-field .btn-file-download:after {
		display: inline-block;
		width: 28px;
		height: 28px;
		background: url("../images/ico/ico_sprites_01.svg") -1026px 0/auto 218px no-repeat;
		content: "";
		vertical-align: middle;
}

.notice-tab-wrap .co-table {
		margin-top: 40px;
}

.tbl-notice td .title {
		overflow: hidden;
		text-overflow: ellipsis;
		white-space: nowrap;
		word-wrap: break-word;
}

.faq-list > li {
		margin-top: 20px;
}

.institution-list-field .list-field {
		margin-top: 20px;
}
.institution-list-field .institution-list {
		display: flex;
		flex-wrap: wrap;
		margin-left: -20px;
		margin-right: -20px;
}
.institution-list-field .institution-list > li {
		padding: 20px;
		width: 25%;
		position: relative;
}
.institution-list-field .institution-list + .co-tbl-paging {
		margin-top: 20px;
}
.institution-list-field .institution-card {
		position: relative;
}
.institution-list-field .institution-card .thumbnail {
		padding-bottom: 45.07%;
		position: relative;
		overflow: hidden;
		border-radius: 12px;
}
.institution-list-field .institution-card .thumbnail .img {
		position: absolute;
		top: 0;
		left: 0;
		width: 100%;
		bottom: 0;
		height: inherit;
		background-position: center;
		background-size: cover;
		background-repeat: no-repeat;
}
.institution-list-field .institution-card .thumbnail img {
		position: absolute;
		top: 0;
		left: 0;
		width: 100%;
		height: 100%;
}
.institution-list-field .institution-card .cont {
		padding-top: 20px;
		font-size: 0;
}
.institution-list-field .institution-card .location {
		position: relative;
		font-size: 12px;
		font-weight: 300;
		line-height: 1.6666666667;
		color: #2f7ad3;
		padding-left: 17px;
}
.institution-list-field .institution-card .location:before {
		position: absolute;
		left: 0;
		top: 50%;
		transform: translateY(-50%);
		display: inline-block;
		width: 10px;
		height: 12px;
		background: url("../images/ico/ico_sprites_01.svg") -807px -8px/auto 218px no-repeat;
		content: "";
}
.institution-list-field .institution-card .title {
		font-size: 16px;
		font-weight: 500;
		line-height: 2;
		overflow: hidden;
		text-overflow: ellipsis;
		white-space: nowrap;
		word-wrap: break-word;
}
.institution-list-field .institution-card.none-data {
		border-radius: 12px;
		background-color: #fcfcfc;
		position: relative;
		position: absolute;
		top: 0;
		left: 0;
		right: 0;
		bottom: 0;
}
.institution-list-field .institution-card.none-data > div {
		position: absolute;
		top: 50%;
		left: 0;
		width: 100%;
		transform: translateY(-50%);
}
.institution-list-field .institution-card.none-data .img {
		overflow: hidden;
		opacity: 0.3;
		width: 100px;
		height: auto;
		margin: 0 auto;
}
.institution-list-field .institution-card.none-data .img img {
		width: 100px;
		height: auto;
}
.institution-list-field .institution-card.none-data .co-none-txt {
		margin-top: 12px;
}
.institution-list-field .institution-card > a {
		cursor: pointer;
}

.rsv-institution-datail-field {
		display: none;
		margin-top: 20px;
		margin-bottom: 20px;
		padding: 40px 20px 40px 40px;
		border-radius: 12px;
		background-color: #fcfcfc;
}
.rsv-institution-datail-field .co-title-field {
		padding-right: 24px;
		padding-bottom: 20px;
}
.rsv-institution-datail-field .title-box {
		display: flex;
		align-items: center;
}
.rsv-institution-datail-field .title-box .title {
		font-size: 20px;
		font-weight: 500;
		line-height: 1.8;
}
.rsv-institution-datail-field .title-box .co-link-2,
.rsv-institution-datail-field .title-box .co-info-txt {
		margin-left: 12px;
}
.rsv-institution-datail-field .title-box .co-caption-txt {
		color: #777676;
		margin-left: 12px;
}
.rsv-institution-datail-field .title-box .co-info-txt {
		font-size: 14px;
		font-weight: 300;
		line-height: 2.1428571429;
}
.rsv-institution-datail-field .title-box .info01 {
		margin-left: 8px;
}
.rsv-institution-datail-field.on {
		display: block;
}

.institution-datail-list > li + li {
		margin-top: 20px;
}

.institution-datail-list-article {
		height: 700px;
		padding-right: 20px;
		overflow-x: hidden;
		overflow-y: scroll;
}

.institution-detail-card {
		position: relative;
		background-color: #fff;
		border-radius: 12px;
		margin: 2px;
		border: 1px solid #e9e9e9;
		overflow: hidden;
		padding: 32px 40px;
}
.institution-detail-card.on {
		box-shadow: 0 0 0 2px #2f7ad3;
		border-color: #fff;
}
.institution-detail-card.on .top-box > em {
		color: #2f7ad3;
}
.institution-detail-card .top-box {
		display: flex;
		justify-content: space-between;
		align-items: center;
}
.institution-detail-card .top-box > em {
		font-size: 16px;
		font-weight: 500;
		line-height: 2;
}
.institution-detail-card .top-box .title {
		font-size: 16px;
		font-weight: 500;
		line-height: 2;
		color: #494949;
}
.institution-detail-card .top-box .detail-btns {
		display: flex;
		align-items: center;
}
.institution-detail-card .top-box .detail-btns [class*=btn] {
		display: flex;
		align-items: center;
}
.institution-detail-card .top-box .detail-btns [class*=btn] > span {
		padding-left: 8px;
		font-size: 12px;
		font-weight: 300;
		line-height: 1.6666666667;
		color: #a4a4a4;
}
.institution-detail-card .top-box .detail-btns [class*=btn] ~ a {
		margin-left: 32px;
}
.institution-detail-card .top-box .detail-btns .is-red + span {
		color: #ff6859;
}
.institution-detail-card .cont {
		padding-top: 12px;
		padding-bottom: 20px;
}
.institution-detail-card .cont > p {
		display: block;
		width: 100%;
		color: #777676;
		overflow: hidden;
		text-overflow: ellipsis;
		display: -webkit-box;
		-webkit-box-orient: vertical;
		-webkit-line-clamp: 2;
		line-height: 30px;
		max-height: 60px;
}
.institution-detail-card .middle-box {
		font-size: 14px;
		font-weight: 300;
		line-height: 2.1428571429;
		overflow: hidden;
		text-overflow: ellipsis;
		white-space: nowrap;
		word-wrap: break-word;
		margin-top: 24px;
		width: 81.29%;
}
.institution-detail-card .btm-box {
		border-top: 1px solid rgba(28, 28, 27, 0.1);
		padding-top: 20px;
		display: flex;
		align-items: center;
		justify-content: space-between;
}
.institution-detail-card .price-info {
		color: #a4a4a4;
}
.institution-detail-card .price-info > span {
		margin-right: 4px;
}
.institution-detail-card .price-info > em {
		margin: 7px 0 0 4px;
		font-size: 16px;
		font-weight: 500;
		line-height: 2;
		color: #2f7ad3;
}
.institution-detail-card .special-examination {
		margin-bottom: 8px;
}
.institution-detail-card .special-examination > span {
		display: inline-block;
		padding: 2px 8px;
		background-color: #253981;
		color: #fff;
		font-size: 12px;
		font-weight: 400;
		line-height: 2;
		position: relative;
		border-radius: 8px;
}

.chkupInstitution-tab-wrap .co-tab-list {
		margin-bottom: 12px;
}
.chkupInstitution-tab-wrap .link {
		color: #494949;
		text-decoration: underline;
}

.popup-institution-swiper {
		position: relative;
		overflow: hidden;
		border-radius: 12px;
}
.popup-institution-swiper .swiper-slide {
		overflow: hidden;
		display: flex;
		justify-content: center;
		align-items: center;
		height: 280px;
}
.popup-institution-swiper .swiper-slide img {
		display: block;
		width: 100%;
		height: 100%;
		object-fit: cover;
}
.popup-institution-swiper .swiper-button-prev,
.popup-institution-swiper .swiper-button-next {
		width: 24px;
		height: 24px;
		background-image: url("../images/ico/slide_arrow_04.svg");
		background-position: center;
		background-size: auto 24px;
		background-repeat: no-repeat;
		position: absolute;
		top: calc(50% + 24px);
		transform: translateY(-50%);
}
.popup-institution-swiper .swiper-button-prev {
		left: 12px;
}
.popup-institution-swiper .swiper-button-next {
		right: 12px;
		transform: rotate(-180deg);
}

.institution-info-list > li {
		margin-top: 40px;
}
.institution-info-list > li ul.circle {
		list-style: disc inside;
}
.institution-info-list .title {
		font-size: 12px;
		font-weight: 300;
		line-height: 1.6666666667;
		color: #2f7ad3;
}
.institution-info-list .cont-box {
		margin-top: 4px;
}

.co-location-map {
		overflow: hidden;
		border-radius: 12px;
		height: 280px;
		background-color: #ddd;
}

.medical-team-list .list-row ~ .list-row {
		margin-top: 24px;
}
.medical-team-list .doctor-row {
		flex-wrap: wrap;
		flex: 1 1 auto;
		display: flex;
		margin-left: -20px;
		margin-right: -20px;
}
.medical-team-list .doctor-row .col {
		border: 2px solid #fff;
		width: 50%;
		display: flex;
		flex-direction: column;
		padding-left: 20px;
		padding-right: 20px;
		flex: 0 0 50%;
		max-width: 50%;
}
.medical-team-list .doctor-row .col > a {
		display: flex;
		flex: 1 1 auto;
		height: 100%;
}
.medical-team-list .ctg-info {
		margin-top: 4px;
		display: flex;
		align-items: flex-start;
		font-size: 14px;
		font-weight: 300;
		line-height: 2.1428571429;
}
.medical-team-list .ctg-info span {
		font-size: 12px;
		font-weight: 400;
		line-height: 2;
		line-height: 30px;
		color: #2f7ad3;
		padding-right: 4px;
		flex: 0 0 auto;
}

.doctor-card .inner {
		border-radius: 12px;
		background-color: #fcfcfc;
		border: 1px solid #e9e9e9;
		overflow: hidden;
		padding: 20px;
		display: flex;
		align-items: center;
		flex: 1 0 0;
		align-items: flex-start;
}
.doctor-card .inner .img {
		border-radius: 8px;
		background-color: #fff;
		overflow: hidden;
		width: 61px;
		height: 66px;
		flex: 0 0 61px;
}
.doctor-card .inner .doctor-info {
		padding-left: 20px;
		font-size: 14px;
		font-weight: 300;
		line-height: 2.1428571429;
		overflow: hidden;
		text-overflow: ellipsis;
		white-space: nowrap;
		word-wrap: break-word;
}
.doctor-card .inner .doctor-info .ctg-info {
		display: block;
}
.doctor-card .inner .doctor-info .title {
		display: block;
		font-size: 12px;
		font-weight: 400;
		line-height: 2;
		color: #2f7ad3;
}
.doctor-card .inner .doctor-info .cont {
		margin-top: 4px;
		font-size: 14px;
		font-weight: 300;
		line-height: 2.1428571429;
		overflow: hidden;
		text-overflow: ellipsis;
		white-space: nowrap;
		word-wrap: break-word;
}
.doctor-card.on .inner {
		box-shadow: 0 0 0 2px #2f7ad3;
		position: relative;
}
.doctor-card.on .inner:before {
		content: "";
		position: absolute;
		top: 12px;
		right: 12px;
		display: inline-block;
		width: 18px;
		height: 18px;
		background: url("../images/ico/ico_chk_blue_01.svg") center/18px no-repeat;
}

.doctor-detail-info {
		display: none;
		border-radius: 12px;
		padding: 20px;
		margin-top: 24px;
		border: 1px solid #e9e9e9;
		background-color: #fcfcfc;
}
.doctor-detail-info.on {
		display: flex;
}
.doctor-detail-info .col ~ .col {
		margin-left: 48px;
}
.doctor-detail-info .ctg-info span {
		padding-right: 12px;
}

.medical-equipment-list > li ~ li {
		margin-top: 40px;
}

.medical-equip-card {
		padding: 20px;
		border: 1px solid #e9e9e9;
		border-radius: 12px;
}
.medical-equip-card .top-box {
		display: flex;
		flex: 0 0 0;
}
.medical-equip-card .top-box .img {
		width: 242px;
		height: 156px;
		border-radius: 12px;
		overflow: hidden;
		flex: 0 0 242px;
}
.medical-equip-card .top-box .detail-info {
		padding-left: 20px;
}
.medical-equip-card .top-box .detail-info li {
		display: flex;
		align-items: center;
		min-height: 30px;
}
.medical-equip-card .top-box .detail-info li ~ li {
		margin-top: 12px;
}
.medical-equip-card .top-box .detail-info li > span {
		width: 52px;
		flex: 0 0 52px;
		color: #2f7ad3;
		font-size: 12px;
		font-weight: 300;
		line-height: 1.6666666667;
}
.medical-equip-card .top-box .detail-info li .cont {
		padding-left: 4px;
		color: #494949;
}
.medical-equip-card .btm-box {
		margin-top: 20px;
}
.medical-equip-card + .medical-equip-card {
		margin-top: 40px;
}

.co-table.cont-type01.tbl-exam-apply-criteria table thead th {
		background-color: #ecf1f8;
}
.co-table.cont-type01.tbl-exam-apply-criteria table th,
.co-table.cont-type01.tbl-exam-apply-criteria table td {
		padding: 10px;
}

/* VGT-01-004 */
.chkup-deliver-field .c-input-list-view {
		margin-top: 20px;
}
.chkup-deliver-field .c-input-list-view ~ .c-input-list-basic {
		margin-top: 32px;
}

.resevation-container .contents-area {
		position: relative;
}
.resevation-container .footer {
		min-width: 1500px;
}

.contents-area.reserve-contents-area {
		display: flex;
		flex-direction: column;
		flex: 1 1 auto;
		justify-content: stretch;
		padding: 0;
}
.contents-area.reserve-contents-area .co-cont-section {
		height: 100%;
}
.contents-area.reserve-contents-area .co-location-wrap {
		margin: 40px 60px 0;
}
.contents-area.reserve-contents-area .co-cont-section {
		padding-left: 60px;
		padding-right: 60px;
		padding-bottom: 40px;
}

.reservation-sidebar {
		border-right: 1px solid #e9e9e9;
		flex-shrink: 0;
		background-color: #f8f9fb;
		max-width: 300px;
		min-width: 300px;
		min-height: 100vh;
		justify-content: stretch;
		position: relative;
		max-height: 100%;
		overflow-x: hidden;
		overflow-y: auto;
		/* RI_01_001 */
}
.reservation-sidebar .inner-wrap {
		padding: 40px;
		position: absolute;
		top: 0;
		left: 0;
		right: 0;
}
.reservation-sidebar .inner-wrap .top-title {
		font-size: 16px;
		font-weight: 500;
		line-height: 2;
		color: #253981;
}
.reservation-sidebar.rsv-sidebar-fixed .inner-wrap {
		max-width: 300px;
		min-width: 300px;
		min-height: 430px;
}

.reservation-step-list > li {
		margin-top: 12px;
}
.reservation-step-list > li .rsv-step {
		position: relative;
		width: 220px;
		height: 48px;
		overflow: hidden;
		width: 100%;
		display: block;
		border-radius: 12px;
}
.reservation-step-list > li .rsv-step > a {
		display: block;
}
.reservation-step-list > li .rsv-title {
		border: 1px solid #e9e9e9;
		background-color: #fff;
		border-radius: 8px;
}
.reservation-step-list > li .rsv-title {
		opacity: 1;
}
.reservation-step-list > li .rsv-data {
		background-color: #494949;
		color: #fff;
		opacity: 0;
}
.reservation-step-list > li .rsv-title,
.reservation-step-list > li .rsv-data {
		position: absolute;
		top: 0;
		left: 0;
		bottom: 0;
		right: 0;
		padding: 8px 20px;
		overflow: hidden;
		text-overflow: ellipsis;
		white-space: nowrap;
		word-wrap: break-word;
		border-radius: 12px;
		overflow: hidden;
}
.reservation-step-list > li.is-end .rsv-step {
		background-color: #ecf1f8;
}
.reservation-step-list > li.is-end .rsv-step .rsv-title,
.reservation-step-list > li.is-end .rsv-step .rsv-data {
		transition: opacity 0.3s;
}
.reservation-step-list > li.is-end .rsv-step .rsv-title:hover,
.reservation-step-list > li.is-end .rsv-step .rsv-data:hover {
		transition: opacity 0.3s;
}
.reservation-step-list > li.is-end .rsv-step .rsv-title {
		background-color: #ecf1f8;
		color: #777676;
}
.reservation-step-list > li.is-end .rsv-step .rsv-title:hover {
		opacity: 0;
}
.reservation-step-list > li.is-end .rsv-step .rsv-data:hover {
		opacity: 1;
}
.reservation-step-list > li.is-now .rsv-title {
		color: #fff;
		background-color: #253981;
		color: #fff;
}

.resevation-btmbar-wrap {
		bottom: -40px;
		background-color: #fff;
		border-top: 1px solid rgba(28, 28, 27, 0.1);
}
.resevation-btmbar-wrap.fixed {
		border-left: 1px solid #e9e9e9;
		position: fixed;
		left: 0;
		bottom: 0;
		right: 0;
		z-index: 1;
		transition: bottom 0.3s ease;
}

.resevation-btm-bar {
		position: relative;
}
.resevation-btm-bar:before {
		content: "";
		position: absolute;
		top: -41px;
		left: 0;
		right: 0;
		background: linear-gradient(to top, rgba(25, 25, 25, 0.025) 0%, rgba(217, 217, 217, 0) 38.05%);
		height: 41px;
}
.resevation-btm-bar .buttons {
		justify-content: space-between;
		flex-grow: 1;
		flex-shrink: 0;
		flex-basis: auto;
}
.resevation-btm-bar .buttons .left,
.resevation-btm-bar .buttons .right {
		font-size: 0;
}
.resevation-btm-bar .buttons .co-btn + .co-btn {
		margin-left: 18px;
}
.resevation-btm-bar.type01 {
		padding: 20px 60px;
		min-width: 1200px;
}
.resevation-btm-bar.type02 {
		padding: 20px 60px;
}
.resevation-btm-bar.type02 .top-box {
		display: flex;
		justify-content: space-between;
		align-items: center;
}
.resevation-btm-bar.type02 .compare-product {
		display: flex;
		align-items: center;
}
.resevation-btm-bar.type02 .compare-product > p {
		margin-left: 8px;
		font-size: 16px;
		font-weight: 500;
		line-height: 2;
}
.resevation-btm-bar.type02 .compare-product .cnt {
		color: #777676;
		line-height: 32px;
		padding-left: 4px;
}
.resevation-btm-bar.type02 .buttons {
		margin-top: 20px;
}
.resevation-btm-bar .btn-reset-product > span {
		font-size: 12px;
		font-weight: 300;
		line-height: 1.6666666667;
		color: #777676;
		position: relative;
		padding-left: 28px;
}
.resevation-btm-bar .btn-reset-product > span:before {
		content: "";
		top: 50%;
		left: 0;
		transform: translateY(-50%);
		position: absolute;
		background: url(../images/ico/ico_sprites_01.svg) -912px 0/auto 218px no-repeat;
		width: 28px;
		height: 28px;
		display: inline-block;
		overflow: hidden;
		text-indent: 100%;
		white-space: nowrap;
		display: inline-block;
}
.resevation-btm-bar .btn-bar-toggle {
		position: absolute;
		right: 90px;
		top: -36px;
		border: 1px solid #d2d2d1;
		border-radius: 12px 12px 0px 0px;
		background-color: #fff;
		display: inline-block;
		width: 60px;
		height: 36px;
}
.resevation-btm-bar .btn-bar-toggle > span {
		background: url("../images/ico/ico_sprites_01.svg") -76px 0/auto 218px no-repeat;
		width: 28px;
		height: 28px;
		transform: rotate(-180deg);
}
.resevation-btm-bar .btn-bar-toggle.is-open > span {
		transform: rotate(0);
}
.resevation-btm-bar .btn-bar-toggle.is-open ~ .btm-compare-list-field {
		display: block;
}
.resevation-btm-bar .btn-reservation-prev span:before,
.resevation-btm-bar .btn-reservation-next span:before {
		position: absolute;
		top: -4px;
		content: "";
		background-image: url("../images/ico/ico_btm_bar_sprite01.svg");
		background-size: auto 28px;
		background-repeat: no-repeat;
		width: 28px;
		height: 28px;
		display: inline-block;
}
.resevation-btm-bar .btn-reservation-prev:hover,
.resevation-btm-bar .btn-reservation-next:hover {
		opacity: 0.4;
}
.resevation-btm-bar .btn-reservation-prev {
		border: 1px solid #253981;
		background-color: #fff;
		border-color: rgba(37, 57, 129, 0.2);
		color: rgba(37, 57, 129, 0.2);
		padding: 8px 20px 8px 8px;
		cursor: default;
}
.resevation-btm-bar .btn-reservation-prev span {
		padding-left: 28px;
}
.resevation-btm-bar .btn-reservation-prev span:before {
		background-position: 0 0;
		left: 0;
}
.resevation-btm-bar .btn-reservation-prev:hover {
		opacity: 1;
}
.resevation-btm-bar .btn-reservation-prev.is-active {
		cursor: pointer;
		border-color: #253981;
		color: #253981;
}
.resevation-btm-bar .btn-reservation-prev.is-active span:before {
		background-position: -38px 0;
}
.resevation-btm-bar .btn-reservation-prev.is-active:hover span:before {
		animation: rsvPrevBtnAni 0.9s 3 linear;
}
.resevation-btm-bar .btn-reservation-next {
		padding: 8px 8px 8px 20px;
}
.resevation-btm-bar .btn-reservation-next span {
		padding-right: 28px;
}
.resevation-btm-bar .btn-reservation-next span:before {
		background-position: -76px 0;
		right: 0;
}
.resevation-btm-bar .btn-reservation-next:hover {
		opacity: 1;
}
.resevation-btm-bar .btn-reservation-next:hover span:before {
		animation: rsvNextBtnAni 0.9s 3 linear;
}

@keyframes rsvNextBtnAni {
		from {
				right: 0;
		}
		50% {
				right: -3px;
		}
		to {
				right: 0;
		}
}
@keyframes rsvPrevBtnAni {
		from {
				left: 0;
		}
		50% {
				left: -3px;
		}
		to {
				left: 0;
		}
}
.targets-list {
		display: flex;
		flex: 1 1 auto;
		flex-wrap: wrap;
		margin-left: -20px;
		margin-right: -20px;
}
.targets-list > li {
		padding: 20px;
		width: 25%;
		flex: 0 0 25%;
		max-width: 25%;
}
.targets-list .btn-chkup-type {
		font-size: 12px;
		font-weight: 400;
		line-height: 2;
		color: #a4a4a4;
		margin-left: 12px;
		margin-right: 4px;
}
.targets-list .btn-chkup-type > span {
		text-decoration: underline;
		padding-right: 20px;
}
.targets-list .btn-chkup-type > span:after {
		position: absolute;
		top: 4px;
		right: 0;
		content: "";
		background: url("../images/ico/ico_sprites_01.svg") -8px -84px/auto 218px no-repeat;
		display: inline-block;
		width: 12px;
		height: 12px;
		overflow: hidden;
		text-indent: 100%;
		white-space: nowrap;
}
.targets-list .client-card.person.on {
		box-shadow: 0 0 0 1px #2f7ad3;
		border-color: #2f7ad3;
		position: relative;
}
.targets-list .client-card.person.on .relationship {
		align-items: center;
		display: flex;
}
.targets-list .client-card.person.on .relationship:after {
		content: "";
		margin-left: 7px;
		margin-top: 1px;
		width: 14px;
		height: 14px;
		background: url("../images/ico/ico_sprites_check.svg") -116px -7px/auto 28px no-repeat;
}
.targets-list .card-cont {
		width: 100%;
		display: flex;
		align-items: center;
		justify-content: center;
}
.targets-list .card-cont {
		text-align: center;
}
.targets-list .title-add {
		font-size: 20px;
		font-weight: 500;
		line-height: 1.8;
		color: #253981;
		vertical-align: top;
}
.targets-list .title-add i {
		display: inline-block;
		vertical-align: top;
		margin-top: 4px;
}
.targets-list .ref-txt {
		color: #a4a4a4;
		font-size: 12px;
		font-weight: 400;
		line-height: 2;
}
.targets-list .client-card {
		display: flex;
		justify-content: center;
		align-items: center;
		margin-top: 0;
		min-height: 164px;
		max-height: 164px;
		height: 164px;
		width: 100%;
		padding-left: 20px;
		padding-right: 20px;
}
.targets-list .client-card .inner {
		width: 100%;
}
.targets-list .client-card .btn-del-target {
		overflow: hidden;
		text-indent: 100%;
		white-space: nowrap;
		display: inline-block;
		position: absolute;
		top: 32px;
		right: 20px;
		width: 24px;
		height: 24px;
		background: url("../images/ico/ico_sprite_del_02.svg") 0 0/24px auto no-repeat;
}
.targets-list .client-card .btn-del-target:hover {
		background-position: 0 bottom;
}
.targets-list .none-data img {
		height: 68px;
		width: auto;
}

.btm-compare-list-field {
		display: none;
		padding-top: 20px;
}

.btm-compare-list {
		margin-left: -10px;
		margin-right: -10px;
		display: flex;
		flex: 1 1 auto;
		flex-wrap: nowrap;
}
.btm-compare-list > li {
		width: 25%;
		padding-left: 10px;
		padding-right: 10px;
}

.compare-card,
.myhistory-card {
		border: 1px solid #e9e9e9;
		border-radius: 12px;
}

.compare-card {
		padding: 15px 37px 15px 27px;
		position: relative;
		background-color: #fff;
		height: 100%;
}
.compare-card.is-has {
		background-color: #fcfcfc;
}
.compare-card .name {
		font-size: 16px;
		font-weight: 500;
		line-height: 2;
		color: #494949;
		overflow: hidden;
		text-overflow: ellipsis;
		white-space: nowrap;
		word-wrap: break-word;
		padding-right: 8px;
}
.compare-card .price-info {
		font-size: 12px;
		font-weight: 300;
		line-height: 2;
		color: #a4a4a4;
		display: flex;
		align-items: center;
		flex: 0;
		line-height: 20px;
}
.compare-card .price-info > em {
		padding-left: 4px;
		padding-right: 4px;
		font-size: 14px;
		font-weight: 300;
		line-height: 2.1428571429;
		color: #2f7ad3;
}
.compare-card .btn-del-compare {
		position: absolute;
		top: 8px;
		right: 10px;
}

.myhistory-card {
		padding: 11px 27px;
}

.rsv-institution-list-wrap {
		padding-top: 20px;
}
.rsv-institution-list-wrap .list-field {
		margin-top: 20px;
}
.rsv-institution-list-wrap .rsv-institution-list .card-row {
		margin-left: -20px;
		margin-right: -20px;
		display: flex;
		flex-wrap: wrap;
}
.rsv-institution-list-wrap .rsv-institution-list + .co-tbl-paging {
		margin-top: 20px;
}
.rsv-institution-list-wrap .rsv-institution-card {
		width: 25%;
		position: relative;
		border: 2px solid #fff;
}
.rsv-institution-list-wrap .rsv-institution-card .thumbnail {
		padding-bottom: 59.259%;
		position: relative;
		overflow: hidden;
		border-radius: 12px;
}
.rsv-institution-list-wrap .rsv-institution-card .thumbnail .img {
		position: absolute;
		top: 0;
		left: 0;
		width: 100%;
		bottom: 0;
		height: inherit;
		background-position: center;
		background-size: cover;
		background-repeat: no-repeat;
}
.rsv-institution-list-wrap .rsv-institution-card .thumbnail img {
		position: absolute;
		top: 0;
		left: 0;
		width: 100%;
		height: 100%;
}
.rsv-institution-list-wrap .rsv-institution-card > a {
		padding: 20px;
		display: block;
		transition: background-color 0.3s;
}
.rsv-institution-list-wrap .rsv-institution-card:hover {
		background-color: #f8f9fb;
		border-color: #f8f9fb;
		border-radius: 12px;
}
.rsv-institution-list-wrap .rsv-institution-card.on {
		border: 2px solid #2f7ad3;
		background-color: #ecf1f8;
		border-radius: 12px;
}
.rsv-institution-list-wrap .rsv-institution-card.on:hover {
		border-color: #2f7ad3;
		background-color: #ecf1f8;
}
.rsv-institution-list-wrap .rsv-institution-card .cont {
		padding-top: 20px;
		font-size: 0;
}
.rsv-institution-list-wrap .rsv-institution-card .location {
		font-size: 12px;
		font-weight: 400;
		line-height: 2;
		position: relative;
		color: #2f7ad3;
		padding-left: 17px;
}
.rsv-institution-list-wrap .rsv-institution-card .location:before {
		position: absolute;
		left: 0;
		top: 50%;
		transform: translateY(-50%);
		display: inline-block;
		width: 10px;
		height: 12px;
		background: url("../images/ico/ico_sprites_01.svg") -807px -8px/auto 218px no-repeat;
		content: "";
}
.rsv-institution-list-wrap .rsv-institution-card .title {
		font-size: 16px;
		font-weight: 500;
		line-height: 2;
		overflow: hidden;
		text-overflow: ellipsis;
		white-space: nowrap;
		word-wrap: break-word;
}
.rsv-institution-list-wrap .rsv-institution-card.none-data {
		border-radius: 12px;
		background-color: #fcfcfc;
		position: relative;
}
.rsv-institution-list-wrap .rsv-institution-card.none-data > div {
		position: absolute;
		top: 50%;
		left: 0;
		width: 100%;
		transform: translateY(-50%);
}
.rsv-institution-list-wrap .rsv-institution-card.none-data .img {
		overflow: hidden;
		opacity: 0.3;
		width: 100px;
		height: auto;
		margin: 0 auto;
}
.rsv-institution-list-wrap .rsv-institution-card.none-data .img img {
		width: 100px;
		height: auto;
}
.rsv-institution-list-wrap .rsv-institution-card.none-data .co-none-txt {
		margin-top: 12px;
}

.co-table-field {
		overflow-y: hidden;
		overflow-x: auto;
}

.tbl-detail-chkctg-compare {
		width: 100%;
		font-size: 14px;
		font-weight: 300;
		line-height: 1.7142857143;
}
.tbl-detail-chkctg-compare::-webkit-scrollbar {
		opacity: 0;
		height: 8px;
		width: 8px;
		background-color: #fff;
}
.tbl-detail-chkctg-compare::-webkit-scrollbar:hover {
		opacity: 1;
}
.tbl-detail-chkctg-compare ::-webkit-scrollbar-track {
		background-color: #fff;
}
.tbl-detail-chkctg-compare::-webkit-scrollbar-thumb {
		opacity: 0;
		background: #e9e9e9;
		border-radius: 50px;
		background-clip: padding-box;
}
.tbl-detail-chkctg-compare::-webkit-scrollbar-corner {
		opacity: 0;
		background-color: transparent;
}
.tbl-detail-chkctg-compare table {
		border-radius: 12px;
}
.tbl-detail-chkctg-compare table thead {
		border-bottom: 1px solid #e9e9e9;
}
.tbl-detail-chkctg-compare table thead th:last-child {
		border-right: 1px solid #e9e9e9;
}
.tbl-detail-chkctg-compare table thead .title-sub {
		font-weight: 300;
		overflow: hidden;
		text-overflow: ellipsis;
		white-space: nowrap;
		word-wrap: break-word;
}
.tbl-detail-chkctg-compare table tbody tr:last-child td:last-child {
		border-bottom-right-radius: 12px;
}
.tbl-detail-chkctg-compare table tbody .tbl-in-cont {
		padding: 4px;
}
.tbl-detail-chkctg-compare table th,
.tbl-detail-chkctg-compare table td {
		padding: 8px;
}

.peronal-add-price {
		overflow: hidden;
		text-overflow: ellipsis;
		white-space: nowrap;
		word-wrap: break-word;
		font-weight: 300;
}
.peronal-add-price .price {
		padding-left: 8px;
		display: inline;
		color: rgba(164, 164, 164, 0.4);
}
.peronal-add-price .price em {
		padding-left: 8px;
		color: rgba(28, 28, 27, 0.8);
}

.co-table.tbl-chkup-detail-compare {
		border-right: 0;
}
.co-table.tbl-chkup-detail-compare thead th:last-child {
		border-right: 1px solid #e9e9e9;
}

.popup-del-exam-target em {
		color: #253981;
		font-weight: 500;
}

.rsv-exam-result-wrap .co-cont-section {
		padding-bottom: 60px;
}

.mychkup-history-field {
		margin-top: 12px;
		overflow: hidden;
		border-radius: 12px;
		position: relative;
		padding-left: 48px;
		padding-right: 48px;
}
.mychkup-history-field .mychkup-history-swiper {
		overflow: hidden;
		width: auto;
}
.mychkup-history-field .swiper {
		width: 100%;
		height: 100%;
}
.mychkup-history-field .swiper-slide {
		width: 296px;
		display: flex;
		align-items: center;
		padding: 1px;
}
.mychkup-history-field .swiper-slide + .swiper-slide {
		margin-left: 20px;
}
.mychkup-history-field .slide-card {
		width: 100%;
		position: relative;
		border-radius: 12px;
		overflow: hidden;
		min-height: 234px;
}
.mychkup-history-field .co-slide-btn-prev,
.mychkup-history-field .co-slide-btn-next {
		cursor: default;
		display: block;
		position: absolute;
		top: 50%;
		transform: translateY(-50%);
}
.mychkup-history-field .co-slide-btn-prev {
		left: 0;
}
.mychkup-history-field .co-slide-btn-prev:hover {
		background-position: 0 0;
}
.mychkup-history-field .co-slide-btn-next {
		right: 0;
		background-position: 0 -38px;
}
.mychkup-history-field .mychkup-history-swiper {
		overflow: hidden;
}
.mychkup-history-field:before {
		content: none;
}
.mychkup-history-field.is-active:before {
		position: absolute;
		top: 1px;
		bottom: 1px;
		right: 48px;
		content: "";
		background: linear-gradient(90deg, rgba(25, 25, 25, 0.025) 0%, rgba(217, 217, 217, 0) 38.05%);
		transform: rotate(-180deg);
		width: 96px;
}
.mychkup-history-field.is-active .co-slide-btn-prev,
.mychkup-history-field.is-active .co-slide-btn-next {
		cursor: pointer;
}
.mychkup-history-field.is-active .co-slide-btn-prev:hover {
		background-position: 0 -76px;
}
.mychkup-history-field.is-active .co-slide-btn-next:hover {
		background-position: 0 -114px;
}
.mychkup-history-field .co-slide-btn-prev.swiper-button-disabled {
		background-position-y: -152px;
		opacity: 1 !important;
}
.mychkup-history-field .co-slide-btn-prev.swiper-button-disabled:hover {
		background-position-y: -152px;
}
.mychkup-history-field .co-slide-btn-next.swiper-button-disabled {
		background-position-y: -190px;
		opacity: 1 !important;
}
.mychkup-history-field .co-slide-btn-next.swiper-button-disabled:hover {
		background-position-y: -190px;
}

.mychkup-history-card {
		border: 1px solid #d2d2d1;
		border-radius: 12px;
		padding: 30px 20px;
		width: 100%;
}
.mychkup-history-card .inner {
		width: 100%;
}
.mychkup-history-card:not(.is-not-apply).on {
		box-shadow: 0 0 0 1px #2f7ad3;
		border-color: #2f7ad3;
}
.mychkup-history-card:not(.is-not-apply).on .date {
		display: flex;
		align-items: center;
}
.mychkup-history-card:not(.is-not-apply).on .date:after {
		content: "";
		display: inline-flex;
		margin-left: 7px;
		width: 14px;
		height: 14px;
		background: url("../images/ico/ico_sprites_checkbox01.svg") -116px -7px/auto 28px no-repeat;
}
.mychkup-history-card .top-box {
		display: flex;
		align-items: center;
		justify-content: space-between;
}
.mychkup-history-card .date {
		font-size: 12px;
		font-weight: 400;
		line-height: 2;
		color: #2f7ad3;
}
.mychkup-history-card .name {
		font-size: 20px;
		font-weight: 500;
		line-height: 1.8;
		color: #1c1c1b;
}
.mychkup-history-card .center {
		margin-top: 20px;
}
.mychkup-history-card .name,
.mychkup-history-card .value {
		overflow: hidden;
		text-overflow: ellipsis;
		white-space: nowrap;
		word-wrap: break-word;
}

.result-article ~ .result-article {
		margin-top: 60px;
}
.result-article .co-title-field {
		display: flex;
		align-items: center;
		justify-content: space-between;
}
.result-article .co-title-field .title {
		font-size: 16px;
		font-weight: 500;
		line-height: 2;
}
.result-article .co-title-field ~ .jo-progress-field {
		margin-top: 12px;
}
.result-article .list-field,
.result-article .cont-field {
		margin-top: 12px;
		padding: 20px;
		border: 1px solid #e9e9e9;
		border-radius: 12px;
		color: #494949;
		background-color: #fcfcfc;
}
.result-article .cont-field .cont > em {
		font-weight: 700;
}

.chkup-result-cont.type01 > .row {
		margin-left: -10px;
		margin-right: -10px;
}
.chkup-result-cont.type01 > .row > [class|=col] {
		padding: 10px;
}
.chkup-result-cont.type01 .result-card01 {
		border: 1px solid #e9e9e9;
		border-radius: 12px;
		padding: 12px 20px;
		background-color: #fff;
}
.chkup-result-cont.type01 .result-card01 .title {
		font-size: 14px;
		font-weight: 300;
		line-height: 2.1428571429;
}
.chkup-result-cont.type01 .result-card01 .col-title,
.chkup-result-cont.type01 .result-card01 .col-compare,
.chkup-result-cont.type01 .result-card01 .col-btn {
		display: flex;
		align-items: center;
}
.chkup-result-cont.type01 .result-card01 [class|=col] {
		padding-left: 0;
		padding-right: 0;
}
.chkup-result-cont.type01 .result-card01 .compare-info {
		justify-content: center;
		font-size: 0;
		display: flex;
		align-items: center;
}
.chkup-result-cont.type01 .result-card01 .compare-info .r-value {
		font-size: 16px;
		font-weight: 400;
		line-height: 2;
}
.chkup-result-cont.type01 .result-card01 .compare-info .unit {
		display: inline-block;
		padding-left: 4px;
		font-size: 12px;
		font-weight: 300;
		line-height: 2;
		color: #a4a4a4;
}
.chkup-result-cont.type01 .result-card01 .compare-info .divide {
		padding: 5px 12px 0;
		font-size: 12px;
		font-weight: 300;
		line-height: 1.6666666667;
		color: #777676;
}
.chkup-result-cont.type01 .result-card01 .col-compare {
		justify-content: center;
}
.chkup-result-cont.type01 .result-card01 .col-btn {
		justify-content: flex-end;
}
.chkup-result-cont .btn-chkup-info-detail {
		margin-top: 2px;
}

.co-btn.btn-result-compare {
		padding: 2px 16px;
		min-width: 84px;
		min-height: 36px;
}
.co-btn.btn-result-compare > span {
		font-size: 14px;
		font-weight: 400;
		line-height: 1.7142857143;
}

.tbl-chkresult-compare {
		margin-top: 20px;
}

.co-table.tbl-chkresult-compare table thead th,
.co-table.tbl-chkresult-compare table thead td {
		padding: 8px;
}
.co-table.tbl-chkresult-compare table tbody th,
.co-table.tbl-chkresult-compare table tbody td {
		padding: 8px 12px;
}
.co-table.tbl-chkresult-compare table th,
.co-table.tbl-chkresult-compare table td {
		color: #494949;
}

.exam-result-inquiry .input-list-field > .row {
		margin-left: -16px;
		margin-right: -16px;
}
.exam-result-inquiry .input-list-field > .row > [class|=col] {
		padding-left: 16px;
		padding-right: 16px;
}

/* RV-01-013 */
.co-subcont-field ~ .examination-date-article {
		margin-top: 60px;
}

.graph-chkupresult-compare-wrap .co-detail-title-field {
		display: flex;
		align-items: center;
		margin-bottom: 20px;
}
.graph-chkupresult-compare-wrap .co-detail-title-field .title {
		font-size: 16px;
		font-weight: 400;
		line-height: 2;
}
.graph-chkupresult-compare-wrap .co-ref-txt {
		margin-top: 20px;
}

.graph-chkupresult-compare-tab-wrap .co-tab-list > li {
		min-width: 138px;
}
.graph-chkupresult-compare-tab-wrap .chart-field {
		display: block;
		margin-top: 20px;
		height: 500px;
}
.graph-chkupresult-compare-tab-wrap .compare-chart {
		height: 100% !important;
		min-width: 100%;
		width: 100%;
}

/* RV-01-016  */
.final-confirm-wrap .left-wrap .co-sub-title-01,
.final-confirm-wrap .right-wrap .co-sub-title-01 {
		color: #494949;
}
.final-confirm-wrap .right-wrap {
		width: 428px;
		max-width: 428px;
}
.final-confirm-wrap .right-wrap .c-inner {
		padding-top: 32px;
}
.final-confirm-wrap > .row {
		margin-left: -20px;
		margin-right: -20px;
}
.final-confirm-wrap .info-txt {
		margin-bottom: 40px;
}

.c-input-view.type01 {
		padding: 32px;
}

.final-confirm-article .title-field {
		display: flex;
		align-items: center;
		justify-content: flex-end;
		justify-content: space-between;
}
.final-confirm-article .title-field .price-subtitle {
		display: flex;
		justify-content: space-between;
		align-items: center;
		padding-left: 40px;
		width: 428px;
}
.final-confirm-article .title-field .price-subtitle .title {
		font-weight: 500;
		font-size: 14px;
		font-weight: 500;
		line-height: 2.1428571429;
}
.final-confirm-article .title {
		font-size: 16px;
		font-weight: 500;
		line-height: 2;
		color: #1c1c1b;
}
.final-confirm-article > .row {
		margin-left: -20px;
		margin-right: -20px;
		padding-bottom: 40px;
}
.final-confirm-article > .row > .column {
		padding-left: 20px;
		padding-right: 20px;
}
.final-confirm-article > .row > .column.left {
		flex: 1 1 auto;
		max-width: calc(100% - 428px);
}
.final-confirm-article > .row > .column.right {
		width: 428px;
}
.final-confirm-article .final-confirm-row .input-type.view01 > label {
		min-width: 82px;
}
.final-confirm-article .final-confirm-row .in-input-row ~ .in-input-row {
		margin-top: 12px;
}
.final-confirm-article .final-confirm-row .in-input-group ~ .in-input-group {
		margin-top: 50px;
}
.final-confirm-article ~ .final-confirm-article {
		padding-top: 40px;
		border-top: 1px solid rgba(28, 28, 27, 0.1);
}
.final-confirm-article .title-field {
		margin-bottom: 16px;
}
.final-confirm-article.type02 .right {
		margin-top: 42px;
}
.final-confirm-article .chkup-type-row {
		display: flex;
		flex: 1 1 auto;
}
.final-confirm-article .chkup-type-row .label-col {
		width: 86px;
		flex: 0 0 80px;
}
.final-confirm-article .chkup-type-row .chkup-exp-column {
		flex: 1;
}
.final-confirm-article .chkup-type-row ~ .chkup-type-row {
		margin-top: 58px;
}
.final-confirm-article .c-input-unit input[type=text] {
		padding-left: 20px;
		padding-right: 20px;
}
.final-confirm-article .c-input-unit .unit {
		right: 20px;
}
.final-confirm-article .c-input-unit-field .c-input-unit input[type=text] {
		padding-left: 0;
		padding-right: 0;
}
.final-confirm-article .c-input-unit-field .c-input-unit .unit {
		right: 0;
}
.final-confirm-article .c-input-unit-field.type02 {
		background-color: #f8f9fb;
}
.final-confirm-article .c-input-unit-field.type02 input[type=text] {
		background-color: #f8f9fb;
}
.final-confirm-article .c-input-unit-field .c-input-unit-field {
		margin-top: 2px;
}
.final-confirm-article.choice-chkup > .final-confirm-row:not(:last-child) {
		padding-bottom: 12px;
}

.confirm-chk-card-list > li ~ li {
		margin-top: 12px;
}
.confirm-chk-card-list .exam-ctg .btn-chkup-info-detail {
		line-height: 1;
}

.confirm-chk-card {
		display: flex;
		border: 1px solid #e9e9e9;
		border-radius: 12px;
		background-color: #fff;
		padding: 12px 20px;
}
.confirm-chk-card .title {
		color: #a4a4a4;
		padding-right: 20px;
		flex: 0 0 auto;
}
.confirm-chk-card .col.list {
		flex: 1 1 auto;
}
.confirm-chk-card .in-row {
		display: flex;
		flex-wrap: wrap;
		flex: 1 1 auto;
		margin-left: -10px;
		margin-right: -10px;
}
.confirm-chk-card .in-row > [class|=col] {
		padding-left: 10px;
		padding-right: 10px;
}

.surcharges-before-chklist > li ~ li {
		margin-top: 12px;
}

/* RV-02-001 */
.status-badge {
		font-size: 12px;
		font-weight: 400;
		line-height: 2;
		padding: 0 12px;
		display: inline-block;
		border-radius: 8px;
}
.status-badge.is-green {
		background-color: #a4f897;
		color: #226418;
}
.status-badge.is-blue {
		background-color: #9aeafc;
		color: #426e78;
}
.status-badge.is-gray {
		background-color: #f3f3f3;
		color: #a4a4a4;
}
.status-badge.is-yellow {
		background-color: #eaec8b;
		color: #6c691d;
}
.status-badge.is-orange {
		background-color: #ffd8bb;
		color: #643b1d;
}

.status-btn {
		padding: 6px 16px;
		display: inline-block;
		border-radius: 12px;
		border-width: 1px;
		border-style: solid;
		font-size: 12px;
		font-weight: 300;
		line-height: 1.6666666667;
}
.status-btn.is-orange {
		border-color: #ff6859;
		color: #ff6859;
}
.status-btn.is-navy {
		border-color: #253981;
		color: #253981;
}

.tbl-apply-btns {
		display: flex;
		justify-content: center;
}
.tbl-apply-btns a,
.tbl-apply-btns button {
		font-size: 0;
		display: inline-block;
}
.tbl-apply-btns a ~ button {
		margin-left: 8px;
}

.rsv-details-wrap .rsv-details-info {
		display: flex;
		justify-content: space-between;
		align-items: center;
		margin-bottom: 16px;
}
@media screen and (max-width: 1600px) {
		.rsv-details-wrap .rsv-details-info {
				display: block;
		}
}
.rsv-details-wrap .rsv-details-info .select-period-btns .chk-btn-type {
		height: 0;
}
.rsv-details-wrap .cont {
		color: #a4a4a4;
}
.rsv-details-wrap .date {
		color: #494949;
}
.rsv-details-wrap .cnt {
		padding-left: 5px;
		font-size: 16px;
		font-weight: 400;
		line-height: 2;
		color: #2f7ad3;
}
.rsv-details-wrap .right {
		display: block;
		align-items: center;
		align-items: flex-start;
}
.rsv-details-wrap .right:after {
		display: block;
		content: "";
		clear: both;
}
.rsv-details-wrap .right .co-date-period,
.rsv-details-wrap .right .select-period-btns {
		float: left;
}

.select-period-btns {
		display: flex;
		align-items: center;
}
.select-period-btns .btn-period {
		padding: 8px 20px;
		border-radius: 12px;
		border: 1px solid #d2d2d1;
		background-color: #fff;
		margin-left: 16px;
}
.select-period-btns .btn-period span {
		font-size: 14px;
		font-weight: 300;
		line-height: 2.1428571429;
		color: #a4a4a4;
}
.select-period-btns .btn-period.is-active {
		background-color: #253981;
		color: #fff;
}

.top-tbl-info .chk-btn-type {
		position: relative;
		margin-left: 16px;
		font-size: 0;
}
.top-tbl-info .chk-btn-type input[type=radio],
.top-tbl-info .chk-btn-type input[type=checkbox] {
		position: absolute;
		top: 0;
		left: 0;
		right: 0;
		bottom: 0;
		opacity: 0;
		cursor: pointer;
}
.top-tbl-info .chk-btn-type input[type=radio] + .chk-label,
.top-tbl-info .chk-btn-type input[type=checkbox] + .chk-label {
		min-height: 44px;
		cursor: pointer;
		display: table;
		table-layout: fixed;
		line-height: 1.5;
		padding: 8px 20px;
		border-radius: 8px;
		border: 1px solid #d2d2d1;
		background-color: #fff;
}
.top-tbl-info .chk-btn-type input[type=radio]:checked + .chk-label,
.top-tbl-info .chk-btn-type input[type=checkbox]:checked + .chk-label {
		border-color: #253981;
		background-color: #253981;
}
.top-tbl-info .chk-btn-type input[type=radio]:checked + .chk-label > span,
.top-tbl-info .chk-btn-type input[type=checkbox]:checked + .chk-label > span {
		color: #fff;
}

.healthexam-item-field .title-field {
		display: flex;
		justify-content: space-between;
		margin-bottom: 12px;
}
.healthexam-item-field .title-field .title {
		font-size: 12px;
		font-weight: 400;
		line-height: 2;
}
.healthexam-item-field .title-field + .healthexam-item-card {
		margin-top: 8px;
}
.healthexam-item-field .title-field .exam-chkbox .chk-label span {
		font-size: 12px;
		font-weight: 400;
		line-height: 1;
		color: rgba(28, 28, 27, 0.8);
}
.healthexam-item-field .top-ref-txt {
		font-size: 12px;
		font-weight: 300;
		line-height: 1.6666666667;
		color: #2f7ad3;
}
.healthexam-item-field + .healthexam-item-card {
		margin-top: 8px;
}
.healthexam-item-field ~ .healthexam-item-field {
		margin-top: 60px;
}
.healthexam-item-field .item-ref-txt {
		margin-top: 8px;
		color: #777676;
}
.healthexam-item-field .item-ref-txt > p ~ p {
		margin-top: 8px;
}

.healthexam-item-list > li ~ li {
		margin-top: 8px;
}

/* RV-01-016-1 */
.total-surcharges-wrap .total-surcharges-list > li {
		margin-bottom: 12px;
}
.total-surcharges-wrap .price-row {
		display: flex;
		justify-content: space-between;
		align-items: center;
}
.total-surcharges-wrap .price-row .name {
		font-size: 14px;
		font-weight: 300;
		line-height: 2.1428571429;
}
.total-surcharges-wrap .price-row .price {
		display: flex;
		align-items: center;
		font-size: 14px;
		font-weight: 300;
		line-height: 2.1428571429;
		color: #a4a4a4;
}
.total-surcharges-wrap .price-row .price em {
		color: #494949;
		padding-right: 4px;
		font-size: 16px;
		font-weight: 500;
		line-height: 2;
}
.total-surcharges-wrap .btm-total-price {
		padding-top: 12px;
		border-top: 2px solid #a4a4a4;
}
.total-surcharges-wrap .btm-total-price .name {
		font-size: 14px;
		font-weight: 500;
		line-height: 2.1428571429;
}
.total-surcharges-wrap .btm-total-price .price {
		font-size: 16px;
		font-weight: 500;
		line-height: 2;
}
.total-surcharges-wrap .btm-total-price .price > em {
		color: #2f7ad3;
		font-size: 16px;
		font-weight: 500;
		line-height: 2;
}

.healthexam-item-card {
		font-size: 14px;
		font-weight: 300;
		line-height: 2.1428571429;
		padding: 12px 24px;
		border: 1px solid #e9e9e9;
		border-radius: 12px;
}
.healthexam-item-card .in-row {
		display: flex;
		flex-wrap: wrap;
		flex: 1 1 auto;
}
.healthexam-item-card .in-row.type01 {
		justify-content: space-between;
}
.healthexam-item-card .in-row.type03 {
		justify-content: space-between;
		align-items: center;
}
.healthexam-item-card .in-row.type03 + .type04 {
		margin-top: 10px;
}
.healthexam-item-card .in-row.type03 .top-ref-txt {
		text-align: right;
}
.healthexam-item-card .in-row.type04 {
		padding-left: 22px;
}
.healthexam-item-card .in-row .col {
		padding: 0;
}
.healthexam-item-card .category {
		display: flex;
		align-items: center;
		font-size: 0;
}
.healthexam-item-card .category .chk-label > span {
		padding-left: 8px;
		font-size: 16px;
		font-weight: 500;
		line-height: 2;
}
.healthexam-item-card .c-chkbox.type02 i {
		margin-top: 8px;
		margin-right: 12px;
}
.healthexam-item-card .chkup-info-detail {
		margin-top: 2px;
		margin-left: 4px;
		font-size: 0;
}
.healthexam-item-card .date-box,
.healthexam-item-card .date-confirm {
		display: flex;
		align-items: center;
		justify-content: flex-end;
}
.healthexam-item-card .date-confirm {
		color: #a4a4a4;
}
.healthexam-item-card .date-confirm .date {
		font-size: 16px;
		font-weight: 500;
		line-height: 2;
		color: #777676;
		padding-left: 12px;
		padding-right: 4px;
}
.healthexam-item-card .date-unable-txt {
		font-size: 16px;
		font-weight: 500;
		line-height: 2;
		color: #253981;
}
.healthexam-item-card.on {
		background-color: #ecf1f8;
}
.healthexam-item-card .healthexam-inner-list {
		margin-top: 10px;
}
.healthexam-item-card .healthexam-inner-list .chk-label > span {
		font-size: 14px;
		font-weight: 300;
		line-height: 2.1428571429;
		font-weight: 300;
		color: #494949;
}
.healthexam-item-card .healthexam-inner-list > li ~ li {
		margin-top: 4px;
}

.chkup-ctg-accordion .info-title-field {
		display: flex;
		align-items: center;
}
.chkup-ctg-accordion .info-title-field .sub-txt {
		padding-left: 16px;
		font-size: 12px;
		font-weight: 300;
		line-height: 2;
		color: #a4a4a4;
}
.chkup-ctg-accordion .info-title-field .sub-txt .num {
		font-size: 14px;
		font-weight: 400;
		line-height: 2.1428571429;
		color: #2f7ad3;
		margin-right: 4px;
}
.chkup-ctg-accordion .info-title-field .select-list {
		padding-left: 16px;
}
.chkup-ctg-accordion .info-title-field .select-list ul {
		display: flex;
		align-items: center;
}
.chkup-ctg-accordion .info-title-field .select-list ul > li {
		font-size: 14px;
		font-weight: 300;
		line-height: 2.1428571429;
		color: #777676;
}
.chkup-ctg-accordion .info-title-field .select-list ul > li ~ li {
		position: relative;
		padding-left: 22px;
}
.chkup-ctg-accordion .info-title-field .select-list ul > li ~ li:before {
		position: absolute;
		color: #d2d2d1;
		left: 8px;
		content: "/";
}
.chkup-ctg-accordion .btn-accrodion .right .inner {
		justify-content: flex-end;
}
.chkup-ctg-accordion .per-surcharges {
		color: #a4a4a4;
		display: flex;
		align-items: center;
		font-size: 14px;
		font-weight: 300;
		line-height: 2.1428571429;
}
.chkup-ctg-accordion .per-surcharges > em {
		font-size: 16px;
		font-weight: 500;
		line-height: 2;
		color: #494949;
		padding-left: 12px;
		padding-right: 4px;
}

/* RV-01-012 */
.add-healthexam-item-list-wrap {
		margin-top: 20px;
}

.medical-chkup-list > li ~ li {
		margin-top: 60px;
}
.medical-chkup-list .chkup-ctg-accordion ~ .co-ref-txt {
		margin-top: 20px;
}

.c-layer-popup.rsv-his-confirm-popup .modal-field {
		width: 290px;
		background-color: #fcfcfc;
}
.c-layer-popup.rsv-his-confirm-popup .c-title {
		font-size: 16px;
		font-weight: 500;
		line-height: 2;
		color: #253981;
}
.c-layer-popup.rsv-his-confirm-popup .c-modal-header {
		padding: 20px;
}
.c-layer-popup.rsv-his-confirm-popup .c-modal-body {
		margin: 0 20px;
}
.c-layer-popup.rsv-his-confirm-popup .c-modal-body .c-modal-inner {
		padding-right: 0;
		padding-bottom: 0;
}
.c-layer-popup.rsv-his-confirm-popup .c-modal-footer {
		padding: 0 20px 20px;
}
.c-layer-popup.rsv-his-confirm-popup .c-modal-footer .inner {
		border-top: 0;
}
.c-layer-popup.rsv-his-confirm-popup .c-modal-footer .buttons {
		justify-content: center;
		width: 100%;
}
.c-layer-popup.rsv-his-confirm-popup .c-modal-footer .buttons .co-btn {
		width: 100%;
}
.c-layer-popup.rsv-his-confirm-popup .c-input-list-basic > .row:not(:first-of-type) {
		padding-top: 4px;
}
.c-layer-popup.rsv-his-confirm-popup .type-sub {
		padding-top: 20px;
}
.c-layer-popup.rsv-his-confirm-popup .type-sub ~ .type-sub {
		padding-top: 4px;
}

.personal-default-info .c-input-list-view .input-type.view01 label {
		flex-basis: 80px;
}

.account-list > li {
		font-size: 14px;
		font-weight: 300;
		line-height: 2;
		margin-top: 20px;
}
.account-list > li > span {
		color: #777676;
}

.depth-order-list.type01 > li ~ li {
		margin-top: 32px;
}
.depth-order-list.type01 ol {
		margin-top: 4px;
}
.depth-order-list.type01 .in-depth {
		padding-left: 16px;
}
.depth-order-list.type01 .depth01 > li ~ li {
		margin-top: 12px;
}

.c-layer-popup .scrollbar-track {
		background-color: #fff;
}
.c-layer-popup .scrollbar-thumb {
		background-color: #e9e9e9;
		border-radius: 12px;
}

.terms-scroll .scrollbar-track {
		background-color: #fcfcfc;
}
.terms-scroll .scrollbar-thumb {
		background-color: #e9e9e9;
		border-radius: 12px;
		width: 4px;
}

.co-horizontal-scroll .scrollbar-track {
		background-color: #fcfcfc;
}
.co-horizontal-scroll .scrollbar-thumb {
		background-color: #e9e9e9;
		border-radius: 12px;
}

#sideNav .sidebar-wrap .scrollbar-track {
		background-color: #253981;
}
#sideNav .sidebar-wrap .scrollbar-thumb {
		background-color: #64709c;
		border-radius: 12px;
}

.checkup-sidebar .inner-wrap .scrollbar-track {
		background-color: #f8f9fb;
}
.checkup-sidebar .inner-wrap .scrollbar-thumb {
		background-color: #e9e9e9;
		border-radius: 12px;
}

.institution-datail-list-article .scrollbar-track {
		background-color: #fcfcfc;
}
.institution-datail-list-article .scrollbar-thumb {
		background-color: #d2d2d1;
		border-radius: 12px;
}

.reservation-sidebar .scrollbar-track {
		background-color: #f8f9fb;
}
.reservation-sidebar .scrollbar-thumb {
		background-color: #e9e9e9;
		border-radius: 12px;
}

.certify-info-cont .title {
		font-size: 12px;
		font-weight: 400;
		line-height: 2;
		color: #1c1c1b;
}
.certify-info-cont .info-cont-box {
		font-size: 12px;
		font-weight: 400;
		line-height: 2;
}
.certify-info-cont .info-cont-box .co-list-card > li {
		font-size: 12px;
		font-weight: 400;
		line-height: 2;
		color: #777676;
}

.chkup-exp-list {
		display: flex;
		flex-wrap: wrap;
		margin: -6px;
}
.chkup-exp-list li {
		padding: 6px;
}

.btn-chkup-exp-detail {
		padding: 2px 8px;
		background-color: #e9e9e9;
		border-radius: 8px;
		transition: all 0.4s;
}
.btn-chkup-exp-detail > span {
		color: #777676;
		font-size: 12px;
		font-weight: 400;
		line-height: 2;
}
.btn-chkup-exp-detail:hover {
		background-color: #ecf1f8;
}
.btn-chkup-exp-detail:hover > span {
		color: #253981;
}

.co-terms-field .main-title {
		font-size: 20px;
		font-weight: 500;
		line-height: 1.5;
		color: #494949;
}

.client-card-field .top-select-box {
		margin-top: 20px;
}
.client-card-field .top-select-box select {
		border-radius: 12px;
}

.c-chkoption-box {
		display: flex;
		flex-wrap: wrap;
}
.c-chkoption-box > .c-chkbtn + .c-chkbtn {
		margin-left: 12px;
}

#container .login-wrapper.type-withkmi {
		min-height: 900px;
}
#container .login-wrapper.type-withkmi .use-info {
		padding: 20px 0;
		border-radius: 16px;
		border: 1px solid #e9e9e9;
		background-color: #f3f3f3;
		color: rgba(28, 28, 27, 0.8);
}
#container .login-wrapper.type-withkmi .use-info .time {
		font-size: 16px;
		font-weight: 500;
		line-height: 2;
}
#container .login-wrapper.type-withkmi .use-info .time ~ p {
		margin-top: 8px;
}
#container .login-wrapper.type-withkmi .side-area {
		width: 520px;
		min-width: 520px;
		padding-left: 3vw;
		padding-right: 3vw;
		border-right: 1px solid #e9e9e9;
		display: flex;
		justify-content: center;
		align-items: center;
		position: relative;
}
#container .login-wrapper.type-withkmi .login-area {
		width: 100%;
		max-width: 100%;
}
#container .login-wrapper:not(.type-withkmi) .side-area {
		width: 560px;
		min-width: 560px;
		height: 100%;
		display: flex;
		align-items: center;
		border-right: 1px solid #e9e9e9;
		background-color: #fff;
		padding: 197px 5vw;
		position: relative;
}
#container .login-wrapper .contents-field-02 {
		justify-content: center;
		height: calc(100% - 80px);
		width: 100%;
		overflow: hidden;
		background-color: rgba(47, 122, 211, 0.15);
		min-width: 920px;
		display: flex;
		align-items: center;
}
#container .login-wrapper .contents-field-02 .logo-txt {
		display: flex;
		align-items: center;
		color: #fda3ba;
}
#container .login-wrapper .contents-field-02 .logo-txt .logo {
		line-height: 32px;
}
#container .login-wrapper .contents-field-02 .logo-txt .logo img {
		vertical-align: middle;
}
#container .login-wrapper .contents-field-02 .logo-txt > em ~ span {
		font-size: 16px;
		font-weight: 500;
		line-height: 2;
		position: relative;
		padding-left: 16px;
}
#container .login-wrapper .contents-field-02 .logo-txt > em ~ span:before {
		position: absolute;
		top: 12px;
		left: 9px;
		content: "";
		width: 1px;
		height: 12px;
		background-color: #2f7ad3;
}
#container .login-wrapper .contents-field-02 .btm-info {
		margin-top: 24px;
		font-size: 18px;
		font-weight: 300;
		line-height: 1.3333333333;
		color: #253981;
}
#container .login-wrapper .contents-field-02 .login-txt .c-title {
		font-size: 40px;
		font-weight: 700;
		line-height: 1.3;
		margin-top: 4px;
		color: #253981;
}
#container .login-wrapper .contents-field-02 .login-img {
		min-height: auto;
		max-height: 100%;
}
#container .login-wrapper .contents-field-02 .login-img .img img {
		min-width: 30.7vw;
		max-width: 30.7vw;
		max-height: 100%;
}
@media screen and (min-width: 1920px) {
		#container .login-wrapper .contents-field-02 {
				justify-content: space-between;
				padding-left: 5.2vw;
				padding-right: 5.2vw;
		}
		#container .login-wrapper .contents-field-02 .logo img {
				height: 24px;
		}
		#container .login-wrapper .contents-field-02 .btm-info {
				font-size: 0.9vw;
				line-height: 1.2vw;
		}
		#container .login-wrapper .contents-field-02 .login-txt .c-title {
				font-size: 2vw;
				line-height: 2.7vw;
		}
		#container .login-wrapper .contents-field-02 .logo-txt > em ~ span {
				padding-left: 20px;
				font-size: 24px;
		}
		#container .login-wrapper .contents-field-02 .logo-txt > em ~ span:before {
				top: 15px;
				left: 11px;
				width: 2px;
				height: 20px;
		}
}

.co-terms-area .top-title {
		padding: 40px 0;
		text-align: center;
		border-bottom: 1px solid #e9e9e9;
}
.co-terms-area .top-title > h1 {
		font-size: 20px;
		font-weight: 500;
		line-height: 1.8;
}
.co-terms-area .terms-tab-wrap {
		padding-top: 20px;
}

.terms-tab-wrap .terms-tab-area {
		display: flex;
		justify-content: space-between;
		align-items: center;
}
.terms-tab-wrap .terms-tab-area .select-box {
		width: 140px;
}
.terms-tab-wrap .terms-field {
		margin-top: 45px;
}
.terms-tab-wrap .terms-field .c-terms-section:not(:first-of-type) {
		margin-top: 60px;
}
.terms-tab-wrap .terms-field .c-terms-section > h1 {
		font-size: 24px;
		font-weight: 700;
		line-height: 1.6666666667;
}
.terms-tab-wrap .terms-field .c-terms-section > h1 + h2 {
		margin-top: 20px;
}
.terms-tab-wrap .terms-field.personal-terms .c-terms-section:not(:first-of-type) {
		margin-top: 0;
		padding-top: 20px;
}
.terms-tab-wrap .terms-field .btm-add-terms {
		margin-top: 60px;
}
.terms-tab-wrap .c-terms-section > h2 {
		font-size: 20px;
		font-weight: 500;
		line-height: 1.5;
		color: #494949;
}
.terms-tab-wrap .c-terms-section > h3 {
		margin-top: 20px;
		font-size: 14px;
		font-weight: 500;
		line-height: 2;
		color: #494949;
}
.terms-tab-wrap .c-terms-section > p {
		margin-top: 4px;
		font-size: 14px;
		font-weight: 300;
		line-height: 2.1428571429;
}
.terms-tab-wrap .c-terms-section p,
.terms-tab-wrap .c-terms-section ol,
.terms-tab-wrap .c-terms-section ul {
		font-size: 14px;
		font-weight: 300;
		line-height: 2.1428571429;
		color: #777676;
}
.terms-tab-wrap .c-terms-section .co-list-card.number01 > li {
		padding-left: 22px;
}
.terms-tab-wrap .c-terms-section .co-list-card.number01 > li:before {
		top: 8px;
		width: 14px;
		height: 14px;
		font-size: 11px;
		line-height: 1.3;
}
.terms-tab-wrap .c-terms-section .co-list-card.number02 > li:before {
		top: 8px;
}
.terms-tab-wrap .c-terms-section .per-cont ~ .per-cont {
		margin-top: 20px;
}
.terms-tab-wrap .terms-ord-list li {
		position: relative;
		list-style: none;
		padding-left: 19px;
		font-size: 14px;
		font-weight: 300;
		line-height: 2.1428571429;
}
.terms-tab-wrap .terms-ord-list li span:first-child {
		position: absolute;
		top: 0;
		left: 0;
}

.c-terms-section .co-list-card.number01 .table-wrap {
		margin-top: 20px;
		margin-left: -20px;
		margin-bottom: 20px;
}
.c-terms-section .c-terms-link {
		font-size: 14px;
		font-weight: 300;
		line-height: 2.1428571429;
		line-height: 1.4;
		color: #253981;
		border-bottom: 1px solid #253981;
}

.policy-list {
		margin-top: 20px;
		font-size: 14px;
		font-weight: 300;
		line-height: 2.1428571429;
}
.policy-list > li ~ li {
		margin-top: 4px;
}
.policy-list > li > a {
		position: relative;
		color: #253981;
		border-bottom: 1px solid #253981;
}
.policy-list > li > a:before {
		content: "";
		border-bottom: 1px solid #253981;
}
.policy-list.co-list-card > li {
		padding-right: 18px;
}
.policy-list.co-list-card > li:before {
		border-bottom: 1px solid #253981;
		line-height: 1.2;
		left: 2px;
		padding-right: 20px;
}

.terms-field table {
		width: max-content;
}
.terms-field table.terms-table-1, .terms-field table.terms-table-2, .terms-field table.terms-table-3 {
		width: 100%;
		text-align: center;
		border-top: 1px solid #e9e9e9;
		border-bottom: 1px solid #e9e9e9;
}
.terms-field table.terms-table-1 thead th, .terms-field table.terms-table-2 thead th, .terms-field table.terms-table-3 thead th {
		text-align: center;
		border-bottom: 1px solid #e9e9e9;
		font-size: 14px;
		font-weight: 700;
		line-height: 2;
		vertical-align: middle;
		padding: 8px;
		color: #494949;
}
.terms-field table.terms-table-1 tbody, .terms-field table.terms-table-2 tbody, .terms-field table.terms-table-3 tbody {
		border-top: 1px solid #d2d2d1;
}
.terms-field table.terms-table-1 tbody th, .terms-field table.terms-table-2 tbody th, .terms-field table.terms-table-3 tbody th {
		font-weight: 300;
}
.terms-field table.terms-table-1 th,
.terms-field table.terms-table-1 td, .terms-field table.terms-table-2 th,
.terms-field table.terms-table-2 td, .terms-field table.terms-table-3 th,
.terms-field table.terms-table-3 td {
		word-break: keep-all;
		vertical-align: middle;
		padding: 8px;
		border-bottom: 1px solid #e9e9e9;
}
.terms-field table.terms-table-1 {
		table-layout: fixed;
}
.terms-field table.terms-table-1 tr tbody th[rowspan] {
		vertical-align: top;
}
.terms-field table.terms-table-2 thead tr th,
.terms-field table.terms-table-2 tbody tr td {
		width: 25%;
}
.terms-field table.terms-table-2 tbody td {
		text-align: left;
}
.terms-field table.terms-table-3 thead tr th,
.terms-field table.terms-table-3 tbody tr td {
		width: 33.333%;
}
.terms-field table.terms-table-3 tbody td {
		text-align: left;
}
.terms-field + p {
		margin-top: 1.25rem;
}
.terms-field.personal-terms {
		position: relative;
}
.terms-field.personal-terms h2 {
		margin-top: 40px;
}

.co-list-card.number01.person-list01 {
		counter-reset: per-listcnt 3;
}
.co-list-card.number01.person-list01 > li {
		counter-increment: per-listcnt 1;
}
.co-list-card.number01.person-list01 > li:before {
		content: counter(per-listcnt);
}

.c-layer-popup.useguide-popup .c-modal-inner {
		min-height: 100%;
		height: 100%;
		padding-left: 20px;
		padding-right: 20px;
}
.c-layer-popup.useguide-popup .modal-wrap {
		height: calc(100% - 80px);
}
.c-layer-popup.useguide-popup .c-modal-body {
		height: 100%;
		overflow-y: hidden;
}
.c-layer-popup.useguide-popup .modal-field {
		width: 1180px;
		height: 100%;
}

.useguide-tab-wrap {
		height: 100%;
		min-height: 100%;
		display: flex;
		flex-direction: column;
}
.useguide-tab-wrap .tab-cont-wrap {
		margin-top: 40px;
		flex: 1;
		overflow: hidden;
}
.useguide-tab-wrap .tab-content {
		height: 100%;
}

.useguide-field {
		height: 100%;
		padding-bottom: 20px;
}
.useguide-field > .inner {
		display: flex;
		flex: 1 0 100%;
		height: 100%;
		position: relative;
}
.useguide-field .left-wrap {
		width: 260px;
		padding-right: 40px;
		border-right: 1px solid #e9e9e9;
		flex: 0 0 260px;
}
.useguide-field .right-wrap {
		position: relative;
		padding-left: 40px;
		padding-right: 20px;
		overflow-x: hidden;
		overflow-y: auto;
		width: 100%;
}
.useguide-field .right-wrap::-webkit-scrollbar {
		opacity: 0;
		height: 8px;
		width: 8px;
		background-color: #fff;
}
.useguide-field .right-wrap::-webkit-scrollbar:hover {
		opacity: 1;
}
.useguide-field .right-wrap ::-webkit-scrollbar-track {
		background-color: #fff;
}
.useguide-field .right-wrap::-webkit-scrollbar-thumb {
		opacity: 0;
		background: #e9e9e9;
		border-radius: 50px;
		background-clip: padding-box;
}
.useguide-field .right-wrap::-webkit-scrollbar-corner {
		opacity: 0;
		background-color: transparent;
}
.useguide-field .right-wrap > .inner {
		position: relative;
}
.useguide-field .co-tab-list {
		flex-shrink: 0;
}

.btn-guide-list > li > button {
		background-color: #fff;
		color: #777676;
		display: block;
		padding: 8px 20px;
		font-size: 14px;
		font-weight: 300;
		line-height: 2.1428571429;
		color: #777676;
		border-radius: 8px;
		border: 1px solid #e9e9e9;
		width: 100%;
		text-align: left;
		overflow: hidden;
		text-overflow: ellipsis;
		white-space: nowrap;
		word-wrap: break-word;
}
.btn-guide-list > li > button.on {
		background-color: #253981;
		color: #fff;
		border: 1px solid #253981;
}
.btn-guide-list > li ~ li {
		margin-top: 12px;
}

.c-guide-section ~ .c-guide-section {
		margin-top: 40px;
}
.c-guide-section h2,
.c-guide-section h3,
.c-guide-section ol,
.c-guide-section ul,
.c-guide-section p,
.c-guide-section div {
		word-break: keep-all;
}
.c-guide-section > h2 {
		font-size: 20px;
		font-weight: 500;
		line-height: 1.8;
}
.c-guide-section > h2:not(:first-of-type) {
		margin-top: 60px;
}
.c-guide-section > h2 + h3 {
		margin-top: 20px;
}
.c-guide-section h3 {
		margin-top: 40px;
		color: #253981;
		font-size: 16px;
		font-weight: 500;
		line-height: 2;
}
.c-guide-section > p ~ img {
		margin-top: 20px;
}
.c-guide-section .guide-step-list {
		margin-top: 20px;
}
.c-guide-section .guide-step-list > li:first-of-type > h3 {
		margin-top: 20px;
}
.c-guide-section .guide-step-list h3.title .step {
		color: #a4a4a4;
}
.c-guide-section .guide-step-list > li p ~ img {
		margin-top: 20px;
}
.c-guide-section .guide-step-list > li ~ li {
		margin-top: 40px;
}

.btn-terms-go-top {
		position: fixed;
		bottom: 40px;
		right: 40px;
		background: url("../images/ico//ico_arrow_12.svg") center/22px auto no-repeat;
		width: 46px;
		height: 46px;
		background-color: #e9e9e9;
		border-radius: 12px;
		display: inline-block;
		opacity: 0;
		transition: opacity 0.4s;
}
.btn-terms-go-top.on {
		opacity: 1;
		transition: opacity 0.4s;
}

#useGuideSubTabPanel_04_04 {
		padding-bottom: 480px;
}

.offered-examination > h5.title {
		font-size: 14px;
		font-weight: 700;
		line-height: 2;
		color: #494949;
		padding: 8px 12px;
}
.offered-examination .offered-list {
		margin-top: 6px;
		border-top: 2px solid #d2d2d1;
}
.offered-examination .offered-list > li {
		padding: 8px 12px;
		border-bottom: 1px solid #e9e9e9;
}

.c-layer-popup.compare {
		overflow-y: hidden;
}
.c-layer-popup.compare .c-modal-inner {
		height: 100%;
}
.c-layer-popup.compare .c-modal-body {
		overflow-y: hidden;
}
.c-layer-popup.compare .modal-wrap {
		padding: 0 40px;
		width: 100%;
}
.c-layer-popup.compare .modal-field {
		width: 100%;
}
.c-layer-popup.compare table {
		position: relative;
		border-radius: 12px;
		padding: 2px 0;
}
.c-layer-popup.compare table thead {
		position: sticky;
		top: 0;
		left: 0;
		border-radius: 10px;
		overflow: hidden;
}
.c-layer-popup.compare table thead th {
		position: relative;
		border: 0;
		background-color: #ecf1f8;
		font-weight: 500;
		position: sticky;
		top: 0;
		outline: 1px solid #e9e9e9;
		outline-offset: -0.5px;
}
.c-layer-popup.compare table thead td {
		padding: 8px;
		border-right: 1px solid #e9e9e9;
}

.tbl-campare-fix-field {
		border-radius: 10px;
		height: 100%;
		overflow-y: auto;
		padding: 0 10px 10px 10px;
		position: relative;
}

.jobstressexam-wrap .condition-field > .co-no-data {
		margin-top: 12px;
}

.jobstress-terms-cont.no-target .col-7 {
		max-width: 100%;
		flex: 0 0 100%;
}

p ~ .jobstress-list-cont {
		margin-top: 12px;
}

.jobstress-list-cont > li ~ li {
		margin-top: 12px;
}
.jobstress-list-cont > li > p {
		padding-left: 16px;
}

.jo-score-row {
		display: flex;
		flex-wrap: wrap;
		margin-top: 12px;
		margin-left: -20px;
		margin-right: -20px;
}
.jo-score-row .col {
		padding-left: 20px;
		padding-right: 20px;
}

.jobstress-exam-list-wrap .sub-title + .cont-01 {
		margin-bottom: 12px;
}
.jobstress-exam-list-wrap .exam-field {
		padding: 24px 32px;
		border: 1px solid #d2d2d1;
		background-color: #fcfcfc;
		border-radius: 8px;
}
.jobstress-exam-list-wrap .exam-field.no-data {
		padding: 24px 32px;
		color: #737372;
}
.jobstress-exam-list-wrap .exam-field ~ .exam-field {
		margin-top: 40px;
}
.jobstress-exam-list-wrap .exam-field h3.title {
		font-size: 24px;
		font-weight: 700;
		line-height: 1.6666666667;
		margin-bottom: 12px;
}
.jobstress-exam-list-wrap .exam-field .cont {
		font-size: 16px;
		font-weight: 500;
		line-height: 2;
		color: #616161;
}
.jobstress-exam-list-wrap .exam-field .cont.is-error {
		color: #e15b4e;
}
.jobstress-exam-list-wrap .exam-field .exam-article ~ .exam-article {
		margin-top: 28px;
}
.jobstress-exam-list-wrap .exam-field .exam-article .row {
		display: flex;
		flex: 1 1 auto;
		margin-top: 8px;
		margin-left: -20px;
		margin-right: -20px;
}
.jobstress-exam-list-wrap .exam-field .exam-article .row .c-chkbox {
		max-width: 25%;
		flex: 0 0 25%;
		padding: 0 20px;
}
.jobstress-exam-list-wrap .exam-field .exam-article .row .c-chkbox input [type=checkbox] + .chk-label > i,
.jobstress-exam-list-wrap .exam-field .exam-article .row .c-chkbox input[type=radio] + .chk-label > i {
		margin-right: 8px;
}
.jobstress-exam-list-wrap .exam-field .exam-article .row .c-chkbox input [type=checkbox]:checked + .chk-label,
.jobstress-exam-list-wrap .exam-field .exam-article .row .c-chkbox input[type=radio]:checked + .chk-label {
		box-shadow: 0 0 0 1px #2f7ad3;
		border-color: #2f7ad3;
		position: relative;
}
.jobstress-exam-list-wrap .exam-field .exam-article .row .c-chkbox input [type=checkbox]:checked + .chk-label > i:before, .jobstress-exam-list-wrap .exam-field .exam-article .row .c-chkbox input [type=checkbox]:checked + .chk-label > i:after,
.jobstress-exam-list-wrap .exam-field .exam-article .row .c-chkbox input[type=radio]:checked + .chk-label > i:before,
.jobstress-exam-list-wrap .exam-field .exam-article .row .c-chkbox input[type=radio]:checked + .chk-label > i:after {
		transition: all 0s linear;
}
.jobstress-exam-list-wrap .exam-field .exam-article .row .c-chkbox input [type=checkbox]:checked + .chk-label > span,
.jobstress-exam-list-wrap .exam-field .exam-article .row .c-chkbox input[type=radio]:checked + .chk-label > span {
		font-weight: 400;
}
.jobstress-exam-list-wrap .exam-field .exam-article .row .c-chkbox > label {
		width: 100%;
		padding: 15px 12px;
		border-radius: 12px;
		border: 1px solid #e9e9e9;
		background-color: #fff;
		display: flex;
		align-items: center;
		justify-content: center;
		cursor: pointer;
}
.jobstress-exam-list-wrap .ref-cont {
		margin-top: 12px;
}
.jobstress-exam-list-wrap .ref-cont > p {
		color: #777676;
}
.jobstress-exam-list-wrap .ref-cont > p > .link {
		text-decoration: underline;
}

.jobstress-taget-list-field .targets-list {
		margin-bottom: -10px;
}
.jobstress-taget-list-field .targets-list li {
		flex: 0 0 50%;
		max-width: 50%;
		padding: 10px;
}
.jobstress-taget-list-field .client-card {
		padding: 24px 20px;
		min-height: 108px;
		max-height: none;
		height: auto;
}
.jobstress-taget-list-field .client-card.person.on:before {
		width: 15px;
		height: 15px;
		top: 26px;
		right: 20px;
}

.jobstress-history-field {
		margin-top: 12px;
		overflow: hidden;
		border-radius: 12px;
		position: relative;
		padding-left: 48px;
		padding-right: 48px;
}
.jobstress-history-field .mychkup-history-swiper {
		overflow: hidden;
		width: auto;
}
.jobstress-history-field .swiper {
		overflow: hidden;
		width: 100%;
		height: 100%;
}
.jobstress-history-field .swiper-slide {
		width: 296px;
		display: flex;
		align-items: center;
		padding: 1px;
}
.jobstress-history-field .swiper-slide + .swiper-slide {
		margin-left: 20px;
}
.jobstress-history-field .slide-card {
		width: 100%;
		position: relative;
		border-radius: 12px;
		overflow: hidden;
}
.jobstress-history-field .co-slide-btn-prev,
.jobstress-history-field .co-slide-btn-next {
		cursor: default;
		display: block;
		position: absolute;
		top: 50%;
		transform: translateY(-50%);
}
.jobstress-history-field .co-slide-btn-prev {
		left: 0;
}
.jobstress-history-field .co-slide-btn-prev:hover {
		background-position: 0 0;
}
.jobstress-history-field .co-slide-btn-next {
		right: 0;
		background-position: 0 -38px;
}
.jobstress-history-field .mychkup-history-swiper {
		overflow: hidden;
}
.jobstress-history-field:before {
		content: none;
}
.jobstress-history-field.is-active:hover:before {
		position: absolute;
		top: 1px;
		bottom: 1px;
		right: 48px;
		content: "";
		background: linear-gradient(90deg, rgba(25, 25, 25, 0.025) 0%, rgba(217, 217, 217, 0) 38.05%);
		transform: rotate(-180deg);
		border-radius: 12px;
		width: 96px;
}
.jobstress-history-field.is-active .co-slide-btn-prev,
.jobstress-history-field.is-active .co-slide-btn-next {
		cursor: pointer;
}
.jobstress-history-field.is-active .co-slide-btn-prev:hover {
		background-position: 0 -76px;
}
.jobstress-history-field.is-active .co-slide-btn-next:hover {
		background-position: 0 -114px;
}
.jobstress-history-field .co-slide-btn-prev.swiper-button-disabled {
		background-position-y: -152px;
		opacity: 1 !important;
}
.jobstress-history-field .co-slide-btn-prev.swiper-button-disabled:hover {
		background-position-y: -152px;
}
.jobstress-history-field .co-slide-btn-next.swiper-button-disabled {
		background-position-y: -190px;
		opacity: 1 !important;
}
.jobstress-history-field .co-slide-btn-next.swiper-button-disabled:hover {
		background-position-y: -190px;
}

.mychkup-history-card.jobstress {
		min-height: 164px;
		padding: 32px 20px;
}
.mychkup-history-card.jobstress .value {
		margin-top: 12px;
}

.co-no-data.type01 {
		padding: 30px 20px;
		border: 1px solid #e9e9e9;
		border-radius: 12px;
		border-radius: 12px;
		background-color: #fcfcfc;
		display: flex;
		justify-content: center;
		align-items: center;
}
.co-no-data.type01 > div {
		display: block;
		text-align: center;
}
.co-no-data.type01 .img img {
		width: 63px;
}
.co-no-data.type01 .co-none-txt {
		margin-top: 12px;
		font-size: 16px;
		font-weight: 500;
		line-height: 2;
		color: #939391;
}

.jo-progress-bar {
		border-radius: 8px;
		position: relative;
		overflow: hidden;
		width: 100%;
		height: 32px;
		background-color: #ecf1f8;
}
.jo-progress-bar .mark {
		position: absolute;
		left: 0;
		right: 0;
		width: 100%;
		display: flex;
}
.jo-progress-bar .jo-progress {
		border-radius: 8px;
		display: block;
		width: 0%;
		height: 100%;
		background: #253981;
		background-image: linear-gradient(-45deg, rgba(255, 255, 255, 0.125) 25%, transparent 25%, transparent 50%, rgba(255, 255, 255, 0.125) 50%, rgba(255, 255, 255, 0.125) 75%, transparent 75%, transparent);
		background-size: 18px 18px;
		animation: aniProgressBar 3s linear infinite;
}

.jo-progress-field {
		padding: 24px 20px;
		background-color: #f8f9fb;
		border-radius: 12px;
		border: 1px solid #d2d2d1;
}
.jo-progress-field .result-value {
		font-size: 16px;
		font-weight: 500;
		line-height: 2;
}
.jo-progress-field .result-value + .progress-box {
		margin-top: 12px;
}
.jo-progress-field .progress-box {
		position: relative;
}
.jo-progress-field .progress-box .mark.step01, .jo-progress-field .progress-box .mark.step02 {
		height: 24px;
		margin-top: 4px;
		width: 100%;
}
.jo-progress-field .progress-box .mark > div {
		position: absolute;
		left: 0;
		width: 100%;
}
.jo-progress-field .progress-box .mark > div > span {
		position: absolute;
}
.jo-progress-field .progress-box .mark > div > span:last-of-type {
		right: 0;
}
.jo-progress-field .progress-box .mark.step01 span:first-child {
		left: 0;
}
.jo-progress-field .progress-box .mark.step01 span:nth-child(2) {
		left: 25%;
}
.jo-progress-field .progress-box .mark.step01 span:nth-child(3) {
		left: 50%;
}
.jo-progress-field .progress-box .mark.step01 span:nth-child(4) {
		left: 75%;
}
.jo-progress-field .progress-box .mark.step02 span {
		margin-left: -30px;
}
.jo-progress-field .progress-box .mark.step02 span:first-child {
		left: 25%;
}
.jo-progress-field .progress-box .mark.step02 span:nth-child(2) {
		left: 50%;
}
.jo-progress-field .progress-box .mark.step02 span:nth-child(3) {
		left: 70%;
}

@keyframes aniProgressBar {
		0% {
				background-position: 0 0;
		}
		100% {
				background-position: 16px 16px;
		}
}
.ui-datepicker.hide {
		display: none !important;
}

.co-consent-form-field .cont-detail-body {
		margin-top: 10px;
		margin-bottom: 10px;
}

.resexam-agreeuse-cont {
		padding: 20px 10px 20px 20px;
		height: 600px;
		border-radius: 12px;
		border: 1px solid #e9e9e9;
}
.resexam-agreeuse-cont .inner {
		padding-right: 20px;
		overflow-y: auto;
		max-height: 100%;
}
.resexam-agreeuse-cont .inner::-webkit-scrollbar {
		opacity: 0;
		height: 6px;
		width: 6px;
		background-color: #fff;
}
.resexam-agreeuse-cont .inner::-webkit-scrollbar:hover {
		opacity: 1;
}
.resexam-agreeuse-cont .inner ::-webkit-scrollbar-track {
		background-color: #fff;
}
.resexam-agreeuse-cont .inner::-webkit-scrollbar-thumb {
		opacity: 0;
		background: #e9e9e9;
		border-radius: 50px;
		background-clip: padding-box;
}
.resexam-agreeuse-cont .inner::-webkit-scrollbar-corner {
		opacity: 0;
		background-color: transparent;
}
.resexam-agreeuse-cont .co-list-card > li:before {
		font-weight: 500;
}
.resexam-agreeuse-cont .co-list-card > li ~ li {
		margin-top: 20px;
}
.resexam-agreeuse-cont .co-list-card .title {
		font-size: 15px;
		font-weight: 500;
		line-height: 2;
}
.resexam-agreeuse-cont .messrs {
		margin-top: 20px;
		text-align: center;
		font-size: 15px;
		font-weight: 500;
		line-height: 2;
}

.consent-personal-info {
		margin-top: 4px;
		text-align: center;
		font-size: 15px;
		font-weight: 400;
		line-height: 2;
}
.consent-personal-info .date {
		display: flex;
		align-items: center;
		justify-content: center;
}
.consent-personal-info .date > span ~ span {
		padding-left: 4px;
}
.consent-personal-info .chk-list {
		display: flex;
		align-items: flex-end;
		justify-content: flex-end;
}