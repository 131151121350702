//= generic
$p-fs-large-1: 40px;
$p-fs-large-2: 24px;
$p-fs-large-3: 20px;
$p-fs-normal: 20px;
$p-fs-normal: 14px;
$p-fs-small-1: 12px;
$p-fs-small-2: 10px;

$g-spacing-s: 10px;
$g-spacing-m: 20px;

//=  line-height
$p-lineheight-normal: 2.14;

$g-lineheight: 1.4;
$g-lineheight-2: 1.7;

// + gap
$gap-2: 2px !default;
$gap-4: 4px !default;
$gap-5: 5px !default;
$gap-6: 6px !default;
$gap-8: 8px !default;
$gap-10: 10px !default;
$gap-12: 12px !default;
$gap-14: 14px !default;
$gap-15: 15px !default;
$gap-16: 16px !default;
$gap-20: 20px !default;
$gap-24: 24px !default;
$gap-40: 40px !default;
$gap-60: 60px !default;

// =font
$font-name: 'NotoSansKR', sans-serif;
$font-path: '../fonts/';

// = color (admin 변수 동일)
$color-black-1: #1c1c1b !default;
$color-black-2: #222225 !default;
$color-black-op1: rgba(28, 28, 27, 0.1) !default;
$color-black-op4: rgba(28, 28, 27, 0.4) !default;
$color-black-op6: rgba(28, 28, 27, 0.6) !default;
$color-black-op8: rgba(28, 28, 27, 0.8) !default;

$color-blue-1: #2f7ad3 !default;
$color-blue-2: #879ce6 !default;
$color-blue-3: #489cff !default;

$color-navy-1: #253981 !default;
$color-navy-1-op2: rgba(37, 57, 129, 0.2) !default;
$color-navy-2: #225490 !default;
$color-navy-3: #394261 !default;
$color-navy-4: #394261 !default;

$color-yellow-1: #ecc600 !default;
$color-orange-1: #ff6859 !default;
$color-orange-2: #e15b4e !default;
$color-orange-3: #ff6633 !default;
$color-green-1: #206f44 !default;

$color-white-1: #fff !default;
$color-white-2: #fcfcfc !default;
$color-white-3: #f8f9fb !default;

$color-white-op1: rgba(255, 255, 255, 0.1) !default;
$color-white-op2: rgba(255, 255, 255, 0.2) !default;
$color-white-op4: rgba(255, 255, 255, 0.4) !default;
$color-white-op6: rgba(255, 255, 255, 0.6) !default;
$color-white-op8: rgba(255, 255, 255, 0.8) !default;

$color-gray-1: #494949 !default;
$color-gray-1-op4: rgba(73, 73, 73, 0.8) !default;
$color-gray-2: #777676 !default;
$color-gray-3: #a4a4a4 !default;
$color-gray-3-op4: rgba(164, 164, 164, 0.4) !default;

$color-gray-4: #d2d2d1 !default;
$color-gray-5: #e9e9e9 !default;
$color-gray-6: #d2d2d1 !default;
$color-gray-7: #f3f3f3 !default;
$color-gray-8: #ecf1f8 !default;
$color-gray-9: #f5f5f9 !default;
$color-gray-10: #c0c4d1 !default;
$color-gray-11: #525258 !default;
$color-gray-12: #f8f9fb !default;
$color-gray-13: #d9d9d9 !default;
$color-gray-14: #777676 !default;
$color-gray-15: #616161 !default;
$color-gray-16: #757574 !default;
$color-gray-17: #939391 !default;
$color-gray-18: #737372 !default;

$color-pink: #fda3ba !default;

//= generic
$fs-nomal: 14px;
$fs-large: 20px;

// =font
$font-name: 'NotoSansKR';
$font-path: '../fonts/';

// - delete

// + font weight
$weight-100: 100; // Thin
$weight-300: 300; // Light
$weight-400: 400; // Regular
$weight-500: 500; // Medium
$weight-700: 700; // Bold
$weight-900: 900; // Black

// + font size
$fs-nomal: 14px !default;
$fs-middle: 16px !default;
$fs-small: 12px !default;

// + radius
$radius-4: 4px;
$radius-6: 6px;
$radius-8: 8px;
$radius-12: 12px;

$side-nav: 260px;

// + image
$image-path: '../images/';
$image-ico-path: $image-path + 'ico/';
$image-ico-sprite01: $image-ico-path + 'ico_sprites_01.svg';
$image-ico-sprite02: $image-ico-path + 'ico_sprites_02.svg';
$image-ico-chkbox-sprite01: $image-ico-path + 'ico_sprites_checkbox01.svg';
$image-ico-chkbox-sprite02: $image-ico-path + 'ico_sprites_checkbox02.svg';
$image-logo-path: $image-path + 'logo/';
$image-logo-kmi-path: $image-path + 'logo/kmi/';

// = [Icon]
$image-ico-sprite01-size: auto 218px;
$ico-sprite01-t-y: 0;
$ico-sprite01-b-y: -38px;
$ico-text: '' !default;

$result: 0;

/*
	button
*/
$btn-size-normal: 8px 24px;

// + basic
$input-text-height: 44px;
$input-border-color: $color-gray-4;
$input-text-color: $color-gray-1;
$input-border-radius: $radius-8;
$input-basic-lineheight: 44px;

$input-error-border-dolor: $color-orange-1;
$input-focus-border-color: $color-blue-1;

// disabled
$input-disabled-border-color: $color-gray-6;
$input-disabled-bg-color: $color-gray-5;
$input-disabled-font-color: $color-gray-1;

// + detail
$input-detail-border-color: $color-gray-5;

// + check, radio
$input-check-size: 18px;

// + input-list
$input-col-gap-h: $gap-12;
$input-col-gap-v: $gap-16;

$focus-style: 2px solid $color-black-1;

// = Spacing

// + article
$per-title-article-gap: 12px;
$article-basic-gap: 60px;

$MinWrapWidth: 1200px;

$guide-spacing-20: 20px;
$guide-spacing-40: 40px;
$guide-spacing-60: 60px;

// = Table
$table-border-color: $color-gray-5;

// = Scrollbar
$scrollbarWidth: 8px;
$scrollbarHeight: 8px;
$scrollbarColor: $color-gray-5;
$scrollbarRadius: 12px;

$scrollbarWidthSm: 6px;
$scrollbarHeightSm: 6px;
$scrollbarColorSm: $color-gray-4;
$scrollbarRadiusSm: 10px;
