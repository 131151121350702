/*
= bread crumb
*/
.c-breadcrumb {
  .breadcrumb-list {
    &:after {
      @include after;
    }
    > li {
      position: relative;
      margin-right: $gap-8;
      float: left;
      font: {
        size: $fs-small;
      }
      color: $color-gray-3;

      & ~ li {
        &:before {
          float: left;
          padding-right: $gap-8;
          color: $color-gray-3;
          content: '/';
        }
      }

      &.on {
        color: $color-navy-1;
      }
    }
  }
}

.top_head {
  .c-breadcrumb {
    & + .c-title-field {
      :after {
        @include after;
      }
      position: relative;
      margin-top: $gap-12;
      padding-bottom: $gap-12;
      .buttons {
        position: absolute;
        top: -6px;
        right: 0;
      }
    }
  }
}
.co-table.cont-type04.tbl-chkup-result01 {
  border-right: 1px solid $table-border-color;
}

// = c-titcont-list
.c-titcont-list {
  display: flex;
  align-items: center;
  > dl {
    display: table;
    & ~ dl {
      margin-left: $gap-20;
    }
    dt,
    dd {
      font: {
        size: $fs-small;
        weight: $weight-300;
      }
      display: table-cell;
      font-size: $fs-small;
    }
    dt {
      color: $color-gray-2;
    }
    dd {
      padding-left: $gap-12;
      color: $color-gray-3;
    }
  }
}
.c-txtarea-field {
  & + .c-titcont-list {
    margin-top: $gap-12;
  }
}

// =Memo
.c-memo-card {
  > a {
    border: 1px solid $color-gray-5;
    background-color: #fff;
    border-radius: $radius-12;
    padding: 12px 20px;
    margin-top: 12px;
    margin-bottom: 12px;
    display: flex;
    flex-wrap: nowrap;
    width: 100%;
    align-items: flex-start;

    > .col-cont.on {
      & + .col-name {
        > i {
          transform: rotate(-180deg);
        }
      }
      .col-name {
        i {
          transform: rotate(-180deg);
        }
      }
    }
  }
  .col-date,
  .col-name {
    flex-shrink: 0;
    flex-grow: 0;
  }
  .col-date {
    width: 110px;
  }
  .col-cont {
    padding-right: 40px;
    flex-grow: 1;
    overflow: hidden;
    height: 24px;

    &.on {
      .detail-cont {
        &.on {
          &:after {
            display: none;
          }
        }
      }
    }

    .detail-cont {
      min-height: 24px;
      line-height: 24px;
      position: relative;
      word-break: break-all;
      &.on {
        &:after {
          content: '...';
          position: absolute;
          top: 0;
          right: 0;
          display: inline-block;
          padding-left: 1px;
          background-color: $color-white-1;
        }
      }
    }
  }
  .col-name {
    width: 100px;
    &:after {
      @include after;
    }
    > .name {
      display: inline-block;
    }
    i {
      float: right;
      //  margin-left: $gap-40;
    }
  }

  .arrow {
    background: url($image-ico-path + 'ico_arrow_07.svg') 0/20px no-repeat;
    width: 20px;
    height: 20px;
    content: '';
    display: inline-block;
    overflow: hidden;
    vertical-align: top;
  }
}

// = c-card-panel

/*
	= Card Type
*/
.c-title-field {
  & + .c-card-article {
    margin-top: $gap-2;
  }
}

.c-card-article {
  & + .c-card-article {
    margin-top: $gap-10;
  }
}

.c-card-article {
  .c-card {
    display: flex;
    flex-direction: column;
    min-width: 0;
    word-wrap: break-word;
    background-color: $color-white-1;
    border-radius: $radius-8;
    border: 1px solid $color-gray-5;
  }
}

.c-card-article {
  > [class*='col'],
  > [class*='col-'] {
    padding: {
      left: $gap-12;
      right: $gap-12;
    }
  }
  &.type01 {
    [class='col-3'] {
      padding: {
        top: $gap-12;
        bottom: $gap-12;
        left: $gap-12;
        right: $gap-12;
      }
    }
  }
}

.c-card {
  //+ type01 (추가 대상자)
  &.type01 {
    $card-type01-padding: 0.428rem; // 6px
    position: relative;

    .card-body {
      padding: 0.428rem; // 6px
    }

    .add-entry {
      display: flex;
      @include justify-row;
      align-items: center;
      padding-right: 0.928rem;
    }

    .info > ul {
      &:after {
        @include after;
      }
      > li {
        padding: $card-type01-padding;
        float: left;
      }
    }

    .c-btn-del {
      background: url($image-ico-path + 'ico_del_01.svg') center/100% no-repeat;
      width: 24px;
      height: 24px;
    }

    & + .add-entry {
      margin-top: 12px;
      align-items: center;
    }
  }

  // + type02 {
  &.type02 {
    padding: 28px 20px;
    display: flex;
    flex-direction: column;
    align-items: center;
    text-align: center;
    .txt {
      line-height: 1.7;
    }

    .cnt {
      line-height: 1.9;
      color: $color-blue-1;
      font: {
        weight: 400;
        size: $p-fs-large-2;
      }
    }
  }

  &.type03 {
    display: flex;
    flex-direction: column;
    padding: 12px 20px;

    .name {
      font-size: $fs-small;
    }
    .c-multi-input-group-02 {
      display: flex;
      align-items: center;
      margin-top: 8px;
      display: flex;
      table-layout: fixed;
      overflow: hidden;
      width: 100%;
      .input-col {
        &.time {
          margin-left: 8px;
          > input[type='text'] {
            text-align: center;
            padding: 2px;
            width: 40px;
            height: 29px;
          }
        }
      }
    }
  }
}

//  + c-plan-chk-group
.c-plan-chk-group {
  display: flex;
  align-items: center;
  .c-multi-input-group-02 {
    display: inline-block;
  }
}

.c-top-cont {
  margin-bottom: 12px;
}
// 예약일정 관리 테이블
.c-plan-chk-group {
  &.type02 {
    .c-multi-input-group-02 {
    }
  }
}

// = reservation-detail
.c-layout-type01 {
  overflow: hidden;
  .left-wrap,
  .right-wrap {
    height: calc(100vh - 330px);
    overflow: hidden;
    > .c-inner {
      height: 100%;
      overflow-y: auto;
      padding-right: 10px;

      @include scrollType01;
    }
  }
}

// [Calendar]
// = c-tbl-calendar
.c-calendar-container {
  padding: 24px 20px;
  background-color: $color-white-1;
  border-radius: $radius-8;
  border: 1px solid $color-gray-5;
}
.c-calendar-wrap {
  .top-field {
    position: relative;
    border-bottom: 2px solid $color-gray-5;
    padding-bottom: 20px;
    &:after {
      @include after;
    }

    .c-controls {
      display: flex;
      justify-content: center;
      align-items: center;

      .year-month {
        padding: 0 20px;
        > p {
          font: {
            size: 28px;
            weight: $weight-400;
          }
          line-height: 41px;
        }
      }
      > button {
        width: 20px;
        height: 21px;
        border-radius: $radius-4;
        background: url($image-ico-path + 'ico_arrow_08.svg') $color-navy-1 center/6px auto no-repeat;
        transform: rotate(-180deg);

        &.btn-next {
          transform: rotate(0deg);
        }
      }
    }

    .c-badge-info {
      position: absolute;
      top: 7px;
      right: 0;
      display: flex;
      > li {
        display: flex;
        align-items: center;
        i {
          display: inline-flex;
          width: 18px;
          height: 18px;
          border: 1px solid $color-gray-4;
          border-radius: $radius-4;
        }
        span {
          display: inline-block;
          margin-left: 8px;
          color: $color-gray-2;
          line-height: 24px;
        }
        & ~ li {
          margin-left: 24px;
        }
      }
    }
  }

  background-color: $color-white-1;

  .weekdays {
    display: flex;

    > li {
      text-align: center;
      padding: 29px 0;
      border-bottom: 2px solid $color-gray-5;
      background-color: $color-white-1;
      line-height: 23px;
      font: {
        size: 16px;
        weight: $weight-400;
      }
    }
  }
  .weekdays,
  .calendar_days {
    > li {
      width: calc(100% / 7);
      display: inline-block;
    }
  }

  // + calendar_days
  .calendar_days {
    display: flex;
    flex-wrap: wrap;
    > li {
      padding: 12px;
      height: 157px;
      border-radius: $radius-8;
      border: 1px solid $color-gray-5;
      background-color: #fff;

      &.is-holiday,
      &.is-reserve-unable,
      &.is-reserve-closed {
        background-color: $color-gray-5;
        opacity: 0.5;
        border-color: $color-white-1;
      }

      &.is-reserve-unable {
        .day {
          color: $color-black-op4;
        }
      }
      &.is-reserve-closed {
        .day {
          color: $color-black-op8;
        }
      }
    }
  }

  .day-top {
    display: flex;
    align-items: center;
    .day {
      font: {
        size: $p-fs-large-2;
        weight: $weight-400;
      }
      line-height: 29px;
    }
    .state {
      margin-left: 10px;
      > span {
        border-radius: $radius-8;
        background-color: $color-orange-1;
        text-align: center;
        display: inline-block;
        padding: 2px 16px;
        font-size: $fs-small;
        line-height: 20px;
        color: $color-white-1;
      }

      .holiday {
        background-color: $color-orange-1;
      }
      .reserve-available {
        background-color: $color-blue-1;
      }
      .reserve-unable {
        background-color: $color-gray-3;
      }
      .reserve-closed {
        background-color: $color-navy-1;
      }
    }
  }

  .day-cont {
    margin-top: 8px;
  }

  .reserve-list {
    > li {
      > a {
        color: $color-blue-1;
        text-decoration: underline;
        font-size: $fs-nomal;
        line-height: 24px;
      }
    }
  }
  .txt-sun {
    color: $color-orange-1;
  }
}

.c-article {
  &.mod-reservation {
    padding: $gap-12;
    height: 384px;
    width: 100%;
    display: flex;
    .c-inner-left,
    .c-inner-right {
      height: 100%;
      overflow-x: hidden;
      overflow-y: auto;
      @include scrollType01;
    }
    .c-inner-left {
      width: 180px;
    }

    .c-inner-right {
      padding-left: 24px;
      flex-grow: 1;
    }

    .rsv-mod-list01 {
      > li {
        padding-right: 12px;
        button {
          text-align: left;
          padding: 10px 20px;
          display: block;
          min-width: 160px;
          display: block;
          border-radius: $radius-6;
          background-color: $color-white-1;
          span {
            color: $color-black-1;
            font {
              size: $fs-nomal;
            }
            line-height: $g-lineheight-2;
          }

          &.on {
            background-color: $color-navy-1;
            span {
              color: $color-white-1;
            }
          }
        }
      }
    }

    .rsv-mode-list02 {
      > li {
        padding-right: 12px;
        & + li {
          margin-top: 8px;
        }
      }
    }

    .rsv-mod-detail {
      padding: 12px;
      border: 1px solid $color-gray-5;
      border-radius: $radius-8;
      display: flex;
      width: 100%;
      align-items: center;

      color: $color-gray-2;

      .c-chkbox {
        flex-grow: 1;
      }
      .region {
        padding: 2px 16px;
      }

      .link {
        margin-left: 12px;
        padding: 2px 16px;
      }
    }
  }
}

//  ======================================== subCont

// [ETC]
#container {
  .etc-wrapper {
    min-width: 1200px;
    padding-left: 0;
    background-color: $color-gray-12;
  }
}
// + c-full-info-wrap
.c-full-info-wrap {
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
  position: relative;
  padding: {
    left: 14.285rem;
    right: 14.285rem;
  }
  height: 100%;

  .c-full-info-field {
    justify-content: space-between;
    align-items: center;
    display: flex;
    width: 100%;

    // + is-error
    &.is-error {
      .info-cont {
        line-height: 36px;
      }

      .img {
        img {
          width: 271px;
          height: 282px;
        }
      }
    }

    // + is-update
    &.is-update {
      .img {
        img {
          width: 328px;
          height: 282px;
        }
      }
    }
  }

  .c-cont-field {
    color: rgba(28, 28, 27, 0.8);

    .title {
      @include fontStyle(80px, 116px, 700);
    }
    .info-cont {
      margin-top: 40px;
      @include fontStyle(24px, 36px, 700);
    }
    .sub-cont {
      margin-top: 8px;
    }

    .co-btn {
      margin-top: 40px;
      display: inline-block;
      border-radius: $radius-8;
      min-width: 134px;
      padding: 8px 40px;
      height: 46px;

      span {
        color: $color-white-1;
        line-height: 30px;
      }
    }
  }

  .footer {
    position: absolute;
    bottom: 40px;
    left: 0;
    right: 0;
    text-align: center;
    margin: 0 auto;
  }
}

// = spinner
.spinner-field {
  position: absolute;
  top: 0%;
  left: 0;
  right: 0;
  bottom: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 100%;
  display: block;

  > div {
    @include position(50%, 50%);
  }
}
.spinner {
  border: 6px solid #e7e9ea;
  border-radius: 50%;
  //  border-right: 10px solid $color-blue-1;
  width: 58px;
  height: 58px;
  animation: spinner 1.5s linear infinite;
  position: relative;
  &:after {
    content: '';
    background: url($image-path + 'spinner.svg') 0 0/28px auto no-repeat;
    display: inline-block;
    width: 28px;
    height: 28px;
    position: absolute;
    top: -6px;
    left: 23px;
  }
}

@keyframes spinner {
  0% {
    transform: rotate(0);
  }
  100% {
    transform: rotate(360deg);
  }
}

// ================================================= STYLE

/*
 = Login
 */
#container {
  .login-wrapper {
    padding-left: 0;
    min-height: 800px;
    height: inherit;
  }
}
.login-wrapper {
  .login-wrap {
    display: flex;
    width: 100%;
    height: 100%;
    flex: 1 0 auto;
  }
  .side-area {
    // width: 560px;
    // min-width: 560px;
    // height: 100%;
    // display: flex;
    // align-items: center;
    // border-right: 1px solid $color-gray-5;
    // background-color: $color-white-1;
    // padding: 197px 90px;
    // position: relative;

    h1.title {
      display: block;
      width: 100%;
      text-align: left;
      font: {
        size: $p-fs-large-1;
        weight: $weight-500;
      }
      line-height: 58px;
    }
  }
  .contents-area {
    width: 100%;
    display: flex;
    flex: 1 1 100%;
    flex-direction: column;
  }
  .login-img {
    min-height: 781px;
  }
  .login-box {
    margin-top: 20px;

    .c-input-btn-group {
      flex-wrap: wrap;
      $gap: $gap-8;
      display: flex;
      flex: 1 1 auto;
      margin: {
        left: -$gap;
        right: -$gap;
      }

      [class|='col'] {
        padding: {
          left: $gap;
          right: $gap;
        }
      }
    }

    .login-input {
      input[type='text'],
      input[type='password'],
      .c-input-btn-group {
        width: 100%;
        flex: 0 0 0;

        & + input[type='text'],
        & + input[type='password'] {
          margin-top: 16px;
        }
      }

      .row {
        width: 100%;
      }

      .search-btn-group {
        position: relative;
        &.row {
          width: auto;
        }
        $gap: 8px;
        margin: {
          left: 0;
          right: 0;
        }

        .col {
          padding: {
            left: $gap;
            right: $gap;
          }
        }
        .input-button {
          flex: 0 0 80px;
          button {
            width: 80px;
          }
        }
        .c-input-search {
          input[type='text'] {
            width: 100%;
            flex: 1;
          }
        }
        & ~ input[type='text'],
        & ~ input[type='password'] {
          margin-top: 12px;
        }
      }

      .search-related-field {
        position: absolute;
        top: 48px;
        left: 0;
        right: 0;
        z-index: 10;
        .search-list {
          background-color: $color-white-1;
          border-radius: 8px;
          border: 1px solid $color-gray-5;
          overflow: hidden;
          box-shadow: 2px 2px 4px rgba(0, 0, 0, 0.25);
          min-width: 100%;
          padding: {
            top: 4px;
            bottom: 4px;
          }

          > li {
            & ~ li {
              border-top: 1px solid $color-gray-5;
            }
            a {
              padding: 7px 20px;
              display: block;
              color: $color-gray-1;
              @include coBodyTxt;
              &:hover {
                color: $color-navy-1;
              }
            }
          }
        }
      }
    }

    .info-cont {
      color: $color-gray-2;
      margin-top: 12px;
    }

    .info-chk {
      margin-top: 40px;
      &:after {
        @include after;
      }

      .c-chkbox {
        float: left;
        .chk-label {
          line-height: $p-lineheight-normal;
        }
        & + .link-guide {
          margin-top: 5px;
        }
      }

      .link-guide {
        float: right;
      }
    }

    .c-btn {
      &.btn-login {
        margin-top: 40px;
        border-radius: $radius-4;
        width: 100%;
        padding: {
          top: 16px;
          bottom: 16px;
        }
        color: $color-white-1;
        line-height: 20px;
      }
    }
  }

  .link-guide {
    font: {
      size: $fs-small;
    }
    color: $color-blue-1;
    line-height: 1.6;
    display: inline-block;
    text-decoration: underline;
  }

  .login-btn {
    width: 100%;
    color: $color-white-1;
    background-color: $color-navy-1;
    margin-top: $gap-12;
    font-size: 0;
    height: auto;
    padding: {
      top: 16px;
      bottom: 16px;
    }
    > span {
      @include fontStyle(14px, 20px, 300);
    }
  }

  .login-info-list {
    margin: 20px auto 0;
    display: table;
    &:after {
      @include after;
    }

    > li {
      color: $color-gray-3;
      @include coTxtCaption;
      float: left;
      position: relative;
      & ~ li {
        padding-left: 16px;
        &:before {
          position: absolute;
          top: 6px;
          left: 8px;
          content: '';
          width: 1px;
          height: 15px;
          display: inline-block;
          background-color: $color-gray-4;
        }
      }
      > a,
      > button {
        display: inline-block;
      }
    }
  }

  .use-info {
    margin-top: 100px;
    text-align: center;
    p {
      color: $color-gray-1;
      @include coBodyTxt;
      &:first-child {
        > span {
          font-weight: $weight-400;
        }
      }
      & ~ p {
      }
    }
  }

  .btm-logo {
    position: absolute;
    bottom: 20px;
    @include position(50%, initial);

    > .copyright {
      margin-top: 20px;
      color: $color-black-op4;
    }
  }

  height: 100%;
  border-left: 1px solid $color-gray-5;

  // .logo {
  //   display: inline-block;
  //   padding: {
  //     top: 18px;
  //     bottom: 18px;
  //   }
  //   > div {
  //     img {
  //       width: auto;
  //       height: 26px;
  //       max-height: 26px;
  //     }
  //   }
  // }

  .contents-field {
    width: 100%;
    height: 100%;
    overflow: hidden;
    > .login-img {
      background-color: #d3d7e6;
      display: flex;
      height: 100%;
      flex: 1 0 0;
      flex-direction: column;
      justify-content: flex-end;
      align-items: center;
      //padding-top: 19.44%;
      .c-title {
        width: 100%;
        white-space: nowrap;
        text-align: center;
        text-align: center;
        color: $color-navy-1;
        display: flex;
        flex-direction: column;
        flex: 1 0 auto;
        justify-content: center;

        > p {
          @include fontStyle(32px, 46px, 300);
          font: {
            size: 1.667vw;
            weight: 300;
          }
          line-height: 1.437;
        }
        > em {
          font: {
            size: 3.25vw;
            // size: 60px;
            weight: 700;
          }
          line-height: 1.467;
        }

        @media screen and (min-width: 1920px) {
          > p {
            font-size: 32px;
          }

          > em {
            font-size: 60px;
          }
        }
      }
      .reg-img {
        width: 100%;
        height: 100%;
        // background: {
        //   position: center;
        //   size: cover;
        //   repeat: no-repeat;
        // }
      }
      .img {
        text-align: center;
        img {
          width: 40vw;
          max-width: 40vw;
          //  max-height: 53vh; //  @include calcPercent(1030px, 768px);
          // min-width: 768px;
          // min-width: 768px;
          @media screen and (min-width: 1920px) {
            min-width: initial;
            width: auto;
            max-height: 53vh;
          }
        }
      }
    }
  }

  .login-categeory {
    margin-top: 20px;
    width: 100%;

    & + .login-box {
      margin-top: 16px;
    }
  }
}

// = 검진 결과 별도 창
#container {
  &.rsv-exam-container {
    .content-wrapper {
      padding-left: 0;
    }

    .header {
      padding-left: 0;
    }

    .co-title-field {
      &.top {
        display: block;
        .title {
          padding-bottom: 20px;
          border-bottom: 1px solid $color-black-op1;
        }
      }
    }

    .reserve-contents-area {
      position: relative;
      .co-title-field {
        &.top {
          padding: 40px 60px 0;
        }
      }
    }

    .top-sub-cont {
      margin: {
        bottom: 40px;
      }
    }

    .contents {
      max-width: 2560px;
    }

    .footer {
      .container {
        max-width: 2560px;
      }
    }
  }

  .top-header {
    // width: 100%;
    // display: flex;
    // align-items: center;
    // height: 80px;
    // padding: 22px 40px;
    // border-bottom: 1px solid $color-gray-5;
    // background-color: $color-white-1;
    &.type-kmi {
      .logo {
        img {
          height: 22px;
        }
      }
    }

    .slogan {
      padding-left: 20px;
      p {
        @include coMainTitle;
        color: $color-gray-1;
      }
    }
  }
}

// = Join
.join-login-field {
  .co-terms-chk-list {
    margin-top: 40px;
  }
  .terms-all-chkbox {
    & + .co-terms-chk-list {
      margin-top: 20px;
    }
  }

  .co-article {
    margin-top: 40px;
  }
  .c-input-list-basic {
    & + .co-terms-detail {
      margin-top: $gap-20;
    }
  }
  .info-cont-box {
    margin-top: 12px;
    color: $color-gray-2;
  }

  // + step02
  &.step02 {
    .co-cont-article {
      margin-top: 32px;
    }
  }
  //+ step03
  &.step03 {
    .c-input-list-basic {
      & + .co-cont-article {
        margin-top: $gap-40;
      }
    }

    .c-input-list-basic {
      margin-top: 32px;

      > .row {
        & ~ .row {
          padding-top: 0;
        }
      }
    }
  }

  // + step04
  &.step04 {
    .co-cont-article.type01 {
      margin-top: 40px;
    }
    .c-input-list-basic {
      margin-top: 40px;
    }
    .c-input-list-view {
      margin-top: 20px;
    }
  }

  .cont-box {
    & + .c-input-list-basic {
      margin-top: 32px;
    }
  }
}

// = Find Idpw
.find-accinfo-field {
  .co-tab-list {
    & + .tab-cont-wrap {
      margin-top: 40px;
    }
  }
}

// = client-card
.client-card {
  position: relative;
  min-height: 160px;
  margin-top: 20px;
  padding: 31px 20px;
  border: 1px solid $color-gray-5;
  background-color: $color-white-1;
  border-radius: $radius-12;

  .none-data {
    .img {
      width: 63px;
      height: auto;
      margin: 0 auto;
    }
    .co-none-txt {
      margin-top: 12px;
    }
  }

  .relationship {
    @include coTxtSmall01;
    color: $color-blue-1;
    display: flex;
    align-items: center;
  }

  .client-info {
    display: flex;
    align-items: center;
    .name {
      @include coMainTitle;
      color: $color-black-1;
    }
    .reg-number {
      padding-left: 12px;
      @include coTxtCaption;
      color: $color-gray-14;
    }
  }

  .detail-info {
    margin-top: 12px;
    padding: {
      top: 12px;
      bottom: 8px;
    }
    border-top: 1px solid $color-gray-5;
    > ul {
      > li {
        display: flex;
        align-items: center;
        @include coTxtSmall01;
        @include ellipsisLine01;
        & ~ li {
          padding-top: 4px;
        }
      }
      li {
        span {
          color: $color-gray-3;
        }
        > p {
          color: $color-black-1;
          padding-left: 12px;
        }
      }
    }
  }
  .buttons {
    margin-top: 12px;
  }
}

// + ctg-list swiper
.ctg-list-slide-wrap {
  margin-top: 12px;
  width: 100%;
  overflow: hidden;
  position: relative;
  .swiper-wrapper {
    width: 268px;
    position: relative;
  }
  .category-swiper {
    position: relative;

    .co-badge {
      display: inline-block;
      border-radius: 12px;
      color: $color-navy-1;
      background-color: $color-gray-8;
    }
    .swiper-slide {
      width: auto;
      display: flex;
      justify-content: center;
      align-items: center;
      margin-right: 8px;
    }
  }
}

//= Main
.main-container {
  .footer {
    min-width: 1588px;
  }
}
.contents-area.main-contents-area {
  // none-data
  .co-list-field {
    &.main-notice {
      position: relative;
      min-height: 226px;
    }

    &.none-data {
      > div {
        @include position(50%, 50%);
      }
    }
  }

  // @
  // @include responsive(laptop) {
  //   min-width: initial;
  //   padding: 40px 32px;
  // }

  .main-notice-list {
    > li {
      height: 46.8px;
      &:hover {
        background-color: $color-white-3;
      }
    }
    .co-badge {
      min-width: 50px;
      text-align: center;
    }
  }

  .main-card {
    > .co-title-field {
      margin-bottom: 12px;
    }
  }
}

.main-notice {
  .none-data {
    // .main-notice-list {
    //   display: none;
    // }
  }
}

// = rolling Banner

// + rolling banner
.rolling-banner {
  border: 1px solid $color-gray-5;
  border-radius: 12px;
  position: relative;
  padding: 2px 20px;
  .list {
    position: relative;
    height: 30px;
    overflow: hidden;
    padding-right: 64px;
    color: $color-gray-1;
    > li {
      @include ellipsisLine01;
      display: flex;
      align-items: center;
      height: 30px;

      .co-badge {
        color: $color-navy-1;
        margin-right: 20px;
      }
    }
  }

  .rolling-btn-group {
    position: absolute;
    top: 8px;
    right: 20px;

    .buttons {
      &:after {
        @include after;
      }
    }
    button {
      float: left;
      & + button {
        margin-left: 4px;
      }
    }
  }

  // + type02
  &.type02 {
    border: 0;
    padding: 0;

    .rolling-btn-group {
      right: 0;
    }
  }
}

// = Main Slide
.main-slide-swiper {
  overflow: hidden;
  .slide {
    img {
      width: 100%;
    }
  }
  .slide-card {
    position: relative;
    //   padding-bottom: 23.33%;
    width: 100%;
    border-radius: $radius-12;
    overflow: hidden;
    height: 280px;
    max-height: 280px;
    background: {
      position: center;
      repeat: no-repeat;
      size: cover;
    }
  }

  .main-banner-pagination {
    position: absolute;
    left: 0;
    width: 100%;
    text-align: center;
    bottom: 12px;
    z-index: 10;

    .swiper-pagination-bullet {
      background-color: $color-gray-6;
      opacity: 1;
      margin: 0 6px;
      &.swiper-pagination-bullet-active {
        width: 20px;
        border-radius: 20px;
        background-color: $color-blue-1;
      }
    }
  }
}

// + main-card
.main-card {
  .card-cont {
    overflow: hidden;
    position: relative;
    display: flex;
    align-items: center;
    flex: 1 0 auto;
    padding: 20px 32px;
    width: 100%;
    border: 1px solid $color-gray-5;
    border-radius: $radius-12;
    background-color: $color-white-1;
    height: 194px;

    .cont-inner {
      display: block;
      width: 100%;
      overflow: hidden;
    }
    // - type01
    &.type01 {
      text-align: center;
      .img {
        margin: 0 auto;
      }

      .title-add {
        margin-top: 12px;
        display: block;
        span {
          @include fontStyle(20px, 36px, 400);
          color: $color-navy-1;
        }

        .c-plus-btn-1 {
          display: inline-block;
          vertical-align: top;
          margin-top: 4px;
        }
      }
      .ref-txt {
        @include coTxtSmall01;
        color: $color-gray-3;
      }
    }

    // - type02
    &.type02 {
      padding: 0;
      .inner {
        position: relative;
        overflow: hidden;
        > a {
          padding: 20px 32px;
          overflow: hidden;
          display: table;
          table-layout: fixed;
          width: 100%;
          position: relative;
          transition: background-color 0.4s;

          &:hover {
            background-color: $color-white-3;
          }
        }
        & ~ .inner {
          border-top: 1px solid $color-gray-5;
        }

        .ico,
        .cont {
          display: table-cell;
          vertical-align: middle;
        }

        &:after {
          position: absolute;
          top: 50%;
          right: 32px;
          transform: translateY(-50%);
          display: inline-block;
          width: 28px;
          height: 28px;
          content: '';
          background: url($image-ico-path + '/ico_arrow_13.svg') center/ 28px no-repeat;
        }
      }
      .ico {
        width: 44px;
        div {
          background-color: $color-gray-8;
          border-radius: 12px;
          margin: 0 auto;
          width: 44px;
          height: 44px;
          position: relative;
          img {
            @include position(50%, 50%);
          }
        }
      }
      .cont {
        padding-left: 20px;
        .title {
          @include coMainTitle;
          color: $color-gray-1;
        }
        .go-link {
          @include coTxtSmall01;
          color: $color-navy-1;
        }
      }
    }

    // - type03
    &.type03 {
      padding: 30px 28px;
    }
  }
  .customer-service {
    .title {
      @include coMainTitle;
    }
    p .tel,
    .time {
      display: block;
    }
    .tel {
      @include fontStyle(30px, 32px, 700);
      color: $color-blue-1;
      display: block;
      margin-bottom: 16px;
    }
    .time {
      @include coBodyTxt;
      color: $color-black-1;
      > span {
        color: $color-gray-2;
      }
      & + p {
        margin-top: 12px;
        color: $color-gray-1;
        // white-space: nowrap;
      }
    }
  }
}

.main-center-introduce {
  position: relative;
  .center-pagination,
  .main-center-pagination {
    position: absolute;
    top: 8px;
    right: 0;
    left: auto;
    bottom: auto;
    width: auto;
    @include fontStyleSmall01;
  }

  // + none-data
  .co-list-field.none-data {
    height: 234px;
  }
}

// + center-introduce-wrapper
.center-introduce-wrapper {
  .tab-cont-wrap {
    margin-top: 12px;
  }
}

.main-popup-field {
  .cont-box {
    & + .c-input-list-basic {
      margin-top: 40px;
    }
  }
}

// = Swiper Slide Custom
// + common
.swiper {
  .co-slide-cont {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
  }
  .inner {
    height: 100%;
    width: 100%;
  }
  .swiper-slide img {
    display: block;
    width: 100%;
    height: 100%;
  }

  .swiper-button-disabled {
    opacity: 1;
  }
}

// + main Slide

.main-swiper-field {
  margin-top: 20px;
  // is-loading
  &.is-loading {
    position: relative;
    .spinner-field {
      border: 1px solid $color-gray-5;
      border-radius: $radius-12;
      overflow: hidden;
    }
  }
}
.main-slide-swiper {
  position: relative;
  overflow: hidden;
  border-radius: $radius-12;
  height: 280px;
  &.swiper {
    position: relative;
  }
  // swiper
  .swiper-slide {
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: $color-white-2;
    border-radius: 12px;
    overflow: hidden;
  }

  .txt-cont {
    display: flex;
    width: 100%;
    height: 100%;
    align-items: center;
    text-align: left;
    flex-direction: column;
    justify-content: center;
    align-items: flex-start;
    padding: {
      left: 80px;
      right: 80px;
    }
  }

  .sub-title-01 {
    @include fontStyle(16px, 32px, 400);
  }

  .title-01 {
    @include fontStyle(32px, 46px, 700);
    margin-top: 12px;
  }

  .cont-01 {
    margin-top: 4px;
    @include fontStyle(20px, 29px, 300);
  }

  .swiper-button-field {
    // position: absolute;
    // top: 0;
    // width: 102px;
    // height: 280px;
    // z-index: 1;
    // border-radius: 12px;

    // cursor: pointer;

    // &:before {
    //   width: inherit;
    //   height: inherit;
    //   display: inline-block;
    //   border-radius: 12px;
    //   top: 0;
    //   opacity: 0;
    //   content: '';
    //   transition: opacity 0.5s;
    // }

    &:hover {
      &:before {
        opacity: 1;
      }
    }

    &.prev {
      left: 0;
      &:hover {
        &:before {
          background: linear-gradient(90deg, rgba(25, 25, 25, 0.1) 0%, rgba(217, 217, 217, 0) 38.05%);
        }
      }
    }
    &.next {
      right: 0;
      &:hover {
        &:before {
          content: '';
          background: linear-gradient(90deg, rgba(25, 25, 25, 0.1) 0%, rgba(217, 217, 217, 0) 38.05%);
          transform: rotate(-180deg);
        }
      }
    }
  }

  .main-slide-btn-prev,
  .main-slide-btn-next {
    // top: calc(50% + 28px);
    // transform: translateY(-50%);
    position: absolute;
    top: 0;
    width: 102px;
    height: 280px;
    z-index: 1;
    border-radius: 12px;
    cursor: pointer;
    background: none;

    > div {
      position: relative;
    }

    .ico {
      position: absolute;
      display: inline-block;
      width: 28px;
      height: 28px;
      background: {
        image: url($image-ico-path + 'ico_sprite_slide_arrow.svg');
        size: 28px auto;
        repeat: no-repeat;
      }
      top: 50%;
      transform: translateY(-50%);
    }
  }

  .main-slide-btn-prev {
    left: 0;
    .ico {
      left: 20px;
      background-position: 0 0px;
    }
    &:hover {
      &:before {
        position: absolute;
        top: 0;
        left: 0;
        bottom: 0;
        right: 0;
        content: '';
        background: linear-gradient(90deg, rgba(25, 25, 25, 0.1) 0%, rgba(217, 217, 217, 0) 38.05%);
      }
      .ico {
        background-position: 0 -76px;
      }
    }
  }
  .main-slide-btn-next {
    right: 0;
    .ico {
      right: 20px;
      background-position: 0 -38px;
    }
    &:hover {
      &:before {
        position: absolute;
        top: 0;
        left: 0;
        bottom: 0;
        right: 0;
        content: '';
        background: linear-gradient(90deg, rgba(25, 25, 25, 0.1) 0%, rgba(217, 217, 217, 0) 38.05%);
        transform: rotate(-180deg);
      }

      .ico {
        background-position: 0 -114px;
      }
    }
  }
}

// + main-center-swiper-01
.main-center-slide-field {
  min-height: 186px;
  overflow: hidden;
  border-radius: 12px;
  position: relative;

  .main-center-swiper {
    width: auto;
  }
  .swiper {
    width: 100%;
    height: 100%;
  }

  .swiper-slide {
    text-align: center;
    font-size: 18px;
    background: #fff;

    /* Center slide text vertically */
    display: flex;
    justify-content: center;
    align-items: center;
  }

  .swiper-slide img {
    display: block;
    width: 100%;
    height: 100%;
    object-fit: cover;
  }

  .swiper-slide {
    width: 360px;
    height: 234px;
    img {
      width: 100%;
      height: 100%;
    }
  }

  .swiper-slide img {
    display: block;
    width: 100%;
    height: 100%;
    object-fit: cover;
  }
  .tab-content {
    overflow: hidden;
  }

  .slide-card {
    width: 100%;
    position: relative;
    border-radius: $radius-12;
    overflow: hidden;
    min-height: 234px;
  }

  .swiper-button-prev,
  .swiper-button-next {
    position: absolute;
    top: calc(50% + 28px);
    transform: translateY(-50%);
  }

  .swiper-button-prev {
    left: 0;
  }
  .swiper-button-next {
    right: 0;
  }

  .none-data {
    position: relative;
    display: flex;
    flex: 1 0 auto;
    height: 100%;
    justify-content: center;
    align-items: center;
    background-color: $color-gray-12;
    height: 186px;

    &:before {
      content: '';
      position: absolute;
      bottom: 11px;
      right: 23px;
      width: 131px;
      height: 129px;
      background: url($image-logo-kmi-path + 'kmi_05.svg') center/100% no-repeat;
      opacity: 0.5;
    }
  }
}

// [RC]
.consult-detail {
  @at-root .co-title-field {
    & + .consult-detail {
      margin-top: 12px;
    }
  }
  padding: 20px;
  border-radius: $radius-12;
  border: 1px solid $color-gray-5;

  dl {
    & ~ dl {
      margin-top: 24px;
    }

    dt {
      @include fontStyleSmall01;
    }
    dd {
      margin-top: 4px;
    }
  }
}

.co-table-field {
  + .co-terms-chk-card {
    margin-top: 20px;
  }
  .tbl-chkresult-compare {
    margin-top: 0;
  }
}

// = checkup-institution-wrap

.institution-info-list-field {
  .list-field {
    margin-top: 20px;
  }

  .institution-info-list {
    display: flex;
    flex-wrap: wrap;
    $gap: 20px;
    margin : {
      left: -$gap;
      right: -$gap;
    }

    > li {
      padding: $gap;
      width: 25%;
    }
  }

  .institution-info-card {
    position: relative;

    .thumbnail {
      height: 160px;
      position: relative;
      overflow: hidden;
      border-radius: $radius-12;
      img {
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
      }
    }

    .cont {
      padding-top: 20px;
      font-size: 0;
    }
    .location {
      position: relative;
      @include fontStyle(12px, 20px, 300);
      color: $color-blue-1;
      padding-left: 17px;

      &:before {
        @include position(0, 50%);
        display: inline-block;
        width: 10px;
        height: 12px;
        background: url($image-ico-sprite01) -807px -8px / $image-ico-sprite01-size no-repeat;
        content: '';
      }
    }

    .title {
      @include coSubTitle;
      @include ellipsisLine01;
    }

    &.none-data {
      position: relative;
      height: 270px;
      padding: 67px 0;
      > div {
        @include position(50%, 50%);
      }
    }

    &.co-none-data {
      position: relative;
      height: 232px;
      padding: 80px 0;
      border-radius: $radius-12;
      background-color: $color-white-2;
      > div {
        position: absolute;
        left: 0;
        width: 100%;
        top: 50%;
        transform: translateY(-50%);
      }
      .co-none-txt {
        margin-top: 12px;
      }
    }
  }
}

// = 검진기관

// [HI]
// = checkup-item-wrap
.checkup-item-wrap {
  .co-tab-wrap {
    margin-top: 20px;
  }
}

.checkup-item-list-field {
  padding-top: 6px;
  margin: {
    left: -20px;
    right: -20px;
  }
}
.checkup-item-list {
  display: flex;
  flex-wrap: wrap;
  width: 100%;
  > li {
    width: calc(100% / 6);
    padding: 14px 20px;
  }

  a {
    display: block;
    text-align: center;
    margin: 0 auto;
  }

  .items-card {
    border-radius: 12px;
    height: 140px;
    color: $color-gray-2;
    border: 1px solid $color-gray-5;
    background-color: $color-white-1;
    display: flex;
    align-items: center;
    justify-content: flex-start;
    padding-top: 27px;
    text-align: center;
    flex-direction: column;

    i {
      height: 46px;
      > img {
        height: 100%;
      }
    }
    &:hover {
      background-color: $color-gray-7;
    }

    .name {
      @include coSubTitle;
      color: $color-gray-2;
      margin-top: 4px;
    }
    .sub-txt {
      @include coTxtSmall01;
      color: $color-gray-2;
    }
  }
}
.checkup-item-detail-field {
  .img {
    border-radius: $radius-12;
    background-color: $color-gray-13;
    overflow: hidden;
    height: 260px;

    img {
      width: 100%;
      height: 100%;
    }
  }
  .datail-card {
    margin-top: 20px;
    .title {
      @include coSubTitle;
      color: $color-black-1;
    }

    .cont {
      margin-top: 12px;
      border: 1px solid $color-gray-5;
      border-radius: $radius-12;
      padding: 20px;
      color: $color-gray-1;
    }

    .co-list-card > li {
      color: $color-gray-1;
    }

    & + .co-ref-txt {
      margin-top: 12px;
    }
  }
}

// = video-list-field

.video-list-field {
  margin-top: 20px;
}

.video-list {
  display: flex;
  flex-wrap: wrap;
  $gap: 20px;
  margin : {
    left: -$gap;
    right: -$gap;
  }

  > li {
    padding: $gap;
    position: relative;
    width: 25%;
  }
}

.video-card {
  position: relative;
  background-color: $color-white-1;
  border: 1px solid $color-black-op1;
  border-radius: $radius-12;
  overflow: hidden;

  &.co-none-data {
    @include cardListNoneData;
    padding-bottom: calc(50.704% + 90px);
  }

  .thumbnail {
    //height: 180px;

    padding-bottom: 50.704%;
    position: relative;
    overflow: hidden;

    .img {
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      bottom: 0;
      height: inherit;
      background: {
        position: center;
        size: cover;
        repeat: no-repeat;
      }
    }

    img {
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
    }
  }

  .cont {
    padding: 20px;
    font-size: 0;
  }
  .date {
    @include fontStyle(12px, 20px, 300);
    color: $color-blue-1;
  }

  .title {
    color: $color-gray-1;
    @include ellipsisLine01;
    @include coBodyTxt;
  }

  &.none-data {
    position: relative;
    height: 270px;
    padding: 67px 0;
    > div {
      @include position(50%, 50%);
    }
  }
}

// = board-detail-field
.board-detail-field {
  .title-field {
    padding: 20px 0;
    border-bottom: 1px solid $color-black-op1;
  }
  .title {
    text-align: center;
    @include coMainTitle;
  }
}

.board-detail-content {
  padding: 20px 0;
  border-bottom: 1px solid $color-black-op1;
  .detail-info {
    display: flex;
    justify-content: flex-end;
    align-items: center;
    color: $color-gray-3;

    .view-cnt {
      p {
        position: relative;
        &:before {
          display: inline-block;
          margin-right: 8px;
          content: '';
          width: 11px;
          height: 8px;
          background: url($image-ico-path + 'ico_veiw_01.svg') center/11px auto no-repeat;
        }
      }
    }

    .date {
      padding-left: 29px;
      position: relative;
      &:before {
        position: absolute;
        left: 13px;
        content: '/';
      }
    }
  }

  .view-video {
    border-radius: $radius-12;
    overflow: hidden;
    background-color: $color-gray-13;
    padding-bottom: 20px;
    height: 559px;
  }
  .buttons {
    justify-content: center;
    padding: 20px 0;
  }
}

.datail-inner-cont {
  margin-top: 20px;
  padding-bottom: 20px;
  min-height: 200px;
}

.btm-nav-board {
  padding: 20px 0;
  border-bottom: 1px solid $color-black-op1;
  display: flex;
  align-items: center;
  justify-content: space-between;
  .col {
    width: 50%;
    padding: 20px;
    text-align: center;
    color: $color-gray-2;
    @include coSubTitle;
    max-width: 50%;

    i {
      margin-top: 20px;
      width: 28px;
      height: 28px;
      background: {
        image: url($image-ico-sprite01);
        size: $image-ico-sprite01-size;
        repeat: no-repeat;
        position: -190px 0;
      }

      &.prev {
        transform: rotate(-180deg);
      }
    }

    &:first-child {
      border-right: 1px solid $color-black-op1;
    }

    a {
      display: inline-block;
    }

    a:hover {
      color: $color-navy-1;

      i {
        background-position-y: -38px;
      }
    }
  }
}

// + visiting-center-section
.visiting-center-section {
  $gap: 20px;
  > .row {
    margin: {
      left: -$gap;
      right: -$gap;
    }
  }
  .left-wrap,
  .right-wrap {
    padding: {
      left: $gap;
      right: $gap;
    }
  }

  // .right-wrap {
  //   width: 500px;
  //   flex: 0 0 500px;
  // }

  .right-wrap {
    position: relative;
    .floating-box {
      position: absolute;
      top: 0;
      right: 20px;
      left: 20px;
    }
  }
}
.visiting-center-list {
  > li {
    & ~ li {
      margin-top: 40px;
    }
  }
}

.visit-center-card {
  border-radius: 12px;
  border: 1px solid $color-gray-5;
  overflow: hidden;
  padding: 24px 16px 24px 28px;
  background-color: $color-white-2;

  .inner {
    display: flex;
    flex: 0 0 0;
    align-items: center;
  }

  .cont-box {
    padding-right: 40px;
    .title-box {
      display: flex;
      align-items: center;
      .title {
        @include coMainTitle;
        color: $color-black-1;
      }
    }
  }
  .thumbnail {
    width: 148px;
    flex: 0 0 148px;
    border-radius: 12px;
    overflow: hidden;
    img {
      width: 100%;
      height: 100%;
    }
  }

  .is-new,
  .is-hot {
    margin-left: 8px;
    display: inline-block;
    @include fontStyle(12px, 20px, 300);
    padding: 2px 8px;
    border-radius: 8px;
  }

  .is-new {
    color: #347928;
    background-color: #a4f897;
  }
  .is-hot {
    color: $color-orange-1;
    background-color: #ffd4d0;
  }

  .cont {
    margin-top: 4px;
    p {
      color: $color-gray-3;
      @include ellipsisMultiline(2, 30px, 60px, 60px);
    }
  }
  .hashtag-list {
    display: flex;
    flex-wrap: wrap;
    flex: 1 0 auto;
    margin: {
      left: -4px;
      right: -4px;
    }

    > li {
      padding: 4px;
    }
  }

  &.none-data {
    height: 198px;
    text-align: center;
    display: flex;
    align-items: center;
    justify-content: center;

    .none-txt {
      @include coSubTitle;
      color: $color-gray-3;
    }
  }
}

.co-inner-cont-article {
  $gap: 10px;
  .hashtag-list {
    display: flex;
    flex-wrap: wrap;
    flex: 1 0 auto;
    margin: {
      left: -$gap;
      right: -$gap;
    }

    > li {
      padding: 6px 10px;
    }
  }
}

// [NI]

.event-tab-wrap {
  margin-top: 40px;
}

.event-list-field {
  .event-list {
    display: flex;
    flex-wrap: wrap;
    $gap: 20px;
    margin : {
      left: -$gap;
      right: -$gap;
    }

    > li {
      padding: $gap;
      width: 25%;
      position: relative;
    }
  }
}

.event-card {
  position: relative;
  background-color: $color-white-1;
  // border: 1px solid $color-black-op1;
  border-radius: $radius-12;
  overflow: hidden;

  .thumbnail {
    //height: 200px;
    padding-bottom: 56.338%;
    position: relative;
    overflow: hidden;
    border-radius: 12px;
    border: 1px solid $color-gray-7;
    .img {
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      bottom: 0;
      height: inherit;
      background: {
        position: center;
        size: 100% 100%;
        repeat: no-repeat;
      }
    }
    img {
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
    }
  }

  .cont {
    padding: 20px 0;
    font-size: 0;
  }
  .date {
    @include fontStyle(12px, 20px, 300);
    color: $color-blue-1;
  }

  .title {
    color: $color-gray-1;
    @include ellipsisLine01;
    @include coBodyTxt;
  }

  &.co-none-data {
    @include cardListNoneData;
    padding-bottom: calc(56.338% + 90px);
    border: 1px solid $color-gray-5;
  }

  &.is-end {
    &:after {
      content: '';
      position: absolute;
      top: 0;
      bottom: 0;
      left: 0;
      right: 0;
      background-color: $color-white-1;
      opacity: 0.5;
    }
  }
}

.detail-download-field {
  margin-top: 20px;

  .download-list {
    overflow: hidden;
    border-radius: $radius-12;
    border: 1px solid $color-gray-5;
    > li {
      padding: 14px 32px;
      display: flex;
      align-items: center;
      justify-content: space-between;
      &:nth-child(odd) {
        background-color: $color-white-1;
      }
      &:nth-child(even) {
        background-color: $color-white-2;
      }
    }

    .file-info {
      color: $color-gray-1;

      .file-size {
        margin-left: 8px;
        @include coTxtSmall01;
        color: $color-gray-3;
      }
    }
  }
  .btn-file-download {
    @include fontStyle(14px, 24px, 300);
    color: $color-gray-3;
    position: relative;
    &:after {
      display: inline-block;
      width: 28px;
      height: 28px;
      background: url($image-ico-sprite01) -1026px 0 / $image-ico-sprite01-size no-repeat;
      content: '';
      vertical-align: middle;
    }
  }
}

//= notice-tab-wrap {
.notice-tab-wrap {
  .co-table {
    margin-top: 40px;
  }
}

.tbl-notice {
  td {
    .title {
      @include ellipsisLine01;
    }
  }
}

// = faq
.faq-list {
  > li {
    margin-top: 20px;
  }
}

// =  건강검진 기관 안내 (health-institution-wrap)

.institution-list-field {
  .list-field {
    margin-top: 20px;
  }

  .institution-list {
    display: flex;
    flex-wrap: wrap;
    $gap: 20px;
    margin : {
      left: -$gap;
      right: -$gap;
    }

    > li {
      padding: $gap;
      width: 25%;
      position: relative;
    }

    & + .co-tbl-paging {
      margin-top: 20px;
    }
  }

  .institution-card {
    position: relative;
    .thumbnail {
      //height: 160px;
      padding-bottom: 45.07%;
      position: relative;
      overflow: hidden;
      border-radius: $radius-12;
      .img {
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        bottom: 0;
        height: inherit;
        background: {
          position: center;
          size: cover;
          repeat: no-repeat;
        }
      }
      img {
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
      }
    }

    .cont {
      padding-top: 20px;
      font-size: 0;
    }
    .location {
      position: relative;
      @include fontStyle(12px, 20px, 300);
      color: $color-blue-1;
      padding-left: 17px;

      &:before {
        @include position(0, 50%);
        display: inline-block;
        width: 10px;
        height: 12px;
        background: url($image-ico-sprite01) -807px -8px / $image-ico-sprite01-size no-repeat;
        content: '';
      }
    }

    .title {
      @include coSubTitle;
      @include ellipsisLine01;
    }

    &.none-data {
      border-radius: 12px;
      background-color: $color-white-2;
      @include cardListNoneData;
      position: absolute;
      top: 0;
      left: 0;
      right: 0;
      bottom: 0;
    }

    > a {
      cursor: pointer;
    }
  }
}

.rsv-institution-datail-field {
  display: none;
  margin: {
    top: 20px;
    bottom: 20px;
  }
  padding: 40px 20px 40px 40px;
  border-radius: 12px;
  background-color: $color-white-2;

  .co-title-field {
    padding: {
      right: 24px;
      bottom: 20px;
    }
  }
  .title-box {
    display: flex;
    align-items: center;
    .title {
      @include coMainTitle;
    }

    // #
    .co-link-2,
    .co-info-txt {
      margin-left: 12px;
    }
    .co-caption-txt {
      color: $color-gray-14;
      margin-left: 12px;
    }

    .co-info-txt {
      @include coBodyTxt;
    }
    .info01 {
      margin-left: 8px;
    }
  }

  &.on {
    display: block;
  }
}

.institution-datail-list {
  @include scrollType03;
  > li {
    & + li {
      margin-top: 20px;
    }
  }
}
.institution-datail-list-article {
  height: 700px;
  padding-right: 20px;
  overflow-x: hidden;
  overflow-y: scroll;
}

.institution-detail-card {
  position: relative;
  background-color: $color-white-1;
  border-radius: 12px;
  margin: 2px;
  border: 1px solid $color-gray-5;
  //box-shadow: 0 0 2px $color-gray-5;
  overflow: hidden;
  padding: 32px 40px;
  &.on {
    box-shadow: 0 0 0 2px $color-blue-1;

    border-color: $color-white-1;
    .top-box {
      > em {
        color: $color-blue-1;
      }
    }
  }

  .top-box {
    display: flex;
    justify-content: space-between;
    align-items: center;

    > em {
      @include coSubTitle;
    }
    .title {
      @include coSubTitle;
      color: $color-gray-1;
    }
    .detail-btns {
      display: flex;
      align-items: center;
      [class*='btn'] {
        display: flex;
        align-items: center;
        > span {
          padding-left: 8px;
          @include coTxtSmall01;
          color: $color-gray-3;
        }
        & ~ a {
          margin-left: 32px;
        }
      }
      .is-red {
        & + span {
          color: $color-orange-1;
        }
      }
    }
  }

  .cont {
    padding: {
      top: 12px;
      bottom: 20px;
    }

    > p {
      display: block;
      width: 100%;
      color: $color-gray-2;
      @include ellipsisMultiline(2, 30px, 60px, 60px);
    }
  }

  //#
  .middle-box {
    @include coBodyTxt;
    @include ellipsisLine01;
    margin-top: 24px;
    width: 81.29%;
  }

  .btm-box {
    border-top: 1px solid $color-black-op1;
    padding-top: 20px;
    display: flex;
    align-items: center;
    justify-content: space-between;
  }

  .price-info {
    color: $color-gray-3;
    > span {
      margin-right: 4px;
    }
    > em {
      margin: 7px 0 0 4px;
      @include coSubTitle;
      color: $color-blue-1;
    }
  }

  .special-examination {
    margin-bottom: 8px;
    > span {
      display: inline-block;
      padding: 2px 8px;
      background-color: $color-navy-1;
      color: $color-white-1;
      @include coTxtCaption;
      position: relative;
      border-radius: 8px;
      // &:before {
      //   content: '';
      //   position: absolute;
      //   left: 4px;
      //   top: 4px;
      //   background: url($image-ico-path + 'ico_special_01.svg') center/18px no-repeat;
      //   width: 18px;
      //   height: 18px;
      //   display: inline-block;
      // }
    }
  }
}

// = 검진기관 상세
.chkupInstitution-tab-wrap {
  .co-tab-list {
    margin-bottom: 12px;
  }

  .link {
    color: $color-gray-1;
    text-decoration: underline;
  }
}

// = [VGT]
.popup-institution-swiper {
  position: relative;
  overflow: hidden;
  border-radius: $radius-12;

  .swiper-slide {
    overflow: hidden;
    display: flex;
    justify-content: center;
    align-items: center;
    height: 280px;
    img {
      display: block;
      width: 100%;
      height: 100%;
      object-fit: cover;
    }
  }
  .swiper-button-prev,
  .swiper-button-next {
    $size: 24px;
    width: $size;
    height: $size;
    background: {
      image: url($image-ico-path + 'slide_arrow_04.svg');
      position: center;
      size: auto $size;
      repeat: no-repeat;
    }
    position: absolute;
    top: calc(50% + $size);
    transform: translateY(-50%);
  }

  .swiper-button-prev {
    left: 12px;
  }
  .swiper-button-next {
    right: 12px;
    transform: rotate(-180deg);
  }
}

.institution-info-list {
  > li {
    margin-top: 40px;

    ul.circle {
      list-style: disc inside;
    }
  }

  .title {
    @include fontStyle(12px, 20px, 300);
    color: $color-blue-1;
  }
  .cont-box {
    margin-top: 4px;
  }
}

.co-location-map {
  overflow: hidden;
  border-radius: $radius-12;
  height: 280px;
  background-color: #ddd;
}

// + medical-team-list
.medical-team-list {
  .list-row {
    & ~ .list-row {
      margin-top: 24px;
    }
  }
  .doctor-row {
    flex-wrap: wrap;
    flex: 1 1 auto;
    display: flex;
    margin: {
      left: -20px;
      right: -20px;
    }

    .col {
      border: 2px solid $color-white-1;
      width: 50%;
      display: flex;
      flex-direction: column;
      padding: {
        left: 20px;
        right: 20px;
      }
      flex: 0 0 50%;
      max-width: 50%;
      > a {
        display: flex;
        flex: 1 1 auto;
        height: 100%;
      }
    }
  }

  .ctg-info {
    margin-top: 4px;
    display: flex;
    align-items: flex-start;
    @include coBodyTxt;

    span {
      @include coTxtCaption;
      line-height: 30px;
      color: $color-blue-1;
      padding-right: 4px;
      flex: 0 0 auto;
    }
  }
}

.doctor-card {
  .inner {
    border-radius: $radius-12;
    background-color: $color-white-2;
    border: 1px solid $color-gray-5;
    overflow: hidden;
    padding: 20px;
    display: flex;
    align-items: center;
    flex: 1 0 0;
    align-items: flex-start;

    .img {
      border-radius: $radius-8;
      background-color: $color-white-1;
      overflow: hidden;
      width: 61px;
      height: 66px;
      flex: 0 0 61px;
    }

    .doctor-info {
      padding-left: 20px;
      @include coBodyTxt;
      @include ellipsisLine01;

      .ctg-info {
        display: block;
      }
      .title {
        display: block;
        @include coTxtCaption;
        color: $color-blue-1;
      }

      .cont {
        margin-top: 4px;
        @include coBodyTxt;
        @include ellipsisLine01;
      }
    }
  }

  &.on {
    .inner {
      box-shadow: 0 0 0 2px $color-blue-1;
      position: relative;
      &:before {
        content: '';
        position: absolute;
        top: 12px;
        right: 12px;
        display: inline-block;
        width: 18px;
        height: 18px;
        background: url($image-ico-path + 'ico_chk_blue_01.svg') center/18px no-repeat;
      }
    }
  }
}

.doctor-detail-info {
  display: none;
  border-radius: $radius-12;
  padding: 20px;
  margin-top: 24px;
  border: 1px solid $color-gray-5;
  background-color: $color-white-2;

  &.on {
    display: flex;
  }

  .col {
    & ~ .col {
      margin-left: 48px;
    }
  }

  .ctg-info {
    span {
      padding-right: 12px;
    }
  }
}

.medical-equipment-list {
  > li {
    & ~ li {
      margin-top: 40px;
    }
  }
}
.medical-equip-card {
  padding: 20px;
  border: 1px solid $color-gray-5;
  border-radius: 12px;

  .top-box {
    display: flex;
    flex: 0 0 0;

    .img {
      width: 242px;
      height: 156px;
      border-radius: 12px;
      overflow: hidden;

      flex: 0 0 242px;
    }

    .detail-info {
      padding-left: 20px;
      li {
        display: flex;
        align-items: center;
        min-height: 30px;

        & ~ li {
          margin-top: 12px;
        }
        > span {
          width: 52px;
          flex: 0 0 52px;
          color: $color-blue-1;
          @include fontStyle(12px, 20px, 300);
        }

        .cont {
          padding-left: 4px;
          color: $color-gray-1;
        }
      }
    }
  }

  .btm-box {
    margin-top: 20px;
  }

  & + .medical-equip-card {
    margin-top: 40px;
  }
}

// = 건강검진 제도
.co-table.cont-type01 {
  &.tbl-exam-apply-criteria {
    table {
      thead {
        th {
          background-color: $color-gray-8;
        }
      }
      th,
      td {
        padding: 10px;
      }
    }
  }
}

/* VGT-01-004 */
.chkup-deliver-field {
  .c-input-list-view {
    margin-top: 20px;

    & ~ .c-input-list-basic {
      margin-top: 32px;
    }
  }
}

// = 세부검진항목

// = 검진 예약
.resevation-container {
  .contents-area {
    position: relative;
  }

  .footer {
    min-width: 1500px;
  }
}

.contents-area {
  &.reserve-contents-area {
    display: flex;
    flex-direction: column;
    flex: 1 1 auto;
    justify-content: stretch;
    padding: 0;
    .co-cont-section {
      height: 100%;
    }

    .co-location-wrap {
      margin: 40px 60px 0;
    }

    .co-cont-section {
      padding: {
        left: 60px;
        right: 60px;
        bottom: 40px;
      }
    }
  }
}

// = reservation-sidebar
.reservation-sidebar {
  border-right: 1px solid $color-gray-5;
  flex-shrink: 0;
  background-color: $color-gray-12;
  max-width: 300px;
  min-width: 300px;
  min-height: 100vh;
  justify-content: stretch;
  position: relative;
  max-height: 100%;
  overflow-x: hidden;
  overflow-y: auto;
  @include scrollType03;
  .inner-wrap {
    padding: 40px;
    position: absolute;
    top: 0;
    left: 0;
    right: 0;

    @include scrollType03;

    .top-title {
      @include coSubTitle;
      color: $color-navy-1;
    }
  }

  /* RI_01_001 */
  &.rsv-sidebar-fixed {
    .inner-wrap {
      max-width: 300px;
      min-width: 300px;
      min-height: 430px;
    }
  }
}
.reservation-step-list {
  > li {
    .rsv-step {
      position: relative;
      width: 220px;
      height: 48px;
      overflow: hidden;
      width: 100%;
      display: block;
      border-radius: 12px;
      > a {
        display: block;
      }
    }
    margin-top: 12px;
    .rsv-title {
      border: 1px solid $color-gray-5;
      background-color: $color-white-1;
      border-radius: 8px;
    }
    .rsv-title {
      opacity: 1;
    }
    .rsv-data {
      background-color: $color-gray-1;
      color: $color-white-1;
      opacity: 0;
    }

    .rsv-title,
    .rsv-data {
      position: absolute;
      top: 0;
      left: 0;
      bottom: 0;
      right: 0;
      padding: 8px 20px;
      @include ellipsisLine01;
      border-radius: 12px;
      overflow: hidden;
    }
    // + is-end
    &.is-end {
      .rsv-step {
        background-color: $color-gray-8;

        .rsv-title,
        .rsv-data {
          transition: opacity 0.3s;
          &:hover {
            transition: opacity 0.3s;
          }
        }
        .rsv-title {
          background-color: $color-gray-8;
          color: $color-gray-2;
          &:hover {
            opacity: 0;
          }
        }
        .rsv-data {
          &:hover {
            opacity: 1;
          }
        }
      }
    }
    // is-now
    &.is-now {
      .rsv-title {
        color: $color-white-1;
        background-color: $color-navy-1;
        color: $color-white-1;
      }
    }
  }
}

.resevation-btmbar-wrap {
  // position: absolute;
  // left: 0;
  // right: 0;
  bottom: -40px;
  background-color: $color-white-1;
  border-top: 1px solid $color-black-op1;
  // height: 96px;
  &.fixed {
    border-left: 1px solid $color-gray-5;
    position: fixed;
    left: 0;
    bottom: 0;
    right: 0;
    z-index: 1;
    transition: bottom 0.3s ease;
  }
}

.resevation-btm-bar {
  position: relative;

  &:before {
    content: '';
    position: absolute;
    top: -41px;
    left: 0;
    right: 0;
    background: linear-gradient(to top, rgba(25, 25, 25, 0.025) 0%, rgba(217, 217, 217, 0) 38.05%);
    height: 41px;
  }

  .buttons {
    justify-content: space-between;
    flex-grow: 1;
    flex-shrink: 0;
    flex-basis: auto;
    .left,
    .right {
      font-size: 0;
    }
    .co-btn {
      & + .co-btn {
        margin-left: 18px;
      }
    }
  }
  // + type01
  &.type01 {
    padding: 20px 60px;
    min-width: 1200px;
  }

  // + type02
  &.type02 {
    padding: 20px 60px;

    .top-box {
      display: flex;
      justify-content: space-between;
      align-items: center;
    }

    .compare-product {
      display: flex;
      align-items: center;
      > p {
        margin-left: 8px;
        @include coSubTitle;
      }
      .cnt {
        color: $color-gray-2;
        line-height: 32px;
        padding-left: 4px;
      }
    }
    .buttons {
      margin-top: 20px;
    }
  }

  .btn-reset-product {
    > span {
      @include coTxtSmall01;
      color: $color-gray-2;
      position: relative;
      padding-left: 28px;
      &:before {
        content: '';
        top: 50%;
        left: 0;
        transform: translateY(-50%);
        position: absolute;
        background: url(../images/ico/ico_sprites_01.svg) -912px 0 / auto 218px no-repeat;
        width: 28px;
        height: 28px;
        display: inline-block;
        overflow: hidden;
        text-indent: 100%;
        white-space: nowrap;
        display: inline-block;
      }
    }
  }
  // + type02
  .btn-bar-toggle {
    position: absolute;
    right: 90px;
    top: -36px;
    border: 1px solid $color-gray-4;
    border-radius: 12px 12px 0px 0px;
    background-color: $color-white-1;
    display: inline-block;
    width: 60px;
    height: 36px;

    > span {
      background: url($image-ico-sprite01) -76px 0 / $image-ico-sprite01-size no-repeat;
      width: 28px;
      height: 28px;
      transform: rotate(-180deg);
    }

    &.is-open {
      > span {
        transform: rotate(0);
      }
      & ~ .btm-compare-list-field {
        display: block;
      }
    }
  }
  .btn-reservation-prev,
  .btn-reservation-next {
    span {
      &:before {
        position: absolute;
        top: -4px;
        content: '';
        background: {
          image: url($image-ico-path + 'ico_btm_bar_sprite01.svg');
          size: auto 28px;
          repeat: no-repeat;
        }
        width: 28px;
        height: 28px;
        display: inline-block;
      }
    }

    &:hover {
      opacity: 0.4;
    }
  }

  .btn-reservation-prev {
    border: 1px solid $color-navy-1;
    background-color: $color-white-1;
    border-color: $color-navy-1-op2;
    color: $color-navy-1-op2;
    padding: 8px 20px 8px 8px;
    cursor: default;
    span {
      padding-left: 28px;
      &:before {
        background-position: 0 0;
        left: 0;
      }
    }

    &:hover {
      opacity: 1;
    }

    &.is-active {
      cursor: pointer;
      border-color: $color-navy-1;
      color: $color-navy-1;
      span {
        &:before {
          background-position: -38px 0;
        }
      }
      &:hover {
        span {
          &:before {
            animation: rsvPrevBtnAni 0.9s 3 linear;
          }
        }
      }
    }
  }
  .btn-reservation-next {
    padding: 8px 8px 8px 20px;
    span {
      padding-right: 28px;
      &:before {
        background-position: -76px 0;
        right: 0;
      }
    }
    &:hover {
      opacity: 1;
      span {
        &:before {
          animation: rsvNextBtnAni 0.9s 3 linear;
        }
      }
    }
  }
}

@keyframes rsvNextBtnAni {
  from {
    right: 0;
  }
  50% {
    right: -3px;
  }
  to {
    right: 0;
  }
}

@keyframes rsvPrevBtnAni {
  from {
    left: 0;
  }
  50% {
    left: -3px;
  }
  to {
    left: 0;
  }
}

.targets-list {
  $gap: 20px;
  display: flex;
  flex: 1 1 auto;
  flex-wrap: wrap;
  margin: {
    left: -$gap;
    right: -$gap;
  }
  > li {
    padding: $gap;
    width: 25%;
    flex: 0 0 25%;
    max-width: 25%;
  }

  .btn-chkup-type {
    @include coTxtCaption;
    color: $color-gray-3;
    margin-left: 12px;
    margin-right: 4px;
    > span {
      text-decoration: underline;
      padding-right: 20px;
      &:after {
        position: absolute;
        top: 4px;
        right: 0;
        content: '';
        background: url($image-ico-sprite01) -8px -84px / $image-ico-sprite01-size no-repeat;
        display: inline-block;
        width: 12px;
        height: 12px;
        @include hide;
      }
    }
  }
  .client-card.person {
    &.on {
      box-shadow: 0 0 0 1px $color-blue-1;
      border-color: $color-blue-1;
      position: relative;
      .relationship {
        align-items: center;
        display: flex;
        &:after {
          content: '';
          margin-left: 7px;
          margin-top: 1px;
          width: 14px;
          height: 14px;
          background: url($image-ico-path + 'ico_sprites_check.svg') -116px -7px / auto 28px no-repeat;
        }
      }
      // &:before {
      //   content: '';
      //   position: absolute;
      //   top: 37px;
      //   right: 55px;
      //   display: inline-block;
      //   width: 14px;
      //   height: 14px;
      //   background: url($image-ico-path + 'ico_sprites_check.svg') -116px -7px / auto 28px no-repeat;
      // }
    }
  }

  .card-cont {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
  }

  .card-cont {
    text-align: center;
  }

  .title-add {
    @include coMainTitle;
    color: $color-navy-1;
    vertical-align: top;

    i {
      display: inline-block;
      vertical-align: top;
      margin-top: 4px;
    }
  }

  .ref-txt {
    color: $color-gray-3;
    @include coTxtCaption;
  }
  .client-card {
    display: flex;
    justify-content: center;
    align-items: center;
    margin-top: 0;
    min-height: 164px;
    max-height: 164px;
    height: 164px;
    width: 100%;
    padding: {
      left: 20px;
      right: 20px;
    }

    .inner {
      width: 100%;
    }
    .btn-del-target {
      overflow: hidden;
      text-indent: 100%;
      white-space: nowrap;
      display: inline-block;
      position: absolute;
      top: 32px;
      right: 20px;
      width: 24px;
      height: 24px;
      background: url($image-ico-path + 'ico_sprite_del_02.svg') 0 0 /24px auto no-repeat;
      &:hover {
        background-position: 0 bottom;
      }
    }
  }

  .none-data {
    img {
      height: 68px;
      width: auto;
    }
  }
}

// + btm-compare-list-field
.btm-compare-list-field {
  display: none;
  padding-top: 20px;
}
.btm-compare-list {
  $gap: 10px;
  margin: {
    left: -$gap;
    right: -$gap;
  }
  display: flex;
  flex: 1 1 auto;
  flex-wrap: nowrap;

  > li {
    width: 25%;
    padding: {
      left: $gap;
      right: $gap;
    }
  }
}

.compare-card,
.myhistory-card {
  border: 1px solid $color-gray-5;
  border-radius: 12px;
}

.compare-card {
  padding: 15px 37px 15px 27px;
  position: relative;
  background-color: $color-white-1;
  height: 100%;

  &.is-has {
    background-color: $color-white-2;
  }

  .name {
    @include coSubTitle;
    color: $color-gray-1;
    @include ellipsisLine01;
    padding-right: 8px;
  }

  .price-info {
    @include fontStyleSmall01;
    display: flex;
    align-items: center;
    flex: 0;
    line-height: 20px;

    > em {
      padding: {
        left: 4px;
        right: 4px;
      }
      @include coBodyTxt;
      color: $color-blue-1;
    }
  }

  .btn-del-compare {
    position: absolute;
    top: 8px;
    right: 10px;
  }
}

.myhistory-card {
  padding: 11px 27px;
}

// [RV]
.rsv-institution-list-wrap {
  padding-top: 20px;
  .list-field {
    margin-top: 20px;
  }

  .rsv-institution-list {
    .card-row {
      $gap: 20px;
      margin: {
        left: -$gap;
        right: -$gap;
      }
      display: flex;
      flex-wrap: wrap;
    }
    & + .co-tbl-paging {
      margin-top: 20px;
    }
  }

  .rsv-institution-card {
    width: 25%;
    position: relative;
    border: 2px solid $color-white-1;
    .thumbnail {
      //height: 160px;
      padding-bottom: 59.259%;
      position: relative;
      overflow: hidden;
      border-radius: $radius-12;
      .img {
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        bottom: 0;
        height: inherit;
        background: {
          position: center;
          size: cover;
          repeat: no-repeat;
        }
      }
      img {
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
      }
    }

    > a {
      padding: 20px;
      display: block;
      transition: background-color 0.3s;
    }
    &:hover {
      background-color: $color-gray-12;
      border-color: $color-gray-12;
      border-radius: 12px;
    }
    &.on {
      border: 2px solid $color-blue-1;
      background-color: $color-gray-8;
      border-radius: 12px;
      &:hover {
        border-color: $color-blue-1;
        background-color: $color-gray-8;
      }
    }

    .cont {
      padding-top: 20px;
      font-size: 0;
    }
    .location {
      @include coTxtCaption;
      position: relative;
      color: $color-blue-1;
      padding-left: 17px;

      &:before {
        @include position(0, 50%);
        display: inline-block;
        width: 10px;
        height: 12px;
        background: url($image-ico-sprite01) -807px -8px / $image-ico-sprite01-size no-repeat;
        content: '';
      }
    }

    .title {
      @include coSubTitle;
      @include ellipsisLine01;
    }

    &.none-data {
      border-radius: 12px;
      background-color: $color-white-2;
      @include cardListNoneData;
    }
  }
}

.co-table-field {
  overflow-y: hidden;
  overflow-x: auto;
}

.tbl-detail-chkctg-compare {
  width: 100%;
  @include scrollType01;

  @include fontStyle(14px, 24px, 300);

  table {
    border-radius: 12px;
    thead {
      border-bottom: 1px solid $color-gray-5;
      th:last-child {
        border-right: 1px solid $color-gray-5;
      }
      .title-sub {
        font-weight: 300;
        @include ellipsisLine01;
      }
    }
    tbody {
      tr {
        &:last-child {
          td {
            &:last-child {
              border-bottom-right-radius: 12px;
            }
          }
        }
      }
      .tbl-in-cont {
        padding: 4px;
      }
    }

    th,
    td {
      padding: 8px;
    }
  }
}

.peronal-add-price {
  @include ellipsisLine01;
  font-weight: 300;
  .price {
    padding-left: 8px;
    display: inline;
    color: $color-gray-3-op4;

    em {
      padding: {
        left: 8px;
      }
      color: $color-black-op8;
    }
  }
}

.co-table {
  &.tbl-chkup-detail-compare {
    thead {
      th {
        &:last-child {
          border-right: 1px solid $table-border-color;
        }
      }
    }
    border-right: 0;
  }
}

//
.popup-del-exam-target {
  em {
    color: $color-navy-1;
    font-weight: $weight-500;
  }
}

// [RI]
.rsv-exam-result-wrap {
  .co-cont-section {
    padding-bottom: 60px;
  }
}
// + 별도 페이지 적용 가능 영역
.mychkup-history-field {
  margin-top: 12px;
  overflow: hidden;
  border-radius: 12px;
  position: relative;

  padding: {
    left: 48px;
    right: 48px;
  }

  .mychkup-history-swiper {
    overflow: hidden;
    width: auto;
  }
  .swiper {
    width: 100%;
    height: 100%;
  }

  .swiper-slide {
    width: 296px;
    display: flex;
    align-items: center;
    padding: 1px;
    & + .swiper-slide {
      margin-left: 20px;
    }
  }

  .slide-card {
    width: 100%;
    position: relative;
    border-radius: $radius-12;
    overflow: hidden;
    min-height: 234px;
  }
  .co-slide-btn-prev,
  .co-slide-btn-next {
    cursor: default;
    display: block;
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
  }

  .co-slide-btn-prev {
    left: 0;
    &:hover {
      background-position: 0 0;
    }
  }
  .co-slide-btn-next {
    right: 0;
    background-position: 0 -38px;
  }

  .mychkup-history-swiper {
    overflow: hidden;
  }

  &:before {
    content: none;
  }
  //  is-active
  &.is-active {
    &:before {
      position: absolute;
      top: 1px;
      bottom: 1px;
      right: 48px;
      content: '';
      background: linear-gradient(90deg, rgba(25, 25, 25, 0.025) 0%, rgba(217, 217, 217, 0) 38.05%);
      transform: rotate(-180deg);
      width: 96px;
      // border-radius: 14px 0 0 14px;
    }
    .co-slide-btn-prev,
    .co-slide-btn-next {
      cursor: pointer;
    }
    .co-slide-btn-prev {
      &:hover {
        background-position: 0 -76px;
      }
    }
    .co-slide-btn-next {
      &:hover {
        background-position: 0 -114px;
      }
    }
  }

  .co-slide-btn-prev {
    &.swiper-button-disabled {
      background-position-y: -152px;
      opacity: 1 !important;
      &:hover {
        background-position-y: -152px;
      }
    }
  }
  .co-slide-btn-next {
    &.swiper-button-disabled {
      background-position-y: -190px;
      opacity: 1 !important;
      &:hover {
        background-position-y: -190px;
      }
    }
  }
}

.mychkup-history-card {
  border: 1px solid $color-gray-4;
  border-radius: 12px;
  padding: 30px 20px;
  width: 100%;

  .inner {
    width: 100%;
  }
  &:not(.is-not-apply) {
    &.on {
      box-shadow: 0 0 0 1px $color-blue-1;
      border-color: $color-blue-1;

      .date {
        display: flex;
        align-items: center;
        &:after {
          content: '';
          display: inline-flex;
          margin-left: 7px;
          width: 14px;
          height: 14px;
          background: url($image-ico-chkbox-sprite01) -116px -7px / auto 28px no-repeat;
        }
      }
    }
  }

  .top-box {
    display: flex;
    align-items: center;
    justify-content: space-between;
  }
  .date {
    @include coTxtCaption;
    color: $color-blue-1;
  }

  .name {
    @include coMainTitle;
    color: $color-black-1;
  }

  .center {
    margin-top: 20px;
  }

  .name,
  .value {
    @include ellipsisLine01;
  }
}

.result-article {
  & ~ .result-article {
    margin-top: 60px;
  }

  .co-title-field {
    display: flex;
    align-items: center;
    justify-content: space-between;
    .title {
      @include coSubTitle;
    }
    & ~ .jo-progress-field {
      margin-top: 12px;
    }
  }
  .list-field,
  .cont-field {
    margin-top: 12px;
    padding: 20px;
    border: 1px solid $color-gray-5;
    border-radius: 12px;
    color: $color-gray-1;
    background-color: $color-white-2;
  }
  .cont-field {
    .cont {
      > em {
        font-weight: $weight-700;
      }
    }
  }
}

// + medical-chkup-result-list
.co-accrodion-list {
  &.medical-chkup-result-list {
  }
}

//= 검진 결과 상세 type
// + type01
.chkup-result-cont {
  &.type01 {
    > .row {
      $gap: 10px;
      margin: {
        left: -$gap;
        right: -$gap;
      }
      > [class|='col'] {
        padding: $gap;
      }
    }
    .result-card01 {
      border: 1px solid $color-gray-5;
      border-radius: 12px;
      padding: 12px 20px;
      background-color: $color-white-1;

      .title {
        @include fontStyle(14px, 30px, 300);
      }

      .col-title,
      .col-compare,
      .col-btn {
        display: flex;
        align-items: center;
      }
      [class|='col'] {
        padding: {
          left: 0;
          right: 0;
        }
      }
      .compare-info {
        justify-content: center;
        font-size: 0;
        display: flex;
        align-items: center;
        .r-value {
          @include fontStyle(16px, 32px, 400);
        }
        .unit {
          display: inline-block;
          padding-left: 4px;
          @include fontStyleSmall01;
        }

        .divide {
          padding: 5px 12px 0;
          @include coTxtSmall01;
          color: $color-gray-2;
        }
      }

      .col-compare {
        justify-content: center;
      }

      .col-btn {
        justify-content: flex-end;
      }
    }
  }

  .btn-chkup-info-detail {
    margin-top: 2px;
  }
}

.co-btn {
  &.btn-result-compare {
    padding: 2px 16px;
    min-width: 84px;
    min-height: 36px;

    > span {
      @include fontStyle(14px, 24px, 400);
    }
  }
}

// 검진 결과 비교
.tbl-chkresult-compare {
  margin-top: 20px;
}
.co-table {
  &.tbl-chkresult-compare {
    table {
      thead {
        th,
        td {
          padding: 8px;
        }
      }
      tbody {
        th,
        td {
          padding: 8px 12px;
        }
      }
      th,
      td {
        color: $color-gray-1;
      }
    }
  }
}

.exam-result-inquiry {
  .input-list-field {
    > .row {
      $gap: 16px;
      margin: {
        left: -$gap;
        right: -$gap;
      }
      > [class|='col'] {
        padding: {
          left: $gap;
          right: $gap;
        }
      }
    }
  }
}

/* RV-01-013 */
.co-subcont-field {
  & ~ .examination-date-article {
    margin-top: 60px;
  }
}

.graph-chkupresult-compare-wrap {
  .co-detail-title-field {
    display: flex;
    align-items: center;

    .title {
      @include fontStyle(16px, 32px, 400);
    }
    margin-bottom: 20px;
  }

  .co-ref-txt {
    margin-top: 20px;
  }
  // .tab-content {
  //   display: block;
  //   opacity: 0;
  // 	&.on {
  // 		opacity: 1;
  // 	}
  // }
}

.graph-chkupresult-compare-tab-wrap {
  .co-tab-list {
    > li {
      min-width: 138px;
    }
  }
  .chart-field {
    display: block;
    margin-top: 20px;
    height: 500px;
  }
  .compare-chart {
    height: 100% !important;
    min-width: 100%;
    width: 100%;
  }
}

/* RV-01-016  */
// + rsv-final-confirm-wrap

// = reservation-detail
.final-confirm-wrap {
  .left-wrap,
  .right-wrap {
    .co-sub-title-01 {
      color: $color-gray-1;
    }
  }

  .right-wrap {
    width: 428px;
    max-width: 428px;
    .c-inner {
      padding-top: 32px;
    }
  }

  > {
    .row {
      $gap: 20px;
      margin: {
        left: -$gap;
        right: -$gap;
      }
    }
  }

  .info-txt {
    margin-bottom: 40px;
  }
}

.c-input-view {
  &.type01 {
    padding: 32px;
  }
}

.final-confirm-article {
  .title-field {
    display: flex;
    align-items: center;
    justify-content: flex-end;
    justify-content: space-between;

    .price-subtitle {
      display: flex;
      justify-content: space-between;
      align-items: center;
      padding-left: 40px;
      width: 428px;
      .title {
        font-weight: 500;
        @include coBodyBtn;
      }
    }
  }
  .title {
    @include coSubTitle;
    color: $color-black-1;
  }
  > .row {
    $gap: 20px;
    margin: {
      left: -$gap;
      right: -$gap;
    }
    padding-bottom: 40px;
    > .column {
      padding: {
        left: $gap;
        right: $gap;
      }
      &.left {
        flex: 1 1 auto;
        max-width: calc(100% - 428px);
      }
      &.right {
        width: 428px;
      }
    }
  }

  .final-confirm-row {
    .input-type {
      &.view01 {
        > label {
          min-width: 82px;
        }
      }
    }

    .in-input-row {
      & ~ .in-input-row {
        margin-top: 12px;
      }
    }

    .in-input-group {
      & ~ .in-input-group {
        margin-top: 50px;
      }
    }
  }
  & ~ .final-confirm-article {
    padding-top: 40px;
    border-top: 1px solid $color-black-op1;
  }

  // + type01
  .title-field {
    margin-bottom: 16px;
  }

  // + type02 (2column)
  &.type02 {
    .right {
      margin-top: 42px;
    }
  }

  // + chkup-type-row
  .chkup-type-row {
    display: flex;
    flex: 1 1 auto;
    .label-col {
      width: 86px;
      flex: 0 0 80px;
    }
    .chkup-exp-column {
      flex: 1;
    }

    & ~ .chkup-type-row {
      margin-top: 58px;
    }
  }

  .c-input-unit {
    input[type='text'] {
      padding: {
        left: 20px;
        right: 20px;
      }
    }
    .unit {
      right: 20px;
    }
  }
  .c-input-unit-field {
    .c-input-unit {
      input[type='text'] {
        padding: {
          left: 0;
          right: 0;
        }
      }

      .unit {
        right: 0;
      }
    }
    &.type02 {
      background-color: $color-white-3;
      input[type='text'] {
        background-color: $color-white-3;
      }
    }

    .c-input-unit-field {
      margin-top: 2px;
    }
  }
  // + choice-chkup
  &.choice-chkup {
    > .final-confirm-row {
      &:not(:last-child) {
        padding-bottom: 12px;
      }
    }
  }
}

// + confirm-chk-card-list
.confirm-chk-card-list {
  > li {
    & ~ li {
      margin-top: 12px;
    }
  }

  .exam-ctg {
    .btn-chkup-info-detail {
      line-height: 1;
    }
  }
}
.confirm-chk-card {
  display: flex;
  border: 1px solid $color-gray-5;
  border-radius: 12px;
  background-color: $color-white-1;
  padding: 12px 20px;
  .title {
    color: $color-gray-3;
    padding-right: 20px;
    flex: 0 0 auto;
  }
  .col.list {
    flex: 1 1 auto;
  }

  .in-row {
    $gap: 10px;
    display: flex;
    flex-wrap: wrap;
    flex: 1 1 auto;

    margin: {
      left: -$gap;
      right: -$gap;
    }
    > [class|='col'] {
      padding: {
        left: $gap;
        right: $gap;
      }
    }
  }
}

.surcharges-before-chklist {
  > li {
    ~ li {
      margin-top: 12px;
    }
  }
}

/* RV-02-001 */
.status-badge {
  @include fontStyle(12px, 24px, 400);
  padding: 0 12px;
  display: inline-block;
  border-radius: 8px;
  &.is-green {
    background-color: #a4f897;
    color: #226418;
  }

  &.is-blue {
    background-color: #9aeafc;
    color: #426e78;
  }

  &.is-gray {
    background-color: #f3f3f3;
    color: #a4a4a4;
  }

  &.is-yellow {
    background-color: #eaec8b;
    color: #6c691d;
  }

  &.is-orange {
    background-color: #ffd8bb;
    color: #643b1d;
  }
}

.status-btn {
  padding: 6px 16px;
  display: inline-block;
  border-radius: 12px;
  border: {
    width: 1px;
    style: solid;
  }
  @include fontStyle(12px, 20px, 300);
  &.is-orange {
    border-color: #ff6859;
    color: #ff6859;
  }
  &.is-navy {
    border-color: $color-navy-1;
    color: $color-navy-1;
  }
}

.tbl-apply-btns {
  display: flex;
  justify-content: center;
  a,
  button {
    font-size: 0;
    display: inline-block;
  }
  a {
    & ~ button {
      margin-left: 8px;
    }
  }
}

.rsv-details-wrap {
  .rsv-details-info {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 16px;
    @media screen and (max-width: 1600px) {
      display: block;
    }

    .select-period-btns {
      .chk-btn-type {
        height: 0;
      }
    }
  }

  .cont {
    color: $color-gray-3;
  }

  .date {
    color: $color-gray-1;
  }

  .cnt {
    padding-left: 5px;
    @include fontStyle(16px, 32px, 400);
    color: $color-blue-1;
  }
  .right {
    display: block;
    &:after {
      @include after;
    }
    .co-date-period,
    .select-period-btns {
      float: left;
    }
    align-items: center;
    align-items: flex-start;
  }
}

.select-period-btns {
  display: flex;
  align-items: center;
  .btn-period {
    padding: 8px 20px;
    border-radius: 12px;
    border: 1px solid $color-gray-4;
    background-color: $color-white-1;

    margin-left: 16px;
    span {
      @include coBodyTxt;
      color: $color-gray-3;
    }

    &.is-active {
      background-color: $color-navy-1;
      color: $color-white-1;
    }
  }
}

.top-tbl-info {
  // co-rdochk-btn
  .chk-btn-type {
    position: relative;
    margin-left: 16px;
    font-size: 0;
    input[type='radio'],
    input[type='checkbox'] {
      position: absolute;
      top: 0;
      left: 0;
      right: 0;
      bottom: 0;
      opacity: 0;
      cursor: pointer;
      & + .chk-label {
        min-height: 44px;
        cursor: pointer;
        display: table;
        table-layout: fixed;
        line-height: 1.5;
        padding: 8px 20px;
        border-radius: 8px;
        border: 1px solid #d2d2d1;
        background-color: #fff;
      }

      &:checked {
        & + .chk-label {
          border-color: $color-navy-1;
          background-color: $color-navy-1;
          > span {
            color: $color-white-1;
          }
        }
      }
    }
  }
}

.healthexam-item-field {
  .title-field {
    display: flex;
    justify-content: space-between;
    margin-bottom: 12px;
    .title {
      @include coTxtCaption;
    }

    & + .healthexam-item-card {
      margin-top: 8px;
    }

    .exam-chkbox {
      .chk-label {
        span {
          font: {
            size: 12px;
            weight: 400;
          }
          line-height: 1;
          color: $color-black-op8;
        }
      }
    }
  }

  .top-ref-txt {
    @include fontStyle(12px, 20px, 300);
    color: $color-blue-1;
  }
  & + .healthexam-item-card {
    margin-top: 8px;
  }

  & ~ .healthexam-item-field {
    margin-top: 60px;
  }

  .item-ref-txt {
    margin-top: 8px;
    color: $color-gray-2;
    > p {
      & ~ p {
        margin-top: 8px;
      }
    }
  }
}

.healthexam-item-list {
  > li {
    & ~ li {
      margin-top: 8px;
    }
  }
}

/* RV-01-016-1 */
.total-surcharges-wrap {
  .total-surcharges-list {
    > li {
      margin-bottom: 12px;
    }
  }

  .price-row {
    display: flex;
    justify-content: space-between;
    align-items: center;

    .name {
      @include coBodyTxt;
    }

    .price {
      display: flex;
      align-items: center;

      em {
        color: $color-gray-1;
        padding-right: 4px;
        @include coSubTitle;
      }
      @include coBodyTxt;
      color: $color-gray-3;
    }
  }

  .btm-total-price {
    padding-top: 12px;
    border-top: 2px solid $color-gray-3;
    .name {
      @include coBodyBtn;
    }
    .price {
      @include coSubTitle;
      > em {
        color: $color-blue-1;
        @include coSubTitle;
      }
    }
  }
}

// = healthexam-item-card
.healthexam-item-card {
  @include coBodyTxt;
  padding: 12px 24px;
  border: 1px solid $color-gray-5;
  border-radius: 12px;

  .in-row {
    display: flex;
    flex-wrap: wrap;
    flex: 1 1 auto;
    &.type01 {
      justify-content: space-between;
    }
    &.type03 {
      justify-content: space-between;
      align-items: center;
      & + .type04 {
        margin-top: 10px;
      }
      .top-ref-txt {
        text-align: right;
      }
    }
    &.type04 {
      padding-left: 22px;
    }

    .col {
      padding: 0;
    }
  }

  .category {
    display: flex;
    align-items: center;
    font-size: 0;

    .chk-label {
      > span {
        padding-left: 8px;
        @include coSubTitle;
      }
    }
  }

  .c-chkbox {
    &.type02 {
      i {
        margin: {
          top: 8px;
          right: 12px;
        }
      }
    }
  }

  .chkup-info-detail {
    margin: {
      top: 2px;
      left: 4px;
    }
    font-size: 0;
  }

  .date-box,
  .date-confirm {
    display: flex;
    align-items: center;
    justify-content: flex-end;
  }
  .date-confirm {
    color: $color-gray-3;
    .date {
      @include coSubTitle;
      color: $color-gray-2;
      padding: {
        left: 12px;
        right: 4px;
      }
    }
  }

  .date-unable-txt {
    @include coSubTitle;
    color: $color-navy-1;
  }

  &.on {
    background-color: $color-gray-8;
  }
  .healthexam-inner-list {
    margin-top: 10px;
    .chk-label {
      > span {
        @include coBodyTxt;
        font-weight: 300;
        color: $color-gray-1;
      }
    }
    > li {
      & ~ li {
        margin-top: 4px;
      }
    }
  }
}

// + chkup-ctg-accordion
.chkup-ctg-accordion {
  .info-title-field {
    display: flex;
    align-items: center;

    .sub-txt {
      padding-left: 16px;
      @include fontStyleSmall01;
      .num {
        @include fontStyle(14px, 30px, 400);
        color: $color-blue-1;
        margin-right: 4px;
      }
    }

    .select-list {
      padding-left: 16px;
      ul {
        display: flex;
        align-items: center;
        > li {
          @include coBodyTxt;
          color: $color-gray-2;
          & ~ li {
            position: relative;
            padding-left: 22px;
            &:before {
              position: absolute;
              color: $color-gray-4;
              left: 8px;
              content: '/';
            }
          }
        }
      }
    }
  }

  .btn-accrodion {
    .right {
      .inner {
        justify-content: flex-end;
      }
    }
  }

  .per-surcharges {
    color: $color-gray-3;
    display: flex;
    align-items: center;
    @include coBodyTxt;

    > em {
      @include coSubTitle;
      color: $color-gray-1;
      padding: {
        left: 12px;
        right: 4px;
      }
    }
  }
}

/* RV-01-012 */
// = 추가검사
.add-health-examination-wrap {
}

.add-healthexam-item-list-wrap {
  margin-top: 20px;
}

.medical-chkup-list {
  > li {
    & ~ li {
      margin-top: 60px;
    }
  }
  .chkup-ctg-accordion {
    & ~ .co-ref-txt {
      margin-top: 20px;
    }
  }
}

// 예약 팝업
.c-layer-popup {
  &.rsv-his-confirm-popup {
    .modal-field {
      width: 290px;
      background-color: $color-white-2;
    }
    .c-title {
      @include coSubTitle;
      color: $color-navy-1;
    }
    .c-modal-header {
      padding: 20px;
    }
    .c-modal-body {
      margin: 0 20px;
      .c-modal-inner {
        padding: {
          right: 0;
          bottom: 0;
        }
      }
    }
    .c-modal-footer {
      padding: 0 20px 20px;

      .inner {
        border-top: 0;
      }
      .buttons {
        justify-content: center;
        width: 100%;
        .co-btn {
          width: 100%;
        }
      }
    }
    .c-input-list-basic > .row:not(:first-of-type) {
      padding-top: 4px;
    }
    .type-sub {
      padding-top: 20px;
      & ~ .type-sub {
        padding-top: 4px;
      }
    }
  }
}

.personal-default-info {
  .c-input-list-view {
    .input-type.view01 {
      label {
        flex-basis: 80px;
      }
    }
  }
}

// LO_01_011_2
.account-list {
  > li {
    @include fontStyle(14px, 28px, 300);
    margin-top: 20px;
    > span {
      color: $color-gray-2;
    }
  }
}

.chkup-apply-criteria-cont {
}

.depth-order-list {
  &.type01 {
    > li {
      & ~ li {
        margin-top: 32px;
      }
    }

    ol {
      margin-top: 4px;
    }
    .in-depth {
      padding-left: 16px;
    }

    .depth01 {
      > li {
        & ~ li {
          margin-top: 12px;
        }
      }
    }
  }
}

// = Scroll Customizing

//+ c-layer-popup
.c-layer-popup {
  .scrollbar-track {
    background-color: $color-white-1;
  }
  .scrollbar-thumb {
    background-color: $scrollbarColor;
    border-radius: $scrollbarRadius;
  }
}

//+ terms-scroll
.terms-scroll {
  .scrollbar-track {
    background-color: $color-white-2;
  }
  .scrollbar-thumb {
    background-color: $scrollbarColor;
    border-radius: $scrollbarRadius;
    width: 4px;
  }
}

//+ table-scroll
.co-horizontal-scroll {
  .scrollbar-track {
    background-color: $color-white-2;
  }
  .scrollbar-thumb {
    background-color: $scrollbarColor;
    border-radius: $scrollbarRadius;
  }
}

// + sidebar Scroll
#sideNav {
  .sidebar-wrap {
    .scrollbar-track {
      background-color: $color-navy-1;
    }
    .scrollbar-thumb {
      background-color: #64709c;
      border-radius: $scrollbarRadius;
    }
  }
}

// + checkup-sidebar
.checkup-sidebar {
  .inner-wrap {
    .scrollbar-track {
      background-color: $color-white-3;
    }
    .scrollbar-thumb {
      background-color: $scrollbarColor;
      border-radius: $scrollbarRadius;
    }
  }
}
// + checkup-sidebar
.institution-datail-list-article {
  .scrollbar-track {
    background-color: $color-white-2;
  }
  .scrollbar-thumb {
    background-color: $color-gray-4;
    border-radius: $scrollbarRadius;
  }
}

// +reservation-sidebar
.reservation-sidebar {
  .scrollbar-track {
    background-color: $color-white-3;
  }
  .scrollbar-thumb {
    background-color: $scrollbarColor;
    border-radius: $scrollbarRadius;
  }
}

.certify-info-cont {
  .title {
    @include coTxtCaption;
    color: $color-black-1;
  }
  .info-cont-box {
    @include coTxtCaption;
    .co-list-card {
      > li {
        @include coTxtCaption;
        color: $color-gray-2;
      }
    }
  }
}

.chkup-exp-list {
  $gap: 6px;
  display: flex;
  flex-wrap: wrap;
  margin: -$gap;

  li {
    padding: $gap;
  }
}
.btn-chkup-exp-detail {
  padding: 2px 8px;
  background-color: $color-gray-5;
  border-radius: 8px;
  transition: all 0.4s;

  > span {
    color: $color-gray-2;
    @include coTxtCaption;
  }

  &:hover {
    background-color: $color-gray-8;
    > span {
      color: $color-navy-1;
    }
  }
}

// = Terms
.co-terms-field {
  .main-title {
    @include coTxtMainTitle;
    color: $color-gray-1;
  }
}

.client-card-field {
  .top-select-box {
    margin-top: 20px;
    select {
      border-radius: 12px;
    }
  }
}

.c-chkoption-box {
  display: flex;
  flex-wrap: wrap;
  > .c-chkbtn {
    & + .c-chkbtn {
      margin-left: 12px;
    }
  }
}

// ================================================= WITH KMI
#container {
  .login-wrapper {
    &.type-withkmi {
      min-height: 900px;
      .use-info {
        padding: 20px 0;
        border-radius: 16px;
        border: 1px solid $color-gray-5;
        background-color: $color-gray-7;
        color: $color-black-op8;
        .time {
          @include coSubTitle;
          & ~ p {
            margin-top: 8px;
          }
        }
      }
      .side-area {
        width: 520px;
        min-width: 520px;
        padding: {
          left: 3vw;
          right: 3vw;
        }
        border-right: 1px solid $color-gray-5;
        display: flex;
        justify-content: center;
        align-items: center;
        position: relative;
        // display: flex;
        // flex-direction: column;
        // justify-content: space-between;
        // flex: 1 1 0;
      }

      .login-area {
        width: 100%;
        max-width: 100%;
      }
      .btm-logo {
        // position: static;
        // left: 0;
        // transform: none;
        // text-align: center;
      }
    }
    &:not(.type-withkmi) {
      .side-area {
        width: 560px;
        min-width: 560px;
        height: 100%;
        display: flex;
        align-items: center;
        border-right: 1px solid $color-gray-5;
        background-color: $color-white-1;
        padding: 197px 5vw;
        position: relative;
      }
    }

    .contents-field-02 {
      justify-content: center;
      height: calc(100% - 80px);
      width: 100%;
      overflow: hidden;
      background-color: rgba(47, 122, 211, 0.15);
      min-width: 920px;
      display: flex;
      align-items: center;
      .logo-txt {
        display: flex;
        align-items: center;

        .logo {
          line-height: 32px;
          img {
            vertical-align: middle;
          }
        }

        color: $color-pink;
        > em {
          & ~ span {
            @include coSubTitle;
            position: relative;
            padding-left: 16px;
            &:before {
              position: absolute;
              top: 12px;
              left: 9px;
              content: '';
              width: 1px;
              height: 12px;
              background-color: $color-blue-1;
            }
          }
        }
      }
      .btm-info {
        margin-top: 24px;
        @include fontStyle(18px, 24px, 300);
        color: $color-navy-1;
      }

      .login-txt {
        // padding-right: 90px;
        .c-title {
          @include fontStyle(40px, 52px, 700);
          margin-top: 4px;
          color: $color-navy-1;
        }
      }
      .login-img {
        min-height: auto;
        max-height: 100%;
        .img {
          img {
            min-width: 30.7vw;
            max-width: 30.7vw;
            max-height: 100%;
          }
        }
      }
    }
    @media screen and (min-width: 1920px) {
      .contents-field-02 {
        justify-content: space-between;
        padding: {
          left: 5.2vw;
          right: 5.2vw;
        }
        .logo {
          img {
            height: 24px;
          }
        }
        .btm-info {
          font-size: 0.9vw;
          line-height: 1.2vw;
        }
        .login-txt {
          .c-title {
            font-size: 2vw;
            line-height: 2.7vw;
          }
        }
        .logo-txt {
          > em {
            & ~ span {
              padding-left: 20px;
              font-size: 24px;
              &:before {
                top: 15px;
                left: 11px;
                width: 2px;
                height: 20px;
              }
            }
          }
        }
      }
    }
  }
}

// + 이용약관

.co-terms-area {
  .top-title {
    padding: 40px 0;
    text-align: center;
    border-bottom: 1px solid $color-gray-5;
    > h1 {
      @include coMainTitle;
    }
  }
  .terms-tab-wrap {
    padding-top: 20px;
  }
}
.terms-tab-wrap {
  .terms-tab-area {
    display: flex;
    justify-content: space-between;
    align-items: center;

    .select-box {
      width: 140px;
    }
  }

  .terms-field {
    margin-top: 45px;

    .c-terms-section {
      &:not(:first-of-type) {
        margin-top: 60px;
      }

      > h1 {
        @include coHeadTitle;
        & + h2 {
          margin-top: 20px;
        }
      }
    }

    &.personal-terms {
      .c-terms-section {
        &:not(:first-of-type) {
          margin-top: 0;
          padding-top: 20px;
        }
      }
    }
    .btm-add-terms {
      margin-top: 60px;
    }
  }
  .c-terms-section {
    > h2 {
      @include coTxtMainTitle;
      color: $color-gray-1;
    }
    > h3 {
      margin-top: 20px;
      @include fontStyle(14px, 28px, 500);
      color: $color-gray-1;
    }
    > p {
      margin-top: 4px;
      @include coBodyTxt;
    }
    p,
    ol,
    ul {
      @include coBodyTxt;
      color: $color-gray-2;
    }
    .co-list-card {
      &.number01 {
        > li {
          padding-left: 22px;
          &:before {
            top: 8px;
            width: 14px;
            height: 14px;
            font-size: 11px;
            line-height: 1.3;
          }
        }
      }
      &.number02 {
        > li {
          &:before {
            top: 8px;
          }
        }
      }
    }

    .per-cont {
      & ~ .per-cont {
        margin-top: 20px;
      }
    }
  }

  .terms-ord-list {
    li {
      position: relative;
      list-style: none;
      padding-left: 19px;
      @include coBodyTxt;
      span {
        &:first-child {
          position: absolute;
          top: 0;
          left: 0;
        }
      }
    }
  }
}

// 개인정보처리방침
.c-terms-section {
  .co-list-card {
    &.number01 {
      .table-wrap {
        margin: {
          top: 20px;
          left: -20px;
          bottom: 20px;
        }
      }
    }
  }

  .c-terms-link {
    @include coBodyTxt;
    line-height: 1.4;
    color: $color-navy-1;
    border-bottom: 1px solid $color-navy-1;
  }
}

.policy-list {
  margin-top: 20px;
  @include coBodyTxt;
  > li {
    & ~ li {
      margin-top: 4px;
    }

    > a {
      position: relative;
      &:before {
        content: '';
        border-bottom: 1px solid $color-navy-1;
      }
      color: $color-navy-1;
      border-bottom: 1px solid $color-navy-1;
    }
  }
  &.co-list-card {
    > li {
      padding-right: 18px;
      &:before {
        border-bottom: 1px solid $color-navy-1;
        line-height: 1.2;
        left: 2px;
        padding-right: 20px;
      }
    }
  }
}

.terms-field {
  table {
    width: max-content;

    &.terms-table-1,
    &.terms-table-2,
    &.terms-table-3 {
      width: 100%;
      text-align: center;
      border-top: 1px solid $color-gray-5;
      border-bottom: 1px solid $color-gray-5;

      thead {
        th {
          text-align: center;
          border-bottom: 1px solid $color-gray-5;
          @include fontStyle(14px, 28px, 700);
          vertical-align: middle;
          padding: 8px;
          color: $color-gray-1;
        }
      }
      tbody {
        border-top: 1px solid $color-gray-6;
        th {
          font-weight: 300;
        }
      }
      th,
      td {
        word-break: keep-all;
        vertical-align: middle;
        padding: 8px;
        border-bottom: 1px solid $color-gray-5;
      }
    }

    &.terms-table-1 {
      table-layout: fixed;
      // tr {
      //   &:first-child {
      //     th {
      //       &:nth-child(1) {
      //         width: 120px;
      //       }
      //       &:nth-child(2) {
      //         width: 178px;
      //       }
      //       &:last-child {
      //         max-width: 100px;
      //         width: 100px;
      //       }
      //     }
      //   }
      //   &:nth-child(2) {
      //     th {
      //       &:nth-child(2) {
      //         width: 240px;
      //       }
      //     }
      //   }
      // }
      tr {
        tbody {
          th[rowspan] {
            vertical-align: top;
          }
        }
      }
    }
    &.terms-table-2 {
      thead tr th,
      tbody tr td {
        width: 25%;
      }
      tbody {
        td {
          text-align: left;
        }
      }
    }
    &.terms-table-3 {
      thead tr th,
      tbody tr td {
        width: 33.333%;
      }
      tbody {
        td {
          text-align: left;
        }
      }
    }
  }
  & + p {
    margin-top: 1.25rem;
  }

  &.personal-terms {
    position: relative;
    h2 {
      margin-top: 40px;
    }
  }
}

.co-list-card {
  &.number01 {
    &.person-list01 {
      counter-reset: per-listcnt 3;
      > li {
        counter-increment: per-listcnt 1;
        &:before {
          content: counter(per-listcnt);
        }
      }
    }
  }
}

// = Use Guide
.c-layer-popup {
  &.useguide-popup {
    .c-modal-inner {
      min-height: 100%;
      height: 100%;
      padding: {
        left: 20px;
        right: 20px;
      }
    }
    .modal-wrap {
      height: calc(100% - 80px);
    }
    .c-modal-body {
      height: 100%;
      overflow-y: hidden;
    }
    .modal-field {
      width: 1180px;
      height: 100%;
    }
  }
}

.useguide-tab-wrap {
  height: 100%;
  min-height: 100%;
  display: flex;
  flex-direction: column;
  .tab-cont-wrap {
    margin-top: 40px;
    flex: 1;
    overflow: hidden;
  }

  .tab-content {
    height: 100%;
  }
}

.useguide-field {
  height: 100%;
  padding-bottom: 20px;
  > .inner {
    display: flex;
    flex: 1 0 100%;
    height: 100%;
    position: relative;
  }
  .left-wrap {
    width: 260px;
    padding-right: 40px;
    border-right: 1px solid $color-gray-5;
    flex: 0 0 260px;
  }
  .right-wrap {
    position: relative;
    padding: {
      left: 40px;
      right: 20px;
    }
    @include scrollType01;
    overflow: {
      x: hidden;
      y: auto;
    }
    > .inner {
      position: relative;
    }
    // @include scrollType01;
    width: 100%;
  }
  .co-tab-list {
    flex-shrink: 0;
  }
}

.btn-guide-list {
  > li {
    > button {
      background-color: $color-white-1;
      color: $color-gray-2;
      display: block;
      padding: 8px 20px;
      @include coBodyTxt;
      color: $color-gray-2;
      border-radius: 8px;
      border: 1px solid $color-gray-5;
      width: 100%;
      text-align: left;
      @include ellipsisLine01;
    }
    > button {
      &.on {
        background-color: $color-navy-1;
        color: $color-white-1;
        border: 1px solid $color-navy-1;
      }
    }
    & ~ li {
      margin-top: 12px;
    }
  }
}

.c-guide-section {
  & ~ .c-guide-section {
    margin-top: $guide-spacing-40;
  }
  h2,
  h3,
  ol,
  ul,
  p,
  div {
    word-break: keep-all;
  }
  > h2 {
    @include coMainTitle;
    &:not(:first-of-type) {
      margin-top: $guide-spacing-60;
    }
    & + h3 {
      margin-top: $guide-spacing-20;
    }
  }

  h3 {
    margin-top: $guide-spacing-40;
    color: $color-navy-1;
    @include coSubTitle;
  }

  > p {
    & ~ img {
      margin-top: $guide-spacing-20;
    }
  }
  .guide-step-list {
    margin-top: $guide-spacing-20;
    > li {
      &:first-of-type {
        > h3 {
          margin-top: 20px;
        }
      }
    }
    h3.title {
      .step {
        color: $color-gray-3;
      }
    }
    > li {
      p ~ img {
        margin-top: $guide-spacing-20;
      }
      & ~ li {
        margin-top: $guide-spacing-40;
      }
    }
  }
}

.btn-terms-go-top {
  position: fixed;
  bottom: 40px;
  right: 40px;
  background: url($image-ico-path + '/ico_arrow_12.svg') center/22px auto no-repeat;
  width: 46px;
  height: 46px;
  background-color: $color-gray-5;
  border-radius: $radius-12;
  display: inline-block;
  opacity: 0;
  transition: opacity 0.4s;
  &.on {
    opacity: 1;
    transition: opacity 0.4s;
  }
}

//+ Temp Style
#useGuideSubTabPanel_04_04 {
  padding-bottom: 480px;
}

.offered-examination {
  > h5.title {
    @include fontStyle(14px, 28px, 700);
    color: $color-gray-1;
    padding: 8px 12px;
  }

  .offered-list {
    margin-top: 6px;
    border-top: 2px solid $color-gray-6;
    > li {
      padding: 8px 12px;
      border-bottom: 1px solid $color-gray-5;
    }
  }
}

// + 세부항목 비교
.c-layer-popup {
  &.compare {
    overflow-y: hidden;
    .c-modal-inner {
      height: 100%;
    }

    .c-modal-body {
      overflow-y: hidden;
    }
    .modal-wrap {
      padding: 0 40px;
      width: 100%;
    }
    .modal-field {
      width: 100%;
    }

    .co-table {
    }
    table {
      position: relative;
      border-radius: 12px;
      padding: 2px 0;
      // overflow: hidden;
      thead {
        position: sticky;
        top: 0;
        left: 0;
        border-radius: 10px;
        overflow: hidden;
        th {
          position: relative;
          border: 0;
          background-color: $color-gray-8;
          font-weight: 500;
          position: sticky;
          top: 0;
          outline: 1px solid $table-border-color;
          // outline-offset: -1px;
          outline-offset: -0.5px;
        }
        td {
          padding: 8px;
          border-right: 1px solid $table-border-color;
        }
      }
    }
  }
}

.tbl-campare-fix-field {
  border-radius: 10px;
  height: 100%;
  overflow-y: auto;
  padding: 0 10px 10px 10px;
  position: relative;
}

// = JO
// + Jon Stress Examination
.jobstressexam-wrap {
  .condition-field {
    > .co-no-data {
      margin-top: 12px;
    }
  }
}

.jobstress-terms-cont {
  &.no-target {
    .col-7 {
      max-width: 100%;
      flex: 0 0 100%;
    }
  }
}
.jobstress-cont-section {
  & ~ .co-btn-field {
  }
}

p {
  & ~ .jobstress-list-cont {
    margin-top: 12px;
  }
}
.jobstress-list-cont {
  > li {
    & ~ li {
      margin-top: 12px;
    }
    > p {
      padding-left: 16px;
    }
  }
}

$jo-padding: 20px;
.jo-score-row {
  display: flex;
  flex-wrap: wrap;
  margin-top: 12px;
  margin: {
    left: -$jo-padding;
    right: -$jo-padding;
  }
  .col {
    padding: {
      left: $jo-padding;
      right: $jo-padding;
    }
  }
}

.jobstress-exam-list-wrap {
  .sub-title {
    & + .cont-01 {
      margin-bottom: 12px;
    }
  }
  .exam-field {
    padding: 24px 32px;
    border: 1px solid $color-gray-6;
    background-color: $color-white-2;
    border-radius: 8px;

    &.no-data {
      padding: 24px 32px;
      color: $color-gray-18;
    }
    & ~ .exam-field {
      margin-top: 40px;
    }
    h3.title {
      @include coHeadTitle;
      margin-bottom: 12px;
    }
    .cont {
      @include coSubTitle;
      color: $color-gray-15;
      &.is-error {
        color: $color-orange-2;
      }
    }

    .exam-article {
      & ~ .exam-article {
        margin-top: 28px;
      }

      .row {
        display: flex;
        flex: 1 1 auto;
        margin: {
          top: 8px;
          left: -20px;
          right: -20px;
        }

        .c-chkbox {
          max-width: 25%;
          flex: 0 0 25%;

          padding: 0 20px;

          input [type='checkbox'],
          input[type='radio'] {
            & + .chk-label {
              > i {
                margin-right: 8px;
              }
            }
            &:checked {
              & + .chk-label {
                box-shadow: 0 0 0 1px $color-blue-1;
                border-color: $color-blue-1;
                position: relative;

                > i {
                  &:before,
                  &:after {
                    transition: all 0s linear;
                  }
                }
                > span {
                  font-weight: 400;
                }
              }
            }
          }

          > label {
            width: 100%;
            padding: 15px 12px;
            border-radius: 12px;
            border: 1px solid $color-gray-5;
            background-color: $color-white-1;
            display: flex;
            align-items: center;
            justify-content: center;
            cursor: pointer;
          }
        }
      }
    }
  }

  .ref-cont {
    margin-top: 12px;
    > p {
      color: $color-gray-14;
      > .link {
        text-decoration: underline;
      }
    }
  }
}

.jobstress-taget-list-field {
  .targets-list {
    margin-bottom: -10px;
    li {
      flex: 0 0 50%;
      max-width: 50%;
      padding: 10px;
    }
  }
  .client-card {
    padding: 24px 20px;
    min-height: 108px;
    max-height: none;
    height: auto;
    &.person {
      &.on {
        &:before {
          width: 15px;
          height: 15px;
          // background-size: auto 20px;
          top: 26px;
          right: 20px;
        }
      }
    }
  }
}

// + job stress result

.jobstress-history-field {
  margin-top: 12px;
  overflow: hidden;
  border-radius: 12px;
  position: relative;

  padding: {
    left: 48px;
    right: 48px;
  }

  .mychkup-history-swiper {
    overflow: hidden;
    width: auto;
  }

  .swiper {
    overflow: hidden;
    width: 100%;
    height: 100%;
  }

  .swiper-slide {
    width: 296px;
    display: flex;
    align-items: center;
    padding: 1px;
    & + .swiper-slide {
      margin-left: 20px;
    }
  }

  .slide-card {
    width: 100%;
    position: relative;
    border-radius: $radius-12;
    overflow: hidden;
  }
  .co-slide-btn-prev,
  .co-slide-btn-next {
    cursor: default;
    display: block;
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
  }

  .co-slide-btn-prev {
    left: 0;
    &:hover {
      background-position: 0 0;
    }
  }
  .co-slide-btn-next {
    right: 0;
    background-position: 0 -38px;
  }

  .mychkup-history-swiper {
    overflow: hidden;
  }

  &:before {
    content: none;
  }
  //  is-active
  &.is-active {
    &:hover {
      &:before {
        position: absolute;
        top: 1px;
        bottom: 1px;
        right: 48px;
        content: '';
        background: linear-gradient(90deg, rgba(25, 25, 25, 0.025) 0%, rgba(217, 217, 217, 0) 38.05%);
        transform: rotate(-180deg);
        border-radius: 12px;
        width: 96px;
        // border-radius: 14px 0 0 14px;
      }
    }

    .co-slide-btn-prev,
    .co-slide-btn-next {
      cursor: pointer;
    }
    .co-slide-btn-prev {
      &:hover {
        background-position: 0 -76px;
      }
    }
    .co-slide-btn-next {
      &:hover {
        background-position: 0 -114px;
      }
    }
  }

  .co-slide-btn-prev {
    &.swiper-button-disabled {
      background-position-y: -152px;
      opacity: 1 !important;
      &:hover {
        background-position-y: -152px;
      }
    }
  }
  .co-slide-btn-next {
    &.swiper-button-disabled {
      background-position-y: -190px;
      opacity: 1 !important;
      &:hover {
        background-position-y: -190px;
      }
    }
  }
}

.mychkup-history-card {
  &.jobstress {
    min-height: 164px;
    padding: 32px 20px;
    .value {
      margin-top: 12px;
    }
  }
}

// + 없을 경우 표시
.co-no-data {
  &.type01 {
    padding: 30px 20px;
    border: 1px solid $color-gray-5;
    border-radius: 12px;
    border-radius: $radius-12;
    background-color: $color-white-2;
    display: flex;
    justify-content: center;
    align-items: center;

    > div {
      display: block;
      text-align: center;
    }
    .img {
      img {
        width: 63px;
      }
    }

    .co-none-txt {
      margin-top: 12px;
      @include coSubTitle;
      color: $color-gray-17;
    }
  }
}

// = [JO]
.jo-progress-bar {
  border-radius: 8px;
  position: relative;
  overflow: hidden;
  width: 100%;
  height: 32px;
  background-color: $color-gray-8;

  .mark {
    position: absolute;
    left: 0;
    right: 0;
    width: 100%;
    display: flex;
  }
  .jo-progress {
    border-radius: 8px;
    display: block;
    width: 0%;
    height: 100%;
    background: #253981;
    background-image: linear-gradient(-45deg, rgba(255, 255, 255, 0.125) 25%, transparent 25%, transparent 50%, rgba(255, 255, 255, 0.125) 50%, rgba(255, 255, 255, 0.125) 75%, transparent 75%, transparent);
    background-size: 18px 18px;
    animation: aniProgressBar 3s linear infinite;
  }
}

.jo-progress-field {
  padding: 24px 20px;
  background-color: $color-white-3;
  border-radius: 12px;
  border: 1px solid $color-gray-4;

  .result-value {
    @include coSubTitle;
    & + .progress-box {
      margin-top: 12px;
    }
  }

  .progress-box {
    position: relative;
    .mark {
      &.step01,
      &.step02 {
        height: 24px;
        margin-top: 4px;
        width: 100%;
      }
      > div {
        position: absolute;
        left: 0;
        width: 100%;
        > span {
          position: absolute;
          &:last-of-type {
            right: 0;
          }
        }
      }
      &.step01 {
        span {
          &:first-child {
            left: 0;
          }
          &:nth-child(2) {
            left: 25%;
          }
          &:nth-child(3) {
            left: 50%;
          }
          &:nth-child(4) {
            left: 75%;
          }
        }
      }

      &.step02 {
        span {
          margin-left: -30px;
          &:first-child {
            left: 25%;
          }
          &:nth-child(2) {
            left: 50%;
          }
          &:nth-child(3) {
            left: 70%;
          }
        }
      }
    }
  }
}

@keyframes aniProgressBar {
  0% {
    background-position: 0 0;
  }

  100% {
    background-position: 16px 16px;
  }
}

.ui-datepicker {
  &.hide {
    display: none !important;
  }
}

// + 검진 정보 활용 동의서
.co-consent-form-field {
  .cont-detail-body {
    margin: {
      top: 10px;
      bottom: 10px;
    }
  }
}

.resexam-agreeuse-cont {
  padding: 20px 10px 20px 20px;
  height: 600px;
  border-radius: $radius-12;
  border: 1px solid $color-gray-5;
  .inner {
    padding-right: 20px;
    @include scrollTypeSm;
    overflow-y: auto;
    max-height: 100%;
  }

  .co-list-card {
    > li {
      &:before {
        font-weight: 500;
      }
      & ~ li {
        margin-top: 20px;
      }
    }
    .title {
      @include fontStyle(15px, 30px, 500);
    }
  }

  .messrs {
    margin-top: 20px;
    text-align: center;
    @include fontStyle(15px, 30px, 500);
  }
}

.consent-personal-info {
  margin-top: $gap-4;
  text-align: center;
  @include fontStyle(15px, 30px, 400);
  .date {
    display: flex;
    align-items: center;
    justify-content: center;

    > span {
      & ~ span {
        padding-left: 4px;
      }
    }
  }

  .chk-list {
    display: flex;
    align-items: flex-end;
    justify-content: flex-end;
  }
}
